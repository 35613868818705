import Quill from 'quill';
import Vue from 'vue';
import { VueEditor } from 'vue2-editor';

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
  constructor(attrName: string, keyName: string, options: any = {}) {
    super(attrName, keyName, options);
  }

  add(node, value) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

const IndentStyle = new IndentAttributor('indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em']
});

const AlignStyle = Quill.import('attributors/style/align');
const BackgroundStyle = Quill.import('attributors/style/background');
const ColorStyle = Quill.import('attributors/style/color');
const DirectionStyle = Quill.import('attributors/style/direction');
const FontStyle = Quill.import('attributors/style/font');
const SizeStyle = Quill.import('attributors/style/size');

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);
Quill.register(IndentStyle, true);

// Vue.use(VueEditor, {name: 'vue-editor'})
Vue.component('vue-editor', VueEditor);
