import { Plugin } from '@nuxt/types';
import { Module } from '../../config';
import guardian from './guardian';

interface ACL {
  (mod: string | Module, components?: string): boolean;
}

declare module 'vue/types/vue' {
  // this.$acl inside Vue components
  interface Vue {
    $acl: ACL;
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$acl inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $acl: ACL;
  }
  // nuxtContext.$acl
  interface Context {
    $acl: ACL;
  }
}

const acl: Plugin = (context, inject) => {
  const $acl: ACL = (mod: string, components?: string) =>
    guardian(mod, null, components);

  inject('acl', $acl);
  context.$acl = $acl;
  context.app.$acl = $acl;
};

export default acl;
