import { render, staticRenderFns } from "./notification.vue?vue&type=template&id=5f1332db&scoped=true"
import script from "./notification.ts?vue&type=script&lang=ts&external"
export * from "./notification.ts?vue&type=script&lang=ts&external"
import style0 from "./notification.vue?vue&type=style&index=0&id=5f1332db&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.1.2_vue-temp_qupblwp5ngh2nkvr44gzg3hsdy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f1332db",
  null
  
)

export default component.exports