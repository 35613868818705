import Vue from 'vue';

Vue.prototype.$print = function (id: string, afterPrint?: () => any) {
  const ref = this.$refs[id].$el || this.$refs[id];
  const element = ref.cloneNode(true);
  const noprint = element.querySelectorAll('.noprint');
  const printonly = element.querySelectorAll('.printonly');
  for (let i = 0; i < noprint.length; i++) {
    noprint[i].style.display = 'none';
  }
  for (let i = 0; i < printonly.length; i++) {
    printonly[i].style.display = 'table-cell';
  }
  const WindowPrt: any = window.open(
    '',
    '_blank',
    ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'].join(',')
  );
  WindowPrt.document.write(element.innerHTML);
  setTimeout(() => {
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }, 1234);
  WindowPrt.onafterprint = function () {
    if (afterPrint) return afterPrint;
  };
};
