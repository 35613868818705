import { MutationTree } from 'vuex';
import { DateTime } from 'luxon';

/**
 * Available duration's filters
 *
 * @export
 * @enum {string}
 */
export enum DURATION {
  ALL = 'ALL',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_MONTH = 'THIS_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_14_DAYS = 'LAST_14_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  CUSTOM_RANGE = 'CUSTOM_RANGE'
}

export const DURATION_OPTIONS_MAP = {
  [DURATION.TODAY]: 'Today',
  [DURATION.YESTERDAY]: 'Yesterday',
  [DURATION.THIS_MONTH]: 'This Month',
  [DURATION.LAST_7_DAYS]: 'Last 7 Days',
  [DURATION.LAST_14_DAYS]: 'Last 14 Days',
  [DURATION.LAST_30_DAYS]: 'Last 30 Days',
  [DURATION.LAST_3_MONTHS]: 'Last 3 Months',
  [DURATION.LAST_6_MONTHS]: 'Last 6 Months',
  [DURATION.LAST_12_MONTHS]: 'Last 12 Months',
  [DURATION.ALL]: 'All The Time',
  [DURATION.CUSTOM_RANGE]: 'Custom'
};

export const state = () => ({
  query: {
    where: {
      startDate: DateTime.local().minus({ month: 1 }).startOf('day').toJSDate(),
      endDate: DateTime.local().endOf('day').toJSDate()
    }
  },
  filter: {
    duration: {
      name: DURATION_OPTIONS_MAP[DURATION.LAST_30_DAYS],
      start: DateTime.local().minus({ month: 1 }).startOf('day').toJSDate(),
      end: DateTime.local().endOf('day').toJSDate()
    }
  },
  data: {
    orderPaymentReceived: 0,
    orderWaitingForPayment: 0,
    orderPaymentExpired: 0,
    orderOnPickup: 0,
    orderConfimed: 0,
    waitingForPickup: 0,
    onPickup: 0,
    onRouteToLab: 0,
    firstFetch: false,
    totalKitActivation: 0,
    totalOngoingReports: 0,
    totalReportsReleased: 0,
    totalUsers: 0,
    totalOrder: 0,
    totalBox: 0,
    totalRevenue: 0
  },
  refetch: null
});

export type DashboardState = ReturnType<typeof state>;

export const mutations: MutationTree<DashboardState> = {
  changeFilterDuration: (
    state,
    duration: DashboardState['filter']['duration']
  ) => {
    Object.assign(state.filter.duration, duration);
    Object.assign(state.query.where, {
      startDate: duration?.start,
      endDate: duration?.end
    });
  },
  refetch: (state, refetch: string) => {
    Object.assign(state, {
      refetch
    });
  },
  changeData: (state, data: DashboardState['data']) => {
    Object.assign(state.data, data);
  }
};
