import { DateTime } from 'luxon';
import Vue from 'vue';

Vue.filter('date', function (
  value: any,
  format: string = 'FF',
  timezone: string = 'local'
) {
  let date: DateTime = DateTime.local();
  if (!value) {
    return '-';
  }
  if (typeof value === 'string') {
    date = DateTime.fromRFC2822(value);
    if (!date.isValid) {
      date = DateTime.fromISO(value);
    }
    if (!date.isValid) {
      date = DateTime.fromSQL(value);
    }
  } else if (value instanceof Date) {
    date = DateTime.fromJSDate(value);
  } else if (typeof value === 'number') {
    date = DateTime.fromMillis(value);
  } else if (typeof value === 'object') {
    date = DateTime.fromJSDate(new Date(value.toString()));
  }

  date = date.setZone(timezone);
  if (!date.isValid) {
    date = date.toLocal();
  }

  // format to string
  return date.toFormat(format);
});
