import Vue from 'vue';

type TDisplay = 'code' | 'name' | 'narrowSymbol' | 'symbol';

/**
 *  Pipe for transforming currency value.
 */
Vue.filter('currency', function (
  value: any,
  currencyCode = 'IDR',
  display: TDisplay = 'code',
  locale = 'id-ID'
) {
  if (isNaN(Number(value))) return '';
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: display,
    minimumFractionDigits: 0
  }).format(value);
});
