import Vue from 'vue';

type options = Parameters<typeof Intl.NumberFormat>;

Vue.filter('number', function (
  value: any,
  locale: string = 'en',
  options: options[1] & { notation: string }
) {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(value);
});
