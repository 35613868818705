import { Component, Vue } from 'nuxt-property-decorator';
import { keys, join } from 'lodash';
import { STYLES } from '../../config';

@Component
export default class ACLComponent extends Vue {
  created() {
    this.styles();
  }

  styles() {
    const themes = STYLES;
    const theme = keys(themes).map((key) => `${key}: ${themes[key]};`);

    if (theme.length) {
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      style.innerHTML = `:root {${join(theme, '')}}`;
      head.appendChild(style);
    }
  }
}
