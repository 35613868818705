import { set } from 'lodash';
import { MutationTree } from 'vuex';

export const state = () => ({
  id: null,
  form: {
    readonly: false
  },
  query: {
    where: {
      name: null,
      dnaPanelId: [],
      id: []
    },
    sort: {
      name: 'ASC'
    },
    offset: 0,
    limit: 10,
    include: ['dna-panel', 'rpx-dna-panel', 'rpx-dna-category-translation']
  },
  table: {
    footer: {
      'items-per-page-options': [10, 25, 50, 100],
      'show-current-page': true,
      'show-first-last-page': true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true
      }
    },
    filter: {
      name: null,
      // reportType: 'DNA_360',
      dnaPanelId: [],
      id: []
    },
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['name'],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true
    }
  }
});

export type SKUCategory = ReturnType<typeof state>;

export const mutations: MutationTree<SKUCategory> = {
  changeId: (state, id) => (state.id = id),
  changeForm: (state, form) => Object.assign(state.form, form),
  changeQuery: (state, query) => Object.assign(state.query, query),
  changeTableFilter: (state, filter) => {
    Object.assign(state.table, { filter });
    Object.assign(state.query.where, {
      name: filter.name,
      reportType: filter.reportType,
      dnaPanelId: filter.dnaPanelId,
      id: filter.id
    });
  },
  changeTableOptions: (state, options) => {
    Object.assign(state.table, { options });
    Object.assign(state.table.footer, { options });
    Object.assign(state.query, {
      sort: set({}, options.sortBy[0], options.sortDesc[0] ? 'DESC' : 'ASC'),
      limit: options.itemsPerPage,
      offset: (options.page - 1) * options.itemsPerPage
    });
  }
};
