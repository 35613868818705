import { MutationTree } from 'vuex';

export const state = () => ({
  id: null,
  query: {
    where: {
      ownerId: null,
      ownedByAsaren: true,
      reportType: ['SPORTGENOMIC'],
      dnaPanel: [],
      dnaCategory: [],
      dnaSubCategory: [],
      dnaTrait: [],
      dnaScoreType: [],
      reversedScore: null,
      sex: null
    },
    sort: {},
    offset: 0,
    limit: 10
  },
  table: {
    footer: {
      'items-per-page-options': [10, 25, 50, 100],
      'show-current-page': true,
      'show-first-last-page': true,
      'page-text': '{{pageStart}}',
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true
      }
    },
    filter: {
      keyword: '',
      ownerId: [],
      ownedByAsaren: true,
      reportType: ['SPORTGENOMIC'],
      dnaPanel: [],
      dnaCategory: [],
      dnaSubCategory: [],
      dnaTrait: [],
      dnaScoreType: [],
      sex: []
    },
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true
    }
  }
});

export type SportgenomicReportMapperState = ReturnType<typeof state>;

export const mutations: MutationTree<SportgenomicReportMapperState> = {
  changeId: (state, id) => (state.id = id),
  changeQuery: (state, query) => Object.assign(state.query, query),
  changeTableFilter: (state, filter) => {
    Object.assign(state.table, { filter });
    Object.assign(state.query.where, {
      keyword: filter.keyword,
      reportType: filter.reportType,
      ownerId: filter.ownerId,
      dnaPanel: filter.dnaPanel,
      dnaCategory: filter.dnaCategory,
      dnaSubCategory: filter.dnaSubCategory,
      dnaTrait: filter.dnaTrait,
      dnaScoreType: filter.dnaScoreType,
      sex: filter.sex
    });
  },
  changeTableOptions: (state, options) => {
    Object.assign(state.table, { options });
    Object.assign(state.table.footer, { options });
    Object.assign(state.query, {
      // sort: set({}, options.sortBy[0], options.sortDesc[0] ? 'DESC' : 'ASC'),
      limit: options.itemsPerPage,
      offset: (options.page - 1) * options.itemsPerPage
    });
  }
};
