import { set } from 'lodash';
import { MutationTree } from 'vuex';

export const state = () => ({
  query: {
    where: {
      id: [],
      scoringType: [],
      status: [],
      keyword: ''
    },
    sort: {},
    offset: 0,
    limit: 10
  },
  table: {
    items: [],
    footer: {
      'items-per-page-options': [10, 25, 50, 100],
      'show-current-page': true,
      'show-first-last-page': true
    },
    filter: {
      id: [],
      scoringType: [],
      status: [],
      keyword: ''
    },
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true
    }
  }
});

/**
 * Master Dictionary - Content
 */
export type MDContentState = ReturnType<typeof state>;

export const mutations: MutationTree<MDContentState> = {
  changeQuery: (state, query) => Object.assign(state.query, query),
  changeTableItems: (state, items) => Object.assign(state.table, { items }),
  changeTableFilter: (state, filter) => {
    Object.assign(state.table, { filter });
    Object.assign(state.query, {
      where: {
        id: filter.id,
        scoringType: filter.scoringType,
        status: filter.status,
        keyword: filter.keyword
      }
    });
  },
  changeTableOptions: (state, options) => {
    Object.assign(state.table, { options });
    Object.assign(state.query, {
      sort: set({}, options.sortBy[0], options.sortDesc[0] ? 'DESC' : 'ASC'),
      limit: options.itemsPerPage,
      offset: (options.page - 1) * options.itemsPerPage
    });
  }
};
