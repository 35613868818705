import Vue from 'vue';
import {
  Paper as YoastSeoPaper,
  ContentAssessor as YoastSeoContentAssessor,
  SeoAssessor as YoastSeoSeoAssessor,
  interpreters as YoastSeoInterpreters,
  string as YoastSeoString
} from 'yoastseo';

Vue.prototype.$yoastseo = function () {
  return {
    YoastSeoPaper,
    YoastSeoContentAssessor,
    YoastSeoSeoAssessor,
    YoastSeoInterpreters,
    YoastSeoString
  };
};
