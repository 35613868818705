import { MutationTree } from 'vuex';

export const state = () => ({
  id: null,
  query: {
    where: {
      status: ['APPROVED'],
      counselor: [],
      startedAt: []
    },
    sort: {
      startedAt: 'ASC'
    }
  }
});

export type AppointmentUpcomingState = ReturnType<typeof state>;

export const mutations: MutationTree<AppointmentUpcomingState> = {
  changeId: (state, id) => (state.id = id),
  changeQuery: (state, query) => Object.assign(state.query, query),
  changeQueryFilter: (state, filter) => Object.assign(state.query.where, filter)
};
