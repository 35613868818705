import Vue from 'vue';

/**
 * merge host and sourcelink
 * @exports
 * @param {string} value link
 */
export function cdn(value: any) {
  if (value) {
    const host = process.env.CDN_URL;
    if (/^\//i.test(value)) {
      return `${host}${value}`;
    }
    return `${host}/${value}`;
  }
  return '';
}

Vue.filter('cdn', cdn);
