import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { Subscription } from 'zen-observable-ts';
import countWaitingPickupQueryDoc from './countWaitingPickup.gql';
import pickupStatusUpdatedSubscriptionDoc from './pickupStatusUpdated.gql';
import newPickupRequestedSubscriptionDoc from './newPickupRequested.gql';
import {
  CountWaitingPickupConfirmationQuery,
  PickupStatusUpdatedSubscription,
  NewPickupRequestedSubscription
} from '~/operation';

/**
 * Component to view number of waiting for pickup badge
 *
 * @export
 * @class CountWaitingPickupBadgeComponent
 * @extends {Vue}
 */
@Component
export default class CountWaitingPickupBadgeComponent extends Vue {
  @Prop() inline: boolean;
  @Prop() overlap: boolean;

  loading = false;
  itemsLength = 0;
  subscriptions = [] as Array<Subscription>;

  mounted() {
    this.$fetch();
    this.subscribes();
  }

  beforeDestroy() {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  async fetch() {
    this.loading = true;
    try {
      const res = await this.$api.query<CountWaitingPickupConfirmationQuery>({
        query: countWaitingPickupQueryDoc,
        fetchPolicy: 'no-cache'
      });
      const { count } = res?.data?.Purchase?.Product?.Pickup?.findAll;

      this.itemsLength = count || 0;
    } catch (error) {
      this.$emitter.emit('TOAST', error);
    } finally {
      this.loading = false;
    }
  }

  subscribes() {
    this.subscriptions[0] = this.$api
      .subscribe<PickupStatusUpdatedSubscription>({
        query: pickupStatusUpdatedSubscriptionDoc
      })
      .subscribe(({ data }) => {
        if (data?.PurchaseProductPickupHistoryUpdated?.id) {
          this.$fetch();
          this.$emit('updated', data?.PurchaseProductPickupHistoryUpdated?.id);
        }
      });

    this.subscriptions[1] = this.$api
      .subscribe<NewPickupRequestedSubscription>({
        query: newPickupRequestedSubscriptionDoc
      })
      .subscribe(({ data }) => {
        if (data?.PurchaseProductPickUpRequested?.id) {
          this.$fetch();
          this.$emit('updated', data?.PurchaseProductPickUpRequested?.id);
        }
      });
  }
}
