import { Plugin } from '@nuxt/types';
import { flattenDeep, map } from 'lodash';
import guardian from '../acl/guardian';

interface Access {
  (mod: string, user?: any): boolean;
}

declare module 'vue/types/vue' {
  // this.$access inside Vue components
  interface Vue {
    $access: Access;
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$access inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $access: Access;
  }
  // nuxtContext.$access
  interface Context {
    $access: Access;
  }
}

const access: Plugin = (context, inject) => {
  const $access: Access = (mod: string, user?: any) => {
    user =
      user || context.$auth.user || context.$auth.$storage.getUniversal('user');

    if (user) {
      if (user.role === 'MASTER') return guardian(mod);
      return guardian(
        mod,
        flattenDeep(map(user.access, (a) => map(a.modules, (m) => m)))
      );
    }
    return false;
  };

  inject('access', $access);
  context.$access = $access;
  context.app.$access = $access;
};

export default access;
