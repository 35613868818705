import { MutationTree } from 'vuex';

export const state = () => ({
  sku: null,
  page: null
});

export type SKUState = ReturnType<typeof state>;

export const mutations: MutationTree<SKUState> = {
  changeSKU: (state, sku) => (state.sku = sku),
  changePage: (state, page) => (state.page = page)
};
