import { Component, Vue } from 'nuxt-property-decorator';
import { gql } from 'graphql-tag';
import { get, isObject, some } from 'lodash';
import modules from '../config';

import ACLComponent from '~/components/acl/index.vue';
import ProfileComponent from '~/components/common/profile/index.vue';
import NavigationComponent from '~/components/common/navigation/index.vue';
import NotificationComponent from '~/components/common/notification/notification.vue';

@Component<Default>({
  components: {
    ACLComponent,
    ProfileComponent,
    NavigationComponent,
    NotificationComponent
  }
})
export default class Default extends Vue {
  clipped = false;
  drawer = true;
  fixed = false;
  miniVariant = false;
  right = true;
  rightDrawer = false;
  title = 'Asa Rén';
  snackbar = {
    show: false,
    text: ''
  };

  get loggedIn() {
    return this.$store.state?.auth?.loggedIn;
  }

  get user(): any {
    return this.$auth.$storage.getUniversal('user') || this.$auth.user;
  }

  mounted() {
    if (this.$auth.loggedIn) {
      this.fetchProfile();
    }

    this.$emitter.on('TOAST', (message: any) => {
      const text = this.$replaceGrapQLError(message);
      this.snackbar.text = text;
      this.snackbar.show = true;
    });
  }

  async fetchProfile() {
    try {
      const query = gql`
        query User {
          Admin {
            User {
              profile {
                id
                type
                role
                status
                username
                access {
                  id
                  role
                  modules
                }
              }
            }
          }
        }
      `;

      const { data } = await this.$api.query({ query });
      const profile = data?.Admin?.User?.profile || {};
      if (profile?.status === 'ACTIVE') {
        this.$auth.setUser(profile);
        this.$auth.$storage.setUniversal('user', profile);
        this.authorize();
      } else {
        this.$router.push('/logout');
      }
    } catch (error) {
      //
    }
  }

  authorize() {
    let access = this.$access('');

    let path: string = this.$route.path;

    // startof - trimming params from route.path
    // example:
    // --original: /360/score/edit/${e7174dc5-9164-44aa-b7e2-bb802e1e775c -> PARAMS_ID}
    // --trimed: /360/score/edit
    if (isObject(this.$route.params)) {
      for (const paramKey in this.$route.params) {
        const paramValue = this.$route.params[paramKey];
        path = path.replace('/' + paramValue, '');
      }
    }
    // endof - trimming params from route.path

    const whitelist = ['/', '/login', '/logout'];

    if (whitelist.some((v) => v === path)) access = true;
    else {
      for (const key in modules) {
        const value = modules[key];
        if (value.href === path) {
          access = this.$access(key, this.user);
          break;
        }

        for (const page of value.pages || []) {
          if (page.href === path) {
            if (some(page.roles || [], (o) => o === 'EDITOR')) {
              access =
                this.$access(key, this.user) &&
                this.$editor(get(this.user, 'role'));
            } else {
              access = this.$access(key, this.user);
            }
            break;
          }
        }

        for (const subkey in value.modules || {}) {
          const subvalue = value.modules[subkey];
          if (subvalue.href === path) {
            access = this.$access([key, subkey].join('->'), this.user);
            break;
          }

          for (const page of subvalue.pages || []) {
            if (page.href === path) {
              if (some(page.roles || [], (o) => o === 'EDITOR')) {
                access =
                  this.$access([key, subkey].join('->'), this.user) &&
                  this.$editor(get(this.user, 'role'));
              } else {
                access = this.$access([key, subkey].join('->'), this.user);
              }
              break;
            }
          }

          for (const subsubkey in subvalue.modules || {}) {
            const subsubvalue = subvalue.modules[subsubkey];
            if (subsubvalue.href === path) {
              access = this.$access(
                [key, subkey, subsubkey].join('->'),
                this.user
              );
              break;
            }

            for (const page of subsubvalue.pages || []) {
              if (page.href === path) {
                if (some(page.roles || [], (o) => o === 'EDITOR')) {
                  access =
                    this.$access(
                      [key, subkey, subsubkey].join('->'),
                      this.user
                    ) && this.$editor(get(this.user, 'role'));
                } else {
                  access = this.$access(
                    [key, subkey, subsubkey].join('->'),
                    this.user
                  );
                }
                break;
              }
            }
          }
        }
      }
    }

    if (access !== true) {
      this.$nuxt.error({
        statusCode: 401,
        message: 'You are not allowed to access this page'
      });
    }
  }
}
