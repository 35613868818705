// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@5.2.7_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/.pnpm/css-loader@5.2.7_webpack@4.47.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/dna-spiral.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".left .v-card[data-v-5aa510c6]{background:none;width:100%;z-index:100}.left .v-card .v-text-field[data-v-5aa510c6]{background-color:#fff}@media(max-width:950px){.left[data-v-5aa510c6]{align-items:center;background:linear-gradient(70deg,rgba(149,184,222,0) 27.28%,#95b8de 134.45%);display:flex;height:100%;justify-content:center;width:100%}}.right[data-v-5aa510c6]{background:linear-gradient(70deg,rgba(149,184,222,0) 27.28%,#95b8de 134.45%);height:100%}.dna-content[data-v-5aa510c6],.right .dna-text[data-v-5aa510c6]{bottom:50%;font-size:32px;font-style:normal;font-weight:400;position:absolute;right:5%;top:50%}.dna-content[data-v-5aa510c6]{align-items:center;display:flex;flex-direction:column;gap:21px}.dna-content .title[data-v-5aa510c6]{font-size:32px!important;font-style:normal;font-weight:400}.dna-content img[data-v-5aa510c6]{width:90px}.dna-content-whitelabel[data-v-5aa510c6]{align-items:center;bottom:50%;display:flex;flex-direction:column;font-size:32px;font-style:normal;font-weight:400;gap:21px;position:absolute;right:5%;top:50%}.dna-content-whitelabel .title[data-v-5aa510c6]{font-size:14px!important;font-weight:500!important}.dna-content-whitelabel img[data-v-5aa510c6]{width:90px}.dna-image[data-v-5aa510c6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:100% 0;background-size:contain;bottom:0;display:block;height:100%;position:absolute;right:0;top:0;width:100%;z-index:1}@media(max-width:768px){.dna-image[data-v-5aa510c6]{background-position:50%;background-size:cover;opacity:.5}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
