import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class ProfileComponent extends Vue {
  loading = false;

  get profile() {
    return this.$store.state?.auth?.user || {};
  }
}
