import { render, staticRenderFns } from "./error.vue?vue&type=template&id=a5d63b7c&scoped=true"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=a5d63b7c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.1.2_vue-temp_qupblwp5ngh2nkvr44gzg3hsdy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5d63b7c",
  null
  
)

export default component.exports