import * as mitt from 'mitt';
import { Plugin } from '@nuxt/types';

const emitter: mitt.Emitter = mitt.default();

// const eventEmitter: Plugin = (context, inject) => {
//   // Inject $emitter in Vue, context and store.
//   inject('emitter', emitter);
//   // For Nuxt <= 2.12, also add 👇
//   context.$emitter = emitter;
// };

// export default eventEmitter;

declare module 'vue/types/vue' {
  // this.$emitter inside Vue components
  interface Vue {
    $emitter: mitt.Emitter;
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$emitter inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $emitter: mitt.Emitter;
  }
  // nuxtContext.$emitter
  interface Context {
    $emitter: mitt.Emitter;
  }
}

declare module 'vuex/types/index' {
  // this.$emitter inside Vuex stores
  interface Store<S> {
    $emitter: mitt.Emitter;
  }
}

const Emitter: Plugin = (context, inject) => {
  inject('emitter', emitter);
  context.$emitter = emitter;
};

export default Emitter;
