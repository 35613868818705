export default {
  reportTypes: [
    { id: 'DNA_PREMIUM', name: 'Premium DNA', isKitReportType: true },
    { id: 'DNA_360', name: '360 DNA', isKitReportType: true },
    { id: 'MCU', name: 'MCU', isKitReportType: false },
    { id: 'PRODIGY', name: 'Prodigy', isKitReportType: true },
    { id: 'ANCESTRY', name: 'Ancestry', isKitReportType: true },
    { id: 'NUTRIGENETIC', name: 'Nourish', isKitReportType: true },
    { id: 'SPORTGENOMIC', name: 'Sport Genomics', isKitReportType: true }
  ]
};
