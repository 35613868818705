import { Plugin } from '@nuxt/types';

interface GraphQLSpec {
  MAX_INT: number;
  MIN_INT: number;
}

interface GraphQL {
  spec: GraphQLSpec;
  validate: {
    int: (value: number) => boolean;
  };
}

// const eventEmitter: Plugin = (context, inject) => {
//   // Inject $emitter in Vue, context and store.
//   inject('emitter', emitter);
//   // For Nuxt <= 2.12, also add 👇
//   context.$emitter = emitter;
// };

// export default eventEmitter;

declare module 'vue/types/vue' {
  // this.$emitter inside Vue components
  interface Vue {
    $graphql: GraphQL;
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$emitter inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $graphql: GraphQL;
  }
  // nuxtContext.$emitter
  interface Context {
    $graphql: GraphQL;
  }
}

declare module 'vuex/types/index' {
  // this.$emitter inside Vuex stores
  interface Store<S> {
    $graphql: GraphQL;
  }
}

const Emitter: Plugin = (context, inject) => {
  const graphqlSpec = {
    MAX_INT: 2147483647,
    MIN_INT: -2147483648
  };
  const graphql = {
    spec: graphqlSpec,
    validate: {
      int: (value: number) =>
        value >= graphqlSpec.MIN_INT && value <= graphqlSpec.MAX_INT
    }
  };
  inject('graphql', graphql);
  context.$graphql = graphql;
};

export default Emitter;
