import { Component, Vue } from 'nuxt-property-decorator';
import { DateTime } from 'luxon';
import { gql } from 'graphql-tag';
import { Subscription } from 'zen-observable-ts';
import { saveAs } from 'file-saver';
import { cdn } from '@/pipes/cdn';
import { last } from 'lodash';
import getAllNotificationDoc from './notification.gql';
import getCountUnreadNotification from './unread-notification.gql';

enum SEGMENT {
  PICKUP = 'PICKUP',
  ORDER = 'ORDER',
  DNA_REPORT = 'DNA_REPORT',
  EXPORT = 'EXPORT'
}
type NotificationType =
  | 'ORDER_PAYMENT_RECEIVED'
  | 'ORDER_CREATED'
  | 'PICKUP_KIT_REQUEST';

export type Notification = {
  [key in SEGMENT]: {
    data?: Array<any>;
    count?: number;
    page?: number;
    loading?: boolean;
  };
};

@Component({})
export default class NotificationComponent extends Vue {
  countUnread: number = 0;
  newMessage = {
    export: 0,
    dnaReport: 0,
    pickup: 0,
    order: 0
  };

  dialog = {
    notification: false,
    delete: false
  };

  dataDialog = {
    delete: {
      id: null,
      index: null
    }
  };

  timeout = null as NodeJS.Timeout;

  subscriptions = [] as Array<Subscription>;

  types = {
    pickup: ['PICKUP_KIT_REQUEST'],
    order: ['ORDER_PAYMENT_RECEIVED', 'ORDER_CREATED'],
    dnaReport: [
      'DNA_REPORT_MAPPER_SUCCEED',
      'DNA_REPORT_MAPPER_PROCESSED',
      'DNA_REPORT_MAPPER_SKIPPED',
      'DNA_REPORT_MAPPER_FAILED',
      'DNA_REPORT_PDF_SUCCEED',
      'DNA_REPORT_PDF_FAILED',
      'DNA_REPORT_RESULT_NOTIFICATION',
      'DNA_REPORT_IMPORT_TRANSLATION_SUCCEED',
      'DNA_REPORT_IMPORT_TRANSLATION_UPLOAD_FAILED',
      'DNA_REPORT_IMPORT_TRANSLATION_UPLOAD_SUCCEED',
      'DNA_GENE_IMPORT_TRANSLATION_SUCCEED',
      'DNA_GENE_IMPORT_TRANSLATION_UPLOAD_FAILED',
      'DNA_GENE_IMPORT_TRANSLATION_UPLOAD_SUCCEED',
      'PRODIGY_IMPORT_UPLOAD_SUCCEED',
      'PRODIGY_IMPORT_UPLOAD_FAILED',
      'CONTENT_MANAGEMENT_IMPORT_TRAIT',
      'CONTENT_MANAGEMENT_IMPORT_TRAIT_SUCCEED',
      'CONTENT_MANAGEMENT_IMPORT_TRAIT_FAILED'
    ],
    export: {
      succeed: [
        'KIT_EXPORT_FILE_SUCCEED',
        'THREESIXTY_PANEL_EXPORT_EXCEL_SUCCEED',
        'THREESIXTY_CATEGORY_EXPORT_EXCEL_SUCCEED',
        'THREESIXTY_SUBCATEGORY_EXPORT_EXCEL_SUCCEED',
        'THREESIXTY_TRAIT_EXPORT_EXCEL_SUCCEED',
        'THREESIXTY_MAPPER_EXPORT_EXCEL_SUCCEED',
        'NUTRIGENETIC_MAPPER_EXPORT_EXCEL_SUCCEED',
        'SPORTGENOMIC_MAPPER_EXPORT_EXCEL_SUCCEED',
        `BULK_MANUAL_ACTIVATION_SUCCEED`,
        'CONTENT_MAPPER_EXPORT_EXCEL_SUCCEED'
      ],
      failed: [
        'KIT_EXPORT_FILE_FAILED',
        'THREESIXTY_PANEL_EXPORT_EXCEL_FAILED',
        'THREESIXTY_CATEGORY_EXPORT_EXCEL_FAILED',
        'THREESIXTY_SUBCATEGORY_EXPORT_EXCEL_FAILED',
        'THREESIXTY_TRAIT_EXPORT_EXCEL_FAILED',
        'THREESIXTY_MAPPER_EXPORT_EXCEL_FAILED',
        'NUTRIGENETIC_MAPPER_EXPORT_EXCEL_FAILED',
        `BULK_MANUAL_ACTIVATION_FAILED`,
        'CONTENT_MAPPER_EXPORT_EXCEL_FAILED'
      ]
    }
  };

  notification: Notification = {
    PICKUP: {
      data: [],
      loading: false,
      count: 0,
      page: 0
    },
    ORDER: {
      data: [],
      loading: false,
      count: 0,
      page: 0
    },
    DNA_REPORT: {
      data: [],
      loading: false,
      count: 0,
      page: 0
    },
    EXPORT: {
      data: [],
      loading: false,
      count: 0,
      page: 0
    }
  };

  segment = 'PICKUP';

  get showLoadMore(): boolean {
    const notification = this.notification?.[this.segment];
    const count = notification?.count;
    const itemLength = notification?.data.length;

    return count > itemLength;
  }

  get notifications() {
    return this.notification?.[this.segment].data;
  }

  get loadingData(): boolean {
    if (this.$isServer) return false;
    const notification = this.notification?.[this.segment];
    if (!notification) return false;

    return notification?.loading;
  }

  get totalNewMessage(): number {
    return Object.keys(this.newMessage).reduce((acc, key) => {
      const value = this.newMessage[key];
      return acc + value;
    }, 0);
  }

  mounted() {
    this.fetchData();
    this.subscribes();
    this.fetchUnreadMessage();
    this.changeSegment(SEGMENT.PICKUP);
  }

  beforeDestroy() {
    this.unsubscribes();
  }

  jsonParse(text: string) {
    return JSON.parse(text);
  }

  fetchData() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.fetchNotifications();
      this.fetchUnreadMessage();
    }, 200);
  }

  refetchData() {
    this.notification[this.segment] = {
      data: [],
      loading: false,
      count: 0,
      page: 0
    };
    this.fetchData();
  }

  async fetchNotifications() {
    if (!this.notification[this.segment]) return;
    const where = {};
    let page = this.notification[this.segment].page;
    const limit = 20;

    this.notification[this.segment].loading = true;
    if (this.segment === SEGMENT.PICKUP) {
      Object.assign(where, {
        type: this.types.pickup
      });
    } else if (this.segment === SEGMENT.ORDER) {
      Object.assign(where, {
        type: this.types.order
      });
    } else if (this.segment === SEGMENT.DNA_REPORT) {
      Object.assign(where, {
        type: this.types.dnaReport
      });
    } else if (this.segment === SEGMENT.EXPORT) {
      Object.assign(where, {
        type: []
          .concat(this.types.export.failed)
          .concat(this.types.export.succeed)
      });
    }

    try {
      const { data } = await this.$api.query({
        query: getAllNotificationDoc,
        variables: {
          where,
          sort: {
            createdAt: 'DESC'
          },
          limit,
          offset: page * limit
        }
      });
      const { results = [], count } =
        (data as any)?.User?.Notification?.findAll || {};
      if (count > 0) {
        if (count > this.notification[this.segment]?.data.length) {
          page += 1;
        }
      }

      this.notification[this.segment].data.push(...results);
      this.notification[this.segment].page = page;
      this.notification[this.segment].count = count;
    } catch (error) {
      this.$emitter.emit('TOAST', error);
    } finally {
      this.notification[this.segment].loading = false;
    }
  }

  loadMore() {
    this.fetchData();
  }

  openNotification(): void {
    this.dialog.notification = true;
    this.resetNewMessage();
  }

  subscribes(): void {
    const userCreated = gql`
      subscription {
        UserNotificationCreated {
          id
          to {
            name
          }
          from {
            name
          }
          href
          type
          title
          unread
          message
          createdAt
        }
      }
    `;
    const dnaReportCreated = gql`
      subscription {
        DNAReportCreated {
          id
          to {
            name
          }
          from {
            name
          }
          href
          type
          title
          unread
          message
          createdAt
        }
      }
    `;
    const kitExportFile = gql`
      subscription {
        PurchaseProductKitExportFile {
          id
          to {
            name
          }
          from {
            name
          }
          href
          type
          title
          unread
          message
          createdAt
        }
      }
    `;

    this.subscriptions = [
      this.$api.subscribe({ query: userCreated }).subscribe(({ data }) => {
        const notif = data?.UserNotificationCreated;
        if (notif?.id) {
          if (this.types.pickup.includes(notif?.type)) {
            this.newMessage.pickup += 1;
          } else if (this.types.order.includes(notif?.type)) {
            this.newMessage.order += 1;
          } else if (this.types.dnaReport.includes(notif?.type)) {
            this.newMessage.dnaReport += 1;
          }
          this.refetchData();
        }
      }),
      this.$api.subscribe({ query: dnaReportCreated }).subscribe(({ data }) => {
        if (data?.DNAReportCreated?.id) {
          this.refetchData();
        }
      }),
      this.$api.subscribe({ query: kitExportFile }).subscribe(({ data }) => {
        if (data?.PurchaseProductKitExportFile?.id) {
          this.refetchData();
          this.newMessage.export += 1;
        }
      })
    ];
  }

  unsubscribes() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  async fetchUnreadMessage(): Promise<void> {
    try {
      const { data } = await this.$api.query({
        query: getCountUnreadNotification,
        variables: {
          where: {
            unread: true,
            type: [
              ...this.types.dnaReport,
              ...this.types.order,
              ...this.types.pickup,
              ...[]
                .concat(this.types.export.succeed)
                .concat(this.types.export.failed)
            ]
          },
          limit: 0
        }
      });
      const { count } = (data as any)?.User?.Notification?.findAll || {};
      this.countUnread = count;
    } catch (error) {
      this.$emitter.emit('TOAST', error);
    }
  }

  segmentActive(type: string) {
    return this.segment === type ? 'active' : '';
  }

  changeSegment(segment: SEGMENT): void {
    this.segment = segment;
    this.resetNewMessage();
    if (!this.notifications.length) this.fetchData();
  }

  resetNewMessage(): void {
    if (this.segment === SEGMENT.EXPORT) {
      this.newMessage.export = 0;
    }
    if (this.segment === SEGMENT.ORDER) {
      this.newMessage.order = 0;
    }
    if (this.segment === SEGMENT.PICKUP) {
      this.newMessage.pickup = 0;
    }
    if (this.segment === SEGMENT.DNA_REPORT) {
      this.newMessage.dnaReport = 0;
    }
  }

  isCurrentDate(iso: string): boolean {
    const now = DateTime.local();
    const date = DateTime.fromISO(iso);

    return now.hasSame(date, 'day');
  }

  iconByType(type: NotificationType) {
    if (type === 'ORDER_CREATED') {
      return require('~/assets/icons/sent.svg');
    } else if (type === 'ORDER_PAYMENT_RECEIVED') {
      return require('~/assets/icons/money.svg');
    } else {
      return require('~/assets/icons/box.svg');
    }
  }

  async deleteItem(id: number): Promise<void> {
    const mutation = gql`
      mutation deleteNotification($id: Int!) {
        User {
          Notification {
            delete(id: $id)
          }
        }
      }
    `;
    try {
      const { data } = await this.$api.mutate({ mutation, variables: { id } });
      if (data?.User?.Notification?.delete) {
        this.$emitter.emit('TOAST', 'Notification deleted successfully');
        this.notifications.splice(this.dataDialog.delete.index, 1);
        await this.fetchData();
      }
      this.dialog.delete = false;
      this.dataDialog.delete.id = null;
      this.dataDialog.delete.index = null;
    } catch (error) {
      this.$emitter.emit('TOAST', error);
    }
  }

  async view(item = {} as any): Promise<void> {
    this.dialog.notification = false;
    if (this.types.order.includes(item?.type)) {
      const isInOrderPage = this.$route.matched.some(({ name }) => {
        return name === 'order';
      });
      if (isInOrderPage) {
        this.$emitter.emit('findOneOrderById', item?.href);
      } else {
        this.$router.push({
          path: '/order',
          query: { id: item?.href },
          replace: true
        });
      }
    } else if (this.types.pickup.includes(item?.type)) {
      const isInKitPage = this.$route.matched.some(({ name }) => {
        return name === 'kit';
      });
      if (isInKitPage) {
        this.$emitter.emit('findOneKitById', item?.href);
      } else {
        this.$router.push({
          path: '/kit',
          query: { id: item?.href },
          replace: true
        });
      }
    }

    await this.readMessage(item?.id);
    await this.fetchUnreadMessage();
    await this.fetchData();
  }

  async readMessage(id: number): Promise<void> {
    const mutation = gql`
      mutation readMessage($id: Int!, $form: UserNotificationForm) {
        User {
          Notification {
            edit(id: $id, form: $form) {
              id
              to {
                name
              }
              from {
                name
              }
              href
              type
              title
              unread
              message
              createdAt
            }
          }
        }
      }
    `;
    const variables = {
      id,
      form: { unread: false }
    };
    try {
      await this.$api.mutate({ mutation, variables });
    } catch (error) {
      this.$emitter.emit('TOAST', error);
    }
  }

  async read(id: any) {
    await this.readMessage(id);
    await this.fetchData();
  }

  confirmDelete(id: number, index: number) {
    this.dialog.delete = true;
    this.dataDialog.delete.id = id;
    this.dataDialog.delete.index = index;
  }

  getIconDNAReport(type: string) {
    let image = '';
    if (type === 'DNA_REPORT_MAPPER_PROCESSED') {
      image = 'mapper-processed.png';
    } else if (type === 'DNA_REPORT_MAPPER_SKIPPED') {
      image = 'mapper-skipped.png';
    } else if (type === 'DNA_REPORT_RESULT_NOTIFICATION') {
      image = 'dna-report-result-push-notif.png';
    } else if (
      ['DNA_REPORT_MAPPER_SUCCEED', 'DNA_REPORT_MAPPER_FAILED'].includes(type)
    ) {
      image = 'dna-report.png';
    } else if (
      ['DNA_REPORT_PDF_SUCCEED', 'DNA_REPORT_PDF_FAILED'].includes(type)
    ) {
      image = 'report-pdf.png';
    } else {
      image = 'mapper-processed.png';
    }
    return require(`../../../assets/icons/${image}`);
  }

  indicatorDNAReport(item: any) {
    const success = [
      'DNA_REPORT_MAPPER_SUCCEED',
      'DNA_REPORT_PDF_SUCCEED',
      'DNA_REPORT_RESULT_NOTIFICATION',
      'DNA_REPORT_MAPPER_PROCESSED',
      'DNA_REPORT_IMPORT_TRANSLATION_UPLOAD_SUCCEED',
      'DNA_REPORT_IMPORT_TRANSLATION_SUCCEED',
      'DNA_GENE_IMPORT_TRANSLATION_UPLOAD_SUCCEED',
      'DNA_GENE_IMPORT_TRANSLATION_SUCCEED'
    ];
    const failed = [
      'DNA_REPORT_PDF_FAILED',
      'DNA_REPORT_MAPPER_FAILED',
      'DNA_REPORT_MAPPER_SKIPPED',
      'DNA_REPORT_IMPORT_TRANSLATION_UPLOAD_FAILED',
      'DNA_GENE_IMPORT_TRANSLATION_UPLOAD_FAILED'
    ];

    if (success.includes(item.type)) {
      return 'bg-success';
    } else if (failed.includes(item.type)) {
      return 'bg-error';
    }

    return 'bg-success';
  }

  downloadFileExport(item: any): void {
    const payload = JSON.parse(item?.message);
    if (!payload?.pointer) this.$emitter.emit('TOAST', 'Invalid file pointer');

    this.$emitter.emit('TOAST', 'Downloading the file...');
    const name: string = last(payload?.pointer?.split('/'));
    saveAs(cdn(payload?.pointer), name);
  }
}
