import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import { AsarenAPIClient } from './api';

const asarenApiPlugin: Plugin = (ctx, inject) => {
  // configurations
  const apiUrl = 'https://api.asaren.ai/graphql';
  const reportUrl = 'https://report.asaren.ai';
  const subscriptionUrl = 'wss://api.asaren.ai/subscriptions';
  const cryptoGraphqlKey = 'aZpLm95LrkMJcrmmAeTmVt3uHF3jKw7A';
  const cryptoGraphqlSecret = '3bMwCQYefJE5yaYK';
  const cryptoGraphqlAlgorithm = 'AES-GCM';

  // configure clients
  const apiClient = new AsarenAPIClient({
    apiUrl,
    reportUrl,
    subscriptionUrl,
    cryptoGraphqlKey,
    cryptoGraphqlSecret,
    cryptoGraphqlAlgorithm
  });
  const client = apiClient.createApolloClient();
  // inject api client
  ctx.$api = client;
  ctx.app.$api = client;
  Vue.prototype.$api = client;

  // inject into vue hooks
  inject('$api', client);
};

export default asarenApiPlugin;
