import { Plugin } from '@nuxt/types';

interface Master {
  (role?: string): boolean;
}

declare module 'vue/types/vue' {
  // this.$master inside Vue components
  interface Vue {
    $master: Master;
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$master inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $master: Master;
  }
  // nuxtContext.$master
  interface Context {
    $master: Master;
  }
}

const master: Plugin = (context, inject) => {
  const $master: Master = (role?: string) => {
    const user = (context.$auth.user ||
      context.$auth.$storage.getUniversal('user')) as any;

    role = role || user?.role;
    if (role === 'MASTER') return true;
    return false;
  };

  inject('master', $master);
  context.$master = $master;
  context.app.$master = $master;
};

export default master;
