import { Plugin } from '@nuxt/types';

/**
 * get error message from graphl error operation
 * @param error error
 * @exports
 * @returns {string}
 */
export function replaceGraphQLErrorMessage(error: any): string {
  const message = error
    ?.toString()
    ?.replace(/Error: GraphQL error:/i, '')
    ?.replace(/error: /i, '')
    ?.trim();
  return message;
}

const replaceGraphQLError: Plugin = ({ app }, inject) => {
  inject('replaceGrapQLError', replaceGraphQLErrorMessage);
  app.$replaceGrapQLError = replaceGraphQLErrorMessage;
};

export default replaceGraphQLError;
