import Vue from 'vue';
import globalACL from '@/static/global-acl';
import { lowerCase, startCase } from 'lodash';

Vue.filter('productType', function (value: string) {
  if (typeof value !== 'string' || value.length <= 0) return '';

  const localName = globalACL.reportTypes.find((item) => item.id === value);
  if (localName) return localName.name;
  return startCase(lowerCase(value));
});
