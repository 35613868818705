import Vue from 'vue';

Vue.filter('duration', function (value: any) {
  const duration = Number(value);
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  // const seconds = Math.floor((duration % 3600) % 60);

  if (hours) return `${hours} hours ${minutes} minutes`;
  return `${minutes} minutes`;
});
