import { set } from 'lodash';
import { MutationTree } from 'vuex';

export const state = () => ({
  id: null,
  query: {
    where: {},
    sort: {
      createdAt: 'DESC'
    },
    offset: 0,
    limit: 10
  },
  table: {
    footer: {
      'items-per-page-options': [10, 25, 50, 100],
      'show-current-page': true,
      'show-first-last-page': true,
      'page-text': '{{pageStart}}',
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true
      }
    },
    filter: {
      categoryName: '',
      type: ''
    },
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: true
    }
  }
});

export type ProductCategory = ReturnType<typeof state>;

export const mutations: MutationTree<ProductCategory> = {
  changeId: (state, id) => (state.id = id),
  changeQuery: (state, query) => Object.assign(state.query, query),
  changeTableFilter: (state, filter) => {
    Object.assign(state.table, { filter });
    Object.assign(state.query.where, {
      categoryName: filter.categoryName,
      type: filter.type
    });
  },
  changeTableOptions: (state, options) => {
    Object.assign(state.table, { options });
    Object.assign(state.table.footer, { options });
    Object.assign(state.query, {
      sort: set({}, options.sortBy[0], options.sortDesc[0] ? 'DESC' : 'ASC'),
      limit: options.itemsPerPage,
      offset: (options.page - 1) * options.itemsPerPage
    });
  }
};
