import { Component, Prop, Vue } from 'vue-property-decorator';
import { decode } from 'jsonwebtoken';
import { assign, cloneDeep } from 'lodash';
import aclConfig, { Module } from '../../../config';
import { EVENT } from '~/types/event';

import CountWaitingPickupBadgeComponent from '~/components/kit/badge/index.vue';
import MasterDataProductTypeNav from '~/components/master/data/product-type/nav/index.vue';

export type Menu = Array<
  Module & { modules: Menu; id: string; active: boolean }
>;
/**
 * Component to render navigation
 *
 * @export
 * @class NavigationComponent
 * @extends {Vue}
 */
@Component({
  components: {
    CountWaitingPickupBadgeComponent,
    MasterDataProductTypeNav
  }
})
export default class NavigationComponent extends Vue {
  @Prop() shown: boolean;
  @Prop() clipped: boolean;
  @Prop() miniVariant: boolean;

  menus: Menu = [];

  get profile() {
    return this.$store.state?.auth?.user || {};
  }

  get user(): any {
    const token = this.token.replace('Bearer ', '');
    const user = decode(token) || {};
    return user;
  }

  get token(): string {
    return this.$auth.getToken('local');
  }

  /**
   * Get all available menu, by recursiving the data.
   * @param {typeof aclConfig} module Module
   * @returns {Menu}
   */
  getMenus(module: typeof aclConfig): Menu {
    const modules: Menu = [];
    for (const [id, value] of Object.entries(module)) {
      const menu = value as Menu[0];

      if (value.show) {
        // if (value.show && this.$access(id)) {
        if (Object.keys(value.modules || {}).length) {
          // access child modules.
          const submodules = this.getMenus(value.modules);
          menu.modules = submodules;
        }
        modules.push({
          ...menu,
          id,
          active: false
        });
      }
    }
    return modules;
  }

  hasChildModules(menu: Menu[0]): boolean {
    return Boolean(Object.keys(menu.modules || {}).length);
  }

  mounted() {
    this.setMenus();
    this.subscribe();
  }

  subscribe(): void {
    this.$emitter.on(EVENT.LOGIN, () => {
      this.setMenus();
    });
    this.$emitter.on(EVENT.LOGOUT, () => {
      this.menus = [];
    });
    // when user data changed, update the menus.
    this.$auth.$storage.watchState('user', () => {
      this.setMenus();
    });
  }

  unsubscribe(): void {
    this.$emitter.off(EVENT.LOGIN, () => {});
    this.$emitter.off(EVENT.LOGOUT, () => {});
  }

  hasAnIcon(menu: Menu[0]): boolean {
    return Boolean(menu?.icon);
  }

  setOtherMenuInActive(selectedMenu: Menu[0], menus: Menu): boolean {
    if (!Array.isArray(menus)) {
      return false;
    }
    for (const menu of menus.filter(
      (v) => v.active && v.id !== selectedMenu.id
    )) {
      if (menu.id !== selectedMenu.id) {
        menu.active = false;
      }
    }
  }

  listGroupClicked(menu: Menu[0], rootMenu: Menu): void {
    setTimeout(() => {
      menu.active = true;
      this.setOtherMenuInActive(menu, rootMenu);
    }, 100);
  }

  beforeDestroy() {
    this.unsubscribe();
  }

  accessible(menu: Menu): Menu {
    const items = menu
      .slice()
      .filter((value) => value.show)
      .filter((value) => {
        if (this.$access(value.id)) return true;
        if (value.modules) {
          for (const val of value.modules) {
            if (this.$access([value.id, val.id].join('->'))) {
              return true;
            }
            if (val.modules) {
              for (const v of val.modules) {
                if (this.$access([value.id, val.id, v.id].join('->'))) {
                  return true;
                }
              }
            }
          }
        }
        return false;
      })
      .map((value) => {
        if (value.modules) {
          const modules = value.modules
            .slice()
            .filter((val) => val.show)
            .filter((val) => {
              if (this.$access([value.id, val.id].join('->'))) {
                return true;
              }
              if (val.modules) {
                for (const v of val.modules) {
                  if (this.$access([value.id, val.id, v.id].join('->'))) {
                    return true;
                  }
                }
              }
              return false;
            })
            .map((val) => {
              if (val.modules) {
                const mods = val.modules
                  .slice()
                  .filter((v) => v.show)
                  .filter((v) => {
                    if (this.$access([value.id, val.id, v.id].join('->'))) {
                      return true;
                    }
                    return false;
                  });
                return assign({}, val, { modules: mods });
              }
              return val;
            });
          return assign({}, value, { modules });
        }
        return value;
      });

    return items;
  }

  setMenus(): void {
    this.menus = this.accessible(this.getMenus(cloneDeep(aclConfig)));
  }
}
