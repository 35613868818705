import { Plugin } from '@nuxt/types';
import { filter, flattenDeep, map } from 'lodash';
import guardian from '../acl/guardian';
import modules from '../../config';

interface Editor {
  (role?: string): boolean;
}

declare module 'vue/types/vue' {
  // this.$editor inside Vue components
  interface Vue {
    $editor: Editor;
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$editor inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $editor: Editor;
  }
  // nuxtContext.$editor
  interface Context {
    $editor: Editor;
  }
}

const editor: Plugin = (context, inject) => {
  const isPathMatch = (
    routePath: string,
    routeParams: string[],
    targetPath: string
  ) => {
    if (typeof targetPath !== 'string') {
      targetPath = '';
    }
    if (typeof routePath !== 'string') {
      routePath = '';
    }
    const splittedTargetPath: Array<{
      isParams: boolean;
      isPath: boolean;
      paramsKey: string;
      pathIndex: number;
      path: string;
    }> = targetPath
      .split('/')
      .filter((item) => item.trim().length > 0)
      .map((chunkPath, index) => {
        const isParams: boolean = chunkPath[0] === '_';
        return {
          isParams,
          pathIndex: index,
          paramsKey: isParams ? chunkPath.replaceAll('_', '') : '',
          isPath: isParams === false,
          path: chunkPath
        };
      });

    const splittedRoutePath = routePath
      .split('/')
      .filter((item) => item.trim().length > 0);

    let isMatch = true;
    if (splittedRoutePath.length !== splittedTargetPath.length) {
      isMatch = false;
    } else {
      const lastRouteParamsIndex = 0;
      for (let i = 0; i < splittedTargetPath.length; i++) {
        const chunkTargetPath = splittedTargetPath[i];
        const chunkRoutePath: string | null = splittedRoutePath[i];
        if (typeof chunkRoutePath === 'string') {
          if (
            chunkTargetPath.isPath === true &&
            chunkTargetPath.path === chunkRoutePath
          ) {
            continue;
          }
          if (chunkTargetPath.isParams === true && routeParams.length > 0) {
            const routeParamsKey: string | undefined | null =
              routeParams.length - 1 >= lastRouteParamsIndex
                ? routeParams[lastRouteParamsIndex]
                : null;
            if (
              typeof routeParamsKey === 'string' &&
              routeParamsKey === chunkTargetPath.paramsKey
            ) {
              continue;
            }
          }
        }
        isMatch = false;
        break;
      }
    }
    return isMatch;
  };
  const $editor: Editor = (role?: string) => {
    let user = (context.$auth.user ||
      context.$auth.$storage.getUniversal('user')) as any;

    if (!user?.id) {
      if (process.client) {
        const localStorageSession = localStorage.getItem('auth.user');
        if (typeof localStorageSession === 'string') {
          user = JSON.parse(localStorageSession);
        }
      }
    }

    const userrole = role || user?.role;

    if (userrole === 'MASTER') {
      return guardian(null, null);
    } else {
      let mod: string;
      const routePath = context.route.path;
      const routeParams: string[] = context.route.params
        ? Object.keys(context.route.params)
        : [];

      const whitelist = ['/', '/login', '/logout'];

      if (whitelist.some((v) => isPathMatch(routePath, routeParams, v)))
        mod = '';
      else {
        for (const key in modules) {
          const value = modules[key];
          // if (value.href === routePath) {
          if (isPathMatch(routePath, routeParams, value.href)) {
            mod = key;
            break;
          }

          for (const page of value.pages || []) {
            // if (page.href === routePath) {
            if (isPathMatch(routePath, routeParams, page.href)) {
              mod = key;
              break;
            }
          }

          for (const subkey in value.modules || {}) {
            const subvalue = value.modules[subkey];
            // if (subvalue.href === routePath) {
            if (isPathMatch(routePath, routeParams, subvalue.href)) {
              mod = [key, subkey].join('->');
              break;
            }

            for (const page of subvalue.pages || []) {
              // if (page.href === routePath) {
              if (isPathMatch(routePath, routeParams, page.href)) {
                mod = [key, subkey].join('->');
                break;
              }
            }

            for (const subsubkey in subvalue.modules || {}) {
              const subsubvalue = subvalue.modules[subsubkey];
              // if (subsubvalue.href === routePath) {
              if (isPathMatch(routePath, routeParams, subsubvalue.href)) {
                mod = [key, subkey, subsubkey].join('->');
                break;
              }

              for (const page of subsubvalue.pages || []) {
                // if (page.href === routePath) {
                if (isPathMatch(routePath, routeParams, page.href)) {
                  mod = [key, subkey, subsubkey].join('->');
                  break;
                }
              }
            }

            if (mod) break;
          }

          if (mod) break;
        }
      }

      const mods = flattenDeep(
        map(
          filter(user?.access, (a) => a.role === 'EDITOR'),
          (a) => map(a.modules, (m) => m)
        )
      );

      const result = guardian(mod, mods);
      return result;
    }
  };

  inject('editor', $editor);
  context.$editor = $editor;
  context.app.$editor = $editor;
};

export default editor;
