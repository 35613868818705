export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  Email: any;
  /** Time resolver */
  Time: any;
};

export type AddPurchaseProductCategoryForm = {
  categoryId?: Maybe<Array<Scalars['Int']>>;
  typeId: Scalars['String'];
};

export type AddPurchaseProductForm = {
  code?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  price: Scalars['Int'];
  serviceMethods: Array<Maybe<PurchaseProductServiceMethod>>;
  description?: Maybe<Scalars['String']>;
  /** Deprecated replaced with description */
  details?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  discount?: Maybe<PurchaseProductDiscountForm>;
  weight?: Maybe<Scalars['Float']>;
  types?: Maybe<Array<Maybe<PurchaseProductType>>>;
  imageApp?: Maybe<Scalars['Upload']>;
  imageWeb?: Maybe<Scalars['Upload']>;
  bgImage?: Maybe<Scalars['Upload']>;
  purchaseType: PurchaseProductPurchaseType;
  preOrderType?: Maybe<PurchaseProductPreorderType>;
  preOrderExpiredAt?: Maybe<Scalars['Date']>;
  preOrderFullfillmentDuration?: Maybe<Scalars['Int']>;
  preOrderFullfillmentInterval?: Maybe<
    PurchaseProductpreOrderFullfillmentInterval
  >;
  preOrderFullfillmentDate?: Maybe<Scalars['String']>;
  bundling?: Maybe<Array<Maybe<PurchaseProductBundlingFormEdit>>>;
  upsell?: Maybe<PurchaseProductUpsellFormEdit>;
  periodFrom?: Maybe<Scalars['Date']>;
  periodTo?: Maybe<Scalars['Date']>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Float']>;
  showOnShop?: Maybe<Scalars['Boolean']>;
  isFreeHomeService?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  requirement?: Maybe<PurchaseProductRequirementForm>;
  order?: Maybe<Scalars['Int']>;
};

export type AddPurchaseProductReportForm = {
  traitsId?: Maybe<Array<Scalars['Int']>>;
  typeId: Scalars['String'];
  categoryId: Scalars['String'];
  status: PurchaseProductReportStatus;
  price: Scalars['Int'];
  endDate: Scalars['Date'];
};

export type AdminMutations = {
  __typename?: 'AdminMutations';
  User?: Maybe<AdminUserMutations>;
};

export type AdminQueries = {
  __typename?: 'AdminQueries';
  User?: Maybe<AdminUserQueries>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<AdminUserType>;
  role?: Maybe<AdminUserRole>;
  access?: Maybe<Array<Maybe<AdminUserAccess>>>;
  status?: Maybe<AdminUserStatus>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AdminUserAccess = {
  __typename?: 'AdminUserAccess';
  id?: Maybe<Scalars['String']>;
  role?: Maybe<AdminUserAccessRole>;
  modules?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AdminUserAccessForm = {
  role?: Maybe<AdminUserAccessRole>;
  modules?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum AdminUserAccessRole {
  Editor = 'EDITOR',
  Viewer = 'VIEWER'
}

export type AdminUserForm = {
  role?: Maybe<AdminUserRole>;
  type?: Maybe<AdminUserType>;
  access?: Maybe<Array<Maybe<AdminUserAccessForm>>>;
  status?: Maybe<AdminUserStatus>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['Boolean']>;
};

export type AdminUserMutations = {
  __typename?: 'AdminUserMutations';
  add?: Maybe<AdminUser>;
  edit?: Maybe<AdminUser>;
};

export type AdminUserMutationsAddArgs = {
  form?: Maybe<AdminUserForm>;
};

export type AdminUserMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<AdminUserForm>;
};

export type AdminUserQueries = {
  __typename?: 'AdminUserQueries';
  findOne?: Maybe<AdminUser>;
  findAll?: Maybe<AdminUserQueryResult>;
  profile?: Maybe<AdminUser>;
};

export type AdminUserQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type AdminUserQueriesFindAllArgs = {
  sort?: Maybe<AdminUserQuerySortBy>;
  where?: Maybe<AdminUserQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AdminUserQueryFilter = {
  roles?: Maybe<Array<Maybe<AdminUserRole>>>;
  username?: Maybe<Scalars['String']>;
};

export type AdminUserQueryResult = {
  __typename?: 'AdminUserQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<AdminUser>>>;
};

export type AdminUserQuerySortBy = {
  type?: Maybe<SortDirection>;
  role?: Maybe<SortDirection>;
  username?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum AdminUserRole {
  Editor = 'EDITOR',
  Master = 'MASTER',
  Viewer = 'VIEWER',
  EditorViewer = 'EDITOR_VIEWER'
}

export enum AdminUserStatus {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE'
}

export enum AdminUserType {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  User = 'USER'
}

export type AntarAjaOrderResult = {
  __typename?: 'AntarAjaOrderResult';
  waybillNumber?: Maybe<Scalars['String']>;
  expectStart?: Maybe<Scalars['String']>;
  expectFinish?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type AntarAjaPickupResult = {
  __typename?: 'AntarAjaPickupResult';
  waybillNumber?: Maybe<Scalars['String']>;
  expectStart?: Maybe<Scalars['String']>;
  expectFinish?: Maybe<Scalars['String']>;
};

export enum AnterAjaDeliveryType {
  Order = 'ORDER',
  Pickup = 'PICKUP'
}

/** anter aja module's modules */
export type AnterAjaMutation = {
  __typename?: 'AnterAjaMutation';
  requestOrder?: Maybe<AntarAjaOrderResult>;
  requestPickup?: Maybe<AntarAjaPickupResult>;
};

/** anter aja module's modules */
export type AnterAjaMutationRequestOrderArgs = {
  orderId?: Maybe<Scalars['String']>;
  shipmentCode?: Maybe<Scalars['String']>;
};

/** anter aja module's modules */
export type AnterAjaMutationRequestPickupArgs = {
  productKitId?: Maybe<Scalars['String']>;
  shipmentCode?: Maybe<Scalars['String']>;
};

/** anter aja module's queries */
export type AnterAjaQueries = {
  __typename?: 'AnterAjaQueries';
  serviceRates?: Maybe<ServiceRateResult>;
  anterAjaCode?: Maybe<Scalars['String']>;
  tracking?: Maybe<TrackingResult>;
};

/** anter aja module's queries */
export type AnterAjaQueriesServiceRatesArgs = {
  origin?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  deliveryType: AnterAjaDeliveryType;
};

/** anter aja module's queries */
export type AnterAjaQueriesAnterAjaCodeArgs = {
  postalCode?: Maybe<Scalars['String']>;
};

/** anter aja module's queries */
export type AnterAjaQueriesTrackingArgs = {
  waybillNumber?: Maybe<Scalars['String']>;
};

/** anter aja service data structure */
export type AnterAjaServicesRate = {
  __typename?: 'AnterAjaServicesRate';
  productCode?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  etd?: Maybe<Scalars['String']>;
  rates?: Maybe<Scalars['Int']>;
};

export type AnterAjaTracking = {
  __typename?: 'AnterAjaTracking';
  hubName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<Scalars['Int']>;
  params?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type Banner = {
  __typename?: 'Banner';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  actionLink?: Maybe<Scalars['String']>;
  actionType?: Maybe<BannerActionType>;
  actionTargetId?: Maybe<Scalars['String']>;
  actionTargetType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  mobileImage?: Maybe<Scalars['String']>;
  desktopImage?: Maybe<Scalars['String']>;
  tabletImage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export enum BannerActionType {
  RedirectToUrl = 'REDIRECT_TO_URL',
  RedirectToPage = 'REDIRECT_TO_PAGE'
}

export type BannerForm = {
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  actionLink?: Maybe<Scalars['String']>;
  actionType?: Maybe<BannerActionType>;
  actionTargetId?: Maybe<Scalars['String']>;
  actionTargetType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  mobileImage: Scalars['Upload'];
  desktopImage: Scalars['Upload'];
  tabletImage: Scalars['Upload'];
};

export type BannerFormEdit = {
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  actionLink?: Maybe<Scalars['String']>;
  actionType?: Maybe<BannerActionType>;
  actionTargetId?: Maybe<Scalars['String']>;
  actionTargetType?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  mobileImage?: Maybe<Scalars['Upload']>;
  desktopImage?: Maybe<Scalars['Upload']>;
  tabletImage?: Maybe<Scalars['Upload']>;
};

export type BannerFormEditOrder = {
  id: Scalars['Int'];
  to: Scalars['Int'];
};

export type BannerMutations = {
  __typename?: 'BannerMutations';
  add?: Maybe<Banner>;
  edit?: Maybe<Banner>;
  delete?: Maybe<Scalars['Boolean']>;
  editOrder?: Maybe<Scalars['Boolean']>;
};

export type BannerMutationsAddArgs = {
  form?: Maybe<BannerForm>;
};

export type BannerMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<BannerFormEdit>;
};

export type BannerMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type BannerMutationsEditOrderArgs = {
  forms?: Maybe<Array<Maybe<BannerFormEditOrder>>>;
};

export type BannerQueries = {
  __typename?: 'BannerQueries';
  findAll?: Maybe<BannerResult>;
  findOne?: Maybe<Banner>;
  maxOrder?: Maybe<Scalars['Int']>;
  minOrder?: Maybe<Scalars['Int']>;
};

export type BannerQueriesFindAllArgs = {
  where?: Maybe<BannerQueryFilter>;
  sort?: Maybe<BannerQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type BannerQueriesFindOneArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type BannerQueryFilter = {
  id?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
};

export type BannerQuerySortBy = {
  active?: Maybe<SortDirection>;
  order?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type BannerResult = {
  __typename?: 'BannerResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Banner>>>;
};

export type BioinformaticCategory = {
  __typename?: 'BioinformaticCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  panel?: Maybe<BioinformaticPanel>;
};

export type BioinformaticCategoryQueries = {
  __typename?: 'BioinformaticCategoryQueries';
  findAll?: Maybe<BioinformaticCategoryQueryResult>;
  findOne?: Maybe<BioinformaticCategory>;
};

export type BioinformaticCategoryQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type BioinformaticCategoryQueryResult = {
  __typename?: 'BioinformaticCategoryQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticGene = {
  __typename?: 'BioinformaticGene';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type BioinformaticGeneQueries = {
  __typename?: 'BioinformaticGeneQueries';
  findAll?: Maybe<BioinformaticGeneQueryResult>;
  findOne?: Maybe<BioinformaticGene>;
};

export type BioinformaticGeneQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type BioinformaticGeneQueryResult = {
  __typename?: 'BioinformaticGeneQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticGene>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticMapper = {
  __typename?: 'BioinformaticMapper';
  id?: Maybe<Scalars['String']>;
  bioinformaticTrait?: Maybe<Scalars['String']>;
  bioinformaticPanel?: Maybe<Scalars['String']>;
  dnaPanel?: Maybe<Scalars['String']>;
  dnaCategory?: Maybe<Scalars['String']>;
  dnaSubCategory?: Maybe<Scalars['String']>;
  dnaTrait?: Maybe<Scalars['String']>;
  dnaScoreType?: Maybe<Scalars['String']>;
  reversedScore?: Maybe<Scalars['Boolean']>;
  ownerId?: Maybe<Scalars['String']>;
  sex?: Maybe<ReportMapperSex>;
};

export type BioinformaticMapperImportNutriGeneticResult = {
  __typename?: 'BioinformaticMapperImportNutriGeneticResult';
  success?: Maybe<Scalars['Boolean']>;
  errorExcelColumnKey?: Maybe<Scalars['String']>;
  errorExcelRow?: Maybe<Scalars['Int']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type BioinformaticMapperMutations = {
  __typename?: 'BioinformaticMapperMutations';
  init?: Maybe<Scalars['Boolean']>;
  /** if you want to import report mapper for asaren please set ownedByAsaren = true, then ownerId will be ignored */
  import?: Maybe<Scalars['Boolean']>;
  importThreeSixty?: Maybe<Scalars['Boolean']>;
  importNutriGenetic?: Maybe<BioinformaticMapperImportNutriGeneticResult>;
  export?: Maybe<Scalars['String']>;
  exportExcel360?: Maybe<Scalars['Boolean']>;
  exportExcelNutrigenetic?: Maybe<Scalars['Boolean']>;
};

export type BioinformaticMapperMutationsImportArgs = {
  file: Scalars['Upload'];
  ownerId?: Maybe<Scalars['String']>;
  reportType: ReportType;
  ownedByAsaren?: Maybe<Scalars['Boolean']>;
};

export type BioinformaticMapperMutationsImportThreeSixtyArgs = {
  file: Scalars['Upload'];
};

export type BioinformaticMapperMutationsImportNutriGeneticArgs = {
  file: Scalars['Upload'];
  partnershipId?: Maybe<Scalars['String']>;
  ownedByAsaren?: Maybe<Scalars['Boolean']>;
};

export type BioinformaticMapperMutationsExportArgs = {
  where?: Maybe<BioinformaticMapperQueryFilter>;
};

export type BioinformaticMapperMutationsExportExcel360Args = {
  where?: Maybe<BioinformaticMapperQueryFilter>;
  sort?: Maybe<BioinformaticMapperSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type BioinformaticMapperMutationsExportExcelNutrigeneticArgs = {
  where?: Maybe<BioinformaticMapperQueryFilter>;
  sort?: Maybe<BioinformaticMapperSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type BioinformaticMapperQueries = {
  __typename?: 'BioinformaticMapperQueries';
  findAll?: Maybe<BioinformaticMapperQueryResult>;
};

export type BioinformaticMapperQueriesFindAllArgs = {
  where?: Maybe<BioinformaticMapperQueryFilter>;
  sort?: Maybe<BioinformaticMapperSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type BioinformaticMapperQueryFilter = {
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** show mapper that belongs to Asa Ren */
  ownedByAsaren?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  reportType?: Maybe<Array<Maybe<ReportType>>>;
  bioinformaticTrait?: Maybe<Scalars['String']>;
  bioinformaticPanel?: Maybe<Scalars['String']>;
  dnaPanel?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaSubCategory?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaTrait?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaScoreType?: Maybe<Array<Maybe<Scalars['String']>>>;
  reversedScore?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  sex?: Maybe<Array<Maybe<ReportMapperSex>>>;
};

export type BioinformaticMapperQueryResult = {
  __typename?: 'BioinformaticMapperQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<BioinformaticMapper>>>;
};

export type BioinformaticMapperQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type BioinformaticMapperSortBy = {
  bioinformaticPanel?: Maybe<SortDirection>;
  bioinformaticTrait?: Maybe<SortDirection>;
  dnaPanel?: Maybe<SortDirection>;
  dnaCategory?: Maybe<SortDirection>;
  dnaSubCategory?: Maybe<SortDirection>;
  dnaTrait?: Maybe<SortDirection>;
  dnaScoreType?: Maybe<SortDirection>;
  sex?: Maybe<SortDirection>;
};

export type BioinformaticMutations = {
  __typename?: 'BioinformaticMutations';
  Mapper?: Maybe<BioinformaticMapperMutations>;
  init?: Maybe<Scalars['Boolean']>;
  syncUsers?: Maybe<Scalars['Boolean']>;
};

export type BioinformaticPanel = {
  __typename?: 'BioinformaticPanel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type BioinformaticPanelQueries = {
  __typename?: 'BioinformaticPanelQueries';
  findAll?: Maybe<BioinformaticPanelQueryResult>;
  findOne?: Maybe<BioinformaticPanel>;
};

export type BioinformaticPanelQueriesFindAllArgs = {
  where?: Maybe<BioinformaticPanelQueryFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type BioinformaticPanelQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type BioinformaticPanelQueryFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BioinformaticPanelQueryResult = {
  __typename?: 'BioinformaticPanelQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticPanel>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticQueries = {
  __typename?: 'BioinformaticQueries';
  Category?: Maybe<BioinformaticCategoryQueries>;
  Gene?: Maybe<BioinformaticGeneQueries>;
  Panel?: Maybe<BioinformaticPanelQueries>;
  Report?: Maybe<BioinformaticReportQueries>;
  Score?: Maybe<BioinformaticScoreQueries>;
  Trait?: Maybe<BioinformaticTraitQueries>;
  User?: Maybe<BioinformaticUserQueries>;
  Mapper?: Maybe<BioinformaticMapperQueries>;
};

export type BioinformaticReport = {
  __typename?: 'BioinformaticReport';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<BioinformaticUser>;
  panel?: Maybe<BioinformaticPanel>;
  category?: Maybe<BioinformaticCategory>;
  trait?: Maybe<BioinformaticTrait>;
  traitScore?: Maybe<BioinformaticScore>;
  score?: Maybe<Scalars['Float']>;
  traitLogo?: Maybe<Scalars['String']>;
  genes?: Maybe<Array<Maybe<BioinformaticGene>>>;
  genotypes?: Maybe<Array<Maybe<BioinformaticReportGenoType>>>;
  dnaTrait?: Maybe<DnaTrait>;
  dnaCategory?: Maybe<DnaCategory>;
  dnaPanel?: Maybe<DnaPanel>;
  dnaSubCategory?: Maybe<DnaSubCategory>;
  dnaScoreTypeGuideline?: Maybe<DnaScoreTypeGuideline>;
  dnaScoreType?: Maybe<DnaScoreType>;
  riskLevel?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageCode>;
  customFilter?: Maybe<Array<Maybe<BioinformaticReportCustomFilter>>>;
};

export type BioinformaticReportByCategory = {
  __typename?: 'BioinformaticReportByCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
  reports?: Maybe<Array<Maybe<BioinformaticReport>>>;
  thumbnail?: Maybe<Scalars['String']>;
  dnaCategory?: Maybe<DnaCategory>;
};

export type BioinformaticReportByCategoryFilter = {
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<PurchaseProductType>;
};

export type BioinformaticReportByCategoryQueryResult = {
  __typename?: 'BioinformaticReportByCategoryQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticReportByCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticReportByCategorySortBy = {
  name?: Maybe<SortDirection>;
  average?: Maybe<SortDirection>;
};

export type BioinformaticReportByPanel = {
  __typename?: 'BioinformaticReportByPanel';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
  reports?: Maybe<Array<Maybe<BioinformaticReport>>>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type BioinformaticReportByPanelFilter = {
  name?: Maybe<Scalars['String']>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<PurchaseProductType>;
};

export type BioinformaticReportByPanelQueryResult = {
  __typename?: 'BioinformaticReportByPanelQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticReportByPanel>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticReportByPanelSortBy = {
  name?: Maybe<SortDirection>;
  average?: Maybe<SortDirection>;
};

export type BioinformaticReportCustomFilter = {
  __typename?: 'BioinformaticReportCustomFilter';
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type BioinformaticReportFilter = {
  panels?: Maybe<Array<Maybe<Scalars['String']>>>;
  traits?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaCategories?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dnaPanels?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaSubCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaScoreTypeGuidelines?: Maybe<Array<Maybe<Scalars['String']>>>;
  riskLevel?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<PurchaseProductType>;
  score?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type BioinformaticReportGenoType = {
  __typename?: 'BioinformaticReportGenoType';
  gene?: Maybe<BioinformaticGene>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BioinformaticReportQueries = {
  __typename?: 'BioinformaticReportQueries';
  findAll?: Maybe<BioinformaticReportQueryResult>;
  findOne?: Maybe<BioinformaticReport>;
  findByPanel?: Maybe<BioinformaticReportByPanelQueryResult>;
  findByCategory?: Maybe<BioinformaticReportByCategoryQueryResult>;
};

export type BioinformaticReportQueriesFindAllArgs = {
  where?: Maybe<BioinformaticReportFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<BioinformaticReportQuerySortBy>;
};

export type BioinformaticReportQueriesFindOneArgs = {
  id: Scalars['String'];
  reportType?: Maybe<PurchaseProductType>;
  profile?: Maybe<Scalars['String']>;
};

export type BioinformaticReportQueriesFindByPanelArgs = {
  where?: Maybe<BioinformaticReportByPanelFilter>;
  sort?: Maybe<BioinformaticReportByPanelSortBy>;
};

export type BioinformaticReportQueriesFindByCategoryArgs = {
  where?: Maybe<BioinformaticReportByCategoryFilter>;
  sort?: Maybe<BioinformaticReportByCategorySortBy>;
};

export type BioinformaticReportQueryResult = {
  __typename?: 'BioinformaticReportQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticReport>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticReportQuerySortBy = {
  /** sort based on subcategory.name and then trait.name */
  subCategoryAndTrait?: Maybe<SortDirection>;
  /** sort based on favourable traits placed at first if ASC, if desc it will the item unfavourable first */
  favourable?: Maybe<SortDirection>;
};

export type BioinformaticScore = {
  __typename?: 'BioinformaticScore';
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Scalars['String']>;
  dos?: Maybe<Scalars['String']>;
  donts?: Maybe<Scalars['String']>;
  trait?: Maybe<BioinformaticTrait>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type BioinformaticScoreQueries = {
  __typename?: 'BioinformaticScoreQueries';
  findAll?: Maybe<BioinformaticScoreQueryResult>;
  findOne?: Maybe<BioinformaticScore>;
};

export type BioinformaticScoreQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type BioinformaticScoreQueryResult = {
  __typename?: 'BioinformaticScoreQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticScore>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticTrait = {
  __typename?: 'BioinformaticTrait';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type BioinformaticTraitQueries = {
  __typename?: 'BioinformaticTraitQueries';
  findAll?: Maybe<BioinformaticTraitQueryResult>;
  findOne?: Maybe<BioinformaticTrait>;
};

export type BioinformaticTraitQueriesFindAllArgs = {
  where?: Maybe<BioinformaticTraitQueryFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type BioinformaticTraitQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type BioinformaticTraitQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type BioinformaticTraitQueryResult = {
  __typename?: 'BioinformaticTraitQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticTrait>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BioinformaticUser = {
  __typename?: 'BioinformaticUser';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  reports?: Maybe<BioinformaticReportQueryResult>;
};

export type BioinformaticUserReportsArgs = {
  where?: Maybe<BioinformaticReportFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type BioinformaticUserFilter = {
  profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BioinformaticUserQueries = {
  __typename?: 'BioinformaticUserQueries';
  findAll?: Maybe<BioinformaticUserQueryResult>;
  findOne?: Maybe<BioinformaticUser>;
};

export type BioinformaticUserQueriesFindAllArgs = {
  where?: Maybe<BioinformaticUserFilter>;
};

export type BioinformaticUserQueryResult = {
  __typename?: 'BioinformaticUserQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticUser>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BooleanWithMessage = {
  __typename?: 'BooleanWithMessage';
  boolean?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Card = {
  __typename?: 'Card';
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  dTrait?: Maybe<DnaTrait>;
  language?: Maybe<Scalars['String']>;
  dInteractome?: Maybe<DnaInteractome>;
};

export type CareerJobForm = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type CareerMutations = {
  __typename?: 'CareerMutations';
  submit?: Maybe<Scalars['Boolean']>;
};

export type CareerMutationsSubmitArgs = {
  form: SubmitCareerForm;
};

export type CheckAvailabilityUnlockProductQueryResult = {
  __typename?: 'CheckAvailabilityUnlockProductQueryResult';
  DNA_PREMIUM?: Maybe<Scalars['Boolean']>;
  DNA_360?: Maybe<Scalars['Boolean']>;
  ANCESTRY?: Maybe<Scalars['Boolean']>;
  PRODIGY?: Maybe<Scalars['Boolean']>;
};

export type CheckAvailableKitResult = {
  __typename?: 'CheckAvailableKitResult';
  available?: Maybe<Scalars['Boolean']>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  error?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum CheckEmailAvailabilityCode {
  /** Email already used */
  Used = 'USED',
  /** Email unused */
  Unused = 'UNUSED'
}

export type CheckEmailAvailabilityQueryFilter = {
  /** email that want to check. */
  email: Scalars['String'];
  /** for which partner role that the email want to check. */
  forPartnerRole: Scalars['String'];
  /** for which partner user type that the email want to check. */
  forPartnerUserType?: Maybe<PartnerUserType>;
};

export type CheckEmailAvailabilityResult = {
  __typename?: 'CheckEmailAvailabilityResult';
  code?: Maybe<CheckEmailAvailabilityCode>;
  message?: Maybe<Scalars['String']>;
};

export type ComplicationTrait = {
  __typename?: 'ComplicationTrait';
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId?: Maybe<Scalars['String']>;
  dnaTraitId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  dInteractome?: Maybe<DnaInteractome>;
  dTrait?: Maybe<DnaTrait>;
  /** 360 */
  bioinformaticReportId?: Maybe<Scalars['String']>;
  bReport?: Maybe<BioinformaticReport>;
};

export type Consent = {
  __typename?: 'Consent';
  id?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
  activationId?: Maybe<Scalars['String']>;
  guardianId?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
  guardian?: Maybe<ConsentGuardian>;
  attachments?: Maybe<Array<Maybe<ConsentAttachment>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ConsentAttachment = {
  __typename?: 'ConsentAttachment';
  id?: Maybe<Scalars['String']>;
  pointer?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type ConsentForm = {
  activationId?: Maybe<Scalars['String']>;
  guardianId?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
};

export type ConsentGuardian = {
  __typename?: 'ConsentGuardian';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  relationship?: Maybe<Relationship>;
  guardianProfileId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ConsentGuardianForm = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  relationship?: Maybe<Relationship>;
  guardianProfileId?: Maybe<Scalars['String']>;
};

export type ConsentGuardianMutations = {
  __typename?: 'ConsentGuardianMutations';
  add?: Maybe<ConsentGuardian>;
  edit?: Maybe<ConsentGuardian>;
};

export type ConsentGuardianMutationsAddArgs = {
  form?: Maybe<ConsentGuardianForm>;
};

export type ConsentGuardianMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<ConsentGuardianForm>;
};

export type ConsentGuardianQueries = {
  __typename?: 'ConsentGuardianQueries';
  findAll?: Maybe<ConsentGuardianQueryResult>;
  findOne?: Maybe<ConsentGuardian>;
};

export type ConsentGuardianQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type ConsentGuardianQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type ConsentGuardianQueryResult = {
  __typename?: 'ConsentGuardianQueryResult';
  results?: Maybe<Array<Maybe<ConsentGuardian>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ConsentMutations = {
  __typename?: 'ConsentMutations';
  Guardian?: Maybe<ConsentGuardianMutations>;
};

export type ConsentQueries = {
  __typename?: 'ConsentQueries';
  findOne?: Maybe<Consent>;
  findOneByActivation?: Maybe<Consent>;
  findAll?: Maybe<ConsentQueryResult>;
  Guardian?: Maybe<ConsentGuardianQueries>;
};

export type ConsentQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type ConsentQueriesFindOneByActivationArgs = {
  id: Scalars['ID'];
};

export type ConsentQueriesFindAllArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type ConsentQueryResult = {
  __typename?: 'ConsentQueryResult';
  results?: Maybe<Array<Maybe<Consent>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ConsultationIncome = {
  __typename?: 'ConsultationIncome';
  company?: Maybe<Scalars['Int']>;
  counselor?: Maybe<Scalars['Int']>;
};

export type ConsultationIncomeForm = {
  company?: Maybe<Scalars['Int']>;
  counselor?: Maybe<Scalars['Int']>;
};

export type ContentManagementImportForm = {
  file: Scalars['Upload'];
};

export type ContentManagementImportResult = {
  __typename?: 'ContentManagementImportResult';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<ContentManagementImportResultError>>>;
};

export type ContentManagementImportResultError = {
  __typename?: 'ContentManagementImportResultError';
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ContentManagementMutations = {
  __typename?: 'ContentManagementMutations';
  export?: Maybe<Scalars['Boolean']>;
  import?: Maybe<ContentManagementImportResult>;
  update?: Maybe<Trait>;
};

export type ContentManagementMutationsExportArgs = {
  where?: Maybe<TraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<TraitSortBy>;
  include?: Maybe<ContentManagementRelationship>;
};

export type ContentManagementMutationsImportArgs = {
  form: ContentManagementImportForm;
};

export type ContentManagementMutationsUpdateArgs = {
  req?: Maybe<TraitForm>;
};

export type ContentManagementQueries = {
  __typename?: 'ContentManagementQueries';
  findAll?: Maybe<TraitQueryResult>;
  findAllScoreType?: Maybe<ContentMangementScoreTypeQueryResult>;
};

export type ContentManagementQueriesFindAllArgs = {
  where?: Maybe<TraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<TraitSortBy>;
  include?: Maybe<ContentManagementRelationship>;
  count?: Maybe<Scalars['Boolean']>;
};

export type ContentManagementQueriesFindAllScoreTypeArgs = {
  count?: Maybe<Scalars['Boolean']>;
  where?: Maybe<ContentMangementScoreTypeQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<ContentMangementScoreTypeQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
};

export type ContentManagementRelationship = {
  dnaScoreType?: Maybe<Scalars['Boolean']>;
  dnaTraitTranslation?: Maybe<Scalars['Boolean']>;
  dnaTraitScore?: Maybe<Scalars['Boolean']>;
  dnaTraitScoreTranslation?: Maybe<Scalars['Boolean']>;
};

export type ContentMangementScoreType = {
  __typename?: 'ContentMangementScoreType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ContentMangementScoreTypeQueryFilter = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ContentMangementScoreTypeQueryResult = {
  __typename?: 'ContentMangementScoreTypeQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<ContentMangementScoreType>>>;
};

export type ContentMangementScoreTypeQuerySortBy = {
  name?: Maybe<SortDirection>;
};

export type CounselorNotification = {
  __typename?: 'CounselorNotification';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  seenAt?: Maybe<Scalars['Date']>;
  counselorId?: Maybe<Scalars['Int']>;
  counselorBookId?: Maybe<Scalars['Int']>;
};

export type CounselorNotificationMutations = {
  __typename?: 'CounselorNotificationMutations';
  delete?: Maybe<Scalars['Boolean']>;
};

export type CounselorNotificationQueries = {
  __typename?: 'CounselorNotificationQueries';
  findAll?: Maybe<Scalars['Boolean']>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
};

export type CountryForm = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
};

export type CountryMutations = {
  __typename?: 'CountryMutations';
  add?: Maybe<Country>;
  edit?: Maybe<Country>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type CountryMutationsAddArgs = {
  form?: Maybe<CountryForm>;
};

export type CountryMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<CountryForm>;
};

export type CountryMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type CountryQueries = {
  __typename?: 'CountryQueries';
  findAll?: Maybe<CountryQueryResult>;
  findOne?: Maybe<Country>;
  countries?: Maybe<Array<Maybe<Place>>>;
  provinces?: Maybe<Array<Maybe<Scalars['String']>>>;
  cities?: Maybe<Array<Maybe<Scalars['String']>>>;
  districts?: Maybe<Array<Maybe<Scalars['String']>>>;
  postalCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CountryQueriesFindAllArgs = {
  where?: Maybe<CountryQueryFilter>;
  sort?: Maybe<CountryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type CountryQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type CountryQueriesCountriesArgs = {
  keyword?: Maybe<Scalars['String']>;
};

export type CountryQueriesProvincesArgs = {
  keyword?: Maybe<Scalars['String']>;
};

export type CountryQueriesCitiesArgs = {
  keyword?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
};

export type CountryQueriesDistrictsArgs = {
  keyword?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
};

export type CountryQueriesPostalCodesArgs = {
  keyword?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
};

export type CountryQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type CountryQueryResult = {
  __typename?: 'CountryQueryResult';
  results?: Maybe<Array<Maybe<Country>>>;
  count?: Maybe<Scalars['Int']>;
};

export type CountryQuerySortBy = {
  name?: Maybe<SortDirection>;
};

export type DateRangeFilter = {
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export enum Day {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type DnaAttachment = {
  __typename?: 'DNAAttachment';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DnaAttachmentType>;
  attachment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type DnaAttachmentQueries = {
  __typename?: 'DNAAttachmentQueries';
  findAll?: Maybe<DnaAttachmentQueryResult>;
};

export type DnaAttachmentQueriesFindAllArgs = {
  where?: Maybe<DnaAttachmentQueryFilter>;
  sort?: Maybe<DnaAttachmentQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DnaAttachmentQueryFilter = {
  name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<DnaAttachmentType>>>;
};

export type DnaAttachmentQueryResult = {
  __typename?: 'DNAAttachmentQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaAttachment>>>;
};

export type DnaAttachmentQuerySortBy = {
  name?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export enum DnaAttachmentType {
  DnaReportTranslation = 'DNA_REPORT_TRANSLATION',
  DnaGeneTranslation = 'DNA_GENE_TRANSLATION'
}

export type DnaCategory = {
  __typename?: 'DnaCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reportType?: Maybe<ReportType>;
  cover?: Maybe<Scalars['String']>;
  objectImage?: Maybe<Scalars['String']>;
  translation?: Maybe<DnaCategoryTranslation>;
  ownerId?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  customFilter?: Maybe<Array<Maybe<BioinformaticReportCustomFilter>>>;
  shortDescription?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Scalars['String']>;
  favourableImage?: Maybe<Scalars['String']>;
  unfavourableImage?: Maybe<Scalars['String']>;
  dnaSubCategory?: Maybe<Array<Maybe<DnaSubCategory>>>;
  dPanel?: Maybe<DnaPanel>;
};

export type DnaCategoryBaseOnReportQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  reportType: ReportType;
  clientProfileId?: Maybe<Scalars['String']>;
};

export type DnaCategoryForm = {
  id?: Maybe<Scalars['Int']>;
  reportType?: Maybe<ReportType>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  description?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['Upload']>;
  objectImage?: Maybe<Scalars['Upload']>;
  backgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  translation?: Maybe<DnaCategoryTranslationForm>;
  shortDescription?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Scalars['String']>;
  favourableImage?: Maybe<Scalars['Upload']>;
  unfavourableImage?: Maybe<Scalars['Upload']>;
};

export type DnaCategoryMutations = {
  __typename?: 'DnaCategoryMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaCategory>>>;
  update?: Maybe<DnaCategory>;
  export?: Maybe<Scalars['String']>;
  exportExcel360?: Maybe<Scalars['Boolean']>;
  /** available mutations of DNA Panel Translation modules */
  DnaSubCategory?: Maybe<DnaSubCategoryMutations>;
};

export type DnaCategoryMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaCategoryForm>>;
};

export type DnaCategoryMutationsUpdateArgs = {
  id: Scalars['Int'];
  form: DnaCategoryForm;
};

export type DnaCategoryMutationsExportArgs = {
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
};

export type DnaCategoryMutationsExportExcel360Args = {
  where?: Maybe<DnaCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaCategorySortBy>;
};

export type DnaCategoryQueries = {
  __typename?: 'DnaCategoryQueries';
  findAll?: Maybe<DnaCategoryQueryResult>;
  findAllBaseOnUserReport?: Maybe<DnaCategoryQueryResult>;
  findOne?: Maybe<DnaCategory>;
  /** available queries of DNA Panel Translation modules */
  DnaSubCategory?: Maybe<DnaSubCategoryQueries>;
};

export type DnaCategoryQueriesFindAllArgs = {
  where?: Maybe<DnaCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaCategorySortBy>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaCategoryQueriesFindAllBaseOnUserReportArgs = {
  where?: Maybe<DnaCategoryBaseOnReportQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DnaCategoryQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type DnaCategoryQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  clientProfileId?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<PurchaseProductType>;
  ownerId?: Maybe<Scalars['String']>;
  riskLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
  affiliatedWithPanel?: Maybe<Scalars['Boolean']>;
};

export type DnaCategoryQueryResult = {
  __typename?: 'DnaCategoryQueryResult';
  results?: Maybe<Array<Maybe<DnaCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaCategorySortBy = {
  name?: Maybe<SortDirection>;
  panelName?: Maybe<SortDirection>;
};

export type DnaCategoryTranslation = {
  __typename?: 'DnaCategoryTranslation';
  id?: Maybe<Scalars['Int']>;
  dnaCategoryId?: Maybe<Scalars['Int']>;
  langCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
};

export type DnaCategoryTranslationForm = {
  id?: Maybe<Scalars['Int']>;
  langCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['Upload']>;
  shortDescription?: Maybe<Scalars['String']>;
};

export type DnaCategoryWithReport = {
  __typename?: 'DnaCategoryWithReport';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reportType?: Maybe<ReportType>;
  cover?: Maybe<Scalars['String']>;
  objectImage?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Scalars['String']>;
  favourableImage?: Maybe<Scalars['String']>;
  unfavourableImage?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<Maybe<BioinformaticReport>>>;
};

export type DnaCategoryWithReportQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  riskLevel?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaCategoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type DnaCategoryWithReportQueryResult = {
  __typename?: 'DnaCategoryWithReportQueryResult';
  results?: Maybe<Array<Maybe<DnaCategoryWithReport>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaGene = {
  __typename?: 'DnaGene';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DnaGeneQueries = {
  __typename?: 'DnaGeneQueries';
  findAll?: Maybe<DnaGeneQueryResult>;
  findOne?: Maybe<DnaGene>;
};

export type DnaGeneQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type DnaGeneQueryResult = {
  __typename?: 'DnaGeneQueryResult';
  results?: Maybe<Array<Maybe<DnaGene>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaInteractome = {
  __typename?: 'DnaInteractome';
  id?: Maybe<Scalars['String']>;
  dnaTraitId?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  dTrait?: Maybe<DnaTrait>;
  dInteractomeCard?: Maybe<Array<Maybe<Card>>>;
  dInteractomeComplicationTrait?: Maybe<Array<Maybe<ComplicationTrait>>>;
  dInteractomeRiskTrait?: Maybe<Array<Maybe<RiskTrait>>>;
  dInteractomeArticle?: Maybe<Array<Maybe<InteractomeArticle>>>;
  dInteractomeCounselor?: Maybe<Array<Maybe<InteractomeCounselor>>>;
  /** 360 */
  bioinformaticReportId?: Maybe<Scalars['String']>;
  bReport?: Maybe<BioinformaticReport>;
};

export type DnaInteractomeArticleDelete = {
  id: Scalars['String'];
};

export type DnaInteractomeArticleEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaInteractomeArticleForm = {
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId?: Maybe<Scalars['String']>;
  articleId?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeArticleMutations = {
  __typename?: 'DnaInteractomeArticleMutations';
  createOrUpdate?: Maybe<Array<Maybe<InteractomeArticle>>>;
  changePosition?: Maybe<Array<Maybe<InteractomeArticle>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaInteractomeArticleMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaInteractomeArticleForm>>;
};

export type DnaInteractomeArticleMutationsChangePositionArgs = {
  forms: Array<Maybe<DnaInteractomeArticleEditOrder>>;
};

export type DnaInteractomeArticleMutationsDeleteArgs = {
  forms: Array<Maybe<DnaInteractomeArticleDelete>>;
};

export type DnaInteractomeArticleQueries = {
  __typename?: 'DnaInteractomeArticleQueries';
  findAll?: Maybe<DnaInteractomeArticleQueryResult>;
};

export type DnaInteractomeArticleQueriesFindAllArgs = {
  where?: Maybe<DnaInteractomeArticleQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaInteractomeArticleQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaInteractomeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  articleId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaInteractomeArticleQueryResult = {
  __typename?: 'DnaInteractomeArticleQueryResult';
  results?: Maybe<Array<Maybe<InteractomeArticle>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeCardDelete = {
  id: Scalars['String'];
};

export type DnaInteractomeCardEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaInteractomeCardForm = {
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId: Scalars['String'];
  imageUrl: Scalars['String'];
  title: Scalars['String'];
  language: Scalars['String'];
  position: Scalars['Int'];
};

export type DnaInteractomeCardMutations = {
  __typename?: 'DnaInteractomeCardMutations';
  createOrUpdate?: Maybe<Array<Maybe<Card>>>;
  changePosition?: Maybe<Array<Maybe<Card>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaInteractomeCardMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaInteractomeCardForm>>;
};

export type DnaInteractomeCardMutationsChangePositionArgs = {
  forms: Array<Maybe<DnaInteractomeCardEditOrder>>;
};

export type DnaInteractomeCardMutationsDeleteArgs = {
  forms: Array<Maybe<DnaInteractomeCardDelete>>;
};

export type DnaInteractomeCardQueries = {
  __typename?: 'DnaInteractomeCardQueries';
  findAll?: Maybe<DnaInteractomeCardQueryResult>;
};

export type DnaInteractomeCardQueriesFindAllArgs = {
  where?: Maybe<DnaInteractomeCardQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaInteractomeCardQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaInteractomeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type DnaInteractomeCardQueryResult = {
  __typename?: 'DnaInteractomeCardQueryResult';
  results?: Maybe<Array<Maybe<Card>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeComplicationTraitDelete = {
  id: Scalars['String'];
};

export type DnaInteractomeComplicationTraitEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaInteractomeComplicationTraitForm = {
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId: Scalars['String'];
  dnaTraitId: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeComplicationTraitMutations = {
  __typename?: 'DnaInteractomeComplicationTraitMutations';
  createOrUpdate?: Maybe<Array<Maybe<ComplicationTrait>>>;
  changePosition?: Maybe<Array<Maybe<ComplicationTrait>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaInteractomeComplicationTraitMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaInteractomeComplicationTraitForm>>;
};

export type DnaInteractomeComplicationTraitMutationsChangePositionArgs = {
  forms: Array<Maybe<DnaInteractomeComplicationTraitEditOrder>>;
};

export type DnaInteractomeComplicationTraitMutationsDeleteArgs = {
  forms: Array<Maybe<DnaInteractomeComplicationTraitDelete>>;
};

export type DnaInteractomeComplicationTraitQueries = {
  __typename?: 'DnaInteractomeComplicationTraitQueries';
  findAll?: Maybe<DnaInteractomeComplicationTraitQueryResult>;
};

export type DnaInteractomeComplicationTraitQueriesFindAllArgs = {
  where?: Maybe<DnaInteractomeComplicationTraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaInteractomeComplicationTraitQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaInteractomeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaTraitId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  position?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaInteractomeComplicationTraitQueryResult = {
  __typename?: 'DnaInteractomeComplicationTraitQueryResult';
  results?: Maybe<Array<Maybe<ComplicationTrait>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeCounselorDelete = {
  id: Scalars['String'];
};

export type DnaInteractomeCounselorEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaInteractomeCounselorForm = {
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId?: Maybe<Scalars['String']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeCounselorMutations = {
  __typename?: 'DnaInteractomeCounselorMutations';
  createOrUpdate?: Maybe<Array<Maybe<InteractomeCounselor>>>;
  changePosition?: Maybe<Array<Maybe<InteractomeCounselor>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaInteractomeCounselorMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaInteractomeCounselorForm>>;
};

export type DnaInteractomeCounselorMutationsChangePositionArgs = {
  forms: Array<Maybe<DnaInteractomeCounselorEditOrder>>;
};

export type DnaInteractomeCounselorMutationsDeleteArgs = {
  forms: Array<Maybe<DnaInteractomeCounselorDelete>>;
};

export type DnaInteractomeCounselorQueries = {
  __typename?: 'DnaInteractomeCounselorQueries';
  findAll?: Maybe<DnaInteractomeCounselorQueryResult>;
};

export type DnaInteractomeCounselorQueriesFindAllArgs = {
  where?: Maybe<DnaInteractomeCounselorQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaInteractomeCounselorQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaInteractomeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  counselorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type DnaInteractomeCounselorQueryResult = {
  __typename?: 'DnaInteractomeCounselorQueryResult';
  results?: Maybe<Array<Maybe<InteractomeCounselor>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaInteractomeForm = {
  id?: Maybe<Scalars['String']>;
  dnaTraitId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  position: Scalars['Int'];
  reportType?: Maybe<ReportType>;
};

export type DnaInteractomeMutations = {
  __typename?: 'DnaInteractomeMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaInteractome>>>;
  changePosition?: Maybe<Array<Maybe<DnaInteractome>>>;
  /** available mutations of DNA Interactome Card modules */
  Card?: Maybe<DnaInteractomeCardMutations>;
  /** available mutations of DNA Interactome Complication Trait modules */
  ComplicationTrait?: Maybe<DnaInteractomeComplicationTraitMutations>;
  /** available mutations of DNA Interactome Risk Trait modules */
  RiskTrait?: Maybe<DnaInteractomeRiskTraitMutations>;
  /** available mutations of DNA Interactome Counselor modules */
  InteractomeCounselor?: Maybe<DnaInteractomeCounselorMutations>;
  /** available mutations of DNA Interactome Article modules */
  InteractomeArticle?: Maybe<DnaInteractomeArticleMutations>;
};

export type DnaInteractomeMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaInteractomeForm>>;
};

export type DnaInteractomeMutationsChangePositionArgs = {
  forms: Array<Maybe<DnaInteractomeEditOrder>>;
};

export type DnaInteractomeQueries = {
  __typename?: 'DnaInteractomeQueries';
  findRelatedByRiskTrait?: Maybe<DnaInteractomeQueryResult>;
  findAll?: Maybe<DnaInteractomeQueryResult>;
  /** available queries of DNA Interactome Card modules */
  Card?: Maybe<DnaInteractomeCardQueries>;
  /** available queries of DNA Interactome Complication Trait modules */
  ComplicationTrait?: Maybe<DnaInteractomeComplicationTraitQueries>;
  /** available queries of DNA Interactome Risk Trait modules */
  RiskTrait?: Maybe<DnaInteractomeRiskTraitQueries>;
  /** available queries of DNA Interactome Counselor modules */
  InteractomeCounselor?: Maybe<DnaInteractomeCounselorQueries>;
  /** available queries of DNA Interactome Article modules */
  InteractomeArticle?: Maybe<DnaInteractomeArticleQueries>;
};

export type DnaInteractomeQueriesFindRelatedByRiskTraitArgs = {
  dnaInteractomeRiskTraitId?: Maybe<Scalars['String']>;
};

export type DnaInteractomeQueriesFindAllArgs = {
  where?: Maybe<DnaInteractomeQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaInteractomeQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaTraitId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isActive?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaInteractomeQueryResult = {
  __typename?: 'DnaInteractomeQueryResult';
  results?: Maybe<Array<Maybe<DnaInteractome>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeRiskTraitDelete = {
  id: Scalars['String'];
};

export type DnaInteractomeRiskTraitEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaInteractomeRiskTraitForm = {
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId: Scalars['String'];
  dnaTraitId: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
};

export type DnaInteractomeRiskTraitMutations = {
  __typename?: 'DnaInteractomeRiskTraitMutations';
  createOrUpdate?: Maybe<Array<Maybe<RiskTrait>>>;
  changePosition?: Maybe<Array<Maybe<RiskTrait>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaInteractomeRiskTraitMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaInteractomeRiskTraitForm>>;
};

export type DnaInteractomeRiskTraitMutationsChangePositionArgs = {
  forms: Array<Maybe<DnaInteractomeRiskTraitEditOrder>>;
};

export type DnaInteractomeRiskTraitMutationsDeleteArgs = {
  forms: Array<Maybe<DnaInteractomeRiskTraitDelete>>;
};

export type DnaInteractomeRiskTraitQueries = {
  __typename?: 'DnaInteractomeRiskTraitQueries';
  findAll?: Maybe<DnaInteractomeRiskTraitQueryResult>;
};

export type DnaInteractomeRiskTraitQueriesFindAllArgs = {
  where?: Maybe<DnaInteractomeRiskTraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaInteractomeRiskTraitQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaInteractomeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaTraitId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  position?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaInteractomeRiskTraitQueryResult = {
  __typename?: 'DnaInteractomeRiskTraitQueryResult';
  results?: Maybe<Array<Maybe<RiskTrait>>>;
  count?: Maybe<Scalars['Int']>;
};

/** available mutations of DNA modules */
export type DnaMutations = {
  __typename?: 'DNAMutations';
  /** available mutations of DNA Report modules */
  Report?: Maybe<DnaReportMutations>;
  /** available mutations of DNA Search modules */
  Search?: Maybe<DnaSearchMutations>;
  /** available mutations of DNA Owner modules */
  Owner?: Maybe<DnaOwnerMutations>;
  /** available mutations of DNA Category modules */
  Category?: Maybe<DnaCategoryMutations>;
  /** available mutations of DNA Trait modules */
  Trait?: Maybe<DnaTraitMutations>;
  /** available mutations of DNA Interactome modules */
  Interactome?: Maybe<DnaInteractomeMutations>;
  /** available mutations of DNA Panel modules */
  Panel?: Maybe<DnaPanelMutations>;
  /** available mutation of DNA ScoreType modules */
  ScoreType?: Maybe<DnaScoreTypeMutations>;
  /**
   * available mutation of DNA Gene modules
   * Gene: DnaGeneMutations
   * available mutation of DNA Score modules
   * Score: DnaScoreMutations
   * available mutation of DNA User modules
   * User: DnaUserMutations
   * available mutation of DNA User modules
   */
  PersonalizedReport?: Maybe<DnaPersonalizedReportMutations>;
  MasterDictionary?: Maybe<MasterDictionaryMutations>;
};

export type DnaOwner = {
  __typename?: 'DNAOwner';
  id?: Maybe<Scalars['String']>;
  partnership?: Maybe<Partnership>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type DnaOwnerForm = {
  partnershipId?: Maybe<Scalars['String']>;
};

export type DnaOwnerMutations = {
  __typename?: 'DNAOwnerMutations';
  add?: Maybe<DnaOwner>;
};

export type DnaOwnerMutationsAddArgs = {
  form?: Maybe<DnaOwnerForm>;
};

export type DnaOwnerQueries = {
  __typename?: 'DNAOwnerQueries';
  findAll?: Maybe<DnaOwnerQueryResult>;
  findOne?: Maybe<DnaOwner>;
};

export type DnaOwnerQueriesFindAllArgs = {
  where?: Maybe<DnaOwnerQueryFilter>;
  sort?: Maybe<DnaOwnerQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type DnaOwnerQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type DnaOwnerQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
};

export type DnaOwnerQueryResult = {
  __typename?: 'DNAOwnerQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaOwner>>>;
};

export type DnaOwnerQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type DnaPanel = {
  __typename?: 'DnaPanel';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  favourableDescription?: Maybe<Scalars['String']>;
  unfavourableDescription?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dCategory?: Maybe<Array<Maybe<DnaCategory>>>;
  dPanelTranslation?: Maybe<Array<Maybe<DnaPanelTranslation>>>;
  dPanelFigure?: Maybe<Array<Maybe<DnaPanelFigure>>>;
};

export type DnaPanelEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaPanelFigure = {
  __typename?: 'DnaPanelFigure';
  id?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dPanel?: Maybe<DnaPanel>;
  dPanelFigurePin?: Maybe<Array<Maybe<DnaPanelFigurePin>>>;
};

export type DnaPanelFigureForm = {
  id?: Maybe<Scalars['String']>;
  dnaPanelId: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  imageUrl: Scalars['Upload'];
};

export type DnaPanelFigureMutations = {
  __typename?: 'DnaPanelFigureMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPanelFigure>>>;
  /** available mutations of DNA Panel Figre Pin modules */
  DnaPanelFigurePin?: Maybe<DnaPanelFigurePinMutations>;
};

export type DnaPanelFigureMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaPanelFigureForm>>;
};

export type DnaPanelFigurePin = {
  __typename?: 'DnaPanelFigurePin';
  id?: Maybe<Scalars['String']>;
  dnaPanelFigureId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dPanelFigure?: Maybe<DnaPanelFigure>;
  dPanelPinCategory?: Maybe<Array<Maybe<DnaPanelPinFigureCategory>>>;
};

export type DnaPanelFigurePinDelete = {
  id: Scalars['String'];
};

export type DnaPanelFigurePinForm = {
  id?: Maybe<Scalars['String']>;
  dnaPanelFigureId: Scalars['String'];
  position: Scalars['Int'];
  lat: Scalars['String'];
  long: Scalars['String'];
};

export type DnaPanelFigurePinMutations = {
  __typename?: 'DnaPanelFigurePinMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPanelFigurePin>>>;
  delete?: Maybe<Scalars['Boolean']>;
  /** available mutations of DNA Panel Figre Pin modules */
  DnaPanelPinFigureCategory?: Maybe<DnaPanelPinFigureCategoryMutations>;
};

export type DnaPanelFigurePinMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaPanelFigurePinForm>>;
};

export type DnaPanelFigurePinMutationsDeleteArgs = {
  forms: Array<Maybe<DnaPanelFigurePinDelete>>;
};

export type DnaPanelFigurePinQueries = {
  __typename?: 'DnaPanelFigurePinQueries';
  findAll?: Maybe<DnaPanelFigurePinQueryResult>;
  /** available queries of DNA Panel Figre Pin modules */
  DnaPanelPinFigureCategory?: Maybe<DnaPanelPinFigureCategoryQueries>;
};

export type DnaPanelFigurePinQueriesFindAllArgs = {
  where?: Maybe<DnaPanelFigurePinQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPanelFigurePinQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaPanelFigureId?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['Int']>;
};

export type DnaPanelFigurePinQueryResult = {
  __typename?: 'DnaPanelFigurePinQueryResult';
  results?: Maybe<Array<Maybe<DnaPanelFigurePin>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaPanelFigureQueries = {
  __typename?: 'DnaPanelFigureQueries';
  findAll?: Maybe<DnaPanelFigureQueryResult>;
  /** available queries of DNA Panel Figre Pin modules */
  DnaPanelFigurePin?: Maybe<DnaPanelFigurePinQueries>;
};

export type DnaPanelFigureQueriesFindAllArgs = {
  where?: Maybe<DnaPanelFigureQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPanelFigureQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaPanelId?: Maybe<Array<Maybe<Scalars['String']>>>;
  gender?: Maybe<Scalars['String']>;
};

export type DnaPanelFigureQueryResult = {
  __typename?: 'DnaPanelFigureQueryResult';
  results?: Maybe<Array<Maybe<DnaPanelFigure>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaPanelForm = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  favourableDescription: Scalars['String'];
  unfavourableDescription: Scalars['String'];
  dPanelTranslation?: Maybe<Array<Maybe<DnaPanelTranslationForm>>>;
  reportType?: Maybe<ReportType>;
};

export type DnaPanelMutations = {
  __typename?: 'DnaPanelMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPanel>>>;
  changePosition?: Maybe<Array<Maybe<DnaPanel>>>;
  exportExcel360?: Maybe<Scalars['Boolean']>;
  /** available mutations of DNA Panel Translation modules */
  DnaPanelTranslation?: Maybe<DnaPanelTranslationMutations>;
  /** available mutations of DNA Figure modules */
  DnaPanelFigure?: Maybe<DnaPanelFigureMutations>;
};

export type DnaPanelMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaPanelForm>>;
};

export type DnaPanelMutationsChangePositionArgs = {
  forms: Array<Maybe<DnaPanelEditOrder>>;
};

export type DnaPanelMutationsExportExcel360Args = {
  where?: Maybe<DnaPanelQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPanelPinFigureCategory = {
  __typename?: 'DnaPanelPinFigureCategory';
  id?: Maybe<Scalars['String']>;
  dnaPanelFigurePinId?: Maybe<Scalars['String']>;
  dnaCategoryId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dPanelFigurePin?: Maybe<DnaPanelFigurePin>;
  dCategory?: Maybe<DnaCategory>;
};

export type DnaPanelPinFigureCategoryDelete = {
  id: Scalars['String'];
};

export type DnaPanelPinFigureCategoryForm = {
  id?: Maybe<Scalars['String']>;
  dnaPanelFigurePinId: Scalars['String'];
  dnaCategoryId: Scalars['Int'];
  position: Scalars['Int'];
};

export type DnaPanelPinFigureCategoryMutations = {
  __typename?: 'DnaPanelPinFigureCategoryMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPanelPinFigureCategory>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaPanelPinFigureCategoryMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaPanelPinFigureCategoryForm>>;
};

export type DnaPanelPinFigureCategoryMutationsDeleteArgs = {
  forms: Array<Maybe<DnaPanelPinFigureCategoryDelete>>;
};

export type DnaPanelPinFigureCategoryQueries = {
  __typename?: 'DnaPanelPinFigureCategoryQueries';
  findAll?: Maybe<DnaPanelPinFigureCategoryQueryResult>;
};

export type DnaPanelPinFigureCategoryQueriesFindAllArgs = {
  where?: Maybe<DnaPanelPinFigureCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPanelPinFigureCategoryQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaPanelFigurePinId?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaCategoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  position?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type DnaPanelPinFigureCategoryQueryResult = {
  __typename?: 'DnaPanelPinFigureCategoryQueryResult';
  results?: Maybe<Array<Maybe<DnaPanelPinFigureCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaPanelQueries = {
  __typename?: 'DnaPanelQueries';
  findAll?: Maybe<DnaPanelQueryResult>;
  /** available queries of DNA Panel Translation modules */
  DnaPanelTranslation?: Maybe<DnaPanelTranslationQueries>;
  /** available queries of DNA Figure modules */
  DnaPanelFigure?: Maybe<DnaPanelFigureQueries>;
};

export type DnaPanelQueriesFindAllArgs = {
  where?: Maybe<DnaPanelQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPanelQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaPanelQueryResult = {
  __typename?: 'DnaPanelQueryResult';
  results?: Maybe<Array<Maybe<DnaPanel>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaPanelTranslation = {
  __typename?: 'DnaPanelTranslation';
  id?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  favourableDescription?: Maybe<Scalars['String']>;
  unfavourableDescription?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dPanel?: Maybe<Array<Maybe<DnaPanel>>>;
};

export type DnaPanelTranslationEditOrder = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type DnaPanelTranslationForm = {
  id?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  favourableDescription: Scalars['String'];
  unfavourableDescription: Scalars['String'];
};

export type DnaPanelTranslationMutations = {
  __typename?: 'DnaPanelTranslationMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPanelTranslation>>>;
};

export type DnaPanelTranslationMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaPanelTranslationForm>>;
};

export type DnaPanelTranslationQueries = {
  __typename?: 'DnaPanelTranslationQueries';
  findAll?: Maybe<DnaPanelTranslationQueryResult>;
};

export type DnaPanelTranslationQueriesFindAllArgs = {
  where?: Maybe<DnaPanelTranslationQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPanelTranslationQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaPanelId?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaPanelTranslationQueryResult = {
  __typename?: 'DnaPanelTranslationQueryResult';
  results?: Maybe<Array<Maybe<DnaPanelTranslation>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaPanelWithReport = {
  __typename?: 'DnaPanelWithReport';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  favourableDescription?: Maybe<Scalars['String']>;
  unfavourableDescription?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  reports?: Maybe<Array<Maybe<BioinformaticReport>>>;
};

export type DnaPanelWithReportQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  riskLevel?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
};

export type DnaPanelWithReportQueryResult = {
  __typename?: 'DnaPanelWithReportQueryResult';
  results?: Maybe<Array<Maybe<DnaPanelWithReport>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaPdf = {
  __typename?: 'DnaPdf';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  files?: Maybe<Array<Maybe<DnaPdfFiles>>>;
  user?: Maybe<UserProfile>;
  latestGeneratedAt?: Maybe<Scalars['Date']>;
};

export type DnaPdfFiles = {
  __typename?: 'DnaPdfFiles';
  id?: Maybe<Scalars['ID']>;
  reportType?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  protected?: Maybe<Scalars['Boolean']>;
  generatedAt?: Maybe<Scalars['Date']>;
};

export type DnaPdfQueries = {
  __typename?: 'DnaPdfQueries';
  findAll?: Maybe<DnaPdfQueryResult>;
};

export type DnaPdfQueriesFindAllArgs = {
  where?: Maybe<DnaPdfQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Boolean']>;
};

export type DnaPdfQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportType?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowToRelease?: Maybe<Scalars['Boolean']>;
  haveAttachment?: Maybe<Scalars['Boolean']>;
  haveProtectedAttachments?: Maybe<Scalars['Boolean']>;
  generatedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPdfQueryResult = {
  __typename?: 'DnaPdfQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaPdf>>>;
};

export type DnaPersonalizedReport = {
  __typename?: 'DnaPersonalizedReport';
  id?: Maybe<Scalars['String']>;
  partnershipId?: Maybe<Scalars['String']>;
  reportType?: Maybe<PurchaseProductType>;
  isCustomized?: Maybe<Scalars['Boolean']>;
  generationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  logoPointer?: Maybe<Scalars['String']>;
  customName?: Maybe<Scalars['String']>;
  footerDescription?: Maybe<Scalars['String']>;
  isPageNumberRemoved?: Maybe<Scalars['Boolean']>;
  isReportPrinted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  blankPage?: Maybe<Array<Maybe<DnaPersonalizedReportBlankPage>>>;
  category?: Maybe<Array<Maybe<DnaPersonalizedReportCategory>>>;
  page?: Maybe<Array<Maybe<DnaPersonalizedReportPage>>>;
};

export type DnaPersonalizedReportBlankPage = {
  __typename?: 'DnaPersonalizedReportBlankPage';
  id?: Maybe<Scalars['String']>;
  personalizedReportId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  blankPage?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type DnaPersonalizedReportBlankPageDelete = {
  id: Scalars['String'];
};

export type DnaPersonalizedReportBlankPageForm = {
  id?: Maybe<Scalars['String']>;
  personalizedReportId: Scalars['String'];
  page: Scalars['String'];
  blankPage?: Maybe<Scalars['Int']>;
};

export type DnaPersonalizedReportBlankPageMutations = {
  __typename?: 'DnaPersonalizedReportBlankPageMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPersonalizedReportBlankPage>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaPersonalizedReportBlankPageMutationsCreateOrUpdateArgs = {
  forms?: Maybe<Array<Maybe<DnaPersonalizedReportBlankPageForm>>>;
};

export type DnaPersonalizedReportBlankPageMutationsDeleteArgs = {
  forms: Array<Maybe<DnaPersonalizedReportBlankPageDelete>>;
};

export type DnaPersonalizedReportBlankPageQueries = {
  __typename?: 'DnaPersonalizedReportBlankPageQueries';
  findAll?: Maybe<DnaPersonalizedReportBlankPageQueryResult>;
};

export type DnaPersonalizedReportBlankPageQueriesFindAllArgs = {
  where?: Maybe<DnaPersonalizedReportBlankPageQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type DnaPersonalizedReportBlankPageQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  personalizedReportId?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPersonalizedReportBlankPageQueryResult = {
  __typename?: 'DnaPersonalizedReportBlankPageQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaPersonalizedReportBlankPage>>>;
};

export type DnaPersonalizedReportCategory = {
  __typename?: 'DnaPersonalizedReportCategory';
  id?: Maybe<Scalars['String']>;
  personalizedReportId?: Maybe<Scalars['String']>;
  dnaCategoryId?: Maybe<Scalars['Int']>;
  pointer?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type DnaPersonalizedReportCategoryDelete = {
  id: Scalars['String'];
};

export type DnaPersonalizedReportCategoryForm = {
  id?: Maybe<Scalars['String']>;
  personalizedReportId?: Maybe<Scalars['String']>;
  dnaCategoryId?: Maybe<Scalars['Int']>;
  pointer?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type DnaPersonalizedReportCategoryMutations = {
  __typename?: 'DnaPersonalizedReportCategoryMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPersonalizedReportCategory>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaPersonalizedReportCategoryMutationsCreateOrUpdateArgs = {
  forms?: Maybe<Array<Maybe<DnaPersonalizedReportCategoryForm>>>;
};

export type DnaPersonalizedReportCategoryMutationsDeleteArgs = {
  forms: Array<Maybe<DnaPersonalizedReportCategoryDelete>>;
};

export type DnaPersonalizedReportCategoryQueries = {
  __typename?: 'DnaPersonalizedReportCategoryQueries';
  findAll?: Maybe<DnaPersonalizedReportCategoryQueryResult>;
};

export type DnaPersonalizedReportCategoryQueriesFindAllArgs = {
  where?: Maybe<DnaPersonalizedReportCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type DnaPersonalizedReportCategoryQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  personalizedReportId?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaCategoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type DnaPersonalizedReportCategoryQueryResult = {
  __typename?: 'DnaPersonalizedReportCategoryQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaPersonalizedReportCategory>>>;
};

export type DnaPersonalizedReportDelete = {
  id: Scalars['String'];
};

export type DnaPersonalizedReportForm = {
  id?: Maybe<Scalars['String']>;
  partnershipId: Scalars['String'];
  reportType: PurchaseProductType;
  isCustomized?: Maybe<Scalars['Boolean']>;
  generationType?: Maybe<Array<Maybe<Scalars['String']>>>;
  logoPointer?: Maybe<Scalars['String']>;
  customName?: Maybe<Scalars['String']>;
  footerDescription?: Maybe<Scalars['String']>;
  isPageNumberRemoved?: Maybe<Scalars['Boolean']>;
  isReportPrinted?: Maybe<Scalars['Boolean']>;
};

export type DnaPersonalizedReportMutations = {
  __typename?: 'DnaPersonalizedReportMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPersonalizedReport>>>;
  delete?: Maybe<Scalars['Boolean']>;
  /** # available queries of DNA Personalized Report Blank Page modules */
  BlankPage?: Maybe<DnaPersonalizedReportBlankPageMutations>;
  /** # available queries of DNA Personalized Report Category modules */
  Category?: Maybe<DnaPersonalizedReportCategoryMutations>;
  /** # available queries of DNA Personalized Report Page modules */
  Page?: Maybe<DnaPersonalizedReportPageMutations>;
};

export type DnaPersonalizedReportMutationsCreateOrUpdateArgs = {
  forms?: Maybe<Array<Maybe<DnaPersonalizedReportForm>>>;
};

export type DnaPersonalizedReportMutationsDeleteArgs = {
  forms: Array<Maybe<DnaPersonalizedReportDelete>>;
};

export type DnaPersonalizedReportPage = {
  __typename?: 'DnaPersonalizedReportPage';
  id?: Maybe<Scalars['String']>;
  personalizedReportId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  pointer?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type DnaPersonalizedReportPageDelete = {
  id: Scalars['String'];
};

export type DnaPersonalizedReportPageForm = {
  id?: Maybe<Scalars['String']>;
  personalizedReportId: Scalars['String'];
  page: Scalars['String'];
  pointer?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type DnaPersonalizedReportPageMutations = {
  __typename?: 'DnaPersonalizedReportPageMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaPersonalizedReportPage>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaPersonalizedReportPageMutationsCreateOrUpdateArgs = {
  forms?: Maybe<Array<Maybe<DnaPersonalizedReportPageForm>>>;
};

export type DnaPersonalizedReportPageMutationsDeleteArgs = {
  forms: Array<Maybe<DnaPersonalizedReportPageDelete>>;
};

export type DnaPersonalizedReportPageQueries = {
  __typename?: 'DnaPersonalizedReportPageQueries';
  findAll?: Maybe<DnaPersonalizedReportPageQueryResult>;
};

export type DnaPersonalizedReportPageQueriesFindAllArgs = {
  where?: Maybe<DnaPersonalizedReportPageQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type DnaPersonalizedReportPageQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  personalizedReportId?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaPersonalizedReportPageQueryResult = {
  __typename?: 'DnaPersonalizedReportPageQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaPersonalizedReportPage>>>;
};

export type DnaPersonalizedReportQueries = {
  __typename?: 'DnaPersonalizedReportQueries';
  findAll?: Maybe<DnaPersonalizedReportQueryResult>;
  /** # available queries of DNA Personalized Report Blank Page modules */
  BlankPage?: Maybe<DnaPersonalizedReportBlankPageQueries>;
  /** # available queries of DNA Personalized Report Category modules */
  Category?: Maybe<DnaPersonalizedReportCategoryQueries>;
  /** # available queries of DNA Personalized Report Page modules */
  Page?: Maybe<DnaPersonalizedReportPageQueries>;
};

export type DnaPersonalizedReportQueriesFindAllArgs = {
  where?: Maybe<DnaPersonalizedReportQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
};

export type DnaPersonalizedReportQueryFilter = {
  partnershipId?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportType?: Maybe<Array<Maybe<PurchaseProductType>>>;
};

export type DnaPersonalizedReportQueryResult = {
  __typename?: 'DnaPersonalizedReportQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaPersonalizedReport>>>;
};

/** available queries of DNA modules */
export type DnaQueries = {
  __typename?: 'DNAQueries';
  /** available queries of DNA Category modules */
  Category?: Maybe<DnaCategoryQueries>;
  /** available queries of DNA Report modules */
  Report?: Maybe<DnaReportQueries>;
  /** available queries of DNA Search modules */
  Search?: Maybe<DnaSearchQueries>;
  /** available queries of DNA Trait modules */
  Trait?: Maybe<DnaTraitQueries>;
  /** available queries of DNA Attachment modules */
  Attachment?: Maybe<DnaAttachmentQueries>;
  /** available queries of DNA Owner modules */
  Owner?: Maybe<DnaOwnerQueries>;
  /** available queries of DNA Interactome modules */
  Interactome?: Maybe<DnaInteractomeQueries>;
  /** available queries of DNA Panel modules */
  Panel?: Maybe<DnaPanelQueries>;
  /** available queries of DNA ScoreType modules */
  ScoreType?: Maybe<DnaScoreTypeQueries>;
  /** available queries of DNA Gene modules */
  Gene?: Maybe<DnaGeneQueries>;
  /** available queries of DNA Score modules */
  Score?: Maybe<DnaScoreQueries>;
  /** available queries of DNA User modules */
  User?: Maybe<DnaUserQueries>;
  /** available queries of DNA User modules */
  Pdf?: Maybe<DnaPdfQueries>;
  /** available queries of DNA User modules */
  PersonalizedReport?: Maybe<DnaPersonalizedReportQueries>;
  MasterDictionary?: Maybe<MasterDictionaryQueries>;
};

export type DnaReport = {
  __typename?: 'DNAReport';
  id?: Maybe<Scalars['String']>;
  /** deprecated; please use files tag for get url attachment */
  attachment?: Maybe<Scalars['String']>;
  /** deprecated; please use files tag for get url attachment */
  attachmentLanguage_ID?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<DnaReportFiles>>>;
  kitId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  dnaUserId?: Maybe<Scalars['String']>;
  bioinformaticUserId?: Maybe<Scalars['String']>;
  /** deprecated */
  notificationStatus?: Maybe<DnaReportNotificationStatus>;
  language?: Maybe<LanguageCode>;
  agreeDisclaimerAt?: Maybe<Scalars['Date']>;
  reportType?: Maybe<Scalars['String']>;
  releasedAt?: Maybe<Scalars['Date']>;
  accessedAt?: Maybe<Scalars['Date']>;
  kit?: Maybe<PurchaseProductKit>;
  dnaUser?: Maybe<DnaUser>;
  profile?: Maybe<UserProfile>;
};

export type DnaReportFiles = {
  __typename?: 'DNAReportFiles';
  id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  protected?: Maybe<Scalars['Boolean']>;
  generatedAt?: Maybe<Scalars['Date']>;
  dnaReportId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type DnaReportFormEdit = {
  /** deprecated */
  notificationStatus?: Maybe<DnaReportNotificationStatus>;
  language?: Maybe<LanguageCode>;
  accessedAt?: Maybe<Scalars['Date']>;
};

export enum DnaReportHistoryStatus {
  ReportMapped = 'REPORT_MAPPED',
  ReportGenerated = 'REPORT_GENERATED',
  EmailSent = 'EMAIL_SENT',
  PushNotifSent = 'PUSH_NOTIF_SENT'
}

export type DnaReportMutations = {
  __typename?: 'DNAReportMutations';
  add?: Maybe<DnaReport>;
  edit?: Maybe<DnaReport>;
  /** deprecated */
  notifyUsers?: Maybe<Scalars['Boolean']>;
  importDNAReportTranslation?: Maybe<Scalars['Boolean']>;
  importDNAGeneTranslation?: Maybe<Scalars['Boolean']>;
  agreeDisclaimer?: Maybe<Scalars['Boolean']>;
};

export type DnaReportMutationsEditArgs = {
  where?: Maybe<DnaReportQueryFilterEdit>;
  form?: Maybe<DnaReportFormEdit>;
};

export type DnaReportMutationsNotifyUsersArgs = {
  file: Scalars['Upload'];
  forceUpdate?: Maybe<Scalars['Boolean']>;
};

export type DnaReportMutationsImportDnaReportTranslationArgs = {
  file: Scalars['Upload'];
};

export type DnaReportMutationsImportDnaGeneTranslationArgs = {
  file: Scalars['Upload'];
};

export type DnaReportMutationsAgreeDisclaimerArgs = {
  agreeDisclaimerAt: Scalars['Date'];
  dnaReportId: Scalars['String'];
};

/** deprecated */
export enum DnaReportNotificationStatus {
  WaitingForNotified = 'WAITING_FOR_NOTIFIED',
  FirstNotified = 'FIRST_NOTIFIED',
  NotificationCompleted = 'NOTIFICATION_COMPLETED'
}

export type DnaReportQueries = {
  __typename?: 'DNAReportQueries';
  findOne?: Maybe<DnaReport>;
  find?: Maybe<DnaReport>;
  findAll?: Maybe<DnaReportQueryResult>;
  download?: Maybe<Scalars['String']>;
  /** available queries of DNA Report User modules */
  DnaReportUser?: Maybe<DnaReportUserQueries>;
};

export type DnaReportQueriesFindOneArgs = {
  reportType?: Maybe<ReportType>;
};

export type DnaReportQueriesFindArgs = {
  where?: Maybe<DnaReportQueryFilter>;
};

export type DnaReportQueriesFindAllArgs = {
  where?: Maybe<DnaReportQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Boolean']>;
};

export type DnaReportQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportType?: Maybe<ReportType>;
  allowToRelease?: Maybe<Scalars['Boolean']>;
  haveAttachment?: Maybe<Scalars['Boolean']>;
  haveProtectedAttachments?: Maybe<Scalars['Boolean']>;
  generatedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaReportQueryFilterEdit = {
  reportType?: Maybe<ReportType>;
};

export type DnaReportQueryResult = {
  __typename?: 'DNAReportQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaReport>>>;
};

export type DnaReportUser = {
  __typename?: 'DnaReportUser';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<DnaUser>;
  panel?: Maybe<DnaPanel>;
  category?: Maybe<DnaCategory>;
  trait?: Maybe<DnaTrait>;
  traitScore?: Maybe<DnaScore>;
  score?: Maybe<Scalars['Float']>;
  traitLogo?: Maybe<Scalars['String']>;
  genes?: Maybe<Array<Maybe<DnaGene>>>;
  genotypes?: Maybe<Array<Maybe<DnaReportUserGenoType>>>;
  dnaTrait?: Maybe<DnaTrait>;
  dnaCategory?: Maybe<DnaCategory>;
  dnaPanel?: Maybe<DnaPanel>;
  dnaSubCategory?: Maybe<DnaSubCategory>;
  dnaScoreTypeGuideline?: Maybe<DnaScoreTypeGuideline>;
  dnaScoreType?: Maybe<DnaScoreType>;
  riskLevel?: Maybe<Scalars['String']>;
  language?: Maybe<LanguageCode>;
  customFilter?: Maybe<Array<Maybe<DnaReportUserCustomFilter>>>;
};

export type DnaReportUserByCategory = {
  __typename?: 'DnaReportUserByCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
  reports?: Maybe<Array<Maybe<DnaReportUser>>>;
  thumbnail?: Maybe<Scalars['String']>;
  dnaCategory?: Maybe<DnaCategory>;
};

export type DnaReportUserByCategoryFilter = {
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<PurchaseProductType>;
};

export type DnaReportUserByCategoryQueryResult = {
  __typename?: 'DnaReportUserByCategoryQueryResult';
  results?: Maybe<Array<Maybe<DnaReportUserByCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaReportUserByCategorySortBy = {
  name?: Maybe<SortDirection>;
  average?: Maybe<SortDirection>;
};

export type DnaReportUserByPanel = {
  __typename?: 'DnaReportUserByPanel';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
  reports?: Maybe<Array<Maybe<DnaReportUser>>>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type DnaReportUserByPanelFilter = {
  name?: Maybe<Scalars['String']>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<PurchaseProductType>;
};

export type DnaReportUserByPanelQueryResult = {
  __typename?: 'DnaReportUserByPanelQueryResult';
  results?: Maybe<Array<Maybe<DnaReportUserByPanel>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaReportUserByPanelSortBy = {
  name?: Maybe<SortDirection>;
  average?: Maybe<SortDirection>;
};

export type DnaReportUserCustomFilter = {
  __typename?: 'DnaReportUserCustomFilter';
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type DnaReportUserFilter = {
  panels?: Maybe<Array<Maybe<Scalars['String']>>>;
  traits?: Maybe<Array<Maybe<Scalars['String']>>>;
  profile?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaCategories?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dnaPanels?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaSubCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaScoreTypeGuidelines?: Maybe<Array<Maybe<Scalars['String']>>>;
  riskLevel?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<PurchaseProductType>;
  score?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type DnaReportUserGenoType = {
  __typename?: 'DnaReportUserGenoType';
  gene?: Maybe<DnaGene>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaReportUserQueries = {
  __typename?: 'DnaReportUserQueries';
  findAll?: Maybe<DnaReportUserQueryResult>;
  findOne?: Maybe<DnaReportUser>;
  findByPanel?: Maybe<DnaReportUserByPanelQueryResult>;
  findByCategory?: Maybe<DnaReportUserByCategoryQueryResult>;
};

export type DnaReportUserQueriesFindAllArgs = {
  where?: Maybe<DnaReportUserFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaReportUserQuerySortBy>;
};

export type DnaReportUserQueriesFindOneArgs = {
  id: Scalars['String'];
  reportType?: Maybe<PurchaseProductType>;
  profile?: Maybe<Scalars['String']>;
};

export type DnaReportUserQueriesFindByPanelArgs = {
  where?: Maybe<DnaReportUserByPanelFilter>;
  sort?: Maybe<DnaReportUserByPanelSortBy>;
};

export type DnaReportUserQueriesFindByCategoryArgs = {
  where?: Maybe<DnaReportUserByCategoryFilter>;
  sort?: Maybe<DnaReportUserByCategorySortBy>;
};

export type DnaReportUserQueryResult = {
  __typename?: 'DnaReportUserQueryResult';
  results?: Maybe<Array<Maybe<DnaReportUser>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaReportUserQuerySortBy = {
  /** sort based on subcategory.name and then trait.name */
  subCategoryAndTrait?: Maybe<SortDirection>;
  /** sort based on favourable traits placed at first if ASC, if desc it will the item unfavourable first */
  favourable?: Maybe<SortDirection>;
};

export type DnaScore = {
  __typename?: 'DnaScore';
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Scalars['String']>;
  dos?: Maybe<Scalars['String']>;
  donts?: Maybe<Scalars['String']>;
  /** trait: BioinformaticTrait */
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type DnaScoreQueries = {
  __typename?: 'DnaScoreQueries';
  findAll?: Maybe<DnaScoreQueryResult>;
  findOne?: Maybe<DnaScore>;
};

export type DnaScoreQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type DnaScoreQueryResult = {
  __typename?: 'DnaScoreQueryResult';
  results?: Maybe<Array<Maybe<DnaScore>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaScoreType = {
  __typename?: 'DnaScoreType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isHigherBetter?: Maybe<Scalars['Boolean']>;
  responseCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dScoreTypeGuideline?: Maybe<Array<Maybe<DnaScoreTypeGuideline>>>;
};

export type DnaScoreTypeForm = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  isHigherBetter: Scalars['Boolean'];
  responseCount: Scalars['Int'];
  reportType?: Maybe<ReportType>;
};

export type DnaScoreTypeGuideline = {
  __typename?: 'DnaScoreTypeGuideline';
  id?: Maybe<Scalars['String']>;
  dnaScoreTypeId?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  responseId?: Maybe<Scalars['String']>;
  mappedScore?: Maybe<Scalars['Int']>;
  riskLevel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dScoreType?: Maybe<DnaScoreType>;
  dScoreTypeGuidelineResponse?: Maybe<
    Array<Maybe<DnaScoreTypeGuidelineResponse>>
  >;
};

export type DnaScoreTypeGuidelineForm = {
  id?: Maybe<Scalars['String']>;
  dnaScoreTypeId: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  response: Scalars['String'];
  responseId?: Maybe<Scalars['String']>;
  mappedScore: Scalars['Int'];
  riskLevel: Scalars['String'];
};

export type DnaScoreTypeGuidelineMutations = {
  __typename?: 'DnaScoreTypeGuidelineMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaScoreTypeGuideline>>>;
  delete?: Maybe<Scalars['Boolean']>;
  /** available mutations of DNA score type guideline modules */
  DnaScoreTypeGuidelineResponse?: Maybe<DnaScoreTypeGuidelineResponseMutations>;
};

export type DnaScoreTypeGuidelineMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaScoreTypeGuidelineForm>>;
};

export type DnaScoreTypeGuidelineMutationsDeleteArgs = {
  forms: Array<Maybe<DnaScoreTypeGuidelinenDelete>>;
};

export type DnaScoreTypeGuidelinenDelete = {
  id: Scalars['String'];
};

export type DnaScoreTypeGuidelineQueries = {
  __typename?: 'DnaScoreTypeGuidelineQueries';
  findAll?: Maybe<DnaScoreTypeGuidelineQueryResult>;
  /** available queries of DNA score type guideline modules */
  DnaScoreTypeGuidelineResponse?: Maybe<DnaScoreTypeGuidelineResponseQueries>;
};

export type DnaScoreTypeGuidelineQueriesFindAllArgs = {
  where?: Maybe<DnaScoreTypeGuidelineQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaScoreTypeGuidelineQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaScoreTypeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaScoreTypeGuidelineQueryResult = {
  __typename?: 'DnaScoreTypeGuidelineQueryResult';
  results?: Maybe<Array<Maybe<DnaScoreTypeGuideline>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaScoreTypeGuidelineResponse = {
  __typename?: 'DnaScoreTypeGuidelineResponse';
  id?: Maybe<Scalars['String']>;
  dnaScoreTypeGuidelineId?: Maybe<Scalars['String']>;
  parameterBar?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dScoreTypeGuideline?: Maybe<DnaScoreTypeGuideline>;
};

export type DnaScoreTypeGuidelineResponseForm = {
  id?: Maybe<Scalars['String']>;
  dnaScoreTypeGuidelineId: Scalars['String'];
  parameterBar: Scalars['String'];
  response: Scalars['String'];
  language: Scalars['String'];
};

export type DnaScoreTypeGuidelineResponseMutations = {
  __typename?: 'DnaScoreTypeGuidelineResponseMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaScoreTypeGuidelineResponse>>>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type DnaScoreTypeGuidelineResponseMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaScoreTypeGuidelineResponseForm>>;
};

export type DnaScoreTypeGuidelineResponseMutationsDeleteArgs = {
  forms: Array<Maybe<DnaScoreTypeGuidelineResponsenDelete>>;
};

export type DnaScoreTypeGuidelineResponsenDelete = {
  id: Scalars['String'];
};

export type DnaScoreTypeGuidelineResponseQueries = {
  __typename?: 'DnaScoreTypeGuidelineResponseQueries';
  findAll?: Maybe<DnaScoreTypeGuidelineResponseQueryResult>;
};

export type DnaScoreTypeGuidelineResponseQueriesFindAllArgs = {
  where?: Maybe<DnaScoreTypeGuidelineResponseQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaScoreTypeGuidelineResponseQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaScoreTypeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaScoreTypeGuidelineResponseQueryResult = {
  __typename?: 'DnaScoreTypeGuidelineResponseQueryResult';
  results?: Maybe<Array<Maybe<DnaScoreTypeGuidelineResponse>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaScoreTypeMutations = {
  __typename?: 'DnaScoreTypeMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaScoreType>>>;
  delete?: Maybe<Scalars['Boolean']>;
  /** available mutations of DNA score type guideline modules */
  DnaScoreTypeGuideline?: Maybe<DnaScoreTypeGuidelineMutations>;
};

export type DnaScoreTypeMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaScoreTypeForm>>;
};

export type DnaScoreTypeMutationsDeleteArgs = {
  forms: Array<Maybe<DnaScoreTypenDelete>>;
};

export type DnaScoreTypenDelete = {
  id: Scalars['String'];
};

export type DnaScoreTypeQueries = {
  __typename?: 'DnaScoreTypeQueries';
  findAll?: Maybe<DnaScoreTypeQueryResult>;
  /** available queries of DNA score type guideline modules */
  DnaScoreTypeGuideline?: Maybe<DnaScoreTypeGuidelineQueries>;
};

export type DnaScoreTypeQueriesFindAllArgs = {
  where?: Maybe<DnaScoreTypeQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaScoreTypeQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHigherBetter?: Maybe<Scalars['Boolean']>;
  responseCount?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaScoreTypeQueryResult = {
  __typename?: 'DnaScoreTypeQueryResult';
  results?: Maybe<Array<Maybe<DnaScoreType>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaSearch = {
  __typename?: 'DNASearch';
  id?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  type?: Maybe<DnaSearchType>;
};

export type DnaSearchCategoryQueryResult = {
  __typename?: 'DNASearchCategoryQueryResult';
  results?: Maybe<Array<Maybe<DnaCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

/** available mutations of DNA Search modules */
export type DnaSearchMutations = {
  __typename?: 'DNASearchMutations';
  /** record customer open category search result event */
  openCategory?: Maybe<DnaSearch>;
  /** deprecated; record customer open report search result event */
  openReport?: Maybe<DnaSearch>;
  /** record customer open report search result event */
  recordOpenReport?: Maybe<DnaSearch>;
};

/** available mutations of DNA Search modules */
export type DnaSearchMutationsOpenCategoryArgs = {
  asarenDNACategoryId: Scalars['Int'];
};

/** available mutations of DNA Search modules */
export type DnaSearchMutationsOpenReportArgs = {
  bioinformaticReportId: Scalars['String'];
};

/** available mutations of DNA Search modules */
export type DnaSearchMutationsRecordOpenReportArgs = {
  bioinformaticReportId: Scalars['String'];
};

/** available queries of DNA Search modules */
export type DnaSearchQueries = {
  __typename?: 'DNASearchQueries';
  /** search dna category by keyword. Default data length is 4 */
  searchCategory?: Maybe<DnaSearchCategoryQueryResult>;
  /** search dna report by keyword. Default data length is 4 */
  searchReport?: Maybe<DnaSearchReportQueryResult>;
};

/** available queries of DNA Search modules */
export type DnaSearchQueriesSearchCategoryArgs = {
  where?: Maybe<DnaSearchReportFilter>;
  limit?: Maybe<Scalars['Int']>;
};

/** available queries of DNA Search modules */
export type DnaSearchQueriesSearchReportArgs = {
  where?: Maybe<DnaSearchReportFilter>;
  limit?: Maybe<Scalars['Int']>;
};

export type DnaSearchReportFilter = {
  keyword?: Maybe<Scalars['String']>;
  asarenDNACategoryId?: Maybe<Scalars['Int']>;
  profileId?: Maybe<Scalars['String']>;
  reportType: ReportType;
};

export type DnaSearchReportQueryResult = {
  __typename?: 'DNASearchReportQueryResult';
  results?: Maybe<Array<Maybe<BioinformaticReport>>>;
  count?: Maybe<Scalars['Int']>;
};

/** available types of dna search */
export enum DnaSearchType {
  Report = 'REPORT',
  Category = 'CATEGORY'
}

export type DnaSubCategory = {
  __typename?: 'DnaSubCategory';
  id?: Maybe<Scalars['String']>;
  dnaCategoryId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dCategory?: Maybe<DnaCategory>;
  dSubCategoryTranslation?: Maybe<Array<Maybe<DnaSubCategoryTranslation>>>;
  dTrait?: Maybe<Array<Maybe<DnaTrait>>>;
};

export type DnaSubCategoryForm = {
  id?: Maybe<Scalars['String']>;
  dnaCategoryId: Scalars['Int'];
  name: Scalars['String'];
  dSubCategoryTranslation?: Maybe<Array<Maybe<DnaSubCategoryTranslationForm>>>;
};

export type DnaSubCategoryMutations = {
  __typename?: 'DnaSubCategoryMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaSubCategory>>>;
  exportExcel360?: Maybe<Scalars['Boolean']>;
  /** available mutations of DNA Translation Translation modules */
  DnaSubCategoryTranslation?: Maybe<DnaSubCategoryTranslationMutations>;
};

export type DnaSubCategoryMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaSubCategoryForm>>;
};

export type DnaSubCategoryMutationsExportExcel360Args = {
  where?: Maybe<DnaSubCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  sortObject?: Maybe<DnaSubCategoryQuerySortBy>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaSubCategoryQueries = {
  __typename?: 'DnaSubCategoryQueries';
  findAll?: Maybe<DnaSubCategoryQueryResult>;
  /** available queries of DNA Translation Translation modules */
  DnaSubCategoryTranslation?: Maybe<DnaSubCategoryTranslationQueries>;
};

export type DnaSubCategoryQueriesFindAllArgs = {
  where?: Maybe<DnaSubCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  sortObject?: Maybe<DnaSubCategoryQuerySortBy>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaSubCategoryQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaCategoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dnaPanelId?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaSubCategoryQueryResult = {
  __typename?: 'DnaSubCategoryQueryResult';
  results?: Maybe<Array<Maybe<DnaSubCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaSubCategoryQuerySortBy = {
  id?: Maybe<SortDirection>;
  panelName?: Maybe<SortDirection>;
  categoryName?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type DnaSubCategoryTranslation = {
  __typename?: 'DnaSubCategoryTranslation';
  id?: Maybe<Scalars['String']>;
  dnaSubCategoryId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dSubCategory?: Maybe<DnaSubCategory>;
};

export type DnaSubCategoryTranslationForm = {
  id?: Maybe<Scalars['String']>;
  dnaSubCategoryId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  language: Scalars['String'];
};

export type DnaSubCategoryTranslationMutations = {
  __typename?: 'DnaSubCategoryTranslationMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaSubCategoryTranslation>>>;
};

export type DnaSubCategoryTranslationMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaSubCategoryTranslationForm>>;
};

export type DnaSubCategoryTranslationQueries = {
  __typename?: 'DnaSubCategoryTranslationQueries';
  findAll?: Maybe<DnaSubCategoryTranslationQueryResult>;
};

export type DnaSubCategoryTranslationQueriesFindAllArgs = {
  where?: Maybe<DnaSubCategoryTranslationQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaSubCategoryTranslationQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaSubCategoryId?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type DnaSubCategoryTranslationQueryResult = {
  __typename?: 'DnaSubCategoryTranslationQueryResult';
  results?: Maybe<Array<Maybe<DnaSubCategoryTranslation>>>;
  count?: Maybe<Scalars['Int']>;
};

export type DnaTrait = {
  __typename?: 'DnaTrait';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  tips?: Maybe<Scalars['String']>;
  causes?: Maybe<Scalars['String']>;
  funFact?: Maybe<Scalars['String']>;
  symptom?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
  category?: Maybe<DnaCategory>;
  reportType?: Maybe<Scalars['String']>;
  translation?: Maybe<DnaTraitTranslation>;
  ownerId?: Maybe<Scalars['String']>;
  guidelineType?: Maybe<Scalars['String']>;
  dnaSubCategoryId?: Maybe<Scalars['String']>;
  dSubCategory?: Maybe<DnaSubCategory>;
};

export type DnaTraitForm = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  translation?: Maybe<DnaTraitTranslationForm>;
  categoryId?: Maybe<Scalars['Int']>;
  dnaSubCategoryId?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
};

export type DnaTraitMutations = {
  __typename?: 'DnaTraitMutations';
  createOrUpdate?: Maybe<Array<Maybe<DnaTrait>>>;
  update?: Maybe<DnaTrait>;
  export?: Maybe<Scalars['String']>;
  DnaTraitScore?: Maybe<DnaTraitScoreMutations>;
  exportExcel360?: Maybe<Scalars['Boolean']>;
};

export type DnaTraitMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<DnaTraitForm>>;
};

export type DnaTraitMutationsUpdateArgs = {
  id: Scalars['Int'];
  form: DnaCategoryForm;
};

export type DnaTraitMutationsExportArgs = {
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  reportType?: Maybe<Scalars['String']>;
};

export type DnaTraitMutationsExportExcel360Args = {
  where?: Maybe<DnaTraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaTraitSortBy>;
};

export type DnaTraitQueries = {
  __typename?: 'DnaTraitQueries';
  findAll?: Maybe<DnaTraitQueryResult>;
  findOne?: Maybe<DnaTrait>;
  DnaTraitScore?: Maybe<DnaTraitScoreQueries>;
};

export type DnaTraitQueriesFindAllArgs = {
  where?: Maybe<DnaTraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaTraitSortBy>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
  count?: Maybe<Scalars['Boolean']>;
};

export type DnaTraitQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type DnaTraitQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dnaSubCategoryId?: Maybe<Array<Maybe<Scalars['String']>>>;
  notId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** TODO - change reportType from PurchaseProductType to PurchaseProductType! after 1.3.8 release */
  reportType?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
};

export type DnaTraitQueryResult = {
  __typename?: 'DnaTraitQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<DnaTrait>>>;
};

export type DnaTraitScore = {
  __typename?: 'DnaTraitScore';
  id?: Maybe<Scalars['String']>;
  dnaTraitId?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Scalars['String']>;
  dos?: Maybe<Scalars['String']>;
  donts?: Maybe<Scalars['String']>;
  translation?: Maybe<Array<Maybe<DnaTraitScoreTranslation>>>;
};

export type DnaTraitScoreForm = {
  id?: Maybe<Scalars['String']>;
  dnaTraitId?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Scalars['String']>;
  dos?: Maybe<Scalars['String']>;
  donts?: Maybe<Scalars['String']>;
  translation?: Maybe<DnaTraitScoreTranslationForm>;
};

export type DnaTraitScoreMutations = {
  __typename?: 'DnaTraitScoreMutations';
  update?: Maybe<Scalars['Boolean']>;
  DnaTraitScoreTranslation?: Maybe<DnaTraitScoreTranslationMutations>;
};

export type DnaTraitScoreQueries = {
  __typename?: 'DnaTraitScoreQueries';
  findAll?: Maybe<Scalars['Boolean']>;
  DnaTraitScoreTranslation?: Maybe<DnaTraitScoreTranslationQueries>;
};

export type DnaTraitScoreTranslation = {
  __typename?: 'DnaTraitScoreTranslation';
  id?: Maybe<Scalars['String']>;
  dnaTraitScoreId?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Scalars['String']>;
  dos?: Maybe<Scalars['String']>;
  donts?: Maybe<Scalars['String']>;
};

export type DnaTraitScoreTranslationForm = {
  id?: Maybe<Scalars['String']>;
  dnaTraitScoreId?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  symptoms?: Maybe<Scalars['String']>;
  dos?: Maybe<Scalars['String']>;
  donts?: Maybe<Scalars['String']>;
};

export type DnaTraitScoreTranslationMutations = {
  __typename?: 'DnaTraitScoreTranslationMutations';
  update?: Maybe<Scalars['Boolean']>;
};

export type DnaTraitScoreTranslationQueries = {
  __typename?: 'DnaTraitScoreTranslationQueries';
  findAll?: Maybe<Scalars['Boolean']>;
};

export type DnaTraitSortBy = {
  name?: Maybe<SortDirection>;
};

export type DnaTraitTranslation = {
  __typename?: 'DnaTraitTranslation';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dnaTraitId?: Maybe<Scalars['Int']>;
  langCode?: Maybe<Scalars['String']>;
};

export type DnaTraitTranslationForm = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  langCode?: Maybe<Scalars['String']>;
};

export type DnaUser = {
  __typename?: 'DnaUser';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
};

export type DnaUserFilter = {
  profiles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DnaUserQueries = {
  __typename?: 'DnaUserQueries';
  findAll?: Maybe<DnaUserQueryResult>;
  findOne?: Maybe<DnaUser>;
};

export type DnaUserQueriesFindAllArgs = {
  where?: Maybe<DnaUserFilter>;
};

export type DnaUserQueryResult = {
  __typename?: 'DnaUserQueryResult';
  results?: Maybe<Array<Maybe<DnaUser>>>;
  count?: Maybe<Scalars['Int']>;
};

export type EditOrderPurchaseProductForm = {
  id: Scalars['String'];
  to: Scalars['Int'];
};

export type EditPurchaseOrderShipmentForm = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  courier?: Maybe<Scalars['String']>;
};

export type EditPurchaseProductCategoryForm = {
  categoryId?: Maybe<Scalars['Int']>;
  typeId?: Maybe<Scalars['String']>;
};

export type EditPurchaseProductForm = {
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  /** Deprecated replaced with description */
  details?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  discount?: Maybe<PurchaseProductDiscountForm>;
  weight?: Maybe<Scalars['Float']>;
  types?: Maybe<Array<Maybe<PurchaseProductType>>>;
  serviceMethods?: Maybe<Array<Maybe<PurchaseProductServiceMethod>>>;
  imageApp?: Maybe<Scalars['Upload']>;
  imageWeb?: Maybe<Scalars['Upload']>;
  bgImage?: Maybe<Scalars['Upload']>;
  showOnShop?: Maybe<Scalars['Boolean']>;
  isFreeHomeService?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  periodFrom?: Maybe<Scalars['Date']>;
  periodTo?: Maybe<Scalars['Date']>;
  purchaseType?: Maybe<PurchaseProductPurchaseType>;
  bundling?: Maybe<Array<Maybe<PurchaseProductBundlingFormEdit>>>;
  upsell?: Maybe<PurchaseProductUpsellFormEdit>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Float']>;
  preOrderType?: Maybe<PurchaseProductPreorderType>;
  preOrderExpiredAt?: Maybe<Scalars['Date']>;
  preOrderFullfillmentDuration?: Maybe<Scalars['Int']>;
  preOrderFullfillmentInterval?: Maybe<
    PurchaseProductpreOrderFullfillmentInterval
  >;
  preOrderFullfillmentDate?: Maybe<Scalars['String']>;
  requirement?: Maybe<PurchaseProductRequirementForm>;
  order?: Maybe<Scalars['Int']>;
};

export type EditPurchaseProductPickupForm = {
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type EditPurchaseProductReportForm = {
  traitId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseProductReportStatus>;
  price?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
};

/** DEPRECATED, after this pr https://bitbucket.org/asa-ren/asaren-api/pull-requests/686 merged in the master/main branch */
export enum EhrAndMcuType {
  Mcu = 'MCU',
  EhrLab = 'EHR_LAB'
}

/** data structure of ehr-model. */
export type EhrLab = {
  __typename?: 'EHRLab';
  id?: Maybe<Scalars['String']>;
  laboratorium?: Maybe<Scalars['String']>;
  resultTypeId?: Maybe<Scalars['Int']>;
  resultType?: Maybe<EhrLabResultType>;
  attachments?: Maybe<Array<Maybe<EhrLabAttachment>>>;
  ownerProfile?: Maybe<UserProfile>;
  ownerProfileId?: Maybe<Scalars['String']>;
  takenAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  ehrLabPatient?: Maybe<EhrLabPatient>;
  ehrOrder?: Maybe<EhrOrder>;
  source?: Maybe<EhrLabSource>;
  resultName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** DEPRECATED, after this pr https://bitbucket.org/asa-ren/asaren-api/pull-requests/686 merged in the master/main branch */
export type EhrLabAndMcu = {
  __typename?: 'EHRLabAndMCU';
  /** id from ehr-mcu-junction table. */
  id?: Maybe<Scalars['String']>;
  mcu?: Maybe<Mcu>;
  ehr?: Maybe<EhrLab>;
  /** if the type is EHR, the ehr data is filled and mcu data is null, otherwise it will be the opposite. */
  type?: Maybe<EhrAndMcuType>;
};

export type EhrLabAndMcuQueryResult = {
  __typename?: 'EHRLabAndMCUQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<EhrLabAndMcu>>>;
};

/** DEPRECATED, after this pr https://bitbucket.org/asa-ren/asaren-api/pull-requests/686 merged in the master/main branch */
export type EhrLabAndMcuQuerySortBy = {
  takenAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** Data structure of ehr-attachment model. */
export type EhrLabAttachment = {
  __typename?: 'EHRLabAttachment';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pointer?: Maybe<Scalars['String']>;
  ehrLabId?: Maybe<Scalars['String']>;
};

export type EhrLabAttachmentForm = {
  name: Scalars['String'];
  file: Scalars['Upload'];
};

export type EhrLabAttachmentFormCreateOrEdit = {
  attachmentId?: Maybe<Scalars['ID']>;
  ehrLabId: Scalars['ID'];
  file: Scalars['Upload'];
};

export type EhrLabAttachmentMutations = {
  __typename?: 'EHRLabAttachmentMutations';
  delete?: Maybe<Scalars['Boolean']>;
  createOrEdit?: Maybe<EhrLabAttachment>;
};

export type EhrLabAttachmentMutationsDeleteArgs = {
  id: Array<Maybe<Scalars['String']>>;
};

export type EhrLabAttachmentMutationsCreateOrEditArgs = {
  form?: Maybe<EhrLabAttachmentFormCreateOrEdit>;
};

export type EhrLabAttachmentQueries = {
  __typename?: 'EHRLabAttachmentQueries';
  findOneAttachment?: Maybe<EhrLabAttachment>;
};

export type EhrLabAttachmentQueriesFindOneAttachmentArgs = {
  id: Scalars['String'];
};

export type EhrLabForm = {
  laboratorium?: Maybe<Scalars['String']>;
  resultTypeId?: Maybe<Scalars['Int']>;
  takenAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  ehrOrderId?: Maybe<Scalars['ID']>;
  resultName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** EHRLab Mutations */
export type EhrLabMutations = {
  __typename?: 'EHRLabMutations';
  add?: Maybe<EhrLab>;
  edit?: Maybe<EhrLab>;
  ResultType?: Maybe<EhrLabResultTypeMutations>;
  Attachment?: Maybe<EhrLabAttachmentMutations>;
};

/** EHRLab Mutations */
export type EhrLabMutationsAddArgs = {
  form?: Maybe<EhrLabForm>;
};

/** EHRLab Mutations */
export type EhrLabMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<EhrLabForm>;
};

export type EhrLabPatient = {
  __typename?: 'EHRLabPatient';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
};

/** EHRLab Queries */
export type EhrLabQueries = {
  __typename?: 'EHRLabQueries';
  findOne?: Maybe<EhrLab>;
  findAll?: Maybe<EhrLabQueryResult>;
  /** DEPRECATED, after this pr https://bitbucket.org/asa-ren/asaren-api/pull-requests/686 merged in the master/main branch */
  findAllEHRAndMCU?: Maybe<EhrLabAndMcuQueryResult>;
  /** this query is for querying ehr-order with type MCU and manual created by user EHR-Lab. (currently this is for mobile apps) */
  findAllEHRMCUAndEHRLab?: Maybe<EhrmcuAndEhrLabQueryResult>;
  ResultType?: Maybe<EhrLabResultTypeQueries>;
  Attachment?: Maybe<EhrLabAttachmentQueries>;
};

/** EHRLab Queries */
export type EhrLabQueriesFindOneArgs = {
  id: Scalars['String'];
};

/** EHRLab Queries */
export type EhrLabQueriesFindAllArgs = {
  where?: Maybe<EhrLabQueryFilter>;
  sort?: Maybe<EhrLabQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** EHRLab Queries */
export type EhrLabQueriesFindAllEhrAndMcuArgs = {
  sort?: Maybe<EhrLabAndMcuQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** EHRLab Queries */
export type EhrLabQueriesFindAllEhrmcuAndEhrLabArgs = {
  where?: Maybe<EhrmcuAndEhrLabQueryFilter>;
  sort?: Maybe<EhrmcuAndEhrLabQuerySortyBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EhrLabQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  ehrOrderId?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultTypeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  takenAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type EhrLabQueryResult = {
  __typename?: 'EHRLabQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<EhrLab>>>;
};

export type EhrLabQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  phone?: Maybe<SortDirection>;
  laboratorium?: Maybe<SortDirection>;
};

/** Lab Result Type of EHR. */
export type EhrLabResultType = {
  __typename?: 'EHRLabResultType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type EhrLabResultTypeForm = {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type EhrLabResultTypeMutations = {
  __typename?: 'EHRLabResultTypeMutations';
  add?: Maybe<EhrLabResultType>;
  edit?: Maybe<EhrLabResultType>;
};

export type EhrLabResultTypeMutationsAddArgs = {
  form?: Maybe<EhrLabResultTypeForm>;
};

export type EhrLabResultTypeMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<EhrLabResultTypeForm>;
};

export type EhrLabResultTypeQueries = {
  __typename?: 'EHRLabResultTypeQueries';
  findOne?: Maybe<EhrLabResultType>;
  findAll?: Maybe<EhrLabResultTypeQueryResult>;
};

export type EhrLabResultTypeQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type EhrLabResultTypeQueriesFindAllArgs = {
  where?: Maybe<EhrLabResultTypeQueryFilter>;
  sort?: Maybe<EhrLabResultTypeQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EhrLabResultTypeQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  code?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EhrLabResultTypeQueryResult = {
  __typename?: 'EHRLabResultTypeQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<EhrLabResultType>>>;
};

export type EhrLabResultTypeQuerySortBy = {
  id?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum EhrLabSource {
  ManualUploadByUser = 'MANUAL_UPLOAD_BY_USER',
  ManualUploadByAdmin = 'MANUAL_UPLOAD_BY_ADMIN'
}

export type EhrmcuAndEhrLabQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  ehrLabId?: Maybe<Array<Maybe<Scalars['String']>>>;
  ehrOrderId?: Maybe<Array<Maybe<Scalars['String']>>>;
  resultTypeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  takenAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type EhrmcuAndEhrLabQueryResult = {
  __typename?: 'EHRMCUAndEHRLabQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<EhrOrderAndEhrLab>>>;
};

export type EhrmcuAndEhrLabQuerySortyBy = {
  createdAt?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  phone?: Maybe<SortDirection>;
  laboratorium?: Maybe<SortDirection>;
};

/** EHRLab Mutations */
export type EhrMutations = {
  __typename?: 'EHRMutations';
  Lab?: Maybe<EhrLabMutations>;
  Order?: Maybe<EhrOrderMutations>;
};

export type EhrOrder = {
  __typename?: 'EHROrder';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<PurchaseOrderType>;
  status?: Maybe<EhrOrderStatus>;
  invoice?: Maybe<Scalars['String']>;
  visitedAt?: Maybe<Scalars['Date']>;
  orderId?: Maybe<Scalars['String']>;
  order?: Maybe<PurchaseOrder>;
  ehrLabResultTypeId?: Maybe<Scalars['String']>;
  ehrLabResultType?: Maybe<EhrLabResultType>;
  creationType?: Maybe<EhrOrderCreationType>;
  buyerProfileId?: Maybe<Scalars['String']>;
  buyerProfile?: Maybe<UserProfile>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type EhrOrderAndEhrLab = {
  __typename?: 'EHROrderAndEHRLab';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<EhrOrderAndEhrLabType>;
  resultTypeId?: Maybe<Scalars['Int']>;
  ownerProfileId?: Maybe<Scalars['String']>;
  ehrLabId?: Maybe<Scalars['String']>;
  ehrOrderId?: Maybe<Scalars['String']>;
  /** The date that our team visited and taken the sample from the patient home. */
  takenAt?: Maybe<Scalars['Date']>;
  ehrLab?: Maybe<EhrLab>;
  ehrOrder?: Maybe<EhrOrder>;
  resultType?: Maybe<EhrLabResultType>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum EhrOrderAndEhrLabType {
  EhrOrder = 'EHR_ORDER',
  EhrLab = 'EHR_LAB'
}

export enum EhrOrderCreationType {
  /** the data created by system. */
  Regular = 'REGULAR',
  /** the data created by admin. */
  Manual = 'MANUAL'
}

export type EhrOrderForm = {
  type?: Maybe<PurchaseOrderType>;
  buyerProfileId?: Maybe<Scalars['ID']>;
  status?: Maybe<EhrOrderStatus>;
  visitedAt?: Maybe<Scalars['Date']>;
  ehrLabResultTypeId?: Maybe<Scalars['ID']>;
};

export type EhrOrderMutations = {
  __typename?: 'EHROrderMutations';
  addManual?: Maybe<EhrOrder>;
};

export type EhrOrderMutationsAddManualArgs = {
  form?: Maybe<EhrOrderForm>;
};

export type EhrOrderQueries = {
  __typename?: 'EHROrderQueries';
  findOne?: Maybe<EhrOrder>;
  findAll?: Maybe<EhrOrderQueryResult>;
};

export type EhrOrderQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type EhrOrderQueriesFindAllArgs = {
  sort?: Maybe<EhrOrderQuerySortBy>;
  where?: Maybe<EhrOrderQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EhrOrderQueryFilter = {
  status?: Maybe<Array<Maybe<EhrOrderStatus>>>;
  keyword?: Maybe<Scalars['String']>;
  visitedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EhrOrderQueryResult = {
  __typename?: 'EHROrderQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<EhrOrder>>>;
};

export type EhrOrderQuerySortBy = {
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<SortDirection>;
  invoice?: Maybe<SortDirection>;
  visitedAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum EhrOrderStatus {
  WaitingForVisit = 'WAITING_FOR_VISIT',
  WaitingForResult = 'WAITING_FOR_RESULT',
  Complete = 'COMPLETE'
}

/** EHRLab Queries */
export type EhrQueries = {
  __typename?: 'EHRQueries';
  Lab?: Maybe<EhrLabQueries>;
  Order?: Maybe<EhrOrderQueries>;
};

export type Event = {
  __typename?: 'Event';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  category?: Maybe<EventCategory>;
  platform?: Maybe<Platform>;
  count?: Maybe<Scalars['Int']>;
};

export type EventCategory = {
  __typename?: 'EventCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EventCategoryQueries = {
  __typename?: 'EventCategoryQueries';
  findAll?: Maybe<EventCategoryResults>;
};

export type EventCategoryQueriesFindAllArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EventCategoryResults = {
  __typename?: 'EventCategoryResults';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<EventCategory>>>;
};

export type EventMutations = {
  __typename?: 'EventMutations';
  Track?: Maybe<EventTrackMutations>;
};

export type EventQueries = {
  __typename?: 'EventQueries';
  findOne?: Maybe<Event>;
  findAll?: Maybe<EventQueryResults>;
  Track?: Maybe<EventTrackQueries>;
  Category?: Maybe<EventCategoryQueries>;
};

export type EventQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type EventQueriesFindAllArgs = {
  where?: Maybe<EventQueryFilter>;
  sort?: Maybe<EventQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EventQueryFilter = {
  name?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
};

export type EventQueryResults = {
  __typename?: 'EventQueryResults';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Event>>>;
};

export type EventQuerySortBy = {
  name?: Maybe<SortDirection>;
  count?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  platform?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type EventTrack = {
  __typename?: 'EventTrack';
  id?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  profile?: Maybe<UserProfile>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type EventTrackAddForm = {
  event: Scalars['String'];
  platform: Platform;
  category: Scalars['String'];
  referenceId?: Maybe<Scalars['String']>;
};

export type EventTrackMutations = {
  __typename?: 'EventTrackMutations';
  add?: Maybe<Scalars['Boolean']>;
  exportExcel?: Maybe<Scalars['String']>;
};

export type EventTrackMutationsAddArgs = {
  form: EventTrackAddForm;
};

export type EventTrackMutationsExportExcelArgs = {
  where?: Maybe<EventTrackQueryFilter>;
  sort?: Maybe<EventTrackQuerySortBy>;
};

export type EventTrackQueries = {
  __typename?: 'EventTrackQueries';
  findAll?: Maybe<EventTrackQueryResult>;
};

export type EventTrackQueriesFindAllArgs = {
  where?: Maybe<EventTrackQueryFilter>;
  sort?: Maybe<EventTrackQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EventTrackQueryFilter = {
  eventId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EventTrackQueryResult = {
  __typename?: 'EventTrackQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<EventTrack>>>;
};

export type EventTrackQuerySortBy = {
  name?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  phone?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** health allergy */
export type HealthAllergy = {
  __typename?: 'HealthAllergy';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  antidote?: Maybe<Scalars['String']>;
};

export type HealthAllergyForm = {
  name?: Maybe<Scalars['String']>;
  antidote?: Maybe<Scalars['String']>;
};

/** health allergy module's mutations */
export type HealthAllergyMutations = {
  __typename?: 'HealthAllergyMutations';
  add?: Maybe<HealthAllergy>;
  edit?: Maybe<HealthAllergy>;
  delete?: Maybe<Scalars['Boolean']>;
};

/** health allergy module's mutations */
export type HealthAllergyMutationsAddArgs = {
  form?: Maybe<HealthAllergyForm>;
};

/** health allergy module's mutations */
export type HealthAllergyMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthAllergyForm>;
};

/** health allergy module's mutations */
export type HealthAllergyMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health allergy module's queries */
export type HealthAllergyQueries = {
  __typename?: 'HealthAllergyQueries';
  findAll?: Maybe<HealthAllergyQueryResult>;
  findOne?: Maybe<HealthAllergy>;
};

/** health allergy module's queries */
export type HealthAllergyQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health allergy module's queries */
export type HealthAllergyQueriesFindOneArgs = {
  id: Scalars['Int'];
};

/** health allergy query result */
export type HealthAllergyQueryResult = {
  __typename?: 'HealthAllergyQueryResult';
  results?: Maybe<Array<Maybe<HealthAllergy>>>;
  count?: Maybe<Scalars['Int']>;
};

/** health article */
export type HealthArticle = {
  __typename?: 'HealthArticle';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  category?: Maybe<HealthArticleCategory>;
  createdAt?: Maybe<Scalars['Date']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  subCategories?: Maybe<Array<Maybe<HealthArticleSubCategory>>>;
  relatedSubCategories?: Maybe<Array<Maybe<HealthArticleSubCategory>>>;
  viewers?: Maybe<Array<Maybe<HealthArticleViewer>>>;
  nViewers?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  metaKeyphrase?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
};

/** health article category */
export type HealthArticleCategory = {
  __typename?: 'HealthArticleCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  subcategories?: Maybe<Array<Maybe<HealthArticleSubCategory>>>;
  isAssociated?: Maybe<Scalars['Boolean']>;
};

export type HealthArticleCategoryForm = {
  name?: Maybe<Scalars['String']>;
};

/** health article category module's mutations */
export type HealthArticleCategoryMutations = {
  __typename?: 'HealthArticleCategoryMutations';
  add?: Maybe<HealthArticleCategory>;
  edit?: Maybe<HealthArticleCategory>;
  delete?: Maybe<Scalars['Boolean']>;
};

/** health article category module's mutations */
export type HealthArticleCategoryMutationsAddArgs = {
  form?: Maybe<HealthArticleCategoryForm>;
};

/** health article category module's mutations */
export type HealthArticleCategoryMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthArticleCategoryForm>;
};

/** health article category module's mutations */
export type HealthArticleCategoryMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health article category module's queries */
export type HealthArticleCategoryQueries = {
  __typename?: 'HealthArticleCategoryQueries';
  findAll?: Maybe<HealthArticleCategoryQueryResult>;
  findOne?: Maybe<HealthArticleCategory>;
};

/** health article category module's queries */
export type HealthArticleCategoryQueriesFindAllArgs = {
  where?: Maybe<HealthArticleCategoryQueryFilter>;
  sort?: Maybe<HealthArticleCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health article category module's queries */
export type HealthArticleCategoryQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type HealthArticleCategoryQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

/** health article category query result */
export type HealthArticleCategoryQueryResult = {
  __typename?: 'HealthArticleCategoryQueryResult';
  results?: Maybe<Array<Maybe<HealthArticleCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type HealthArticleCategoryQuerySortBy = {
  name?: Maybe<SortDirection>;
};

export type HealthArticleForm = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  subCategoryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  slug?: Maybe<Scalars['String']>;
  metaKeyphrase?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
};

/** health article module's mutations */
export type HealthArticleMutations = {
  __typename?: 'HealthArticleMutations';
  add?: Maybe<HealthArticle>;
  edit?: Maybe<HealthArticle>;
  archive?: Maybe<HealthArticle>;
  delete?: Maybe<Scalars['Boolean']>;
  /** health article category module's mutations */
  Category?: Maybe<HealthArticleCategoryMutations>;
  SubCategory?: Maybe<HealthArticleSubCategoryMutations>;
  /** health article viewer module's mutations */
  Viewer?: Maybe<HealthArticleViewerMutations>;
};

/** health article module's mutations */
export type HealthArticleMutationsAddArgs = {
  form?: Maybe<HealthArticleForm>;
};

/** health article module's mutations */
export type HealthArticleMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthArticleForm>;
};

/** health article module's mutations */
export type HealthArticleMutationsArchiveArgs = {
  id: Scalars['Int'];
  isArchived?: Maybe<Scalars['Boolean']>;
};

/** health article module's mutations */
export type HealthArticleMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health article module's queries */
export type HealthArticleQueries = {
  __typename?: 'HealthArticleQueries';
  findAll?: Maybe<HealthArticleQueryResult>;
  findOne?: Maybe<HealthArticle>;
  findOneBySlug?: Maybe<HealthArticle>;
  findMostViewed?: Maybe<Array<Maybe<HealthArticle>>>;
  search?: Maybe<HealthArticleQueryResult>;
  /** health article category module's queries */
  Category?: Maybe<HealthArticleCategoryQueries>;
  SubCategory?: Maybe<HealthArticleSubCategoryQueries>;
  /** health article viewer module's queries */
  Viewer?: Maybe<HealthArticleViewerQueries>;
};

/** health article module's queries */
export type HealthArticleQueriesFindAllArgs = {
  where?: Maybe<HealthArticleQueryFilter>;
  sort?: Maybe<HealthArticleQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health article module's queries */
export type HealthArticleQueriesFindOneArgs = {
  id: Scalars['Int'];
};

/** health article module's queries */
export type HealthArticleQueriesFindOneBySlugArgs = {
  slug?: Maybe<Scalars['String']>;
};

/** health article module's queries */
export type HealthArticleQueriesFindMostViewedArgs = {
  slug?: Maybe<Scalars['String']>;
};

/** health article module's queries */
export type HealthArticleQueriesSearchArgs = {
  keyword?: Maybe<Scalars['String']>;
  sort?: Maybe<HealthArticleQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type HealthArticleQueryFilter = {
  title?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  subCategoryId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  journalList?: Maybe<Scalars['Boolean']>;
  mobileList?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Array<Maybe<Scalars['Date']>>>;
};

/** health article query result */
export type HealthArticleQueryResult = {
  __typename?: 'HealthArticleQueryResult';
  results?: Maybe<Array<Maybe<HealthArticle>>>;
  count?: Maybe<Scalars['Int']>;
};

export type HealthArticleQuerySortBy = {
  title?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  nViewers?: Maybe<SortDirection>;
};

/** health article sub category */
export type HealthArticleSubCategory = {
  __typename?: 'HealthArticleSubCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<HealthArticleCategory>;
  categoryId?: Maybe<Scalars['Int']>;
  articles?: Maybe<Array<Maybe<HealthArticle>>>;
  isAssociated?: Maybe<Scalars['Boolean']>;
};

export type HealthArticleSubCategoryForm = {
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
};

/** health article sub category module's mutations */
export type HealthArticleSubCategoryMutations = {
  __typename?: 'HealthArticleSubCategoryMutations';
  add?: Maybe<HealthArticleSubCategory>;
  edit?: Maybe<HealthArticleSubCategory>;
  delete?: Maybe<Scalars['Boolean']>;
};

/** health article sub category module's mutations */
export type HealthArticleSubCategoryMutationsAddArgs = {
  form?: Maybe<HealthArticleSubCategoryForm>;
};

/** health article sub category module's mutations */
export type HealthArticleSubCategoryMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthArticleSubCategoryForm>;
};

/** health article sub category module's mutations */
export type HealthArticleSubCategoryMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health article sub category module's queries */
export type HealthArticleSubCategoryQueries = {
  __typename?: 'HealthArticleSubCategoryQueries';
  findAll?: Maybe<HealthArticleSubCategoryQueryResult>;
  findOne?: Maybe<HealthArticleSubCategory>;
};

/** health article sub category module's queries */
export type HealthArticleSubCategoryQueriesFindAllArgs = {
  where?: Maybe<HealthArticleSubCategoryQueryFilter>;
  sort?: Maybe<HealthArticleSubCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health article sub category module's queries */
export type HealthArticleSubCategoryQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type HealthArticleSubCategoryQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
};

/** health article sub category query result */
export type HealthArticleSubCategoryQueryResult = {
  __typename?: 'HealthArticleSubCategoryQueryResult';
  results?: Maybe<Array<Maybe<HealthArticleSubCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type HealthArticleSubCategoryQuerySortBy = {
  name?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
};

/** health article viewer */
export type HealthArticleViewer = {
  __typename?: 'HealthArticleViewer';
  id?: Maybe<Scalars['Int']>;
  identity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  articleId?: Maybe<Scalars['Int']>;
  n_viewers?: Maybe<Scalars['Int']>;
};

export type HealthArticleViewerForm = {
  identity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  articleId?: Maybe<Scalars['Int']>;
};

/** health article viewer module's mutations */
export type HealthArticleViewerMutations = {
  __typename?: 'HealthArticleViewerMutations';
  add?: Maybe<HealthArticleViewer>;
};

/** health article viewer module's mutations */
export type HealthArticleViewerMutationsAddArgs = {
  form?: Maybe<HealthArticleViewerForm>;
};

/** health article viewer module's queries */
export type HealthArticleViewerQueries = {
  __typename?: 'HealthArticleViewerQueries';
  findAll?: Maybe<HealthArticleViewerQueryResult>;
  findOne?: Maybe<HealthArticleViewer>;
};

/** health article viewer module's queries */
export type HealthArticleViewerQueriesFindAllArgs = {
  where?: Maybe<HealthArticleViewerQueryFilter>;
  sort?: Maybe<HealthArticleViewerQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health article viewer module's queries */
export type HealthArticleViewerQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type HealthArticleViewerQueryFilter = {
  identity?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  articleId?: Maybe<Scalars['Int']>;
};

/** health article viewer query result */
export type HealthArticleViewerQueryResult = {
  __typename?: 'HealthArticleViewerQueryResult';
  results?: Maybe<Array<Maybe<HealthArticleViewer>>>;
  count?: Maybe<Scalars['Int']>;
};

export type HealthArticleViewerQuerySortBy = {
  identity?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  n_viewers?: Maybe<SortDirection>;
};

/** health background */
export type HealthBackground = {
  __typename?: 'HealthBackground';
  id?: Maybe<Scalars['Int']>;
  asthma?: Maybe<Scalars['Boolean']>;
  smoker?: Maybe<Scalars['Boolean']>;
  obesity?: Maybe<Scalars['Boolean']>;
  diabetes?: Maybe<Scalars['Boolean']>;
  highBloodPressure?: Maybe<Scalars['Boolean']>;
};

export type HealthBackgroundForm = {
  asthma?: Maybe<Scalars['Boolean']>;
  smoker?: Maybe<Scalars['Boolean']>;
  obesity?: Maybe<Scalars['Boolean']>;
  diabetes?: Maybe<Scalars['Boolean']>;
  highBloodPressure?: Maybe<Scalars['Boolean']>;
  familyId?: Maybe<Scalars['Int']>;
};

/** health background module's mutations */
export type HealthBackgroundMutations = {
  __typename?: 'HealthBackgroundMutations';
  add?: Maybe<HealthBackground>;
};

/** health background module's mutations */
export type HealthBackgroundMutationsAddArgs = {
  form?: Maybe<HealthBackgroundForm>;
};

/** health background module's queries */
export type HealthBackgroundQueries = {
  __typename?: 'HealthBackgroundQueries';
  findOne?: Maybe<HealthBackground>;
};

/** health background module's queries */
export type HealthBackgroundQueriesFindOneArgs = {
  familyId?: Maybe<Scalars['Int']>;
};

/** health faq */
export type HealthFaq = {
  __typename?: 'HealthFAQ';
  answer?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
};

/** health glossary */
export type HealthGlossary = {
  __typename?: 'HealthGlossary';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  meaning?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type HealthGlossaryForm = {
  name?: Maybe<Scalars['String']>;
  meaning?: Maybe<Scalars['String']>;
};

export type HealthGlossaryMutations = {
  __typename?: 'HealthGlossaryMutations';
  add?: Maybe<HealthGlossary>;
  edit?: Maybe<HealthGlossary>;
  delete?: Maybe<Scalars['Boolean']>;
  init?: Maybe<Scalars['Boolean']>;
};

export type HealthGlossaryMutationsAddArgs = {
  form?: Maybe<HealthGlossaryForm>;
};

export type HealthGlossaryMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthGlossaryForm>;
};

export type HealthGlossaryMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health glossary module's queries */
export type HealthGlossaryQueries = {
  __typename?: 'HealthGlossaryQueries';
  findAll?: Maybe<HealthGlossaryQueryResult>;
  findOne?: Maybe<HealthGlossary>;
};

/** health glossary module's queries */
export type HealthGlossaryQueriesFindAllArgs = {
  where?: Maybe<HealthGlossaryQueryFilter>;
  sort?: Maybe<HealthGlossaryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health glossary module's queries */
export type HealthGlossaryQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type HealthGlossaryQueryFilter = {
  name?: Maybe<Scalars['String']>;
  meaning?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
};

/** health glossary query result */
export type HealthGlossaryQueryResult = {
  __typename?: 'HealthGlossaryQueryResult';
  results?: Maybe<Array<Maybe<HealthGlossary>>>;
  count?: Maybe<Scalars['Int']>;
};

export type HealthGlossaryQuerySortBy = {
  name?: Maybe<SortDirection>;
  meaning?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** health information */
export type HealthInformation = {
  __typename?: 'HealthInformation';
  id?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<Scalars['String']>;
  weightUnit?: Maybe<Scalars['String']>;
};

export type HealthInformationForm = {
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<Scalars['String']>;
  weightUnit?: Maybe<Scalars['String']>;
};

/** health information module's mutations */
export type HealthInformationMutations = {
  __typename?: 'HealthInformationMutations';
  add?: Maybe<HealthInformation>;
};

/** health information module's mutations */
export type HealthInformationMutationsAddArgs = {
  form?: Maybe<HealthInformationForm>;
};

/** health information module's queries */
export type HealthInformationQueries = {
  __typename?: 'HealthInformationQueries';
  findOne?: Maybe<HealthInformation>;
};

/** health medical */
export type HealthMedical = {
  __typename?: 'HealthMedical';
  id?: Maybe<Scalars['Int']>;
  name_en?: Maybe<Scalars['String']>;
  name_id?: Maybe<Scalars['String']>;
  isOther?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MedicalType>;
};

export type HealthMedicalForm = {
  name_en?: Maybe<Scalars['String']>;
  name_id?: Maybe<Scalars['String']>;
  isOther?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MedicalType>;
};

/** health medical history */
export type HealthMedicalHistory = {
  __typename?: 'HealthMedicalHistory';
  id?: Maybe<Scalars['Int']>;
  profile?: Maybe<UserProfile>;
  medical?: Maybe<HealthMedical>;
};

export type HealthMedicalHistoryForm = {
  profileId?: Maybe<Scalars['String']>;
  medicalId?: Maybe<Scalars['Int']>;
};

/** health medical history module's mutations */
export type HealthMedicalHistoryMutations = {
  __typename?: 'HealthMedicalHistoryMutations';
  add?: Maybe<HealthMedicalHistory>;
  edit?: Maybe<HealthMedicalHistory>;
  delete?: Maybe<Scalars['Boolean']>;
};

/** health medical history module's mutations */
export type HealthMedicalHistoryMutationsAddArgs = {
  form?: Maybe<HealthMedicalHistoryForm>;
};

/** health medical history module's mutations */
export type HealthMedicalHistoryMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthMedicalHistoryForm>;
};

/** health medical history module's mutations */
export type HealthMedicalHistoryMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health medical history module's queries */
export type HealthMedicalHistoryQueries = {
  __typename?: 'HealthMedicalHistoryQueries';
  findAll?: Maybe<HealthMedicalHistoryQueryResult>;
  findOne?: Maybe<HealthMedicalHistory>;
};

/** health medical history module's queries */
export type HealthMedicalHistoryQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health medical history module's queries */
export type HealthMedicalHistoryQueriesFindOneArgs = {
  id: Scalars['Int'];
};

/** health medical history query result */
export type HealthMedicalHistoryQueryResult = {
  __typename?: 'HealthMedicalHistoryQueryResult';
  results?: Maybe<Array<Maybe<HealthMedicalHistory>>>;
  count?: Maybe<Scalars['Int']>;
};

/** health medical module's mutations */
export type HealthMedicalMutations = {
  __typename?: 'HealthMedicalMutations';
  add?: Maybe<HealthMedical>;
  edit?: Maybe<HealthMedical>;
  delete?: Maybe<Scalars['Boolean']>;
  History?: Maybe<HealthMedicalHistoryMutations>;
};

/** health medical module's mutations */
export type HealthMedicalMutationsAddArgs = {
  form?: Maybe<HealthMedicalForm>;
};

/** health medical module's mutations */
export type HealthMedicalMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthMedicalForm>;
};

/** health medical module's mutations */
export type HealthMedicalMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health medical module's queries */
export type HealthMedicalQueries = {
  __typename?: 'HealthMedicalQueries';
  findAll?: Maybe<HealthMedicalQueryResult>;
  findOne?: Maybe<HealthMedical>;
  History?: Maybe<HealthMedicalHistoryQueries>;
};

/** health medical module's queries */
export type HealthMedicalQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health medical module's queries */
export type HealthMedicalQueriesFindOneArgs = {
  id: Scalars['Int'];
};

/** health medical query result */
export type HealthMedicalQueryResult = {
  __typename?: 'HealthMedicalQueryResult';
  results?: Maybe<Array<Maybe<HealthMedical>>>;
  count?: Maybe<Scalars['Int']>;
};

/** health medication */
export type HealthMedication = {
  __typename?: 'HealthMedication';
  id?: Maybe<Scalars['Int']>;
  drug?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  permanently?: Maybe<Scalars['Boolean']>;
};

export type HealthMedicationForm = {
  drug?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  permanently?: Maybe<Scalars['Boolean']>;
};

/** health medication module's mutations */
export type HealthMedicationMutations = {
  __typename?: 'HealthMedicationMutations';
  add?: Maybe<HealthMedication>;
  edit?: Maybe<HealthMedication>;
  delete?: Maybe<Scalars['Boolean']>;
};

/** health medication module's mutations */
export type HealthMedicationMutationsAddArgs = {
  form?: Maybe<HealthMedicationForm>;
};

/** health medication module's mutations */
export type HealthMedicationMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<HealthMedicationForm>;
};

/** health medication module's mutations */
export type HealthMedicationMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** health medication module's queries */
export type HealthMedicationQueries = {
  __typename?: 'HealthMedicationQueries';
  findAll?: Maybe<HealthMedicationQueryResult>;
  findOne?: Maybe<HealthMedication>;
};

/** health medication module's queries */
export type HealthMedicationQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** health medication module's queries */
export type HealthMedicationQueriesFindOneArgs = {
  id: Scalars['Int'];
};

/** health medication query result */
export type HealthMedicationQueryResult = {
  __typename?: 'HealthMedicationQueryResult';
  results?: Maybe<Array<Maybe<HealthMedication>>>;
  count?: Maybe<Scalars['Int']>;
};

/** health module's mutations */
export type HealthMutations = {
  __typename?: 'HealthMutations';
  /** health allergy module's mutations */
  Allergy?: Maybe<HealthAllergyMutations>;
  /** health article module's mutations */
  Article?: Maybe<HealthArticleMutations>;
  /** health background module's mutations */
  Background?: Maybe<HealthBackgroundMutations>;
  /** health glossary module's mutations */
  Glossary?: Maybe<HealthGlossaryMutations>;
  /** health information module's mutations */
  Information?: Maybe<HealthInformationMutations>;
  /** health medication module's mutations */
  Medication?: Maybe<HealthMedicationMutations>;
  /** health medical module's mutations */
  Medical?: Maybe<HealthMedicalMutations>;
};

/** health module's queries */
export type HealthQueries = {
  __typename?: 'HealthQueries';
  faq?: Maybe<Array<Maybe<HealthFaq>>>;
  setting?: Maybe<SettingPayload>;
  /** health allergy module's queries */
  Allergy?: Maybe<HealthAllergyQueries>;
  /** health article module's queries */
  Article?: Maybe<HealthArticleQueries>;
  /** health background module's queries */
  Background?: Maybe<HealthBackgroundQueries>;
  /** health glossary module's queries */
  Glossary?: Maybe<HealthGlossaryQueries>;
  /** health information module's queries */
  Information?: Maybe<HealthInformationQueries>;
  /** health medication module's queries */
  Medication?: Maybe<HealthMedicationQueries>;
  /** health medical module queries */
  Medical?: Maybe<HealthMedicalQueries>;
};

/** Health Score */
export type HealthScore = {
  __typename?: 'HealthScore';
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<HealthScoreInput>;
  output?: Maybe<HelathScoreOutput>;
  type?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type HealthScoreAscvdForm = {
  sex?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  race?: Maybe<Scalars['String']>;
  totalCholesterol?: Maybe<Scalars['Float']>;
  HDLCholesterol?: Maybe<Scalars['Float']>;
  treatedSystolicBloodPressure?: Maybe<Scalars['Float']>;
  untreatedSystolicBloodPressure?: Maybe<Scalars['Float']>;
  diabetesStatus?: Maybe<Scalars['Int']>;
  smokingStatus?: Maybe<Scalars['Int']>;
  type?: Maybe<HealthScoreTypeCode>;
};

export type HealthScoreAscvdInput = {
  __typename?: 'HealthScoreASCVDInput';
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  totalCholesterol?: Maybe<Scalars['Float']>;
  HDLCholesterol?: Maybe<Scalars['Float']>;
  treatedSystolicBloodPressure?: Maybe<Scalars['Float']>;
  untreatedSystolicBloodPressure?: Maybe<Scalars['Float']>;
  diabetesStatus?: Maybe<Scalars['Int']>;
  smokingStatus?: Maybe<Scalars['Int']>;
};

export type HealthScoreCkdForm = {
  sex?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  hypertension?: Maybe<Scalars['Int']>;
  dyslipidemia?: Maybe<Scalars['Int']>;
  diabetesStatus?: Maybe<Scalars['Int']>;
  hyperuricemia?: Maybe<Scalars['Int']>;
  eGFR?: Maybe<Scalars['Float']>;
};

export type HealthScoreDiabetesForm = {
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  hypertension?: Maybe<Scalars['Int']>;
  physicalActivity?: Maybe<Scalars['Int']>;
  familyHistory?: Maybe<Scalars['Int']>;
};

export type HealthScoreDiabetesInput = {
  __typename?: 'HealthScoreDiabetesInput';
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  hypertension?: Maybe<Scalars['Int']>;
  physicalActivity?: Maybe<Scalars['Int']>;
  familyHistory?: Maybe<Scalars['Int']>;
};

export type HealthScoreHypertensionForm = {
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  systolicBloodPressure?: Maybe<Scalars['Int']>;
  diastolicBloodPressure?: Maybe<Scalars['Int']>;
  smokingStatus?: Maybe<Scalars['Int']>;
  parentalHypertension?: Maybe<Scalars['Int']>;
};

export type HealthScoreHypertensionInput = {
  __typename?: 'HealthScoreHypertensionInput';
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  systolicBloodPressure?: Maybe<Scalars['Int']>;
  diastolicBloodPressure?: Maybe<Scalars['Int']>;
  smokingStatus?: Maybe<Scalars['Int']>;
  parentalHypertension?: Maybe<Scalars['Int']>;
};

export type HealthScoreInput =
  | HealthScoreAscvdInput
  | HealthScoreDiabetesInput
  | HealthScoreHypertensionInput
  | HealthScoreNafldInput;

/** Health Score module's mutations */
export type HealthScoreMutations = {
  __typename?: 'HealthScoreMutations';
  addASCVD?: Maybe<HealthScore>;
  addDiabetes?: Maybe<HealthScore>;
  addHypertension?: Maybe<HealthScore>;
  addNAFLD?: Maybe<HealthScore>;
  addCKD?: Maybe<HealthScore>;
  Type?: Maybe<HealthScoreTypeMutations>;
};

/** Health Score module's mutations */
export type HealthScoreMutationsAddAscvdArgs = {
  form: HealthScoreAscvdForm;
};

/** Health Score module's mutations */
export type HealthScoreMutationsAddDiabetesArgs = {
  form: HealthScoreDiabetesForm;
};

/** Health Score module's mutations */
export type HealthScoreMutationsAddHypertensionArgs = {
  form: HealthScoreHypertensionForm;
};

/** Health Score module's mutations */
export type HealthScoreMutationsAddNafldArgs = {
  form: HealthScoreNafldForm;
};

/** Health Score module's mutations */
export type HealthScoreMutationsAddCkdArgs = {
  form: HealthScoreCkdForm;
};

/** Non-Alkoholic Fatty Liver Disease */
export type HealthScoreNafldForm = {
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['String']>;
  typeTwoDiabetes?: Maybe<Scalars['Int']>;
  race?: Maybe<Scalars['String']>;
  triglycerides?: Maybe<Scalars['Int']>;
  gammaGlutamyltransferase?: Maybe<Scalars['Float']>;
  parentalHypertension?: Maybe<Scalars['Int']>;
};

export type HealthScoreNafldInput = {
  __typename?: 'HealthScoreNAFLDInput';
  age?: Maybe<Scalars['Int']>;
  sex?: Maybe<Scalars['String']>;
  bmi?: Maybe<Scalars['Float']>;
  smokingStatus?: Maybe<Scalars['String']>;
  typeTwoDiabetes?: Maybe<Scalars['Int']>;
  race?: Maybe<Scalars['String']>;
  triglycerides?: Maybe<Scalars['Int']>;
  gammaGlutamyltransferase?: Maybe<Scalars['Float']>;
  parentalHypertension?: Maybe<Scalars['Int']>;
};

/** Health Score module's queries */
export type HealthScoreQueries = {
  __typename?: 'HealthScoreQueries';
  Type?: Maybe<HealthScoreTypeQueries>;
  Report?: Maybe<HealthScoreReportQueries>;
};

/** Health Score Report */
export type HealthScoreReport = {
  __typename?: 'HealthScoreReport';
  id?: Maybe<Scalars['ID']>;
  score?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  interpretation?: Maybe<HealthScoreReportInterpretationValue>;
  type?: Maybe<HealthScoreType>;
  typeId?: Maybe<Scalars['String']>;
  healthScore?: Maybe<HealthScore>;
  healthScoreId?: Maybe<Scalars['ID']>;
};

export enum HealthScoreReportInterpretationValue {
  Low = 'LOW',
  Borderline = 'BORDERLINE',
  Intermediate = 'INTERMEDIATE',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Moderate = 'MODERATE'
}

/** Health Score Report module's queries */
export type HealthScoreReportQueries = {
  __typename?: 'HealthScoreReportQueries';
  findAll?: Maybe<HealthScoreReportQueryResult>;
  findOne?: Maybe<HealthScoreReport>;
};

/** Health Score Report module's queries */
export type HealthScoreReportQueriesFindAllArgs = {
  where?: Maybe<HealthScoreReportQueryFilter>;
  sort?: Maybe<HealthScoreReportQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** Health Score Report module's queries */
export type HealthScoreReportQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type HealthScoreReportQueryFilter = {
  healthScoreId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Health Score Report query result */
export type HealthScoreReportQueryResult = {
  __typename?: 'HealthScoreReportQueryResult';
  results?: Maybe<Array<Maybe<HealthScoreReport>>>;
  count?: Maybe<Scalars['Int']>;
};

export type HealthScoreReportQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

/** Health Score Type */
export type HealthScoreType = {
  __typename?: 'HealthScoreType';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  report?: Maybe<HealthScoreReport>;
  show?: Maybe<Scalars['Boolean']>;
};

export enum HealthScoreTypeCode {
  Ascvd = 'ASCVD',
  Diabetes = 'DIABETES',
  Hypertension = 'HYPERTENSION',
  Nafld = 'NAFLD',
  /** Chronic Kidney Disease */
  Ckd = 'CKD'
}

export type HealthScoreTypeForm = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['Boolean']>;
};

/** Health Score Type module's mutations */
export type HealthScoreTypeMutations = {
  __typename?: 'HealthScoreTypeMutations';
  add?: Maybe<HealthScoreType>;
  edit?: Maybe<HealthScoreType>;
  delete?: Maybe<Scalars['Boolean']>;
};

/** Health Score Type module's mutations */
export type HealthScoreTypeMutationsAddArgs = {
  form?: Maybe<HealthScoreTypeForm>;
};

/** Health Score Type module's mutations */
export type HealthScoreTypeMutationsEditArgs = {
  id: Scalars['ID'];
  form?: Maybe<HealthScoreTypeForm>;
};

/** Health Score Type module's mutations */
export type HealthScoreTypeMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** Health Score Type module's queries */
export type HealthScoreTypeQueries = {
  __typename?: 'HealthScoreTypeQueries';
  findAll?: Maybe<HealthScoreTypeQueryResult>;
  findOne?: Maybe<HealthScoreType>;
};

/** Health Score Type module's queries */
export type HealthScoreTypeQueriesFindAllArgs = {
  where?: Maybe<HealthScoreTypeQueryFilter>;
  sort?: Maybe<HealthScoreTypeQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** Health Score Type module's queries */
export type HealthScoreTypeQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type HealthScoreTypeQueryFilter = {
  available?: Maybe<Scalars['Boolean']>;
  show?: Maybe<Scalars['Boolean']>;
};

/** Health Score Type query result */
export type HealthScoreTypeQueryResult = {
  __typename?: 'HealthScoreTypeQueryResult';
  results?: Maybe<Array<Maybe<HealthScoreType>>>;
  count?: Maybe<Scalars['Int']>;
};

export type HealthScoreTypeQuerySortBy = {
  title?: Maybe<SortDirection>;
  position?: Maybe<SortDirection>;
};

/** health setting */
export type HealthSetting = {
  __typename?: 'HealthSetting';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  helpline?: Maybe<Scalars['String']>;
  callcenter?: Maybe<Scalars['String']>;
  callAppointment?: Maybe<Scalars['String']>;
};

export type HelathScoreOutput = {
  __typename?: 'HelathScoreOutput';
  riskScore?: Maybe<Scalars['Float']>;
  recommendation?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type HighlightPanel = {
  __typename?: 'HighlightPanel';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  favourableDescription?: Maybe<Scalars['String']>;
  unfavourableDescription?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dPanelTranslation?: Maybe<Array<Maybe<DnaPanelTranslation>>>;
  dPanelFigure?: Maybe<Array<Maybe<PanelFigure>>>;
};

export type HighlightQueryResult = {
  __typename?: 'HighlightQueryResult';
  results?: Maybe<Array<Maybe<HighlightPanel>>>;
  count?: Maybe<Scalars['Int']>;
};

export type InputWherePurchaseOrderFindAll = {
  status?: Maybe<PurchaseOrderStatus>;
};

export type InteractomeArticle = {
  __typename?: 'InteractomeArticle';
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId?: Maybe<Scalars['String']>;
  articleId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dInteractome?: Maybe<DnaInteractome>;
  article?: Maybe<HealthArticle>;
};

export type InteractomeCounselor = {
  __typename?: 'InteractomeCounselor';
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId?: Maybe<Scalars['String']>;
  counselorId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dInteractome?: Maybe<DnaInteractome>;
  counselor?: Maybe<UserCounselor>;
};

export type InteractomeReport = {
  __typename?: 'InteractomeReport';
  id?: Maybe<Scalars['String']>;
};

export type Kit = {
  __typename?: 'Kit';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['String']>;
  verifiedAt?: Maybe<Scalars['Date']>;
  partnershipId?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  reports?: Maybe<Array<Maybe<KitReport>>>;
};

export type KitActivateKitManuallyForm = {
  /** Profile id of the kit activator */
  activatorProfileId?: Maybe<Scalars['String']>;
  kitCode: Scalars['String'];
  consentForm: Scalars['Upload'];
  email: Scalars['String'];
  /** Pass true to create a new profile */
  createNewProfile?: Maybe<Scalars['Boolean']>;
  /** Pass true to create a new subprofile from masterProfile */
  createNewSubProfile?: Maybe<Scalars['Boolean']>;
  /** Required if createNewSubProfile = true */
  masterProfileId?: Maybe<Scalars['String']>;
  /** activator detail */
  firstName?: Maybe<Scalars['String']>;
  /** activator detail */
  lastName?: Maybe<Scalars['String']>;
  /** activator detail */
  dob?: Maybe<Scalars['String']>;
  /** activator detail */
  gender?: Maybe<Scalars['String']>;
};

export type KitActivation = {
  __typename?: 'KitActivation';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  kitId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  isProfileDeleted?: Maybe<Scalars['Boolean']>;
  tutorialWatchedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  kit?: Maybe<Kit>;
  isCollected?: Maybe<Scalars['Boolean']>;
  productKitPickupId?: Maybe<Scalars['String']>;
  sampleId?: Maybe<Scalars['String']>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<KitActivationType>;
};

export type KitActivationQueryFilter = {
  reportTypes?: Maybe<Array<Maybe<Report_Type>>>;
  isPickedUpOrCollected?: Maybe<Scalars['Boolean']>;
  isSampled?: Maybe<Scalars['Boolean']>;
};

export type KitActivationQueryResult = {
  __typename?: 'KitActivationQueryResult';
  results?: Maybe<Array<Maybe<KitActivation>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum KitActivationType {
  Regular = 'REGULAR',
  Manual = 'MANUAL'
}

export enum KitCheckActivationAvailabilityErrorCodeEnum {
  /** kit not found. */
  Err1 = 'ERR1',
  /** kit already activated. */
  Err2 = 'ERR2',
  /** Kit batch not found. */
  Err3 = 'ERR3',
  /** Profile not found. */
  Err4 = 'ERR4',
  /** User already activated a kit with the same product. */
  Err5 = 'ERR5'
}

export type KitCheckActivationAvailabilityQuery = {
  profileId?: Maybe<Scalars['String']>;
  kitcode: Scalars['String'];
};

export type KitCheckActivationAvailabilityResult = {
  __typename?: 'KitCheckActivationAvailabilityResult';
  ableToActivate?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  errorCode?: Maybe<KitCheckActivationAvailabilityErrorCodeEnum>;
};

export type KitCheckManualActivationAvailabilityForm = {
  email?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  kitcode: Scalars['String'];
};

export enum KitProcessStatus {
  Activated = 'ACTIVATED',
  TutorialWatched = 'TUTORIAL_WATCHED',
  PickupRequested = 'PICKUP_REQUESTED',
  OnLab = 'ON_LAB',
  Collected = 'COLLECTED',
  ReportReleased = 'REPORT_RELEASED'
}

export type KitProcessStatusQueryResult = {
  __typename?: 'KitProcessStatusQueryResult';
  kit?: Maybe<PurchaseProductKit>;
  kitActivation?: Maybe<KitActivation>;
  status?: Maybe<KitProcessStatus>;
};

export type KitReport = {
  __typename?: 'KitReport';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  interpretedAt?: Maybe<Scalars['Date']>;
  releasedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export enum LabExtractionMethod {
  Genotek = 'GENOTEK',
  Beckman = 'BECKMAN',
  Qiasymphony = 'QIASYMPHONY',
  Tianglong = 'TIANGLONG'
}

export type LabMutations = {
  __typename?: 'LabMutations';
  Sample?: Maybe<LabSampleMutations>;
  Setting?: Maybe<LabSettingMutations>;
  Process?: Maybe<LabProcessMutations>;
  User?: Maybe<LabUserMutations>;
};

export type LabProcess = {
  __typename?: 'LabProcess';
  id?: Maybe<Scalars['String']>;
  sample?: Maybe<LabSample>;
  extraction?: Maybe<LabProcessExtraction>;
  infinium?: Maybe<LabProcessInfinium>;
  iscan?: Maybe<LabProcessIscan>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabProcessExtraction = {
  __typename?: 'LabProcessExtraction';
  id?: Maybe<Scalars['String']>;
  concentration?: Maybe<Scalars['Float']>;
  status?: Maybe<LabProcessStatus>;
  sample?: Maybe<LabSample>;
  labProcess?: Maybe<LabProcess>;
  method?: Maybe<LabExtractionMethod>;
  finishedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabProcessExtractionAddForm = {
  kitCode: Scalars['String'];
  method?: Maybe<LabExtractionMethod>;
  concentration?: Maybe<Scalars['Float']>;
};

export type LabProcessExtractionEditForm = {
  method?: Maybe<LabExtractionMethod>;
  concentration?: Maybe<Scalars['Float']>;
  status?: Maybe<LabProcessStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  finishedAt?: Maybe<Scalars['Date']>;
};

export type LabProcessExtractionMutations = {
  __typename?: 'LabProcessExtractionMutations';
  add?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<LabProcessExtraction>;
  exportExcel?: Maybe<Scalars['String']>;
};

export type LabProcessExtractionMutationsAddArgs = {
  forms: Array<LabProcessExtractionAddForm>;
};

export type LabProcessExtractionMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<LabProcessExtractionEditForm>;
};

export type LabProcessExtractionMutationsExportExcelArgs = {
  where?: Maybe<LabProcessExtractionQueryFilter>;
  sort?: Maybe<LabProcessExtractionSortBy>;
};

export type LabProcessExtractionQueries = {
  __typename?: 'LabProcessExtractionQueries';
  findOne?: Maybe<LabProcessExtraction>;
  findAll?: Maybe<LabProcessExtractionQueryResult>;
  findOneByKitCode?: Maybe<LabProcessExtraction>;
};

export type LabProcessExtractionQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type LabProcessExtractionQueriesFindAllArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<LabProcessExtractionQueryFilter>;
  sort?: Maybe<LabProcessExtractionSortBy>;
};

export type LabProcessExtractionQueriesFindOneByKitCodeArgs = {
  code: Scalars['String'];
};

export type LabProcessExtractionQueryFilter = {
  status?: Maybe<Array<Maybe<LabProcessStatus>>>;
  kitCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
  methods?: Maybe<Array<Maybe<LabExtractionMethod>>>;
  concentrations?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type LabProcessExtractionQueryResult = {
  __typename?: 'LabProcessExtractionQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LabProcessExtraction>>>;
};

export type LabProcessExtractionSortBy = {
  finishedAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  concentration?: Maybe<SortDirection>;
  method?: Maybe<SortDirection>;
};

export type LabProcessInfinium = {
  __typename?: 'LabProcessInfinium';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  sample?: Maybe<LabSample>;
  labProcess?: Maybe<LabProcess>;
  status?: Maybe<LabProcessStatus>;
  finishedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabProcessInfiniumAddForm = {
  kitCode: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type LabProcessInfiniumEditForm = {
  code?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  finishedAt?: Maybe<Scalars['Date']>;
  status?: Maybe<LabProcessStatus>;
};

export type LabProcessInfiniumMutation = {
  __typename?: 'LabProcessInfiniumMutation';
  add?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<LabProcessInfinium>;
  exportExcel?: Maybe<Scalars['String']>;
};

export type LabProcessInfiniumMutationAddArgs = {
  forms: Array<Maybe<LabProcessInfiniumAddForm>>;
  batch?: Maybe<Scalars['Int']>;
};

export type LabProcessInfiniumMutationEditArgs = {
  id: Scalars['String'];
  form?: Maybe<LabProcessInfiniumEditForm>;
};

export type LabProcessInfiniumMutationExportExcelArgs = {
  where?: Maybe<LabProcessInfiniumQueryFilter>;
  sort?: Maybe<LabProcessInfiniumSortBy>;
};

export type LabProcessInfiniumQueries = {
  __typename?: 'LabProcessInfiniumQueries';
  findOne?: Maybe<LabProcessInfinium>;
  findOneByKitCode?: Maybe<LabProcessInfinium>;
  findAll?: Maybe<LabProcessInfiniumQueryResult>;
};

export type LabProcessInfiniumQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type LabProcessInfiniumQueriesFindOneByKitCodeArgs = {
  code: Scalars['String'];
};

export type LabProcessInfiniumQueriesFindAllArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<LabProcessInfiniumQueryFilter>;
  sort?: Maybe<LabProcessInfiniumSortBy>;
};

export type LabProcessInfiniumQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<LabProcessStatus>>>;
};

export type LabProcessInfiniumQueryResult = {
  __typename?: 'LabProcessInfiniumQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LabProcessInfinium>>>;
};

export type LabProcessInfiniumSortBy = {
  code?: Maybe<SortDirection>;
  sample?: Maybe<LabSampleQuerySortBy>;
  createdAt?: Maybe<SortDirection>;
  finishedAt?: Maybe<SortDirection>;
};

export type LabProcessIscan = {
  __typename?: 'LabProcessIscan';
  id?: Maybe<Scalars['String']>;
  result?: Maybe<LabProcessIscanResult>;
  status?: Maybe<LabProcessStatus>;
  callRate?: Maybe<Scalars['Float']>;
  sample?: Maybe<LabSample>;
  labProcess?: Maybe<LabProcess>;
  finishedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabProcessIscanAddForm = {
  kitCode: Scalars['String'];
  result?: Maybe<LabProcessIscanResult>;
  callRate?: Maybe<Scalars['Float']>;
};

export type LabProcessIscanEditForm = {
  status?: Maybe<LabProcessStatus>;
  result?: Maybe<LabProcessIscanResult>;
  callRate?: Maybe<Scalars['Float']>;
  finishedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type LabProcessIscanMutations = {
  __typename?: 'LabProcessIscanMutations';
  add?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<LabProcessIscan>;
  reextraction?: Maybe<Scalars['Boolean']>;
  reswab?: Maybe<Scalars['Boolean']>;
  confirmReswab?: Maybe<Scalars['Boolean']>;
  exportExcel?: Maybe<Scalars['String']>;
  importCSV?: Maybe<Scalars['Boolean']>;
};

export type LabProcessIscanMutationsAddArgs = {
  forms: Array<Maybe<LabProcessIscanAddForm>>;
};

export type LabProcessIscanMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<LabProcessIscanEditForm>;
};

export type LabProcessIscanMutationsReextractionArgs = {
  id: Scalars['String'];
};

export type LabProcessIscanMutationsReswabArgs = {
  id: Scalars['String'];
};

export type LabProcessIscanMutationsConfirmReswabArgs = {
  id: Scalars['String'];
};

export type LabProcessIscanMutationsExportExcelArgs = {
  where?: Maybe<LabProcessIscanQueryFilter>;
  sort?: Maybe<LabProcessIscanSortBy>;
};

export type LabProcessIscanMutationsImportCsvArgs = {
  file: Scalars['Upload'];
};

export type LabProcessIscanQueries = {
  __typename?: 'LabProcessIscanQueries';
  findOne?: Maybe<LabProcessIscan>;
  findAll?: Maybe<LabProcessIscanQueryResult>;
  findOneByKitCode?: Maybe<LabProcessIscan>;
};

export type LabProcessIscanQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type LabProcessIscanQueriesFindAllArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  where?: Maybe<LabProcessIscanQueryFilter>;
  sort?: Maybe<LabProcessIscanSortBy>;
};

export type LabProcessIscanQueriesFindOneByKitCodeArgs = {
  code: Scalars['String'];
};

export type LabProcessIscanQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<LabProcessIscanResult>>>;
  status?: Maybe<Array<Maybe<LabProcessStatus>>>;
  callRate?: Maybe<Scalars['Float']>;
};

export type LabProcessIscanQueryResult = {
  __typename?: 'LabProcessIscanQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LabProcessIscan>>>;
};

export enum LabProcessIscanResult {
  Pass = 'PASS',
  Fail = 'FAIL'
}

export type LabProcessIscanSortBy = {
  sample?: Maybe<LabSampleQuerySortBy>;
  status?: Maybe<SortDirection>;
  result?: Maybe<SortDirection>;
  callRate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type LabProcessMutations = {
  __typename?: 'LabProcessMutations';
  Extraction?: Maybe<LabProcessExtractionMutations>;
  Infinium?: Maybe<LabProcessInfiniumMutation>;
  Iscan?: Maybe<LabProcessIscanMutations>;
};

export type LabProcessQueries = {
  __typename?: 'LabProcessQueries';
  findOne?: Maybe<LabProcess>;
  findAll?: Maybe<LabProcessQueryResult>;
  Extraction?: Maybe<LabProcessExtractionQueries>;
  Infinium?: Maybe<LabProcessInfiniumQueries>;
  Iscan?: Maybe<LabProcessIscanQueries>;
};

export type LabProcessQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type LabProcessQueriesFindAllArgs = {
  where?: Maybe<LabProcessQueryFilter>;
  sort?: Maybe<LabProcessSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LabProcessQueryFilter = {
  sampleId?: Maybe<Scalars['String']>;
};

export type LabProcessQueryResult = {
  __typename?: 'LabProcessQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LabProcess>>>;
};

export type LabProcessSortBy = {
  createdAt?: Maybe<SortDirection>;
};

export enum LabProcessStatus {
  Processed = 'PROCESSED',
  WaitingToScan = 'WAITING_TO_SCAN',
  Finished = 'FINISHED'
}

export type LabQueries = {
  __typename?: 'LabQueries';
  Sample?: Maybe<LabSampleQueries>;
  Setting?: Maybe<LabSettingQueries>;
  Process?: Maybe<LabProcessQueries>;
  User?: Maybe<LabUserQueries>;
  Tracking?: Maybe<LabTrackingQueries>;
};

export type LabSample = {
  __typename?: 'LabSample';
  id?: Maybe<Scalars['String']>;
  labId?: Maybe<Scalars['String']>;
  kit?: Maybe<PurchaseProductKit>;
  user?: Maybe<UserProfile>;
  batch?: Maybe<Scalars['Int']>;
  status?: Maybe<LabSampleStatus>;
  histories?: Maybe<Array<Maybe<LabSampleHistory>>>;
  attachment?: Maybe<Scalars['String']>;
  totalReswab?: Maybe<Scalars['Int']>;
  totalReextraction?: Maybe<Scalars['Int']>;
  turnAroundAt?: Maybe<Scalars['Date']>;
  receivedAt?: Maybe<Scalars['Date']>;
  finishedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  reswabShipment?: Maybe<ReswabShipmentResult>;
  process?: Maybe<LabProcess>;
  trackHistory?: Maybe<Array<Maybe<TrackHistoryQueryResult>>>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabSampleTrackHistoryArgs = {
  where?: Maybe<TrackHistoryQueryFilter>;
  sort?: Maybe<TrackHistoryQuerySortBy>;
};

export type LabSampleForm = {
  kitId?: Maybe<Scalars['String']>;
  batch?: Maybe<Scalars['Int']>;
  status?: Maybe<LabSampleStatus>;
  profileId?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  receivedAt?: Maybe<Scalars['Date']>;
};

export type LabSampleHistory = {
  __typename?: 'LabSampleHistory';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<LabSampleStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabSampleMutations = {
  __typename?: 'LabSampleMutations';
  add?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<LabSample>;
  exportExcel?: Maybe<Scalars['String']>;
  exportIscan?: Maybe<Scalars['String']>;
};

export type LabSampleMutationsAddArgs = {
  form?: Maybe<Array<Maybe<LabSampleForm>>>;
};

export type LabSampleMutationsEditArgs = {
  id?: Maybe<Scalars['String']>;
  form?: Maybe<LabSampleForm>;
};

export type LabSampleMutationsExportExcelArgs = {
  where?: Maybe<LabSampleQueryFilter>;
  sort?: Maybe<LabSampleQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LabSampleMutationsExportIscanArgs = {
  where?: Maybe<LabSampleQueryFilter>;
  sort?: Maybe<LabSampleQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LabSampleQueries = {
  __typename?: 'LabSampleQueries';
  findOne?: Maybe<LabSample>;
  findAll?: Maybe<LabSampleQueryResult>;
  findByKitCode?: Maybe<LabSample>;
  findBatch?: Maybe<Array<Maybe<Scalars['Int']>>>;
  statistics?: Maybe<LabSampleStatistics>;
  reswab?: Maybe<LabSampleQueryResult>;
  trackHistory?: Maybe<Array<Maybe<TrackHistoryQueryResult>>>;
  findLatestChipType?: Maybe<Scalars['String']>;
};

export type LabSampleQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type LabSampleQueriesFindAllArgs = {
  where?: Maybe<LabSampleQueryFilter>;
  sort?: Maybe<LabSampleQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LabSampleQueriesFindByKitCodeArgs = {
  code: Scalars['String'];
};

export type LabSampleQueriesFindBatchArgs = {
  id?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<LabSampleQuerySortBy>;
};

export type LabSampleQueriesReswabArgs = {
  where?: Maybe<LabSampleQueryFilter>;
  sort?: Maybe<LabSampleQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LabSampleQueriesTrackHistoryArgs = {
  id?: Maybe<Scalars['String']>;
  where?: Maybe<TrackHistoryQueryFilter>;
  sort?: Maybe<TrackHistoryQuerySortBy>;
};

export type LabSampleQueryFilter = {
  kit?: Maybe<Scalars['String']>;
  batch?: Maybe<Array<Maybe<Scalars['Int']>>>;
  gender?: Maybe<Array<Maybe<UserGender>>>;
  status?: Maybe<Array<Maybe<LabSampleStatus>>>;
  keyword?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  receivedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  finishedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnershipId?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalReextraction?: Maybe<Scalars['Int']>;
};

export type LabSampleQueryResult = {
  __typename?: 'LabSampleQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LabSample>>>;
};

export type LabSampleQuerySortBy = {
  kit?: Maybe<PurchaseProductKitQuerySortBy>;
  user?: Maybe<UserQuerySortBy>;
  labId?: Maybe<SortDirection>;
  batch?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  receivedAt?: Maybe<SortDirection>;
  turnAroundAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
  finishedAt?: Maybe<SortDirection>;
};

export type LabSampleStatistics = {
  __typename?: 'LabSampleStatistics';
  id?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['Int']>;
  extraction?: Maybe<Scalars['Int']>;
  infinium?: Maybe<Scalars['Int']>;
  iScanPass?: Maybe<Scalars['Int']>;
  iScanFail?: Maybe<Scalars['Int']>;
  reswab?: Maybe<Scalars['Int']>;
  reextraction?: Maybe<Scalars['Int']>;
};

export type LabSampleStatisticsReceivedArgs = {
  where?: Maybe<LabSampleStatisticsQueryFilter>;
};

export type LabSampleStatisticsExtractionArgs = {
  where?: Maybe<LabSampleStatisticsQueryFilter>;
};

export type LabSampleStatisticsInfiniumArgs = {
  where?: Maybe<LabSampleStatisticsQueryFilter>;
};

export type LabSampleStatisticsIScanPassArgs = {
  where?: Maybe<LabSampleStatisticsQueryFilter>;
};

export type LabSampleStatisticsIScanFailArgs = {
  where?: Maybe<LabSampleStatisticsQueryFilter>;
};

export type LabSampleStatisticsReswabArgs = {
  where?: Maybe<LabSampleStatisticsQueryFilter>;
};

export type LabSampleStatisticsReextractionArgs = {
  where?: Maybe<LabSampleStatisticsQueryFilter>;
};

export type LabSampleStatisticsQueryFilter = {
  durations?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum LabSampleStatus {
  Received = 'RECEIVED',
  OnHold = 'ON_HOLD',
  Extraction = 'EXTRACTION',
  Infinium = 'INFINIUM',
  Iscan = 'ISCAN',
  IscanPass = 'ISCAN_PASS',
  IscanFail = 'ISCAN_FAIL',
  ReswabRequested = 'RESWAB_REQUESTED',
  ReswabConfirmed = 'RESWAB_CONFIRMED',
  Reextraction = 'REEXTRACTION',
  ReportOut = 'REPORT_OUT'
}

export type LabSetting = {
  __typename?: 'LabSetting';
  id?: Maybe<Scalars['String']>;
  batch?: Maybe<Scalars['Int']>;
  turnAroundTime?: Maybe<Scalars['Float']>;
  payload?: Maybe<LabSettingPayload>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabSettingForm = {
  batch?: Maybe<Scalars['Int']>;
  turnAroundTime?: Maybe<Scalars['Float']>;
  payload?: Maybe<LabSettingPayloadForm>;
};

export type LabSettingMutations = {
  __typename?: 'LabSettingMutations';
  edit?: Maybe<LabSetting>;
};

export type LabSettingMutationsEditArgs = {
  form?: Maybe<LabSettingForm>;
};

export type LabSettingPayload = {
  __typename?: 'LabSettingPayload';
  iscan?: Maybe<LabSettingPayloadIscan>;
};

export type LabSettingPayloadForm = {
  iscan?: Maybe<LabSettingPayloadIscanForm>;
};

export type LabSettingPayloadIscan = {
  __typename?: 'LabSettingPayloadIscan';
  treshold?: Maybe<Scalars['Float']>;
};

export type LabSettingPayloadIscanForm = {
  treshold?: Maybe<Scalars['Float']>;
};

export type LabSettingQueries = {
  __typename?: 'LabSettingQueries';
  findOne?: Maybe<LabSetting>;
};

export type LabTracking = {
  __typename?: 'LabTracking';
  id?: Maybe<Scalars['ID']>;
  kit?: Maybe<Kit>;
  kitActivation?: Maybe<KitActivation>;
  type?: Maybe<LabTrackingType>;
  status?: Maybe<Scalars['String']>;
  orderUnlock?: Maybe<OrderUnlock>;
  orderUnlockHistories?: Maybe<Array<Maybe<OrderUnlockHistory>>>;
  sampleHistories?: Maybe<Array<Maybe<LabSampleHistory>>>;
  sample?: Maybe<LabSample>;
  createdAt?: Maybe<Scalars['Date']>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isReportOut?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Array<Maybe<LabTrackingHistory>>>;
  timeline?: Maybe<Array<Maybe<LabTrackingTimeLine>>>;
  turnAroundAt?: Maybe<Scalars['Date']>;
};

export type LabTrackingHistory = {
  __typename?: 'LabTrackingHistory';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  iteration?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabTrackingQueries = {
  __typename?: 'LabTrackingQueries';
  findAll?: Maybe<LabTrackingQueryResult>;
  findOne?: Maybe<LabTracking>;
};

export type LabTrackingQueriesFindAllArgs = {
  where?: Maybe<LabTrackingQueryFilter>;
  sort?: Maybe<LabTrackingQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LabTrackingQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type LabTrackingQueryFilter = {
  sampleId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabTrackingQueryResult = {
  __typename?: 'LabTrackingQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LabTracking>>>;
};

export type LabTrackingQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type LabTrackingTimeLine = {
  __typename?: 'LabTrackingTimeLine';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  reportType?: Maybe<Scalars['String']>;
};

export enum LabTrackingType {
  OrderUnlock = 'ORDER_UNLOCK',
  Sample = 'SAMPLE',
  OrderUnlockNochip = 'ORDER_UNLOCK_NOCHIP'
}

export type LabUser = {
  __typename?: 'LabUser';
  id?: Maybe<Scalars['String']>;
  role?: Maybe<LabUserRole>;
  access?: Maybe<LabUserAccess>;
  status?: Maybe<LabUserStatus>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabUserAccess = {
  __typename?: 'LabUserAccess';
  id?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LabUserAccessForm = {
  modules?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LabUserForm = {
  role?: Maybe<LabUserRole>;
  access?: Maybe<LabUserAccessForm>;
  status?: Maybe<LabUserStatus>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['Boolean']>;
};

export type LabUserMutations = {
  __typename?: 'LabUserMutations';
  add?: Maybe<LabUser>;
  edit?: Maybe<LabUser>;
};

export type LabUserMutationsAddArgs = {
  form?: Maybe<LabUserForm>;
};

export type LabUserMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<LabUserForm>;
};

export type LabUserQueries = {
  __typename?: 'LabUserQueries';
  findOne?: Maybe<LabUser>;
  findAll?: Maybe<LabUserQueryResult>;
  profile?: Maybe<LabUser>;
};

export type LabUserQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type LabUserQueriesFindAllArgs = {
  sort?: Maybe<LabUserQuerySortBy>;
  where?: Maybe<LabUserQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type LabUserQueryFilter = {
  roles?: Maybe<Array<Maybe<LabUserRole>>>;
  username?: Maybe<Scalars['String']>;
};

export type LabUserQueryResult = {
  __typename?: 'LabUserQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<LabUser>>>;
};

export type LabUserQuerySortBy = {
  role?: Maybe<SortDirection>;
  username?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum LabUserRole {
  Editor = 'EDITOR',
  Master = 'MASTER',
  Viewer = 'VIEWER'
}

export enum LabUserStatus {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE'
}

export enum LanguageCode {
  En = 'EN',
  Id = 'ID'
}

export type ManualBulkActivationForm = {
  file: Scalars['Upload'];
  domain: Scalars['String'];
};

export type MasterDictionaryMutations = {
  __typename?: 'MasterDictionaryMutations';
  ContentManagement?: Maybe<ContentManagementMutations>;
};

export type MasterDictionaryQueries = {
  __typename?: 'MasterDictionaryQueries';
  ContentManagement?: Maybe<ContentManagementQueries>;
};

/** Deprecated, Please use on EHROrder module */
export type Mcu = {
  __typename?: 'MCU';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<PurchaseOrderType>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  order?: Maybe<PurchaseOrder>;
  status?: Maybe<McuStatus>;
  invoice?: Maybe<Scalars['String']>;
  visitedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  resultType?: Maybe<Scalars['String']>;
  creationType?: Maybe<Scalars['String']>;
  ownerProfileId?: Maybe<Scalars['String']>;
  ownerProfile?: Maybe<UserProfile>;
  laboratorium?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Deprecated, Please use on EHROrder module */
export type McuAddManualForm = {
  email?: Maybe<Scalars['String']>;
  ownerProfileId?: Maybe<Scalars['String']>;
  visitedAt?: Maybe<Scalars['Date']>;
  resultType?: Maybe<Scalars['String']>;
};

/** Deprecated, Please use on EHROrder module */
export type McuMutations = {
  __typename?: 'MCUMutations';
  addManual?: Maybe<Mcu>;
  Patient?: Maybe<McuPatientMutations>;
};

/** Deprecated, Please use on EHROrder module */
export type McuMutationsAddManualArgs = {
  form?: Maybe<McuAddManualForm>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatient = {
  __typename?: 'MCUPatient';
  id?: Maybe<Scalars['ID']>;
  mcu?: Maybe<Mcu>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
  laboratorium?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientForm = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  laboratorium?: Maybe<Scalars['String']>;
  mcuId?: Maybe<Scalars['ID']>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientMutations = {
  __typename?: 'MCUPatientMutations';
  add?: Maybe<McuPatient>;
  edit?: Maybe<McuPatient>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientMutationsAddArgs = {
  form?: Maybe<McuPatientForm>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientMutationsEditArgs = {
  id: Scalars['ID'];
  form?: Maybe<McuPatientForm>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientQueries = {
  __typename?: 'MCUPatientQueries';
  findAll?: Maybe<McuPatientQueryResult>;
  findOne?: Maybe<McuPatient>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientQueriesFindAllArgs = {
  sort?: Maybe<McuPatientQuerySortBy>;
  where?: Maybe<McuPatientQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientQueriesFindOneArgs = {
  id: Scalars['ID'];
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientQueryFilter = {
  mcu?: Maybe<Scalars['ID']>;
  keyword?: Maybe<Scalars['String']>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientQueryResult = {
  __typename?: 'MCUPatientQueryResult';
  results?: Maybe<Array<Maybe<McuPatient>>>;
  count?: Maybe<Scalars['Int']>;
};

/** Deprecated, Please use on EHRLab module */
export type McuPatientQuerySortBy = {
  name?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  phone?: Maybe<SortDirection>;
  laboratorium?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** Deprecated, Please use on EHROrder module */
export type McuQueries = {
  __typename?: 'MCUQueries';
  Patient?: Maybe<McuPatientQueries>;
  findAll?: Maybe<McuQueryResult>;
  findOne?: Maybe<Mcu>;
  total?: Maybe<Scalars['Int']>;
};

/** Deprecated, Please use on EHROrder module */
export type McuQueriesFindAllArgs = {
  sort?: Maybe<McuQuerySortBy>;
  where?: Maybe<McuQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** Deprecated, Please use on EHROrder module */
export type McuQueriesFindOneArgs = {
  id: Scalars['ID'];
};

/** Deprecated, Please use on EHROrder module */
export type McuQueryFilter = {
  status?: Maybe<Array<Maybe<McuStatus>>>;
  keyword?: Maybe<Scalars['String']>;
  visitedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Deprecated, Please use on EHROrder module */
export type McuQueryResult = {
  __typename?: 'MCUQueryResult';
  results?: Maybe<Array<Maybe<Mcu>>>;
  count?: Maybe<Scalars['Int']>;
};

/** Deprecated, Please use on EHROrder module */
export type McuQuerySortBy = {
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<SortDirection>;
  invoice?: Maybe<SortDirection>;
  visitedAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** Deprecated, Please use on EHROrder module */
export enum McuStatus {
  WaitingForVisit = 'WAITING_FOR_VISIT',
  WaitingForResult = 'WAITING_FOR_RESULT',
  Complete = 'COMPLETE'
}

export type MediaMutations = {
  __typename?: 'MediaMutations';
  Music?: Maybe<MusicMutations>;
  Podcast?: Maybe<PodcastMutations>;
  Banner?: Maybe<BannerMutations>;
};

export type MediaQueries = {
  __typename?: 'MediaQueries';
  id?: Maybe<Scalars['ID']>;
  Music?: Maybe<MusicQueries>;
  Podcast?: Maybe<PodcastQueries>;
  Banner?: Maybe<BannerQueries>;
};

export enum MedicalType {
  Disease = 'DISEASE',
  Illness = 'ILLNESS',
  Surgical = 'SURGICAL'
}

export type Merchant = {
  __typename?: 'Merchant';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  picName?: Maybe<Scalars['String']>;
  picPhone?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  categories?: Maybe<Array<Maybe<MerchantCategory>>>;
  vouchers?: Maybe<Array<Maybe<MerchantVoucher>>>;
};

export type MerchantCategory = {
  __typename?: 'MerchantCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type MerchantCategoryForm = {
  name: Scalars['String'];
};

export type MerchantCategoryMutations = {
  __typename?: 'MerchantCategoryMutations';
  add?: Maybe<MerchantCategory>;
  edit?: Maybe<MerchantCategory>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type MerchantCategoryMutationsAddArgs = {
  req?: Maybe<MerchantCategoryForm>;
};

export type MerchantCategoryMutationsEditArgs = {
  id: Scalars['String'];
  req?: Maybe<MerchantCategoryForm>;
};

export type MerchantCategoryMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type MerchantCategoryQueries = {
  __typename?: 'MerchantCategoryQueries';
  id?: Maybe<Scalars['String']>;
  findAll?: Maybe<MerchantCategoryQueryResult>;
  findOne?: Maybe<MerchantCategory>;
};

export type MerchantCategoryQueriesFindAllArgs = {
  where?: Maybe<MerchantCategoryQueryFilter>;
  sort?: Maybe<MerchantCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MerchantCategoryQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type MerchantCategoryQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type MerchantCategoryQueryResult = {
  __typename?: 'MerchantCategoryQueryResult';
  results?: Maybe<Array<Maybe<MerchantCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type MerchantCategoryQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type MerchantForm = {
  categories: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  logoUrl: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  picName: Scalars['String'];
  picPhone: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type MerchantMutations = {
  __typename?: 'MerchantMutations';
  add?: Maybe<Merchant>;
  edit?: Maybe<Merchant>;
  delete?: Maybe<Scalars['Boolean']>;
  MerchantCategory?: Maybe<MerchantCategoryMutations>;
  MerchantVoucher?: Maybe<MerchantVoucherMutations>;
};

export type MerchantMutationsAddArgs = {
  req: MerchantForm;
};

export type MerchantMutationsEditArgs = {
  id: Scalars['String'];
  req?: Maybe<MerchantForm>;
};

export type MerchantMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type MerchantQueries = {
  __typename?: 'MerchantQueries';
  id?: Maybe<Scalars['String']>;
  findAll?: Maybe<MerchantQueryResult>;
  findOne?: Maybe<Merchant>;
  findByCode?: Maybe<Merchant>;
  MerchantCategory?: Maybe<MerchantCategoryQueries>;
  MerchantVoucher?: Maybe<MerchantVoucherQueries>;
};

export type MerchantQueriesFindAllArgs = {
  where?: Maybe<MerchantQueryFilter>;
  sort?: Maybe<MerchantQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MerchantQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type MerchantQueriesFindByCodeArgs = {
  code: Scalars['String'];
};

export type MerchantQueryFilter = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type MerchantQueryResult = {
  __typename?: 'MerchantQueryResult';
  results?: Maybe<Array<Maybe<Merchant>>>;
  count?: Maybe<Scalars['Int']>;
};

export type MerchantQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type MerchantVoucher = {
  __typename?: 'MerchantVoucher';
  id?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<MerchantVoucherType>;
  expireDate?: Maybe<Scalars['Date']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isOneTimeUse?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  successfullTransactionCount?: Maybe<Scalars['Int']>;
  failedTransactionCount?: Maybe<Scalars['Int']>;
  transactionCount?: Maybe<Scalars['Int']>;
  merchant?: Maybe<Merchant>;
  isUserEligible?: Maybe<Scalars['Boolean']>;
};

export type MerchantVoucherForm = {
  merchantId: Scalars['String'];
  name: Scalars['String'];
  bannerUrl?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: MerchantVoucherType;
  expireDate: Scalars['Date'];
  isActive: Scalars['Boolean'];
  isOneTimeUse: Scalars['Boolean'];
};

export type MerchantVoucherMutations = {
  __typename?: 'MerchantVoucherMutations';
  add?: Maybe<MerchantVoucher>;
  edit?: Maybe<MerchantVoucher>;
  delete?: Maybe<Scalars['Boolean']>;
  available?: Maybe<Scalars['Int']>;
  MerchantVoucherUser?: Maybe<MerchantVoucherUserMutations>;
};

export type MerchantVoucherMutationsAddArgs = {
  req?: Maybe<MerchantVoucherForm>;
};

export type MerchantVoucherMutationsEditArgs = {
  id: Scalars['String'];
  req?: Maybe<MerchantVoucherForm>;
};

export type MerchantVoucherMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type MerchantVoucherMutationsAvailableArgs = {
  profileId?: Maybe<Scalars['String']>;
};

export type MerchantVoucherQueries = {
  __typename?: 'MerchantVoucherQueries';
  id?: Maybe<Scalars['String']>;
  findAll?: Maybe<MerchantVoucherQueryResult>;
  findOne?: Maybe<MerchantVoucher>;
  findByCode?: Maybe<MerchantVoucher>;
  MerchantVoucherUser?: Maybe<MerchantVoucherUserQueries>;
};

export type MerchantVoucherQueriesFindAllArgs = {
  where?: Maybe<MerchantVoucherQueryFilter>;
  sort?: Maybe<MerchantVoucherQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MerchantVoucherQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type MerchantVoucherQueriesFindByCodeArgs = {
  code: Scalars['String'];
};

export type MerchantVoucherQueryFilter = {
  merchantId?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<MerchantVoucherType>>>;
  status?: Maybe<Array<Maybe<MerchantVoucherStatusEnum>>>;
  transactionCount?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  expireDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type MerchantVoucherQueryResult = {
  __typename?: 'MerchantVoucherQueryResult';
  results?: Maybe<Array<Maybe<MerchantVoucher>>>;
  count?: Maybe<Scalars['Int']>;
};

export type MerchantVoucherQuerySortBy = {
  name?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  isUserEligible?: Maybe<SortDirection>;
};

export enum MerchantVoucherStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export enum MerchantVoucherStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Expired = 'EXPIRED'
}

export enum MerchantVoucherType {
  Discount = 'DISCOUNT',
  Free = 'FREE',
  FreebiesItems = 'FREEBIES_ITEMS',
  Bundle = 'BUNDLE',
  Voucher = 'VOUCHER',
  Cashback = 'CASHBACK'
}

export type MerchantVoucherUser = {
  __typename?: 'MerchantVoucherUser';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  voucherId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  status?: Maybe<MerchantVoucherStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  voucher?: Maybe<MerchantVoucher>;
  profile?: Maybe<UserProfile>;
};

export type MerchantVoucherUserForm = {
  voucherId: Scalars['String'];
  merchantId: Scalars['String'];
};

export type MerchantVoucherUserMutations = {
  __typename?: 'MerchantVoucherUserMutations';
  add?: Maybe<MerchantVoucherUser>;
};

export type MerchantVoucherUserMutationsAddArgs = {
  req?: Maybe<MerchantVoucherUserForm>;
};

export type MerchantVoucherUserQueries = {
  __typename?: 'MerchantVoucherUserQueries';
  id?: Maybe<Scalars['String']>;
  findAll?: Maybe<MerchantVoucherUserQueryResult>;
  findOne?: Maybe<MerchantVoucherUser>;
};

export type MerchantVoucherUserQueriesFindAllArgs = {
  where?: Maybe<MerchantVoucherUserQueryFilter>;
  sort?: Maybe<MerchantVoucherUserQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MerchantVoucherUserQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type MerchantVoucherUserQueryFilter = {
  voucherId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<MerchantVoucherStatus>>>;
  keyword?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MerchantVoucherUserQueryResult = {
  __typename?: 'MerchantVoucherUserQueryResult';
  results?: Maybe<Array<Maybe<MerchantVoucherUser>>>;
  count?: Maybe<Scalars['Int']>;
};

export type MerchantVoucherUserQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type MinMaxOrder = {
  __typename?: 'MinMaxOrder';
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};

export type Music = {
  __typename?: 'Music';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  category?: Maybe<MusicCategory>;
  thumbnail?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type MusicCategory = {
  __typename?: 'MusicCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MusicCategoryForm = {
  name?: Maybe<Scalars['String']>;
};

export type MusicCategoryMutations = {
  __typename?: 'MusicCategoryMutations';
  add?: Maybe<MusicCategory>;
  edit?: Maybe<MusicCategory>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type MusicCategoryMutationsAddArgs = {
  form?: Maybe<MusicCategoryForm>;
};

export type MusicCategoryMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<MusicCategoryForm>;
};

export type MusicCategoryMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type MusicCategoryQueries = {
  __typename?: 'MusicCategoryQueries';
  findAll?: Maybe<MusicCategoryQueryResult>;
  findOne?: Maybe<MusicCategory>;
};

export type MusicCategoryQueriesFindAllArgs = {
  where?: Maybe<MusicCategoryQueryFilter>;
  sort?: Maybe<MusicCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MusicCategoryQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type MusicCategoryQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type MusicCategoryQueryResult = {
  __typename?: 'MusicCategoryQueryResult';
  results?: Maybe<Array<Maybe<MusicCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type MusicCategoryQuerySortBy = {
  name?: Maybe<SortDirection>;
};

export type MusicForm = {
  title?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['Upload']>;
  duration?: Maybe<Scalars['Float']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  categoryId?: Maybe<Scalars['String']>;
};

export type MusicMutations = {
  __typename?: 'MusicMutations';
  Category?: Maybe<MusicCategoryMutations>;
  add?: Maybe<Music>;
  edit?: Maybe<Music>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type MusicMutationsAddArgs = {
  form?: Maybe<MusicForm>;
};

export type MusicMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<MusicForm>;
};

export type MusicMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type MusicQueries = {
  __typename?: 'MusicQueries';
  Category?: Maybe<MusicCategoryQueries>;
  findAll?: Maybe<MusicQueryResult>;
  findOne?: Maybe<Music>;
};

export type MusicQueriesFindAllArgs = {
  where?: Maybe<MusicQueryFilter>;
  sort?: Maybe<MusicQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type MusicQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type MusicQueryFilter = {
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MusicQueryResult = {
  __typename?: 'MusicQueryResult';
  results?: Maybe<Array<Maybe<Music>>>;
  count?: Maybe<Scalars['Int']>;
};

export type MusicQuerySortBy = {
  title?: Maybe<SortDirection>;
  duration?: Maybe<SortDirection>;
  category?: Maybe<MusicCategoryQuerySortBy>;
  createdAt?: Maybe<SortDirection>;
};

/** root mutation */
export type Mutation = {
  __typename?: 'Mutation';
  /** admin module's mutations */
  Admin?: Maybe<AdminMutations>;
  /** country module's mutations */
  Country?: Maybe<CountryMutations>;
  /** dna module's mutations */
  DNA?: Maybe<DnaMutations>;
  /** health module's mutations */
  Health?: Maybe<HealthMutations>;
  /** news module's mutations */
  News?: Maybe<NewsMutations>;
  /** asa lab module's mutations */
  Lab?: Maybe<LabMutations>;
  /** mcu module's mutations */
  MCU?: Maybe<McuMutations>;
  /** media module's mutations */
  Media?: Maybe<MediaMutations>;
  /** partner module's mutations */
  Partner?: Maybe<PartnerMutations>;
  /** payment module's mutations */
  Payment?: Maybe<PaymentMutations>;
  /** prodigy module's mutations */
  Prodigy?: Maybe<ProdigyMutations>;
  /** promo module's mutations */
  Promo?: Maybe<PromoMutations>;
  /** purchase module's mutations */
  Purchase?: Maybe<PurchaseMutations>;
  /** tracker module's mutations */
  Tracker?: Maybe<TrackerMutations>;
  /** user module's mutations */
  User?: Maybe<UserMutations>;
  /** voucher module's mutations */
  Voucher?: Maybe<VoucherMutations>;
  /** sales module's mutations */
  Sales?: Maybe<SalesMutations>;
  /** seting module's mutations */
  Setting?: Maybe<SettingMutations>;
  /** anteraja module's mutation */
  AnterAja?: Maybe<AnterAjaMutation>;
  /** event module's mutation */
  Event?: Maybe<EventMutations>;
  /** merchant module's mutation */
  Merchant?: Maybe<MerchantMutations>;
  /** report module's mutation */
  Report?: Maybe<ReportMutations>;
  /** bioinformatic module's mutation */
  Bioinformatic?: Maybe<BioinformaticMutations>;
  /** Electronic-Health-Record modules's mutation */
  EHR?: Maybe<EhrMutations>;
  /** PgX module's mutation */
  PgX?: Maybe<PgXMutations>;
  /** Career modules's mutation */
  Career?: Maybe<CareerMutations>;
  /** Health Score modules's mutation */
  HealthScore?: Maybe<HealthScoreMutations>;
};

export type MyKitProcessStatusQueryFilter = {
  onlyShowBeforeStatus?: Maybe<KitProcessStatus>;
  activationType?: Maybe<KitActivationType>;
};

export type MyKitProcessStatusQueryFilterFindOne = {
  onlyShowBeforeStatus?: Maybe<KitProcessStatus>;
  reportType: Report_Type;
  activationType?: Maybe<KitActivationType>;
};

/** News */
export type News = {
  __typename?: 'News';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type NewsForm = {
  title?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

/** News module's mutations */
export type NewsMutations = {
  __typename?: 'NewsMutations';
  add?: Maybe<News>;
  edit?: Maybe<News>;
  delete?: Maybe<Scalars['Boolean']>;
};

/** News module's mutations */
export type NewsMutationsAddArgs = {
  form?: Maybe<NewsForm>;
};

/** News module's mutations */
export type NewsMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<NewsForm>;
};

/** News module's mutations */
export type NewsMutationsDeleteArgs = {
  id: Scalars['Int'];
};

/** News module's queries */
export type NewsQueries = {
  __typename?: 'NewsQueries';
  id?: Maybe<Scalars['ID']>;
  findAll?: Maybe<NewsQueryResult>;
  findOne?: Maybe<News>;
};

/** News module's queries */
export type NewsQueriesFindAllArgs = {
  where?: Maybe<NewsQueryFilter>;
  sort?: Maybe<NewsQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** News module's queries */
export type NewsQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type NewsQueryFilter = {
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
};

/** News query result */
export type NewsQueryResult = {
  __typename?: 'NewsQueryResult';
  results?: Maybe<Array<Maybe<News>>>;
  count?: Maybe<Scalars['Int']>;
};

export type NewsQuerySortBy = {
  title?: Maybe<SortDirection>;
  link?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type OrderQueryFilter = {
  code?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<Array<Maybe<PurchaseOrderPurchaseType>>>;
  homeservice?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  price?: Maybe<Array<Maybe<Scalars['String']>>>;
  buyer?: Maybe<Scalars['String']>;
  date?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  qty?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
};

export type OrderQuerySortBy = {
  code?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
};

export type OrderUnlock = {
  __typename?: 'OrderUnlock';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  kit?: Maybe<Kit>;
  status?: Maybe<OrderUnlockStatus>;
  reportType?: Maybe<ReportType>;
  TAT?: Maybe<Scalars['Date']>;
  sample?: Maybe<LabSample>;
  purchaseType?: Maybe<OrderUnlockPurchaseType>;
  profile?: Maybe<UserProfile>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type OrderUnlockHistory = {
  __typename?: 'OrderUnlockHistory';
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<OrderUnlockStatus>;
  createdAt?: Maybe<Scalars['Date']>;
};

export enum OrderUnlockPurchaseType {
  UnlockKit = 'UNLOCK_KIT',
  UnlockReport = 'UNLOCK_REPORT'
}

export enum OrderUnlockStatus {
  PaymentReceived = 'PAYMENT_RECEIVED',
  OrderConfirmed = 'ORDER_CONFIRMED',
  ProcessingReport = 'PROCESSING_REPORT',
  ReportOut = 'REPORT_OUT'
}

export type OrderVoucherQueryFilter = {
  code?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Date']>;
  endAt?: Maybe<Scalars['Date']>;
};

export type PanelFigure = {
  __typename?: 'PanelFigure';
  id?: Maybe<Scalars['String']>;
  dnaPanelId?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  favourableFigurePin?: Maybe<Array<Maybe<PanelFigurePin>>>;
  unfavourableFigurePin?: Maybe<Array<Maybe<PanelFigurePin>>>;
};

export type PanelFigurePin = {
  __typename?: 'PanelFigurePin';
  id?: Maybe<Scalars['String']>;
  dnaPanelFigureId?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dPanelPinCategory?: Maybe<Array<Maybe<PanelFigurePinCategory>>>;
};

export type PanelFigurePinCategory = {
  __typename?: 'PanelFigurePinCategory';
  id?: Maybe<Scalars['String']>;
  dnaPanelFigurePinId?: Maybe<Scalars['String']>;
  dnaCategoryId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dnaCategory?: Maybe<DnaCategory>;
};

export type Partner = {
  __typename?: 'Partner';
  id?: Maybe<Scalars['String']>;
  pics?: Maybe<Array<Maybe<PartnerPic>>>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<PartnerRole>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
  allowManualKitActivation?: Maybe<Scalars['Boolean']>;
  latestTransaction?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  /** Pass PARTNER_RESELLER to query include options to get this value. */
  reseller?: Maybe<PartnerReseller>;
};

export type PartnerAuth = {
  __typename?: 'PartnerAuth';
  id?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerAuthForm = {
  host?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerAuthMutations = {
  __typename?: 'PartnerAuthMutations';
  add?: Maybe<Scalars['Boolean']>;
};

export type PartnerAuthMutationsAddArgs = {
  form?: Maybe<PartnerAuthForm>;
};

export type PartnerAuthQueries = {
  __typename?: 'PartnerAuthQueries';
  findOne?: Maybe<PartnerAuth>;
};

export type PartnerAuthQueriesFindOneArgs = {
  partnershipId: Scalars['String'];
};

export type PartnerBank = {
  __typename?: 'PartnerBank';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerBankForm = {
  name?: Maybe<Scalars['String']>;
};

export type PartnerBankMutations = {
  __typename?: 'PartnerBankMutations';
  add?: Maybe<PartnerBank>;
};

export type PartnerBankMutationsAddArgs = {
  form?: Maybe<PartnerBankForm>;
};

export type PartnerBankQueries = {
  __typename?: 'PartnerBankQueries';
  findAll?: Maybe<PartnerBankQueryResult>;
  findOne?: Maybe<PartnerBank>;
};

export type PartnerBankQueriesFindAllArgs = {
  sort?: Maybe<PartnerBankQuerySortBy>;
  where?: Maybe<PartnerBankQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerBankQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PartnerBankQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  code?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PartnerBankQueryResult = {
  __typename?: 'PartnerBankQueryResult';
  results?: Maybe<Array<Maybe<PartnerBank>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerBankQuerySortBy = {
  code?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PartnerBatch = {
  __typename?: 'PartnerBatch';
  id?: Maybe<Scalars['String']>;
  /**
   * if the batch doesnt have any partnershipId (partnershipId = null)
   * it means the batch belongs to Asa Ren.
   * otherwise it belongsto partnership.
   */
  partnershipId?: Maybe<Scalars['String']>;
  kit?: Maybe<PartnerKit>;
  code?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  product?: Maybe<PartnerProduct>;
  quantity?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerBatchForm = {
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerBatchMutations = {
  __typename?: 'PartnerBatchMutations';
  exportExcel?: Maybe<Scalars['String']>;
  generate?: Maybe<Scalars['Boolean']>;
  verify?: Maybe<Scalars['Boolean']>;
};

export type PartnerBatchMutationsExportExcelArgs = {
  sort?: Maybe<PartnerBatchQuerySortBy>;
  where?: Maybe<PartnerBatchQueryFilter>;
};

export type PartnerBatchMutationsGenerateArgs = {
  form?: Maybe<PartnerBatchForm>;
};

export type PartnerBatchMutationsVerifyArgs = {
  form?: Maybe<PartnerBatchVerificationForm>;
};

export type PartnerBatchQueries = {
  __typename?: 'PartnerBatchQueries';
  findAll?: Maybe<PartnerBatchQueryResult>;
  findOne?: Maybe<PartnerBatch>;
  verify?: Maybe<Scalars['Boolean']>;
};

export type PartnerBatchQueriesFindAllArgs = {
  sort?: Maybe<PartnerBatchQuerySortBy>;
  where?: Maybe<PartnerBatchQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerBatchQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PartnerBatchQueriesVerifyArgs = {
  form?: Maybe<PartnerBatchVerificationForm>;
};

export type PartnerBatchQueryFilter = {
  code?: Maybe<Scalars['String']>;
  kitCode?: Maybe<Scalars['String']>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerBatchQueryResult = {
  __typename?: 'PartnerBatchQueryResult';
  results?: Maybe<Array<Maybe<PartnerBatch>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerBatchQuerySortBy = {
  code?: Maybe<SortDirection>;
  number?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  activatedAt?: Maybe<SortDirection>;
};

export type PartnerBatchVerificationForm = {
  kit?: Maybe<Scalars['String']>;
  batch?: Maybe<Scalars['String']>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerForm = {
  pics?: Maybe<Array<Maybe<PartnerPicForm>>>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
  allowManualKitActivation?: Maybe<Scalars['Boolean']>;
  /** add this value to create a partnership-reseller. */
  reseller?: Maybe<PartnerResellerForm>;
};

export type PartnerKit = {
  __typename?: 'PartnerKit';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  partnershipId?: Maybe<Scalars['String']>;
  batch?: Maybe<PartnerBatch>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  verifiedAt?: Maybe<Scalars['Date']>;
  activation?: Maybe<PartnerKitActivation>;
};

export type PartnerKitActivation = {
  __typename?: 'PartnerKitActivation';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerKitForm = {
  limit?: Maybe<Scalars['Int']>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerKitMutations = {
  __typename?: 'PartnerKitMutations';
  exportExcel?: Maybe<Scalars['String']>;
  generate?: Maybe<Scalars['Boolean']>;
};

export type PartnerKitMutationsExportExcelArgs = {
  sort?: Maybe<PartnerKitQuerySortBy>;
  where?: Maybe<PartnerKitQueryFilter>;
};

export type PartnerKitMutationsGenerateArgs = {
  form?: Maybe<PartnerKitForm>;
};

export type PartnerKitQueries = {
  __typename?: 'PartnerKitQueries';
  findAll?: Maybe<PartnerKitQueryResult>;
  findOne?: Maybe<PartnerKit>;
};

export type PartnerKitQueriesFindAllArgs = {
  sort?: Maybe<PartnerKitQuerySortBy>;
  where?: Maybe<PartnerKitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerKitQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PartnerKitQueryFilter = {
  code?: Maybe<Scalars['String']>;
  hasBatch?: Maybe<Scalars['Boolean']>;
  activatedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerKitQueryResult = {
  __typename?: 'PartnerKitQueryResult';
  results?: Maybe<Array<Maybe<PartnerKit>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerKitQuerySortBy = {
  code?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  verifiedAt?: Maybe<SortDirection>;
};

export type PartnerMutations = {
  __typename?: 'PartnerMutations';
  Auth?: Maybe<PartnerAuthMutations>;
  Bank?: Maybe<PartnerBankMutations>;
  Batch?: Maybe<PartnerBatchMutations>;
  Kit?: Maybe<PartnerKitMutations>;
  Order?: Maybe<PartnerOrderMutations>;
  Product?: Maybe<PartnerProductMutations>;
  Reseller?: Maybe<PartnerResellerMutations>;
  Role?: Maybe<PartnerRoleMutations>;
  add?: Maybe<Partner>;
  edit?: Maybe<Partner>;
  resetPasswordResellerAccount?: Maybe<Scalars['Boolean']>;
};

export type PartnerMutationsAddArgs = {
  form?: Maybe<PartnerForm>;
};

export type PartnerMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<PartnerForm>;
};

export type PartnerMutationsResetPasswordResellerAccountArgs = {
  partnershipId: Scalars['ID'];
};

export type PartnerOrder = {
  __typename?: 'PartnerOrder';
  id?: Maybe<Scalars['String']>;
  item?: Maybe<PartnerOrderItemQueryResult>;
  itemCount?: Maybe<Scalars['Int']>;
  itemDeletedCount?: Maybe<Scalars['Int']>;
  profit?: Maybe<Scalars['Float']>;
  partner?: Maybe<Partner>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerOrderForm = {
  batch?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['Float']>;
  batchId?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerOrderFormVerify = {
  batchNumber: Scalars['ID'];
  partnershipId: Scalars['ID'];
};

export type PartnerOrderItem = {
  __typename?: 'PartnerOrderItem';
  id?: Maybe<Scalars['String']>;
  batch?: Maybe<PartnerBatch>;
  order?: Maybe<PartnerOrder>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerOrderItemMutations = {
  __typename?: 'PartnerOrderItemMutations';
  exportExcel?: Maybe<Scalars['String']>;
};

export type PartnerOrderItemMutationsExportExcelArgs = {
  sort?: Maybe<PartnerOrderItemQuerySortBy>;
  where?: Maybe<PartnerOrderItemQueryFilter>;
};

export type PartnerOrderItemQueries = {
  __typename?: 'PartnerOrderItemQueries';
  findAll?: Maybe<PartnerOrderItemQueryResult>;
};

export type PartnerOrderItemQueriesFindAllArgs = {
  sort?: Maybe<PartnerOrderItemQuerySortBy>;
  where?: Maybe<PartnerOrderItemQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerOrderItemQueryFilter = {
  kit?: Maybe<PartnerKitQueryFilter>;
  batch?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};

export type PartnerOrderItemQueryResult = {
  __typename?: 'PartnerOrderItemQueryResult';
  results?: Maybe<Array<Maybe<PartnerOrderItem>>>;
  count?: Maybe<Scalars['Int']>;
  countActivation?: Maybe<Scalars['Int']>;
};

export type PartnerOrderItemQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type PartnerOrderMutations = {
  __typename?: 'PartnerOrderMutations';
  Item?: Maybe<PartnerOrderItemMutations>;
  add?: Maybe<PartnerOrder>;
};

export type PartnerOrderMutationsAddArgs = {
  form?: Maybe<PartnerOrderForm>;
};

export type PartnerOrderQueries = {
  __typename?: 'PartnerOrderQueries';
  Item?: Maybe<PartnerOrderItemQueries>;
  findAll?: Maybe<PartnerOrderQueryResult>;
  findOne?: Maybe<PartnerOrder>;
  verify?: Maybe<PartnerOrderVerifyResult>;
};

export type PartnerOrderQueriesFindAllArgs = {
  sort?: Maybe<PartnerOrderQuerySortBy>;
  where?: Maybe<PartnerOrderQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerOrderQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PartnerOrderQueriesVerifyArgs = {
  form?: Maybe<PartnerOrderFormVerify>;
};

export type PartnerOrderQueryFilter = {
  partnership?: Maybe<Scalars['String']>;
};

export type PartnerOrderQueryResult = {
  __typename?: 'PartnerOrderQueryResult';
  results?: Maybe<Array<Maybe<PartnerOrder>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerOrderQuerySortBy = {
  profit?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PartnerOrderVerifyResult = {
  __typename?: 'PartnerOrderVerifyResult';
  allow?: Maybe<Scalars['Boolean']>;
  partnerBatchOriginalPartnershipId?: Maybe<Scalars['ID']>;
  partnerBatchTargetPartnershipId?: Maybe<Scalars['ID']>;
  partnerBatchOriginalPartnership?: Maybe<Partner>;
  partnerBatchTargetPartnership?: Maybe<Partner>;
  partnerBatch?: Maybe<PartnerBatch>;
};

export type PartnerPic = {
  __typename?: 'PartnerPic';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerPicForm = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PartnerProduct = {
  __typename?: 'PartnerProduct';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  productTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerProductForm = {
  cost?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  productTypes?: Maybe<Array<Maybe<PurchaseProductType>>>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PartnerProductMutations = {
  __typename?: 'PartnerProductMutations';
  add?: Maybe<PartnerProduct>;
};

export type PartnerProductMutationsAddArgs = {
  form?: Maybe<PartnerProductForm>;
};

export type PartnerProductQueries = {
  __typename?: 'PartnerProductQueries';
  findAll?: Maybe<PartnerProductQueryResult>;
  findOne?: Maybe<PartnerProduct>;
};

export type PartnerProductQueriesFindAllArgs = {
  sort?: Maybe<PartnerProductQuerySortBy>;
  where?: Maybe<PartnerProductQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerProductQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PartnerProductQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  partnership?: Maybe<Scalars['String']>;
};

export type PartnerProductQueryResult = {
  __typename?: 'PartnerProductQueryResult';
  results?: Maybe<Array<Maybe<PartnerProduct>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerProductQuerySortBy = {
  code?: Maybe<SortDirection>;
  cost?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  price?: Maybe<SortDirection>;
  width?: Maybe<SortDirection>;
  height?: Maybe<SortDirection>;
  weight?: Maybe<SortDirection>;
  length?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PartnerQueries = {
  __typename?: 'PartnerQueries';
  Auth?: Maybe<PartnerAuthQueries>;
  Bank?: Maybe<PartnerBankQueries>;
  Batch?: Maybe<PartnerBatchQueries>;
  Kit?: Maybe<PartnerKitQueries>;
  Order?: Maybe<PartnerOrderQueries>;
  Product?: Maybe<PartnerProductQueries>;
  Reseller?: Maybe<PartnerResellerQueries>;
  Role?: Maybe<PartnerRoleQueries>;
  findAll?: Maybe<PartnerQueryResult>;
  findOne?: Maybe<Partner>;
  checkEmailAvailability?: Maybe<CheckEmailAvailabilityResult>;
};

export type PartnerQueriesFindAllArgs = {
  sort?: Maybe<PartnerQuerySortBy>;
  where?: Maybe<PartnerQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  include?: Maybe<Array<Maybe<PartnerQueryInclude>>>;
};

export type PartnerQueriesFindOneArgs = {
  id: Scalars['String'];
  include?: Maybe<Array<Maybe<PartnerQueryInclude>>>;
};

export type PartnerQueriesCheckEmailAvailabilityArgs = {
  where?: Maybe<CheckEmailAvailabilityQueryFilter>;
};

export type PartnerQueryFilter = {
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
};

/** include other related partner data while fetching the data. */
export enum PartnerQueryInclude {
  PartnerReseller = 'PARTNER_RESELLER'
}

export type PartnerQueryResult = {
  __typename?: 'PartnerQueryResult';
  results?: Maybe<Array<Maybe<Partner>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerQuerySortBy = {
  code?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PartnerReseller = {
  __typename?: 'PartnerReseller';
  id?: Maybe<Scalars['Int']>;
  userType?: Maybe<PartnerUserType>;
  partnerName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  partnerStatus?: Maybe<Scalars['Boolean']>;
  allowManualKitActivation?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  nik?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  partnerBank?: Maybe<PartnerBank>;
};

export type PartnerResellerForm = {
  userType?: Maybe<PartnerUserType>;
  partnerName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  partnerStatus?: Maybe<Scalars['Boolean']>;
  allowManualKitActivation?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Scalars['String']>;
  nik?: Maybe<Scalars['String']>;
  npwp?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  bankId?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  counselorId?: Maybe<Scalars['Int']>;
  partnershipId?: Maybe<Scalars['String']>;
  /**
   * Password for reselller to do login on counselor-dashboard or related platform.
   * only for reseller that have userType = "DOCTOR".
   */
  password?: Maybe<Scalars['String']>;
};

export type PartnerResellerMutations = {
  __typename?: 'PartnerResellerMutations';
  add?: Maybe<PartnerReseller>;
  edit?: Maybe<PartnerReseller>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PartnerResellerMutationsAddArgs = {
  form?: Maybe<PartnerResellerForm>;
};

export type PartnerResellerMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<PartnerResellerForm>;
};

export type PartnerResellerMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type PartnerResellerQueries = {
  __typename?: 'PartnerResellerQueries';
  findOne?: Maybe<PartnerReseller>;
};

export type PartnerResellerQueriesFindOneArgs = {
  partnershipId: Scalars['String'];
};

export type PartnerRole = {
  __typename?: 'PartnerRole';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PartnerRoleForm = {
  name?: Maybe<Scalars['String']>;
};

export type PartnerRoleMutations = {
  __typename?: 'PartnerRoleMutations';
  add?: Maybe<PartnerRole>;
};

export type PartnerRoleMutationsAddArgs = {
  form?: Maybe<PartnerRoleForm>;
};

export type PartnerRoleQueries = {
  __typename?: 'PartnerRoleQueries';
  findAll?: Maybe<PartnerRoleQueryResult>;
  findOne?: Maybe<PartnerRole>;
};

export type PartnerRoleQueriesFindAllArgs = {
  sort?: Maybe<PartnerRoleQuerySortBy>;
  where?: Maybe<PartnerRoleQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PartnerRoleQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PartnerRoleQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
};

export type PartnerRoleQueryResult = {
  __typename?: 'PartnerRoleQueryResult';
  results?: Maybe<Array<Maybe<PartnerRole>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnerRoleQuerySortBy = {
  name?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type Partnership = {
  __typename?: 'Partnership';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  totalKit?: Maybe<Scalars['Int']>;
  totalActivatedKit?: Maybe<Scalars['Int']>;
  account?: Maybe<PartnershipAccount>;
};

export type PartnershipAccount = {
  __typename?: 'PartnershipAccount';
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
};

export type PartnershipAccountAddForm = {
  partnershipId: Scalars['String'];
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type PartnershipAccountEditForm = {
  email?: Maybe<Scalars['String']>;
};

export type PartnershipAccountForm = {
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type PartnershipAccountMutations = {
  __typename?: 'PartnershipAccountMutations';
  add?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<Scalars['Boolean']>;
  deactivate?: Maybe<Scalars['Boolean']>;
};

export type PartnershipAccountMutationsAddArgs = {
  form: PartnershipAccountAddForm;
};

export type PartnershipAccountMutationsEditArgs = {
  id: Scalars['String'];
  form: PartnershipAccountEditForm;
};

export type PartnershipAccountMutationsDeactivateArgs = {
  id: Scalars['String'];
};

export type PartnershipAddProductForm = {
  batch: Array<Maybe<Scalars['String']>>;
};

export type PartnershipForm = {
  name?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['Float']>;
  batch?: Maybe<Array<Maybe<Scalars['String']>>>;
  account?: Maybe<PartnershipAccountForm>;
};

export type PartnershipMutations = {
  __typename?: 'PartnershipMutations';
  add?: Maybe<Scalars['Boolean']>;
  addProduct?: Maybe<Scalars['Boolean']>;
  export?: Maybe<Scalars['String']>;
  Account?: Maybe<PartnershipAccountMutations>;
};

export type PartnershipMutationsAddArgs = {
  form?: Maybe<PartnershipForm>;
};

export type PartnershipMutationsAddProductArgs = {
  id: Scalars['String'];
  form: PartnershipAddProductForm;
};

export type PartnershipMutationsExportArgs = {
  where?: Maybe<PartnershipProductsQueryFilter>;
  sort?: Maybe<PurchaseProductKitBatchQuerySortBy>;
};

export type PartnershipProductsQueryFilter = {
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PartnershipQueries = {
  __typename?: 'PartnershipQueries';
  findAll?: Maybe<PartnershipQueryResult>;
  findAllProduct?: Maybe<PurchaseProductKitBatchQueryResult>;
  findOne?: Maybe<Partnership>;
};

export type PartnershipQueriesFindAllArgs = {
  where?: Maybe<PartnershipQueryFilter>;
  sort?: Maybe<PartnershipQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PartnershipQueriesFindAllProductArgs = {
  where?: Maybe<PartnershipProductsQueryFilter>;
  sort?: Maybe<PurchaseProductKitBatchQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PartnershipQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PartnershipQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type PartnershipQueryResult = {
  __typename?: 'PartnershipQueryResult';
  results?: Maybe<Array<Maybe<Partnership>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PartnershipQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum PartnerUserType {
  Doctor = 'DOCTOR',
  Counselor = 'COUNSELOR',
  Partner = 'PARTNER'
}

export type Payment = {
  __typename?: 'Payment';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentType>;
  status?: Maybe<PaymentStatus>;
  gateway?: Maybe<PaymentGateway>;
  voucher?: Maybe<PaymentVoucher>;
  payload?: Maybe<PaymentPayload>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  chargeType?: Maybe<Scalars['String']>;
  failureReason?: Maybe<Scalars['String']>;
  captureAmount?: Maybe<Scalars['String']>;
  authorizedAmount?: Maybe<Scalars['Int']>;
};

export type PaymentEWallet = {
  __typename?: 'PaymentEWallet';
  id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  ewalletType?: Maybe<Scalars['String']>;
  failureCode?: Maybe<Scalars['String']>;
  /** NOTE - will be depreated, will be deleted after v1.1.11 release, replaced by actions field */
  checkoutUrl?: Maybe<Scalars['String']>;
  /** NOTE - END */
  actions?: Maybe<PaymentEwalletPayloadActions>;
  expirationDate?: Maybe<Scalars['String']>;
};

export type PaymentEwalletPayloadActions = {
  __typename?: 'PaymentEwalletPayloadActions';
  desktop_web_checkout_url?: Maybe<Scalars['String']>;
  mobile_web_checkout_url?: Maybe<Scalars['String']>;
};

export type PaymentForm = {
  type?: Maybe<PaymentType>;
  phone?: Maybe<Scalars['String']>;
  voucherId?: Maybe<Scalars['Int']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type PaymentGateway = {
  __typename?: 'PaymentGateway';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<PaymentType>;
  type?: Maybe<PaymentGatewayType>;
  guide?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PaymentGatewayMutations = {
  __typename?: 'PaymentGatewayMutations';
  PayLater?: Maybe<PaymentGatewayPayLaterMutations>;
};

export type PaymentGatewayPayLaterMutations = {
  __typename?: 'PaymentGatewayPayLaterMutations';
  payLaterPlan?: Maybe<PaymentGatewayPayLaterPlan>;
};

export type PaymentGatewayPayLaterMutationsPayLaterPlanArgs = {
  form: PaymentGatewayPayLaterPlanForm;
};

export type PaymentGatewayPayLaterPlan = {
  __typename?: 'PaymentGatewayPayLaterPlan';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  options?: Maybe<Array<Maybe<PaymentGatewayPayLaterPlanOptions>>>;
};

export type PaymentGatewayPayLaterPlanForm = {
  carts?: Maybe<Array<Scalars['String']>>;
  payment: PurchaseOrderPaymentForm;
  shipment: PurchaseOrderShipmentForm;
  homeservice?: Maybe<PurchaseOrderHomeserviceForm>;
  isUpsell?: Maybe<Scalars['Boolean']>;
};

export type PaymentGatewayPayLaterPlanOptions = {
  __typename?: 'PaymentGatewayPayLaterPlanOptions';
  downpaymentAmount?: Maybe<Scalars['Float']>;
  installmentAmount?: Maybe<Scalars['Float']>;
  interestRate?: Maybe<Scalars['Float']>;
  totalAmount?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['String']>;
  intervalCount?: Maybe<Scalars['Float']>;
  totalRecurrence?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentGatewayQueries = {
  __typename?: 'PaymentGatewayQueries';
  findAll?: Maybe<PaymentGatewayQueryResult>;
  findOne?: Maybe<PaymentGateway>;
};

export type PaymentGatewayQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type PaymentGatewayQueryResult = {
  __typename?: 'PaymentGatewayQueryResult';
  results?: Maybe<Array<Maybe<PaymentGateway>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum PaymentGatewayType {
  Qr = 'QR',
  Ewallet = 'EWALLET',
  CreditCard = 'CREDIT_CARD',
  VirtualAccount = 'VIRTUAL_ACCOUNT',
  Paylater = 'PAYLATER'
}

export type PaymentMutations = {
  __typename?: 'PaymentMutations';
  add?: Maybe<Payment>;
  Gateway?: Maybe<PaymentGatewayMutations>;
};

export type PaymentMutationsAddArgs = {
  form?: Maybe<PaymentForm>;
};

export type PaymentPayload =
  | PaymentEWallet
  | PaymentCard
  | PaymentQr
  | PaymentVa;

export type PaymentQr = {
  __typename?: 'PaymentQR';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  qrString?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PaymentQueries = {
  __typename?: 'PaymentQueries';
  Gateway?: Maybe<PaymentGatewayQueries>;
  Voucher?: Maybe<PaymentVoucherQueries>;
  findAll?: Maybe<PaymentQueryResult>;
  findOne?: Maybe<Payment>;
};

export type PaymentQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PaymentQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PaymentQueryResult = {
  __typename?: 'PaymentQueryResult';
  results?: Maybe<Array<Maybe<Payment>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum PaymentStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  Pending = 'PENDING',
  PaymentExpired = 'PAYMENT_EXPIRED'
}

export enum PaymentType {
  Ovo = 'OVO',
  Dana = 'DANA',
  Linkaja = 'LINKAJA',
  Bca = 'BCA',
  Bni = 'BNI',
  Bri = 'BRI',
  Mandiri = 'MANDIRI',
  Permata = 'PERMATA',
  Bsi = 'BSI',
  Bjb = 'BJB',
  CreditCard = 'CREDIT_CARD',
  Qris = 'QRIS',
  Kredivo = 'KREDIVO',
  Free = 'FREE',
  Cimb = 'CIMB'
}

export type PaymentVa = {
  __typename?: 'PaymentVA';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  merchantCode?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  expectedAmount?: Maybe<Scalars['Int']>;
  suggestedAmount?: Maybe<Scalars['Int']>;
};

export type PaymentVoucher = {
  __typename?: 'PaymentVoucher';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PaymentVoucherQueries = {
  __typename?: 'PaymentVoucherQueries';
  findAll?: Maybe<PaymentVoucherQueryResult>;
  findOne?: Maybe<PaymentVoucher>;
};

export type PaymentVoucherQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type PaymentVoucherQueryResult = {
  __typename?: 'PaymentVoucherQueryResult';
  results?: Maybe<Array<Maybe<PaymentVoucher>>>;
  count?: Maybe<Scalars['Int']>;
};

/** time period */
export enum Period {
  Week = 'WEEK',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Semester = 'SEMESTER',
  Annual = 'ANNUAL'
}

export type PgxClass = {
  __typename?: 'PgxClass';
  id?: Maybe<Scalars['String']>;
  dnaOwnerId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  descriptionId?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PgxClassForm = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  descriptionId: Scalars['String'];
  descriptionEn: Scalars['String'];
};

/** PgXClass Mutations */
export type PgXClassMutations = {
  __typename?: 'PgXClassMutations';
  createOrUpdate?: Maybe<Array<Maybe<PgxClass>>>;
  export?: Maybe<Scalars['Boolean']>;
  /** available mutations of DNA Panel Translation modules */
  Drug?: Maybe<PgXDrugMutations>;
};

/** PgXClass Mutations */
export type PgXClassMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<PgxClassForm>>;
};

/** PgXClass Mutations */
export type PgXClassMutationsExportArgs = {
  where?: Maybe<PgxClassQueryFilter>;
};

/** PgXClass Queries */
export type PgXClassQueries = {
  __typename?: 'PgXClassQueries';
  findAll?: Maybe<PgxClassQueryResult>;
  findOne?: Maybe<PgxClass>;
  /** available queries of DNA Panel Translation modules */
  Drug?: Maybe<PgXDrugQueries>;
};

/** PgXClass Queries */
export type PgXClassQueriesFindAllArgs = {
  where?: Maybe<PgxClassQueryFilter>;
  sort?: Maybe<PgxClassQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** PgXClass Queries */
export type PgXClassQueriesFindOneArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type PgxClassQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type PgxClassQueryResult = {
  __typename?: 'PgxClassQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PgxClass>>>;
};

export type PgxClassQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PgxDrug = {
  __typename?: 'PgxDrug';
  id?: Maybe<Scalars['String']>;
  dnaOwnerId?: Maybe<Scalars['String']>;
  pgxDrugClassId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  descriptionId?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  pgxDrugClass?: Maybe<PgxClass>;
};

export type PgxDrugForm = {
  id?: Maybe<Scalars['String']>;
  pgxDrugClassId: Scalars['String'];
  name: Scalars['String'];
  descriptionId: Scalars['String'];
  descriptionEn: Scalars['String'];
};

/** PgXDrug Mutations */
export type PgXDrugMutations = {
  __typename?: 'PgXDrugMutations';
  createOrUpdate?: Maybe<Array<Maybe<PgxDrug>>>;
  export?: Maybe<Scalars['Boolean']>;
};

/** PgXDrug Mutations */
export type PgXDrugMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<PgxDrugForm>>;
};

/** PgXDrug Mutations */
export type PgXDrugMutationsExportArgs = {
  where?: Maybe<PgxDrugQueryFilter>;
  include?: Maybe<PgxDrugQueryInclude>;
};

/** PgXDrug Queries */
export type PgXDrugQueries = {
  __typename?: 'PgXDrugQueries';
  findAll?: Maybe<PgxDrugQueryResult>;
  findOne?: Maybe<PgxDrug>;
};

/** PgXDrug Queries */
export type PgXDrugQueriesFindAllArgs = {
  where?: Maybe<PgxDrugQueryFilter>;
  sort?: Maybe<PgxDrugQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  include?: Maybe<PgxDrugQueryInclude>;
};

/** PgXDrug Queries */
export type PgXDrugQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type PgxDrugQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  pgxDrugClassId?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type PgxDrugQueryInclude = {
  pgxDrugClass?: Maybe<Scalars['Boolean']>;
};

export type PgxDrugQueryResult = {
  __typename?: 'PgxDrugQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PgxDrug>>>;
};

export type PgxDrugQuerySortBy = {
  name?: Maybe<SortDirection>;
  pgxDrugClassName?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PgxGlossary = {
  __typename?: 'PgxGlossary';
  id?: Maybe<Scalars['String']>;
  dnaOwnerId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  descriptionId?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PgxGlossaryForm = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  descriptionId: Scalars['String'];
  descriptionEn: Scalars['String'];
};

/** PgXGlossary Mutations */
export type PgXGlossaryMutations = {
  __typename?: 'PgXGlossaryMutations';
  createOrUpdate?: Maybe<Array<Maybe<PgxGlossary>>>;
  export?: Maybe<Scalars['Boolean']>;
};

/** PgXGlossary Mutations */
export type PgXGlossaryMutationsCreateOrUpdateArgs = {
  forms: Array<Maybe<PgxGlossaryForm>>;
};

/** PgXGlossary Mutations */
export type PgXGlossaryMutationsExportArgs = {
  where?: Maybe<PgxGlossaryQueryFilter>;
};

/** PgXGlossary Queries */
export type PgXGlossaryQueries = {
  __typename?: 'PgXGlossaryQueries';
  findAll?: Maybe<PgxGlossaryQueryResult>;
  findOne?: Maybe<PgxGlossary>;
};

/** PgXGlossary Queries */
export type PgXGlossaryQueriesFindAllArgs = {
  where?: Maybe<PgxGlossaryQueryFilter>;
  sort?: Maybe<PgxGlossaryQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

/** PgXGlossary Queries */
export type PgXGlossaryQueriesFindOneArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type PgxGlossaryQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
};

export type PgxGlossaryQueryResult = {
  __typename?: 'PgxGlossaryQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PgxGlossary>>>;
};

export type PgxGlossaryQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

/** PgX Mutations */
export type PgXMutations = {
  __typename?: 'PgXMutations';
  Glossary?: Maybe<PgXGlossaryMutations>;
  Class?: Maybe<PgXClassMutations>;
};

/** PgX Queries */
export type PgXQueries = {
  __typename?: 'PgXQueries';
  Glossary?: Maybe<PgXGlossaryQueries>;
  Class?: Maybe<PgXClassQueries>;
  Report?: Maybe<PgXReportQueries>;
};

export type PgxReport = {
  __typename?: 'PgxReport';
  id?: Maybe<Scalars['String']>;
  dnaUserId?: Maybe<Scalars['String']>;
  pgxDrugClassId?: Maybe<Scalars['String']>;
  pgxDrugId?: Maybe<Scalars['String']>;
  pgxDrugClass?: Maybe<PgxClass>;
  pgxDrug?: Maybe<PgxDrug>;
  category?: Maybe<Scalars['String']>;
  drugs?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  clinicalOutcome?: Maybe<Scalars['String']>;
  alternativeDrugs?: Maybe<Scalars['String']>;
  dosageRequirement?: Maybe<Scalars['String']>;
  scientificActions?: Maybe<Scalars['String']>;
  geneHaps?: Maybe<Scalars['String']>;
  rsidCustGenotype?: Maybe<Scalars['String']>;
  detailInformation?: Maybe<Scalars['String']>;
  specializedRecommendation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

/** PgXReport Queries */
export type PgXReportQueries = {
  __typename?: 'PgXReportQueries';
  findAll?: Maybe<PgxReportQueryResult>;
};

/** PgXReport Queries */
export type PgXReportQueriesFindAllArgs = {
  where?: Maybe<PgxReportQueryFilter>;
  sort?: Maybe<PgxReportQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  include?: Maybe<PgxReportQueryInclude>;
};

export type PgxReportQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyword?: Maybe<Scalars['String']>;
  pgxDrugClassId?: Maybe<Array<Maybe<Scalars['String']>>>;
  pgxDrugId?: Maybe<Array<Maybe<Scalars['String']>>>;
  dnaUserId?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyCode?: Maybe<Scalars['String']>;
};

export type PgxReportQueryInclude = {
  pgxDrugClass?: Maybe<Scalars['Boolean']>;
  pgxDrug?: Maybe<Scalars['Boolean']>;
};

export type PgxReportQueryResult = {
  __typename?: 'PgxReportQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PgxReport>>>;
};

export type PgxReportQuerySortBy = {
  drugClassName?: Maybe<SortDirection>;
  drugName?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PickupKitShipment = {
  __typename?: 'PickupKitShipment';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  shipmentCode?: Maybe<Scalars['String']>;
  status?: Maybe<ProductKitPickupStatus>;
  waybill?: Maybe<Scalars['String']>;
};

export type Place = {
  __typename?: 'Place';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum Platform {
  Website = 'WEBSITE',
  Mobile = 'MOBILE'
}

export type Podcast = {
  __typename?: 'Podcast';
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<PodcastKind>;
  type?: Maybe<PodcastType>;
  title?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  category?: Maybe<PodcastCategory>;
  thumbnail?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PodcastCategory = {
  __typename?: 'PodcastCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PodcastCategoryForm = {
  name?: Maybe<Scalars['String']>;
};

export type PodcastCategoryMutations = {
  __typename?: 'PodcastCategoryMutations';
  add?: Maybe<PodcastCategory>;
  edit?: Maybe<PodcastCategory>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PodcastCategoryMutationsAddArgs = {
  form?: Maybe<PodcastCategoryForm>;
};

export type PodcastCategoryMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<PodcastCategoryForm>;
};

export type PodcastCategoryMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type PodcastCategoryQueries = {
  __typename?: 'PodcastCategoryQueries';
  findAll?: Maybe<PodcastCategoryQueryResult>;
  findOne?: Maybe<PodcastCategory>;
};

export type PodcastCategoryQueriesFindAllArgs = {
  where?: Maybe<PodcastCategoryQueryFilter>;
  sort?: Maybe<PodcastCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PodcastCategoryQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PodcastCategoryQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type PodcastCategoryQueryResult = {
  __typename?: 'PodcastCategoryQueryResult';
  results?: Maybe<Array<Maybe<PodcastCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PodcastCategoryQuerySortBy = {
  name?: Maybe<SortDirection>;
};

export type PodcastForm = {
  kind?: Maybe<PodcastKind>;
  type?: Maybe<PodcastType>;
  title?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['Upload']>;
  sourceLink?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  categoryId?: Maybe<Scalars['String']>;
};

export enum PodcastKind {
  Video = 'VIDEO',
  Podcast = 'PODCAST'
}

export type PodcastMutations = {
  __typename?: 'PodcastMutations';
  Category?: Maybe<PodcastCategoryMutations>;
  add?: Maybe<Podcast>;
  edit?: Maybe<Podcast>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PodcastMutationsAddArgs = {
  form?: Maybe<PodcastForm>;
};

export type PodcastMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<PodcastForm>;
};

export type PodcastMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type PodcastQueries = {
  __typename?: 'PodcastQueries';
  Category?: Maybe<PodcastCategoryQueries>;
  findAll?: Maybe<PodcastQueryResult>;
  findOne?: Maybe<Podcast>;
};

export type PodcastQueriesFindAllArgs = {
  where?: Maybe<PodcastQueryFilter>;
  sort?: Maybe<PodcastQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PodcastQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PodcastQueryFilter = {
  kind?: Maybe<Array<Maybe<PodcastKind>>>;
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PodcastQueryResult = {
  __typename?: 'PodcastQueryResult';
  results?: Maybe<Array<Maybe<Podcast>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PodcastQuerySortBy = {
  kind?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  title?: Maybe<SortDirection>;
  duration?: Maybe<SortDirection>;
  category?: Maybe<PodcastCategoryQuerySortBy>;
  createdAt?: Maybe<SortDirection>;
};

export enum PodcastType {
  Audio = 'AUDIO',
  AudioUrl = 'AUDIO_URL',
  Video = 'VIDEO',
  VideoUrl = 'VIDEO_URL'
}

export type PriceRangeFilter = {
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type ProcessResult = {
  __typename?: 'ProcessResult';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ProdigyCategory = {
  __typename?: 'ProdigyCategory';
  id?: Maybe<Scalars['ID']>;
  panelId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  panel?: Maybe<ProdigyPanel>;
  preset?: Maybe<ProdigyCategoryPreset>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ProdigyCategoryStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  localization?: Maybe<ProdigyCategoryLocalization>;
};

export type ProdigyCategoryLocalizationArgs = {
  where?: Maybe<ProdigyCategoryLocalizationQueryFilter>;
};

export type ProdigyCategoryForm = {
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  preset?: Maybe<ProdigyCategoryPresetForm>;
  panelId?: Maybe<Scalars['ID']>;
  localization?: Maybe<ProdigyCategoryLocalizationForm>;
  localizations?: Maybe<Array<Maybe<ProdigyCategoryLocalizationForm>>>;
};

export type ProdigyCategoryLocalization = {
  __typename?: 'ProdigyCategoryLocalization';
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyCategoryLocalizationForm = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ProdigyCategoryLocalizationQueryFilter = {
  lang?: Maybe<Scalars['String']>;
  /** return default localization with english version if other language is unavailbale */
  defaultIfEmpty?: Maybe<Scalars['Boolean']>;
};

export type ProdigyCategoryLocalizationQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type ProdigyCategoryMutations = {
  __typename?: 'ProdigyCategoryMutations';
  export?: Maybe<Scalars['String']>;
  create?: Maybe<ProdigyCategory>;
  update?: Maybe<ProdigyCategory>;
};

export type ProdigyCategoryMutationsExportArgs = {
  sort?: Maybe<ProdigyCategoryQuerySortBy>;
  where?: Maybe<ProdigyCategoryQueryFilter>;
};

export type ProdigyCategoryMutationsCreateArgs = {
  form?: Maybe<ProdigyCategoryForm>;
};

export type ProdigyCategoryMutationsUpdateArgs = {
  id: Scalars['ID'];
  form?: Maybe<ProdigyCategoryForm>;
};

export type ProdigyCategoryPreset = {
  __typename?: 'ProdigyCategoryPreset';
  id?: Maybe<Scalars['ID']>;
  icon?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  thumbnailAge1?: Maybe<Scalars['String']>;
  thumbnailAge2?: Maybe<Scalars['String']>;
  bgColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyCategoryPresetForm = {
  id?: Maybe<Scalars['ID']>;
  icon?: Maybe<Scalars['Upload']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  thumbnailAge1?: Maybe<Scalars['Upload']>;
  thumbnailAge2?: Maybe<Scalars['Upload']>;
  bgColors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProdigyCategoryQueries = {
  __typename?: 'ProdigyCategoryQueries';
  findAll?: Maybe<ProdigyCategoryQueryResult>;
  findOne?: Maybe<ProdigyCategory>;
  findAllByReportPanel?: Maybe<ProdigyCategoryQueryResult>;
};

export type ProdigyCategoryQueriesFindAllArgs = {
  sort?: Maybe<ProdigyCategoryQuerySortBy>;
  where?: Maybe<ProdigyCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyCategoryQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type ProdigyCategoryQueriesFindAllByReportPanelArgs = {
  sort?: Maybe<ProdigyCategoryQuerySortBy>;
  where?: Maybe<ProdigyCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyCategoryQueryFilter = {
  name?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  panelId?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  panels?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<ProdigyCategoryStatus>>>;
};

export type ProdigyCategoryQueryResult = {
  __typename?: 'ProdigyCategoryQueryResult';
  results?: Maybe<Array<Maybe<ProdigyCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ProdigyCategoryQuerySortBy = {
  code?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  panel?: Maybe<ProdigyPanelQuerySortBy>;
  active?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  localization_en?: Maybe<ProdigyCategoryLocalizationQuerySortBy>;
  localization_id?: Maybe<ProdigyCategoryLocalizationQuerySortBy>;
};

export enum ProdigyCategoryStatus {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE'
}

export type ProdigyGuideline = {
  __typename?: 'ProdigyGuideline';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<ProdigyGuidelineType>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyGuidelineForm = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<ProdigyGuidelineType>;
  content?: Maybe<Scalars['String']>;
};

export type ProdigyGuidelineMutations = {
  __typename?: 'ProdigyGuidelineMutations';
  create?: Maybe<ProdigyGuideline>;
};

export type ProdigyGuidelineMutationsCreateArgs = {
  form?: Maybe<ProdigyGuidelineForm>;
};

export type ProdigyGuidelineQueries = {
  __typename?: 'ProdigyGuidelineQueries';
  find?: Maybe<ProdigyGuideline>;
  findOne?: Maybe<ProdigyGuideline>;
};

export type ProdigyGuidelineQueriesFindArgs = {
  where?: Maybe<ProdigyGuidelineQueryFilter>;
};

export type ProdigyGuidelineQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type ProdigyGuidelineQueryFilter = {
  type?: Maybe<ProdigyGuidelineType>;
};

export enum ProdigyGuidelineType {
  Panel = 'PANEL',
  Trait = 'TRAIT',
  Mapper = 'MAPPER',
  General = 'GENERAL',
  Category = 'CATEGORY',
  ScoreType = 'SCORE_TYPE',
  TraitLocalization = 'TRAIT_LOCALIZATION'
}

export type ProdigyMapper = {
  __typename?: 'ProdigyMapper';
  id?: Maybe<Scalars['ID']>;
  panel?: Maybe<Scalars['String']>;
  trait?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  asaTrait?: Maybe<ProdigyTrait>;
  reverseScore?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  sex?: Maybe<ReportMapperSex>;
};

export type ProdigyMapperMutations = {
  __typename?: 'ProdigyMapperMutations';
  export?: Maybe<Scalars['String']>;
  import?: Maybe<Scalars['Boolean']>;
};

export type ProdigyMapperMutationsExportArgs = {
  sort?: Maybe<ProdigyMapperQuerySortBy>;
  where?: Maybe<ProdigyMapperQueryFilter>;
};

export type ProdigyMapperMutationsImportArgs = {
  file?: Maybe<Scalars['Upload']>;
};

export type ProdigyMapperQueries = {
  __typename?: 'ProdigyMapperQueries';
  findAll?: Maybe<ProdigyMapperQueryResult>;
  findOne?: Maybe<ProdigyMapper>;
};

export type ProdigyMapperQueriesFindAllArgs = {
  sort?: Maybe<ProdigyMapperQuerySortBy>;
  where?: Maybe<ProdigyMapperQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyMapperQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type ProdigyMapperQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  panel?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  trait?: Maybe<Array<Maybe<Scalars['String']>>>;
  reversedScore?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  scoreType?: Maybe<Array<Maybe<Scalars['String']>>>;
  sex?: Maybe<Array<Maybe<ReportMapperSex>>>;
};

export type ProdigyMapperQueryResult = {
  __typename?: 'ProdigyMapperQueryResult';
  results?: Maybe<Array<Maybe<ProdigyMapper>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ProdigyMapperQuerySortBy = {
  panel?: Maybe<SortDirection>;
  trait?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  asaTrait?: Maybe<ProdigyTraitQuerySortBy>;
  reverseScore?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  sex?: Maybe<SortDirection>;
};

export type ProdigyMutations = {
  __typename?: 'ProdigyMutations';
  Category?: Maybe<ProdigyCategoryMutations>;
  Guideline?: Maybe<ProdigyGuidelineMutations>;
  Mapper?: Maybe<ProdigyMapperMutations>;
  Panel?: Maybe<ProdigyPanelMutations>;
  Score?: Maybe<ProdigyScoreMutations>;
  Trait?: Maybe<ProdigyTraitMutations>;
};

export type ProdigyPanel = {
  __typename?: 'ProdigyPanel';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  preset?: Maybe<ProdigyPanelPreset>;
  presetPdf?: Maybe<ProdigyPanelPresetPdf>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  localization?: Maybe<ProdigyPanelLocalization>;
};

export type ProdigyPanelPresetPdfArgs = {
  where?: Maybe<ProdigyPanelPresetPdfQueryFilter>;
};

export type ProdigyPanelLocalizationArgs = {
  where?: Maybe<ProdigyPanelLocalizationQueryFilter>;
};

export type ProdigyPanelForm = {
  name?: Maybe<Scalars['String']>;
  preset?: Maybe<ProdigyPanelPresetForm>;
  presetPdf?: Maybe<ProdigyPanelPresetPdfForm>;
  localization?: Maybe<ProdigyPanelLocalizationForm>;
};

export type ProdigyPanelLocalization = {
  __typename?: 'ProdigyPanelLocalization';
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyPanelLocalizationForm = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ProdigyPanelLocalizationQueryFilter = {
  lang?: Maybe<Scalars['String']>;
  /** return default localization with english version if other language is unavailbale */
  defaultIfEmpty?: Maybe<Scalars['Boolean']>;
};

export type ProdigyPanelLocalizationQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type ProdigyPanelMutations = {
  __typename?: 'ProdigyPanelMutations';
  export?: Maybe<Scalars['String']>;
  update?: Maybe<ProdigyPanel>;
};

export type ProdigyPanelMutationsExportArgs = {
  sort?: Maybe<ProdigyPanelQuerySortBy>;
  where?: Maybe<ProdigyPanelQueryFilter>;
};

export type ProdigyPanelMutationsUpdateArgs = {
  id: Scalars['ID'];
  form?: Maybe<ProdigyPanelForm>;
};

export type ProdigyPanelPreset = {
  __typename?: 'ProdigyPanelPreset';
  id?: Maybe<Scalars['ID']>;
  icon?: Maybe<Scalars['String']>;
  bgColors?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyPanelPresetForm = {
  id?: Maybe<Scalars['ID']>;
  icon?: Maybe<Scalars['Upload']>;
  bgColors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProdigyPanelPresetPdf = {
  __typename?: 'ProdigyPanelPresetPdf';
  id?: Maybe<Scalars['ID']>;
  age?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyPanelPresetPdfForm = {
  id?: Maybe<Scalars['ID']>;
  age?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['Upload']>;
  gender?: Maybe<Scalars['String']>;
};

export type ProdigyPanelPresetPdfQueryFilter = {
  age?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
};

export type ProdigyPanelQueries = {
  __typename?: 'ProdigyPanelQueries';
  findAll?: Maybe<ProdigyPanelQueryResult>;
  findAllByReportPanel?: Maybe<ProdigyPanelQueryResult>;
  findOne?: Maybe<ProdigyPanel>;
};

export type ProdigyPanelQueriesFindAllArgs = {
  sort?: Maybe<ProdigyPanelQuerySortBy>;
  where?: Maybe<ProdigyPanelQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyPanelQueriesFindAllByReportPanelArgs = {
  sort?: Maybe<ProdigyPanelQuerySortBy>;
  where?: Maybe<ProdigyPanelQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyPanelQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type ProdigyPanelQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProdigyPanelQueryResult = {
  __typename?: 'ProdigyPanelQueryResult';
  results?: Maybe<Array<Maybe<ProdigyPanel>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ProdigyPanelQuerySortBy = {
  code?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  localization_en?: Maybe<ProdigyPanelLocalizationQuerySortBy>;
  localization_id?: Maybe<ProdigyPanelLocalizationQuerySortBy>;
};

export type ProdigyQueries = {
  __typename?: 'ProdigyQueries';
  Category?: Maybe<ProdigyCategoryQueries>;
  Guideline?: Maybe<ProdigyGuidelineQueries>;
  Mapper?: Maybe<ProdigyMapperQueries>;
  Panel?: Maybe<ProdigyPanelQueries>;
  Score?: Maybe<ProdigyScoreQueries>;
  Trait?: Maybe<ProdigyTraitQueries>;
  Report?: Maybe<ProdigyReportQueries>;
};

export type ProdigyReport = {
  __typename?: 'ProdigyReport';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  releasedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyReportPanel = {
  __typename?: 'ProdigyReportPanel';
  id?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  actualScore?: Maybe<Scalars['Float']>;
  /** type of index used for bar visualization on mobile apps. */
  barIndex?: Maybe<Scalars['Float']>;
  genes?: Maybe<Array<Maybe<BioinformaticGene>>>;
  genotypes?: Maybe<Array<Maybe<BioinformaticReportGenoType>>>;
  panel?: Maybe<ProdigyPanel>;
  trait?: Maybe<ProdigyTrait>;
  category?: Maybe<ProdigyCategory>;
  report?: Maybe<ProdigyReport>;
  traitScore?: Maybe<ProdigyScore>;
  traitScoreReport?: Maybe<ProdigyScoreReport>;
  traitScores?: Maybe<Array<Maybe<ProdigyScore>>>;
  biScore?: Maybe<BioinformaticScore>;
};

export type ProdigyReportPanelTraitScoreReportArgs = {
  where?: Maybe<ProdigyScoreReportQueryFilter>;
};

export type ProdigyReportPanelGenoType = {
  __typename?: 'ProdigyReportPanelGenoType';
  geneId?: Maybe<Scalars['String']>;
  genoTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProdigyReportPanelQueries = {
  __typename?: 'ProdigyReportPanelQueries';
  findOne?: Maybe<ProdigyReportPanel>;
  findAll?: Maybe<ProdigyReportPanelQueryResult>;
};

export type ProdigyReportPanelQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type ProdigyReportPanelQueriesFindAllArgs = {
  where?: Maybe<ProdigyReportPanelQueryFilter>;
  sort?: Maybe<ProdigyReportPanelSortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyReportPanelQueryFilter = {
  panel?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  trait?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  panelId?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId?: Maybe<Array<Maybe<Scalars['String']>>>;
  traitId?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<StatusCategoryAndTrait>;
};

export type ProdigyReportPanelQueryResult = {
  __typename?: 'ProdigyReportPanelQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<ProdigyReportPanel>>>;
};

export type ProdigyReportPanelSortBy = {
  score?: Maybe<SortDirection>;
  actualScore?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type ProdigyReportQueries = {
  __typename?: 'ProdigyReportQueries';
  Panel?: Maybe<ProdigyReportPanelQueries>;
  findOne?: Maybe<ProdigyReport>;
  findAll?: Maybe<ProdigyReportQueryResult>;
};

export type ProdigyReportQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type ProdigyReportQueriesFindAllArgs = {
  where?: Maybe<ProdigyReportQueryFilter>;
  sort?: Maybe<ProdigyReportQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyReportQueryFilter = {
  code: Scalars['String'];
};

export type ProdigyReportQueryResult = {
  __typename?: 'ProdigyReportQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<ProdigyReport>>>;
};

export type ProdigyReportQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type ProdigyScore = {
  __typename?: 'ProdigyScore';
  id?: Maybe<Scalars['ID']>;
  to?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  scoreMapped?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyScoreForm = {
  id?: Maybe<Scalars['ID']>;
  to?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['Float']>;
  response?: Maybe<Scalars['String']>;
  scoreMapped?: Maybe<Scalars['Int']>;
};

export type ProdigyScoreMutations = {
  __typename?: 'ProdigyScoreMutations';
  Type?: Maybe<ProdigyScoreTypeMutations>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type ProdigyScoreMutationsDeleteArgs = {
  id: Scalars['ID'];
};

export type ProdigyScoreQueries = {
  __typename?: 'ProdigyScoreQueries';
  Type?: Maybe<ProdigyScoreTypeQueries>;
  findAll?: Maybe<ProdigyScoreQueryResult>;
};

export type ProdigyScoreQueriesFindAllArgs = {
  where?: Maybe<ProdigyScoreQueryFilter>;
  sort?: Maybe<ProdigyScoreQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyScoreQueryFilter = {
  scoreTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  traitId?: Maybe<Scalars['String']>;
};

export type ProdigyScoreQueryResult = {
  __typename?: 'ProdigyScoreQueryResult';
  results?: Maybe<Array<Maybe<ProdigyScore>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ProdigyScoreQuerySortBy = {
  to?: Maybe<SortDirection>;
  from?: Maybe<SortDirection>;
  response?: Maybe<SortDirection>;
  scoreMapped?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<SortDirection>;
};

export type ProdigyScoreReport = {
  __typename?: 'ProdigyScoreReport';
  id?: Maybe<Scalars['ID']>;
  age?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  noteInList?: Maybe<Array<Maybe<Scalars['String']>>>;
  score?: Maybe<ProdigyScore>;
  gender?: Maybe<Scalars['String']>;
  significance?: Maybe<Scalars['String']>;
  significanceInList?: Maybe<Array<Maybe<Scalars['String']>>>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyScoreReportForm = {
  id?: Maybe<Scalars['ID']>;
  age?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  significance?: Maybe<Scalars['String']>;
  interpretation?: Maybe<Scalars['String']>;
  shortInterpretation?: Maybe<Scalars['String']>;
  scoreId?: Maybe<Scalars['ID']>;
};

export type ProdigyScoreReportQueryFilter = {
  lang?: Maybe<Scalars['String']>;
  /** return default localization with english version if other language is unavailbale */
  defaultIfEmpty?: Maybe<Scalars['Boolean']>;
};

export type ProdigyScoreType = {
  __typename?: 'ProdigyScoreType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  scores?: Maybe<ProdigyScoreQueryResult>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyScoreTypeForm = {
  name?: Maybe<Scalars['String']>;
  scores?: Maybe<Array<Maybe<ProdigyScoreForm>>>;
};

export type ProdigyScoreTypeMutations = {
  __typename?: 'ProdigyScoreTypeMutations';
  delete?: Maybe<Scalars['Boolean']>;
  create?: Maybe<ProdigyScoreType>;
  update?: Maybe<ProdigyScoreType>;
};

export type ProdigyScoreTypeMutationsDeleteArgs = {
  id: Scalars['ID'];
};

export type ProdigyScoreTypeMutationsCreateArgs = {
  form?: Maybe<ProdigyScoreTypeForm>;
};

export type ProdigyScoreTypeMutationsUpdateArgs = {
  id: Scalars['ID'];
  form?: Maybe<ProdigyScoreTypeForm>;
};

export type ProdigyScoreTypeQueries = {
  __typename?: 'ProdigyScoreTypeQueries';
  findAll?: Maybe<ProdigyScoreTypeQueryResult>;
  findOne?: Maybe<ProdigyScoreType>;
};

export type ProdigyScoreTypeQueriesFindAllArgs = {
  sort?: Maybe<ProdigyScoreTypeQuerySortBy>;
  where?: Maybe<ProdigyScoreTypeQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyScoreTypeQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type ProdigyScoreTypeQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
};

export type ProdigyScoreTypeQueryResult = {
  __typename?: 'ProdigyScoreTypeQueryResult';
  results?: Maybe<Array<Maybe<ProdigyScoreType>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ProdigyScoreTypeQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type ProdigyTrait = {
  __typename?: 'ProdigyTrait';
  id?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ProdigyTraitStatus>;
  category?: Maybe<ProdigyCategory>;
  scoreType?: Maybe<ProdigyScoreType>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  localization?: Maybe<ProdigyTraitLocalization>;
};

export type ProdigyTraitLocalizationArgs = {
  where?: Maybe<ProdigyTraitLocalizationQueryFilter>;
};

export type ProdigyTraitForm = {
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['ID']>;
  localization?: Maybe<ProdigyTraitLocalizationForm>;
  localizations?: Maybe<Array<Maybe<ProdigyTraitLocalizationForm>>>;
};

export type ProdigyTraitLocalization = {
  __typename?: 'ProdigyTraitLocalization';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<Maybe<ProdigyScoreReport>>>;
  description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProdigyTraitLocalizationForm = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<Maybe<ProdigyScoreReportForm>>>;
};

export type ProdigyTraitLocalizationQueryFilter = {
  lang?: Maybe<Scalars['String']>;
  /** return default localization with english version if other language is unavailbale */
  defaultIfEmpty?: Maybe<Scalars['Boolean']>;
};

export type ProdigyTraitLocalizationQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type ProdigyTraitMutations = {
  __typename?: 'ProdigyTraitMutations';
  export?: Maybe<Scalars['String']>;
  create?: Maybe<ProdigyTrait>;
  update?: Maybe<ProdigyTrait>;
};

export type ProdigyTraitMutationsExportArgs = {
  sort?: Maybe<ProdigyTraitQuerySortBy>;
  where?: Maybe<ProdigyTraitQueryFilter>;
};

export type ProdigyTraitMutationsCreateArgs = {
  form?: Maybe<ProdigyTraitForm>;
};

export type ProdigyTraitMutationsUpdateArgs = {
  id: Scalars['ID'];
  form?: Maybe<ProdigyTraitForm>;
};

export type ProdigyTraitQueries = {
  __typename?: 'ProdigyTraitQueries';
  findAll?: Maybe<ProdigyTraitQueryResult>;
  findAllByReportPanel?: Maybe<ProdigyTraitQueryResult>;
  findOne?: Maybe<ProdigyTrait>;
};

export type ProdigyTraitQueriesFindAllArgs = {
  sort?: Maybe<ProdigyTraitQuerySortBy>;
  where?: Maybe<ProdigyTraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyTraitQueriesFindAllByReportPanelArgs = {
  sort?: Maybe<ProdigyTraitQuerySortBy>;
  where?: Maybe<ProdigyTraitQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProdigyTraitQueriesFindOneArgs = {
  id: Scalars['ID'];
};

export type ProdigyTraitQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  localization?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  panels?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<ProdigyTraitStatus>>>;
};

export type ProdigyTraitQueryResult = {
  __typename?: 'ProdigyTraitQueryResult';
  results?: Maybe<Array<Maybe<ProdigyTrait>>>;
  count?: Maybe<Scalars['Int']>;
};

export type ProdigyTraitQuerySortBy = {
  code?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  active?: Maybe<SortDirection>;
  category?: Maybe<ProdigyCategoryQuerySortBy>;
  scoreType?: Maybe<ProdigyScoreTypeQuerySortBy>;
  createdAt?: Maybe<SortDirection>;
  localization_en?: Maybe<ProdigyTraitLocalizationQuerySortBy>;
  localization_id?: Maybe<ProdigyTraitLocalizationQuerySortBy>;
};

export enum ProdigyTraitStatus {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE',
  NotMapped = 'NOT_MAPPED'
}

export type ProductKitPickupHistory = {
  __typename?: 'ProductKitPickupHistory';
  id?: Maybe<Scalars['String']>;
  waybill?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProductKitPickupHistoryForm = {
  status?: Maybe<ProductKitPickupStatus>;
  message?: Maybe<Scalars['String']>;
};

export type ProductKitPickupHistoryMutations = {
  __typename?: 'ProductKitPickupHistoryMutations';
  edit?: Maybe<Scalars['Boolean']>;
};

export type ProductKitPickupHistoryMutationsEditArgs = {
  id?: Maybe<Scalars['String']>;
  form?: Maybe<ProductKitPickupHistoryForm>;
};

export enum ProductKitPickupStatus {
  WaitingKitpickupConfirmed = 'WAITING_KITPICKUP_CONFIRMED',
  KitpickupConfirmed = 'KITPICKUP_CONFIRMED',
  OnKitpickup = 'ON_KITPICKUP',
  OnKitdelivery = 'ON_KITDELIVERY',
  KitpickupReceived = 'KITPICKUP_RECEIVED'
}

export type ProductPickupForm = {
  id?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
};

export type ProductPurchaseReport = {
  __typename?: 'ProductPurchaseReport';
  id?: Maybe<Scalars['String']>;
  buyer?: Maybe<UserProfile>;
  report?: Maybe<PurchaseProductReport>;
  category?: Maybe<PurchaseProductCategory>;
  type?: Maybe<PurchaseProduct>;
  status?: Maybe<ProductPurchaseReportStatus>;
  price?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ProductPurchaseReportForm = {
  traitId: Scalars['Int'];
};

export type ProductPurchaseReportMutations = {
  __typename?: 'ProductPurchaseReportMutations';
  add?: Maybe<ProductPurchaseReport>;
};

export type ProductPurchaseReportMutationsAddArgs = {
  form?: Maybe<ProductPurchaseReportForm>;
};

export type ProductPurchaseReportQueries = {
  __typename?: 'ProductPurchaseReportQueries';
  findOneMaxPrice?: Maybe<Scalars['Int']>;
  findOneMinPrice?: Maybe<Scalars['Int']>;
  findOne?: Maybe<ProductPurchaseReport>;
  findAll?: Maybe<ProductPurchaseReportQueryResult>;
};

export type ProductPurchaseReportQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type ProductPurchaseReportQueriesFindAllArgs = {
  where?: Maybe<ProductPurchaseReportQueryFilter>;
  sort?: Maybe<ProductPurchaseReportQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ProductPurchaseReportQueryFilter = {
  buyer?: Maybe<Scalars['String']>;
  trait?: Maybe<Scalars['String']>;
  traits?: Maybe<Array<Maybe<Scalars['String']>>>;
  report?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<ProductPurchaseReportStatus>>>;
  price?: Maybe<PriceRangeFilter>;
  createdAt?: Maybe<DateRangeFilter>;
};

export type ProductPurchaseReportQueryResult = {
  __typename?: 'ProductPurchaseReportQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<ProductPurchaseReport>>>;
};

export type ProductPurchaseReportQuerySortBy = {
  buyer?: Maybe<SortDirection>;
  trait?: Maybe<SortDirection>;
  price?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum ProductPurchaseReportStatus {
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  PaymentReceived = 'PAYMENT_RECEIVED',
  PaymentExpired = 'PAYMENT_EXPIRED'
}

export type Promo = {
  __typename?: 'Promo';
  id?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<PromoStatus>;
  paidAt?: Maybe<Scalars['Date']>;
  address?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PromoForm = {
  fee?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['Date']>;
  referral?: Maybe<Scalars['String']>;
};

export type PromoMutations = {
  __typename?: 'PromoMutations';
  Referral?: Maybe<PromoReferralMutations>;
  Visitor?: Maybe<PromoVisitorMutations>;
  add?: Maybe<Promo>;
};

export type PromoMutationsAddArgs = {
  form?: Maybe<PromoForm>;
};

export type PromoQueries = {
  __typename?: 'PromoQueries';
  Referral?: Maybe<PromoReferralQueries>;
};

export type PromoQueryFilter = {
  status?: Maybe<Array<Maybe<PromoStatus>>>;
};

export type PromoQueryResult = {
  __typename?: 'PromoQueryResult';
  results?: Maybe<Array<Maybe<Promo>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PromoReferral = {
  __typename?: 'PromoReferral';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  baseUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  orders?: Maybe<PromoQueryResult>;
  visitors?: Maybe<PromoVisitorQueryResult>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PromoReferralOrdersArgs = {
  where?: Maybe<PromoQueryFilter>;
};

export type PromoReferralForm = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  baseUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PromoReferralMutations = {
  __typename?: 'PromoReferralMutations';
  add?: Maybe<PromoReferral>;
};

export type PromoReferralMutationsAddArgs = {
  form?: Maybe<PromoReferralForm>;
};

export type PromoReferralQueries = {
  __typename?: 'PromoReferralQueries';
  findAll?: Maybe<PromoReferralQueryResult>;
  findOne?: Maybe<PromoReferral>;
  findByCode?: Maybe<PromoReferral>;
};

export type PromoReferralQueriesFindAllArgs = {
  where?: Maybe<PromoReferralQueryFilter>;
  sort?: Maybe<PromoReferralQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PromoReferralQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PromoReferralQueriesFindByCodeArgs = {
  code: Scalars['String'];
};

export type PromoReferralQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
};

export type PromoReferralQueryResult = {
  __typename?: 'PromoReferralQueryResult';
  results?: Maybe<Array<Maybe<PromoReferral>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PromoReferralQuerySortBy = {
  link?: Maybe<SortDirection>;
  description?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum PromoStatus {
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  PaymentReceived = 'PAYMENT_RECEIVED',
  PaymentExpired = 'PAYMENT_EXPIRED'
}

export type PromoVisitor = {
  __typename?: 'PromoVisitor';
  id?: Maybe<Scalars['String']>;
  referral?: Maybe<PromoReferral>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PromoVisitorForm = {
  referral?: Maybe<Scalars['String']>;
};

export type PromoVisitorMutations = {
  __typename?: 'PromoVisitorMutations';
  add?: Maybe<PromoVisitor>;
};

export type PromoVisitorMutationsAddArgs = {
  form?: Maybe<PromoVisitorForm>;
};

export type PromoVisitorQueryResult = {
  __typename?: 'PromoVisitorQueryResult';
  results?: Maybe<Array<Maybe<PromoVisitor>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseCart = {
  __typename?: 'PurchaseCart';
  id?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  product?: Maybe<PurchaseProduct>;
  quantity?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseCartForm = {
  quantity?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
};

export type PurchaseCartMutations = {
  __typename?: 'PurchaseCartMutations';
  add?: Maybe<PurchaseCart>;
  edit?: Maybe<PurchaseCart>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PurchaseCartMutationsAddArgs = {
  form?: Maybe<PurchaseCartForm>;
};

export type PurchaseCartMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<PurchaseCartForm>;
};

export type PurchaseCartMutationsDeleteArgs = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseCartQueries = {
  __typename?: 'PurchaseCartQueries';
  findAll?: Maybe<PurchaseCartQueryResult>;
  findOne?: Maybe<PurchaseCart>;
};

export type PurchaseCartQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  where?: Maybe<PurchaseCartQueryFilter>;
};

export type PurchaseCartQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseCartQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseCartQueryResult = {
  __typename?: 'PurchaseCartQueryResult';
  results?: Maybe<Array<Maybe<PurchaseCart>>>;
  count?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PurchaseInstallmentQueryResult = {
  __typename?: 'PurchaseInstallmentQueryResult';
  results?: Maybe<Array<Maybe<PurchaseOrderInstallment>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseMutations = {
  __typename?: 'PurchaseMutations';
  Cart?: Maybe<PurchaseCartMutations>;
  Order?: Maybe<PurchaseOrderMutations>;
  Product?: Maybe<PurchaseProductMutations>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<PurchaseOrderType>;
  homeservice?: Maybe<PurchaseOrderHomeservice>;
  items?: Maybe<Array<Maybe<PurchaseOrderItem>>>;
  total?: Maybe<Scalars['Float']>;
  source?: Maybe<Platform>;
  discount?: Maybe<Scalars['Float']>;
  buyOneGetOneDiscount?: Maybe<Scalars['Float']>;
  promoDiscount?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  status?: Maybe<PurchaseOrderStatus>;
  payment?: Maybe<PurchaseOrderPayment>;
  installment?: Maybe<PurchaseOrderInstallment>;
  shipment?: Maybe<PurchaseOrderShipment>;
  invoice?: Maybe<PurchaseOrderInvoice>;
  histories?: Maybe<Array<Maybe<PurchaseOrderHistory>>>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  attachment?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<Scalars['String']>;
  preOrderFullfillmentDate?: Maybe<Scalars['String']>;
  isUpsell?: Maybe<Scalars['Boolean']>;
  profile?: Maybe<UserProfile>;
  receivedAt?: Maybe<Scalars['Date']>;
  deliveredAt?: Maybe<Scalars['Date']>;
  completedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderDelivery = {
  __typename?: 'PurchaseOrderDelivery';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type PurchaseOrderDeliveryForm = {
  id?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
};

export type PurchaseOrderDeliveryMutations = {
  __typename?: 'PurchaseOrderDeliveryMutations';
  add?: Maybe<PurchaseOrderDelivery>;
  resend?: Maybe<PurchaseOrderDelivery>;
};

export type PurchaseOrderDeliveryMutationsAddArgs = {
  form?: Maybe<PurchaseOrderDeliveryForm>;
};

export type PurchaseOrderDeliveryMutationsResendArgs = {
  form?: Maybe<PurchaseOrderDeliveryForm>;
};

export type PurchaseOrderForm = {
  type?: Maybe<PurchaseOrderType>;
  isUpsell?: Maybe<Scalars['Boolean']>;
  homeservice?: Maybe<PurchaseOrderHomeserviceForm>;
  carts?: Maybe<Array<Maybe<Scalars['String']>>>;
  payment?: Maybe<PurchaseOrderPaymentForm>;
  installment?: Maybe<PurchaseOrderInstallmentForm>;
  shipment?: Maybe<PurchaseOrderShipmentForm>;
};

export type PurchaseOrderHistory = {
  __typename?: 'PurchaseOrderHistory';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<PurchaseOrderStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderHomeservice = {
  __typename?: 'PurchaseOrderHomeservice';
  id?: Maybe<Scalars['String']>;
  pax?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  order?: Maybe<PurchaseOrder>;
  status?: Maybe<PurchaseOrderStatus>;
  visitedAt?: Maybe<Scalars['Date']>;
  additionalPax?: Maybe<Scalars['Int']>;
  additionalPrice?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderHomeserviceForm = {
  pax?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<PurchaseOrderStatus>;
  visitedAt?: Maybe<Scalars['Date']>;
  additionalPax?: Maybe<Scalars['Int']>;
  additionalPrice?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderHomeserviceMutations = {
  __typename?: 'PurchaseOrderHomeserviceMutations';
  Setting?: Maybe<PurchaseOrderHomeserviceSettingMutations>;
  edit?: Maybe<PurchaseOrderHomeservice>;
  exportExcel?: Maybe<Scalars['String']>;
};

export type PurchaseOrderHomeserviceMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<PurchaseOrderHomeserviceForm>;
};

export type PurchaseOrderHomeserviceMutationsExportExcelArgs = {
  sort?: Maybe<PurchaseOrderHomeserviceQuerySortBy>;
  where?: Maybe<PurchaseOrderHomeserviceQueryFilter>;
};

export enum PurchaseOrderHomeServicePurchaseType {
  Preorder = 'PREORDER',
  Stock = 'STOCK'
}

export type PurchaseOrderHomeserviceQueries = {
  __typename?: 'PurchaseOrderHomeserviceQueries';
  Setting?: Maybe<PurchaseOrderHomeserviceSettingQueries>;
  findAll?: Maybe<PurchaseOrderHomeserviceQueryResult>;
  findOne?: Maybe<PurchaseOrderHomeservice>;
};

export type PurchaseOrderHomeserviceQueriesFindAllArgs = {
  sort?: Maybe<PurchaseOrderHomeserviceQuerySortBy>;
  where?: Maybe<PurchaseOrderHomeserviceQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderHomeserviceQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseOrderHomeserviceQueryFilter = {
  status?: Maybe<Array<Maybe<PurchaseOrderStatus>>>;
  keyword?: Maybe<Scalars['String']>;
  totalKit?: Maybe<Scalars['Int']>;
  totalPax?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  visitedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  purchaseType?: Maybe<Array<Maybe<PurchaseOrderHomeServicePurchaseType>>>;
};

export type PurchaseOrderHomeserviceQueryResult = {
  __typename?: 'PurchaseOrderHomeserviceQueryResult';
  results?: Maybe<Array<Maybe<PurchaseOrderHomeservice>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderHomeserviceQuerySortBy = {
  code?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  visitedAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type PurchaseOrderHomeserviceSetting = {
  __typename?: 'PurchaseOrderHomeserviceSetting';
  id?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  additionalPrice?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderHomeserviceSettingForm = {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  additionalPrice?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderHomeserviceSettingMutations = {
  __typename?: 'PurchaseOrderHomeserviceSettingMutations';
  edit?: Maybe<PurchaseOrderHomeserviceSetting>;
};

export type PurchaseOrderHomeserviceSettingMutationsEditArgs = {
  form?: Maybe<PurchaseOrderHomeserviceSettingForm>;
};

export type PurchaseOrderHomeserviceSettingQueries = {
  __typename?: 'PurchaseOrderHomeserviceSettingQueries';
  findOne?: Maybe<PurchaseOrderHomeserviceSetting>;
};

export type PurchaseOrderInstallment = {
  __typename?: 'PurchaseOrderInstallment';
  id?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['String']>;
  order?: Maybe<PurchaseOrder>;
  payload?: Maybe<PaymentPayload>;
  interest?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderInstallmentForm = {
  count?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  interval?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['Float']>;
};

export type PurchaseOrderInstallmentQueries = {
  __typename?: 'PurchaseOrderInstallmentQueries';
  findAll?: Maybe<PurchaseInstallmentQueryResult>;
  findOne?: Maybe<PurchaseOrderInstallment>;
  checkZeroInterestWithBIN?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrderInstallmentQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderInstallmentQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseOrderInstallmentQueriesCheckZeroInterestWithBinArgs = {
  bin: Scalars['String'];
};

export type PurchaseOrderInvoice = {
  __typename?: 'PurchaseOrderInvoice';
  invoiceNumber?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['String']>;
};

export type PurchaseOrderItem = {
  __typename?: 'PurchaseOrderItem';
  id?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  product?: Maybe<PurchaseProduct>;
  quantity?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderMutations = {
  __typename?: 'PurchaseOrderMutations';
  Homeservice?: Maybe<PurchaseOrderHomeserviceMutations>;
  Reminder?: Maybe<PurchaseOrderReminderMutations>;
  add?: Maybe<PurchaseOrder>;
  complete?: Maybe<PurchaseOrder>;
  Delivery?: Maybe<PurchaseOrderDeliveryMutations>;
  Shipment?: Maybe<PurchaseOrderShipmentMutations>;
  export?: Maybe<Scalars['String']>;
  exportReport?: Maybe<Scalars['String']>;
};

export type PurchaseOrderMutationsAddArgs = {
  form?: Maybe<PurchaseOrderForm>;
};

export type PurchaseOrderMutationsCompleteArgs = {
  id: Scalars['String'];
};

export type PurchaseOrderMutationsExportArgs = {
  where?: Maybe<OrderQueryFilter>;
  sort?: Maybe<OrderQuerySortBy>;
};

export type PurchaseOrderMutationsExportReportArgs = {
  where?: Maybe<OrderVoucherQueryFilter>;
  partnershipId: Scalars['String'];
  createdAt?: Maybe<SortDirection>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderPayment = {
  __typename?: 'PurchaseOrderPayment';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentType>;
  order?: Maybe<PurchaseOrder>;
  status?: Maybe<PaymentStatus>;
  gateway?: Maybe<PaymentGateway>;
  /** payment referenceid that we sent to payment-gateway platform. */
  referenceId?: Maybe<Scalars['String']>;
  payload?: Maybe<PaymentPayload>;
  voucher?: Maybe<Voucher>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderPaymentForm = {
  type?: Maybe<PaymentType>;
  phone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  voucherId?: Maybe<Scalars['String']>;
  payLaterPlanId?: Maybe<Scalars['String']>;
};

export type PurchaseOrderPaymentQueries = {
  __typename?: 'PurchaseOrderPaymentQueries';
  findAll?: Maybe<PurchasePaymentQueryResult>;
  findOne?: Maybe<PurchaseOrderPayment>;
};

export type PurchaseOrderPaymentQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderPaymentQueriesFindOneArgs = {
  id: Scalars['String'];
};

export enum PurchaseOrderPurchaseType {
  Preorder = 'PREORDER',
  Stock = 'STOCK',
  /** unlock report by buying physical kit. */
  UnlockKit = 'UNLOCK_KIT',
  /** unlock report by not buying physical kit. */
  UnlockReport = 'UNLOCK_REPORT'
}

export type PurchaseOrderQueries = {
  __typename?: 'PurchaseOrderQueries';
  Homeservice?: Maybe<PurchaseOrderHomeserviceQueries>;
  Payment?: Maybe<PurchaseOrderPaymentQueries>;
  Installment?: Maybe<PurchaseOrderInstallmentQueries>;
  Shipment?: Maybe<PurchaseOrderShipmentQueries>;
  findAll?: Maybe<PurchaseOrderQueryResult>;
  findOne?: Maybe<PurchaseOrder>;
  findByVoucher?: Maybe<PurchaseOrderQueryResult>;
};

export type PurchaseOrderQueriesFindAllArgs = {
  where?: Maybe<OrderQueryFilter>;
  sort?: Maybe<OrderQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseOrderQueriesFindByVoucherArgs = {
  where?: Maybe<OrderVoucherQueryFilter>;
  partnershipId: Scalars['String'];
  createdAt?: Maybe<SortDirection>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderQueryResult = {
  __typename?: 'PurchaseOrderQueryResult';
  results?: Maybe<Array<Maybe<PurchaseOrder>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderReminder = {
  __typename?: 'PurchaseOrderReminder';
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderReminderMutations = {
  __typename?: 'PurchaseOrderReminderMutations';
  add?: Maybe<PurchaseOrderReminder>;
};

export type PurchaseOrderShipment = {
  __typename?: 'PurchaseOrderShipment';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PurchaseOrderShipmentType>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  anterAjaCode?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  waybill?: Maybe<Scalars['String']>;
  shipmentCode?: Maybe<Scalars['String']>;
  courier?: Maybe<PurchaseOrderShipmentCourier>;
  histories?: Maybe<Array<Maybe<PurchaseOrderShipmentHistory>>>;
};

export type PurchaseOrderShipmentCourier = {
  __typename?: 'PurchaseOrderShipmentCourier';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type PurchaseOrderShipmentCourierQueries = {
  __typename?: 'PurchaseOrderShipmentCourierQueries';
  findAll?: Maybe<PurchaseOrderShipmentCourierQueryResult>;
  findOne?: Maybe<PurchaseOrderShipmentCourier>;
};

export type PurchaseOrderShipmentCourierQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderShipmentCourierQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseOrderShipmentCourierQueryResult = {
  __typename?: 'PurchaseOrderShipmentCourierQueryResult';
  results?: Maybe<Array<Maybe<PurchaseOrderShipmentCourier>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseOrderShipmentForm = {
  type?: Maybe<PurchaseOrderShipmentType>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  courier?: Maybe<Scalars['String']>;
};

export type PurchaseOrderShipmentHistory = {
  __typename?: 'PurchaseOrderShipmentHistory';
  id?: Maybe<Scalars['String']>;
  waybill?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseOrderShipmentHistoryForm = {
  status?: Maybe<PurchaseOrderStatus>;
  message?: Maybe<Scalars['String']>;
};

export type PurchaseOrderShipmentHistoryMutations = {
  __typename?: 'PurchaseOrderShipmentHistoryMutations';
  edit?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrderShipmentHistoryMutationsEditArgs = {
  id?: Maybe<Scalars['String']>;
  form?: Maybe<PurchaseOrderShipmentHistoryForm>;
};

export type PurchaseOrderShipmentMutations = {
  __typename?: 'PurchaseOrderShipmentMutations';
  edit?: Maybe<Scalars['Boolean']>;
  History?: Maybe<PurchaseOrderShipmentHistoryMutations>;
};

export type PurchaseOrderShipmentMutationsEditArgs = {
  form?: Maybe<EditPurchaseOrderShipmentForm>;
};

export type PurchaseOrderShipmentQueries = {
  __typename?: 'PurchaseOrderShipmentQueries';
  Courier?: Maybe<PurchaseOrderShipmentCourierQueries>;
};

export enum PurchaseOrderShipmentType {
  Regular = 'REGULAR',
  HomeService = 'HOME_SERVICE',
  WalkIn = 'WALK_IN'
}

export enum PurchaseOrderStatus {
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  PaymentReceived = 'PAYMENT_RECEIVED',
  PaymentExpired = 'PAYMENT_EXPIRED',
  WaitingOrderConfirmed = 'WAITING_ORDER_CONFIRMED',
  OrderConfirmed = 'ORDER_CONFIRMED',
  OrderReceived = 'ORDER_RECEIVED',
  OnDelivery = 'ON_DELIVERY',
  OnPickup = 'ON_PICKUP',
  Cancelled = 'CANCELLED',
  DateSet = 'DATE_SET',
  HomeVisited = 'HOME_VISITED'
}

export enum PurchaseOrderType {
  Regular = 'REGULAR',
  HomeService = 'HOME_SERVICE',
  WalkIn = 'WALK_IN',
  Unlock = 'UNLOCK'
}

export type PurchasePaymentQueryResult = {
  __typename?: 'PurchasePaymentQueryResult';
  results?: Maybe<Array<Maybe<PurchaseOrderPayment>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProduct = {
  __typename?: 'PurchaseProduct';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageApp?: Maybe<Scalars['String']>;
  imageWeb?: Maybe<Scalars['String']>;
  bgImage?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Deprecated replaced with description */
  details?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  types?: Maybe<Array<Maybe<PurchaseProductType>>>;
  displayPrice?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<Maybe<Scalars['String']>>>;
  variant?: Maybe<PurchaseProductVariant>;
  categories?: Maybe<Array<Maybe<PurchaseProductCategory>>>;
  serviceMethods?: Maybe<Array<Maybe<PurchaseProductServiceMethod>>>;
  showOnShop?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFreeHomeService?: Maybe<Scalars['Boolean']>;
  periodFrom?: Maybe<Scalars['Date']>;
  periodTo?: Maybe<Scalars['Date']>;
  purchaseType?: Maybe<PurchaseProductPurchaseType>;
  totalPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Float']>;
  upsell?: Maybe<PurchaseProductUpsell>;
  bundling?: Maybe<Array<Maybe<PurchaseProductBundling>>>;
  promoPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<PurchaseProductDiscount>;
  bundlingId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  preOrderType?: Maybe<PurchaseProductPreorderType>;
  preOrderExpiredAt?: Maybe<Scalars['Date']>;
  preOrderFullfillmentDuration?: Maybe<Scalars['Int']>;
  preOrderFullfillmentInterval?: Maybe<Scalars['String']>;
  preOrderFullfillmentDate?: Maybe<Scalars['String']>;
  isBuyOneGetOne?: Maybe<Scalars['Boolean']>;
  buyOneMultiplier?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isUserEligibleForUpsell?: Maybe<Scalars['Boolean']>;
  upsellTag?: Maybe<PurchaseProductUpsellTag>;
  activatedReportTypeRequirement?: Maybe<PurchaseProductRequirement>;
  order?: Maybe<Scalars['Int']>;
};

export type PurchaseProductActivation = {
  __typename?: 'PurchaseProductActivation';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tutorialWatchedAt?: Maybe<Scalars['Date']>;
  isProfileDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  isCollected?: Maybe<Scalars['Boolean']>;
  productKitPickupId?: Maybe<Scalars['String']>;
  sampleId?: Maybe<Scalars['String']>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductActivationForm = {
  code?: Maybe<Scalars['String']>;
  guardianId?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
  reportTypes?: Maybe<Report_Type>;
};

export type PurchaseProductActivationMutations = {
  __typename?: 'PurchaseProductActivationMutations';
  add?: Maybe<KitActivation>;
  addWithReportTypes?: Maybe<KitActivation>;
  addKitForWebView?: Maybe<KitActivation>;
  activateKitManually?: Maybe<ProcessResult>;
  seeTutorial?: Maybe<KitActivation>;
  manualBulkActivation?: Maybe<Scalars['Boolean']>;
  Consent?: Maybe<ConsentMutations>;
};

export type PurchaseProductActivationMutationsAddArgs = {
  form?: Maybe<PurchaseProductActivationForm>;
};

export type PurchaseProductActivationMutationsAddWithReportTypesArgs = {
  form?: Maybe<PurchaseProductActivationWithReportTypesForm>;
};

export type PurchaseProductActivationMutationsAddKitForWebViewArgs = {
  form?: Maybe<PurchaseProductActivationWebviewForm>;
};

export type PurchaseProductActivationMutationsActivateKitManuallyArgs = {
  form?: Maybe<KitActivateKitManuallyForm>;
};

export type PurchaseProductActivationMutationsSeeTutorialArgs = {
  id: Scalars['String'];
};

export type PurchaseProductActivationMutationsManualBulkActivationArgs = {
  file: Scalars['Upload'];
  domain: Scalars['String'];
};

export type PurchaseProductActivationQueries = {
  __typename?: 'PurchaseProductActivationQueries';
  findAll?: Maybe<PurchaseProductActivationQueryResult>;
  findOne?: Maybe<PurchaseProductActivation>;
  findAllWithReportTypes?: Maybe<KitActivationQueryResult>;
  findOneWithReportTypes?: Maybe<KitActivation>;
  findAllUnsent?: Maybe<Array<Maybe<KitActivation>>>;
  /** deprecated */
  checkAvailableKit?: Maybe<Scalars['Boolean']>;
  checkAvailableKitV2?: Maybe<CheckAvailableKitResult>;
  checkKitActivationAvailability?: Maybe<KitCheckActivationAvailabilityResult>;
  Consent?: Maybe<ConsentQueries>;
};

export type PurchaseProductActivationQueriesFindAllArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductActivationQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductActivationQueriesFindAllWithReportTypesArgs = {
  where?: Maybe<KitActivationQueryFilter>;
};

export type PurchaseProductActivationQueriesFindOneWithReportTypesArgs = {
  id: Scalars['String'];
};

export type PurchaseProductActivationQueriesFindAllUnsentArgs = {
  profileId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductActivationQueriesCheckAvailableKitArgs = {
  code: Scalars['String'];
  reportTypes: Report_Type;
};

export type PurchaseProductActivationQueriesCheckAvailableKitV2Args = {
  code: Scalars['String'];
  reportTypes?: Maybe<Report_Type>;
};

export type PurchaseProductActivationQueriesCheckKitActivationAvailabilityArgs = {
  where?: Maybe<KitCheckActivationAvailabilityQuery>;
};

export type PurchaseProductActivationQueryResult = {
  __typename?: 'PurchaseProductActivationQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProductActivation>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductActivationWebviewForm = {
  activationId: Scalars['String'];
  reportTypes: Report_Type;
  guardianId?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseProductActivationWithReportTypesForm = {
  code: Scalars['String'];
  reportTypes: Report_Type;
  guardianId?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseProductBundling = {
  __typename?: 'PurchaseProductBundling';
  id?: Maybe<Scalars['String']>;
  bundlingId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<PurchaseProduct>>>;
  promoPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type PurchaseProductBundlingFormAdd = {
  productId: Scalars['String'];
  promoPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
};

export type PurchaseProductBundlingFormEdit = {
  bundlingId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  promoPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
};

export type PurchaseProductBundlingMutations = {
  __typename?: 'PurchaseProductBundlingMutations';
  add?: Maybe<PurchaseProductBundling>;
  edit?: Maybe<PurchaseProductBundling>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductBundlingMutationsAddArgs = {
  form?: Maybe<PurchaseProductBundlingFormAdd>;
};

export type PurchaseProductBundlingMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<PurchaseProductBundlingFormEdit>;
};

export type PurchaseProductBundlingMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type PurchaseProductBundlingQueries = {
  __typename?: 'PurchaseProductBundlingQueries';
  findAll?: Maybe<PurchaseProductBundlingQueryResult>;
  findOne?: Maybe<PurchaseProductBundling>;
};

export type PurchaseProductBundlingQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductBundlingQueryFilter>;
  sort?: Maybe<PurchaseProductBundlingQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductBundlingQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductBundlingQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  bundlingId?: Maybe<Scalars['String']>;
  productId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductBundlingQueryResult = {
  __typename?: 'PurchaseProductBundlingQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProductBundling>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductBundlingQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type PurchaseProductCategory = {
  __typename?: 'PurchaseProductCategory';
  id?: Maybe<Scalars['String']>;
  category?: Maybe<DnaCategory>;
  type?: Maybe<PurchaseProduct>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseProductCategoryMutations = {
  __typename?: 'PurchaseProductCategoryMutations';
  add?: Maybe<Array<Maybe<PurchaseProductCategory>>>;
  edit?: Maybe<PurchaseProductCategory>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductCategoryMutationsAddArgs = {
  form?: Maybe<AddPurchaseProductCategoryForm>;
};

export type PurchaseProductCategoryMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<EditPurchaseProductCategoryForm>;
};

export type PurchaseProductCategoryMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type PurchaseProductCategoryQueries = {
  __typename?: 'PurchaseProductCategoryQueries';
  findOne?: Maybe<PurchaseProductCategory>;
  findAll?: Maybe<PurchaseProductCategoryQueryResult>;
};

export type PurchaseProductCategoryQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductCategoryQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductCategoryQueryFilter>;
  sort?: Maybe<PurchaseProductCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductCategoryQueryFilter = {
  categoryName?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  traits?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type PurchaseProductCategoryQueryResult = {
  __typename?: 'PurchaseProductCategoryQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PurchaseProductCategory>>>;
};

export type PurchaseProductCategoryQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
};

export type PurchaseProductDiscount = {
  __typename?: 'PurchaseProductDiscount';
  id?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type PurchaseProductDiscountPercentageArgs = {
  rounded?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductDiscountForm = {
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type PurchaseProductKit = {
  __typename?: 'PurchaseProductKit';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  batch?: Maybe<PurchaseProductKitBatch>;
  sample?: Maybe<LabSample>;
  partnership?: Maybe<Partner>;
  /** releasedReports - temporary query - dont use it */
  releasedReports?: Maybe<Array<Maybe<DnaReport>>>;
  report?: Maybe<DnaReport>;
  reports?: Maybe<Array<Maybe<PurchaseProductKitReport>>>;
  bioinformatic?: Maybe<BioinformaticUser>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  printedAt?: Maybe<Scalars['Date']>;
  activatedAt?: Maybe<Scalars['Date']>;
  collectedAt?: Maybe<Scalars['Date']>;
  activation?: Maybe<PurchaseProductActivation>;
  activatedUser?: Maybe<UserProfile>;
  activatedUserStatus?: Maybe<UserStatus>;
  requestPickupDate?: Maybe<Scalars['Date']>;
  attachment?: Maybe<Scalars['String']>;
  batchNumber?: Maybe<Scalars['String']>;
  pickupShipment?: Maybe<PickupKitShipment>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type PurchaseProductKitReleasedReportsArgs = {
  reportType: Array<Maybe<Scalars['String']>>;
};

export type PurchaseProductKitActivatedUserQuerySortBy = {
  name?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
};

export type PurchaseProductKitBatch = {
  __typename?: 'PurchaseProductKitBatch';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<PurchaseProduct>;
  number?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  printedAt?: Maybe<Scalars['Date']>;
  activatedAt?: Maybe<Scalars['Date']>;
  profile?: Maybe<UserProfile>;
  kit?: Maybe<PurchaseProductKit>;
  partnership?: Maybe<Partnership>;
};

export type PurchaseProductKitBatchForm = {
  type?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

export enum PurchaseProductKitBatchIncludeOptions {
  Kit = 'KIT',
  Activation = 'ACTIVATION',
  Product = 'PRODUCT'
}

export type PurchaseProductKitBatchMutations = {
  __typename?: 'PurchaseProductKitBatchMutations';
  print?: Maybe<PurchaseProductKitBatch>;
  generate?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductKitBatchMutationsPrintArgs = {
  id?: Maybe<Scalars['String']>;
};

export type PurchaseProductKitBatchMutationsGenerateArgs = {
  form?: Maybe<Array<Maybe<PurchaseProductKitBatchForm>>>;
};

export type PurchaseProductKitBatchQueries = {
  __typename?: 'PurchaseProductKitBatchQueries';
  findAll?: Maybe<PurchaseProductKitBatchQueryResult>;
  findOne?: Maybe<PurchaseProductKitBatch>;
  findOneByCode?: Maybe<PurchaseProductKitBatch>;
};

export type PurchaseProductKitBatchQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductKitBatchQueryFilter>;
  sort?: Maybe<PurchaseProductKitBatchQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  include?: Maybe<Array<Maybe<PurchaseProductKitBatchIncludeOptions>>>;
};

export type PurchaseProductKitBatchQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductKitBatchQueriesFindOneByCodeArgs = {
  code: Scalars['String'];
};

export type PurchaseProductKitBatchQueryFilter = {
  kit?: Maybe<PurchaseProductKitQueryFilter>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductKitBatchQueryResult = {
  __typename?: 'PurchaseProductKitBatchQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProductKitBatch>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductKitBatchQuerySortBy = {
  code?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  activatedAt?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  number?: Maybe<SortDirection>;
  kit?: Maybe<SortKit>;
  price?: Maybe<SortDirection>;
};

export type PurchaseProductKitFilterQueryResult = {
  __typename?: 'PurchaseProductKitFilterQueryResult';
  requestPickupDateMax?: Maybe<Scalars['Date']>;
  requestPickupDateMin?: Maybe<Scalars['Date']>;
  activatedAtMax?: Maybe<Scalars['Date']>;
  activatedAtMin?: Maybe<Scalars['Date']>;
};

export type PurchaseProductKitFindOneV2Form = {
  reportType?: Maybe<ReportType>;
};

export type PurchaseProductKitForm = {
  code?: Maybe<Scalars['String']>;
};

export type PurchaseProductKitMutations = {
  __typename?: 'PurchaseProductKitMutations';
  Batch?: Maybe<PurchaseProductKitBatchMutations>;
  add?: Maybe<PurchaseProductKit>;
  generate?: Maybe<Scalars['Boolean']>;
  pickup?: Maybe<PurchaseProductKit>;
  resend?: Maybe<PurchaseProductKit>;
  printed?: Maybe<Scalars['Boolean']>;
  collected?: Maybe<Scalars['Boolean']>;
  multipleCollected?: Maybe<Scalars['Boolean']>;
  deactivate?: Maybe<Scalars['Boolean']>;
  verification?: Maybe<Scalars['Boolean']>;
  export?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductKitMutationsAddArgs = {
  form?: Maybe<PurchaseProductKitForm>;
};

export type PurchaseProductKitMutationsGenerateArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductKitMutationsPickupArgs = {
  form?: Maybe<ProductPickupForm>;
};

export type PurchaseProductKitMutationsResendArgs = {
  form?: Maybe<ProductPickupForm>;
};

export type PurchaseProductKitMutationsPrintedArgs = {
  id: Scalars['String'];
};

export type PurchaseProductKitMutationsCollectedArgs = {
  id: Scalars['String'];
};

export type PurchaseProductKitMutationsMultipleCollectedArgs = {
  asarenKitIds: Array<Maybe<Scalars['String']>>;
};

export type PurchaseProductKitMutationsDeactivateArgs = {
  id: Scalars['String'];
};

export type PurchaseProductKitMutationsVerificationArgs = {
  kitCode?: Maybe<Scalars['String']>;
  batchCode?: Maybe<Scalars['String']>;
};

export type PurchaseProductKitMutationsExportArgs = {
  where?: Maybe<PurchaseProductKitQueryFilter>;
  sort?: Maybe<PurchaseProductKitQuerySortBy>;
  includeBatchCode?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductKitQueries = {
  __typename?: 'PurchaseProductKitQueries';
  Batch?: Maybe<PurchaseProductKitBatchQueries>;
  findAll?: Maybe<PurchaseProductKitQueryResult>;
  findOne?: Maybe<PurchaseProductKit>;
  findOneV2?: Maybe<PurchaseProductKit>;
  verification?: Maybe<Scalars['String']>;
  findAllMyKitProcessStatus?: Maybe<Array<Maybe<KitProcessStatusQueryResult>>>;
  findOneMyKitProcessStatus?: Maybe<KitProcessStatusQueryResult>;
};

export type PurchaseProductKitQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductKitQueryFilter>;
  sort?: Maybe<PurchaseProductKitQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductKitQueriesFindOneArgs = {
  id?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
};

export type PurchaseProductKitQueriesFindOneV2Args = {
  where?: Maybe<PurchaseProductKitFindOneV2Form>;
};

export type PurchaseProductKitQueriesVerificationArgs = {
  kitCode?: Maybe<Scalars['String']>;
  batchCode?: Maybe<Scalars['String']>;
};

export type PurchaseProductKitQueriesFindAllMyKitProcessStatusArgs = {
  where?: Maybe<MyKitProcessStatusQueryFilter>;
};

export type PurchaseProductKitQueriesFindOneMyKitProcessStatusArgs = {
  where: MyKitProcessStatusQueryFilterFindOne;
};

export type PurchaseProductKitQueryFilter = {
  code?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  activatedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestPickupDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnershipId?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  activatedBy?: Maybe<Scalars['String']>;
  profileId?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductKitQueryResult = {
  __typename?: 'PurchaseProductKitQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProductKit>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductKitQuerySortBy = {
  code?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  activatedAt?: Maybe<SortDirection>;
  requestPickupDate?: Maybe<SortDirection>;
  activatedBy?: Maybe<SortDirection>;
  activatedUser?: Maybe<PurchaseProductKitActivatedUserQuerySortBy>;
};

export type PurchaseProductKitReport = {
  __typename?: 'PurchaseProductKitReport';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  releasedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseProductMultiplePickupForm = {
  kitId?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type PurchaseProductMutations = {
  __typename?: 'PurchaseProductMutations';
  Activation?: Maybe<PurchaseProductActivationMutations>;
  Kit?: Maybe<PurchaseProductKitMutations>;
  Pickup?: Maybe<PurchaseProductPickupMutations>;
  Category?: Maybe<PurchaseProductCategoryMutations>;
  Report?: Maybe<PurchaseProductReportMutations>;
  Partnership?: Maybe<PartnershipMutations>;
  Bundling?: Maybe<PurchaseProductBundlingMutations>;
  Upsell?: Maybe<PurchaseProductUpsellMutations>;
  add?: Maybe<PurchaseProduct>;
  delete?: Maybe<Scalars['Boolean']>;
  edit?: Maybe<PurchaseProduct>;
  editOrder?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductMutationsAddArgs = {
  form?: Maybe<AddPurchaseProductForm>;
};

export type PurchaseProductMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type PurchaseProductMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<EditPurchaseProductForm>;
};

export type PurchaseProductMutationsEditOrderArgs = {
  forms?: Maybe<Array<Maybe<EditOrderPurchaseProductForm>>>;
};

export type PurchaseProductPickup = {
  __typename?: 'PurchaseProductPickup';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<ProductKitPickupStatus>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  histories?: Maybe<Array<Maybe<ProductKitPickupHistory>>>;
  requestPickupDate?: Maybe<Scalars['Date']>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductPickupForm = {
  code?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type PurchaseProductPickupMutations = {
  __typename?: 'PurchaseProductPickupMutations';
  add?: Maybe<PurchaseProductPickup>;
  addMultiple?: Maybe<PurchaseProductPickup>;
  edit?: Maybe<Scalars['Boolean']>;
  History?: Maybe<ProductKitPickupHistoryMutations>;
};

export type PurchaseProductPickupMutationsAddArgs = {
  form?: Maybe<PurchaseProductPickupForm>;
};

export type PurchaseProductPickupMutationsAddMultipleArgs = {
  form?: Maybe<PurchaseProductMultiplePickupForm>;
};

export type PurchaseProductPickupMutationsEditArgs = {
  form?: Maybe<EditPurchaseProductPickupForm>;
};

export type PurchaseProductPickupQueries = {
  __typename?: 'PurchaseProductPickupQueries';
  findAll?: Maybe<PurchaseProductPickupQueryResult>;
  findOne?: Maybe<PurchaseProductPickup>;
  filter?: Maybe<PurchaseProductKitFilterQueryResult>;
};

export type PurchaseProductPickupQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductPickupQueryFilter>;
  sort?: Maybe<PurchaseProductPickupQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductPickupQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductPickupQueryFilter = {
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductPickupQueryResult = {
  __typename?: 'PurchaseProductPickupQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProductPickup>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductPickupQuerySortBy = {
  status?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum PurchaseProductpreOrderFullfillmentInterval {
  Days = 'DAYS',
  Weeks = 'WEEKS',
  Months = 'MONTHS'
}

export enum PurchaseProductPreorderType {
  Date = 'DATE',
  Time = 'TIME'
}

export enum PurchaseProductPurchaseType {
  Preorder = 'PREORDER',
  Stock = 'STOCK',
  Bundling = 'BUNDLING',
  Unlock = 'UNLOCK'
}

export type PurchaseProductQueries = {
  __typename?: 'PurchaseProductQueries';
  Activation?: Maybe<PurchaseProductActivationQueries>;
  Kit?: Maybe<PurchaseProductKitQueries>;
  Pickup?: Maybe<PurchaseProductPickupQueries>;
  Partnership?: Maybe<PartnershipQueries>;
  findAll?: Maybe<PurchaseProductQueryResult>;
  findAllBatchProduct?: Maybe<PurchaseProductQueryResult>;
  findOne?: Maybe<PurchaseProduct>;
  findAllForVoucherManagement?: Maybe<Array<Maybe<PurchaseProduct>>>;
  findAllShopProduct?: Maybe<PurchaseProductQueryResult>;
  minMaxOrder?: Maybe<MinMaxOrder>;
  checkAvailabilityUnlockProduct?: Maybe<
    CheckAvailabilityUnlockProductQueryResult
  >;
  Category?: Maybe<PurchaseProductCategoryQueries>;
  Report?: Maybe<PurchaseProductReportQueries>;
  Bundling?: Maybe<PurchaseProductBundlingQueries>;
  Upsell?: Maybe<PurchaseProductUpsellQueries>;
};

export type PurchaseProductQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductQueryFilter>;
  sort?: Maybe<PurchaseProductQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductQueriesFindAllBatchProductArgs = {
  where?: Maybe<PurchaseProductQueryFilter>;
  sort?: Maybe<PurchaseProductQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductQueriesFindAllForVoucherManagementArgs = {
  voucherConditionType?: Maybe<VoucherConditionType>;
};

export type PurchaseProductQueriesFindAllShopProductArgs = {
  where?: Maybe<PurchaseProductShopProductQuery>;
  whereProduct?: Maybe<PurchaseProductQueryFilter>;
  sort?: Maybe<PurchaseProductQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['Int']>>>;
  reports?: Maybe<Array<Maybe<Scalars['String']>>>;
  purchaseType?: Maybe<Array<Maybe<PurchaseProductPurchaseType>>>;
  code?: Maybe<Scalars['String']>;
  periodFrom?: Maybe<Scalars['Date']>;
  periodTo?: Maybe<Scalars['Date']>;
  showOnShop?: Maybe<Scalars['Boolean']>;
  isPeriodActive?: Maybe<Scalars['Boolean']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isFreeHomeService?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
};

export type PurchaseProductQueryResult = {
  __typename?: 'PurchaseProductQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProduct>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductQuerySortBy = {
  code?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  title?: Maybe<SortDirection>;
  price?: Maybe<SortDirection>;
  showOnShop?: Maybe<SortDirection>;
  isAvailable?: Maybe<SortDirection>;
  isFreeHomeService?: Maybe<SortDirection>;
  recommendation?: Maybe<SortDirection>;
  order?: Maybe<SortDirection>;
};

export type PurchaseProductReport = {
  __typename?: 'PurchaseProductReport';
  id?: Maybe<Scalars['String']>;
  trait?: Maybe<DnaTrait>;
  category?: Maybe<PurchaseProductCategory>;
  type?: Maybe<PurchaseProduct>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<PurchaseProductReportStatus>;
  endDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type PurchaseProductReportMutations = {
  __typename?: 'PurchaseProductReportMutations';
  Purchase?: Maybe<ProductPurchaseReportMutations>;
  add?: Maybe<PurchaseProductReportQueryResult>;
  edit?: Maybe<PurchaseProductReport>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductReportMutationsAddArgs = {
  form?: Maybe<AddPurchaseProductReportForm>;
};

export type PurchaseProductReportMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<EditPurchaseProductReportForm>;
};

export type PurchaseProductReportMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type PurchaseProductReportQueries = {
  __typename?: 'PurchaseProductReportQueries';
  Purchase?: Maybe<ProductPurchaseReportQueries>;
  findOne?: Maybe<PurchaseProductReport>;
  findAll?: Maybe<PurchaseProductReportQueryResult>;
  findOneMaxPrice?: Maybe<Scalars['Int']>;
  findOneMinPrice?: Maybe<Scalars['Int']>;
};

export type PurchaseProductReportQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductReportQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductReportQueryFilter>;
  sort?: Maybe<PurchaseProductReportQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductReportQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  trait?: Maybe<Scalars['String']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<PurchaseProductReportStatus>>>;
  price?: Maybe<PriceRangeFilter>;
  endDate?: Maybe<DateRangeFilter>;
  createdAt?: Maybe<DateRangeFilter>;
};

export type PurchaseProductReportQueryResult = {
  __typename?: 'PurchaseProductReportQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<PurchaseProductReport>>>;
};

export type PurchaseProductReportQuerySortBy = {
  trait?: Maybe<SortDirection>;
  price?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum PurchaseProductReportStatus {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED'
}

export type PurchaseProductRequirement = {
  __typename?: 'PurchaseProductRequirement';
  id?: Maybe<Scalars['ID']>;
  criteria?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type PurchaseProductRequirementForm = {
  activatedReportType?: Maybe<Array<Maybe<ReportType>>>;
};

export enum PurchaseProductServiceMethod {
  Regular = 'REGULAR',
  HomeService = 'HOME_SERVICE',
  WalkIn = 'WALK_IN'
}

export type PurchaseProductShopProductQuery = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  shopTypes?: Maybe<Array<Maybe<PurchaseProductShopType>>>;
  productTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  reportType?: Maybe<Array<Maybe<PurchaseProductType>>>;
  /** deprecated, to be deleted after mobile version of asaren and diagnos locked at >= 2.0.12 */
  type: PurchaseProductShopType;
};

export enum PurchaseProductShopType {
  Regular = 'REGULAR',
  Unlock = 'UNLOCK'
}

export enum PurchaseProductType {
  DnaPremium = 'DNA_PREMIUM',
  Dna_360 = 'DNA_360',
  Ancestry = 'ANCESTRY',
  Prodigy = 'PRODIGY',
  Mcu = 'MCU',
  LabTest = 'LAB_TEST',
  Nutrigenetic = 'NUTRIGENETIC'
}

export type PurchaseProductUpsell = {
  __typename?: 'PurchaseProductUpsell';
  id?: Maybe<Scalars['String']>;
  bundlingId?: Maybe<Scalars['String']>;
  isOneTimePerUser?: Maybe<Scalars['Boolean']>;
  isSelectiveCondition?: Maybe<Scalars['Boolean']>;
  condition?: Maybe<Array<Maybe<PurchaseProductUpsellConditionEnum>>>;
  reportTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<PurchaseProductUpsellProduct>>>;
  totalPrice?: Maybe<Scalars['Int']>;
  promoPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Float']>;
};

export enum PurchaseProductUpsellConditionEnum {
  Activated = 'ACTIVATED',
  Purchased = 'PURCHASED'
}

export type PurchaseProductUpsellFormAdd = {
  bundlingId?: Maybe<Scalars['String']>;
  isOneTimePerUser?: Maybe<Scalars['Boolean']>;
  isSelectiveCondition?: Maybe<Scalars['Boolean']>;
  condition?: Maybe<Array<Maybe<PurchaseProductUpsellConditionEnum>>>;
  reportTypes?: Maybe<Array<Maybe<Report_Type>>>;
  products?: Maybe<Array<Maybe<PurchaseProductUpsellProductFormAdd>>>;
};

export type PurchaseProductUpsellFormEdit = {
  bundlingId?: Maybe<Scalars['String']>;
  isOneTimePerUser?: Maybe<Scalars['Boolean']>;
  isSelectiveCondition?: Maybe<Scalars['Boolean']>;
  condition?: Maybe<Array<Maybe<PurchaseProductUpsellConditionEnum>>>;
  reportTypes?: Maybe<Array<Maybe<Report_Type>>>;
  products?: Maybe<Array<Maybe<PurchaseProductUpsellProductFormEdit>>>;
  totalPrice?: Maybe<Scalars['Int']>;
  promoPrice?: Maybe<Scalars['Int']>;
  totalDiscount?: Maybe<Scalars['Float']>;
};

export type PurchaseProductUpsellMutations = {
  __typename?: 'PurchaseProductUpsellMutations';
  delete?: Maybe<Scalars['Boolean']>;
  UpsellProduct?: Maybe<PurchaseProductUpsellProductMutations>;
};

export type PurchaseProductUpsellMutationsDeleteArgs = {
  bundlingId: Scalars['String'];
};

export type PurchaseProductUpsellProduct = {
  __typename?: 'PurchaseProductUpsellProduct';
  id?: Maybe<Scalars['String']>;
  bundlingId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<PurchaseProduct>>>;
  promoPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
};

export type PurchaseProductUpsellProductFormAdd = {
  productId: Scalars['String'];
  promoPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
};

export type PurchaseProductUpsellProductFormEdit = {
  bundlingId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  promoPrice?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
};

export type PurchaseProductUpsellProductMutations = {
  __typename?: 'PurchaseProductUpsellProductMutations';
  add?: Maybe<PurchaseProductUpsellProduct>;
  edit?: Maybe<PurchaseProductUpsellProduct>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type PurchaseProductUpsellProductMutationsAddArgs = {
  form?: Maybe<PurchaseProductUpsellProductFormAdd>;
};

export type PurchaseProductUpsellProductMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<PurchaseProductUpsellProductFormEdit>;
};

export type PurchaseProductUpsellProductMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type PurchaseProductUpsellProductQueries = {
  __typename?: 'PurchaseProductUpsellProductQueries';
  findAll?: Maybe<PurchaseProductUpsellProductQueryResult>;
  findOne?: Maybe<PurchaseProductUpsellProduct>;
};

export type PurchaseProductUpsellProductQueriesFindAllArgs = {
  where?: Maybe<PurchaseProductUpsellProductQueryFilter>;
  sort?: Maybe<PurchaseProductUpsellProductQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PurchaseProductUpsellProductQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type PurchaseProductUpsellProductQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  bundlingId?: Maybe<Scalars['String']>;
  productId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PurchaseProductUpsellProductQueryResult = {
  __typename?: 'PurchaseProductUpsellProductQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProductUpsellProduct>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductUpsellProductQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type PurchaseProductUpsellQueries = {
  __typename?: 'PurchaseProductUpsellQueries';
  UpsellProduct?: Maybe<PurchaseProductUpsellProductQueries>;
};

export type PurchaseProductUpsellQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  bundlingId?: Maybe<Scalars['String']>;
};

export type PurchaseProductUpsellQueryResult = {
  __typename?: 'PurchaseProductUpsellQueryResult';
  results?: Maybe<Array<Maybe<PurchaseProductUpsell>>>;
  count?: Maybe<Scalars['Int']>;
};

export type PurchaseProductUpsellQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export enum PurchaseProductUpsellTag {
  OneTimeOffer = 'ONE_TIME_OFFER',
  LimitedOffer = 'LIMITED_OFFER'
}

export type PurchaseProductVariant = {
  __typename?: 'PurchaseProductVariant';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PurchaseQueries = {
  __typename?: 'PurchaseQueries';
  id?: Maybe<Scalars['ID']>;
  Cart?: Maybe<PurchaseCartQueries>;
  Order?: Maybe<PurchaseOrderQueries>;
  Product?: Maybe<PurchaseProductQueries>;
};

/** root query */
export type Query = {
  __typename?: 'Query';
  /** admin module's queries */
  Admin?: Maybe<AdminQueries>;
  /** country module's queries */
  Country?: Maybe<CountryQueries>;
  /** dna module's queries */
  DNA?: Maybe<DnaQueries>;
  /** health module's queries */
  Health?: Maybe<HealthQueries>;
  /** news module's queries */
  News?: Maybe<NewsQueries>;
  /** asa lab modules's queries */
  Lab?: Maybe<LabQueries>;
  /** mcu module's queries */
  MCU?: Maybe<McuQueries>;
  /** media module's queries */
  Media?: Maybe<MediaQueries>;
  /** partner module's queries */
  Partner?: Maybe<PartnerQueries>;
  /** payment module's queries */
  Payment?: Maybe<PaymentQueries>;
  /** prodigy module's queries */
  Prodigy?: Maybe<ProdigyQueries>;
  /** promo module's queries */
  Promo?: Maybe<PromoQueries>;
  /** purchase module's queries */
  Purchase?: Maybe<PurchaseQueries>;
  /** sales module's queries */
  Sales?: Maybe<SalesQueries>;
  /** setting module's queries */
  Setting?: Maybe<SettingQueries>;
  /** splash module's queries */
  Splash?: Maybe<SplashQueries>;
  /** statistics module's queries */
  Statistics?: Maybe<StatisticsQueries>;
  /** tracker module's queries */
  Tracker?: Maybe<TrackerQueries>;
  /** user module's queries */
  User?: Maybe<UserQueries>;
  /** voucher module's queries */
  Voucher?: Maybe<VoucherQueries>;
  /** anteraja module's queries */
  AnterAja?: Maybe<AnterAjaQueries>;
  /** event module's queries */
  Event?: Maybe<EventQueries>;
  /** merchant module's queries */
  Merchant?: Maybe<MerchantQueries>;
  /** report module's queries */
  Report?: Maybe<ReportQueries>;
  /** bioinformatic module's queries */
  Bioinformatic?: Maybe<BioinformaticQueries>;
  /** Electronic-Health-Record modules's queries */
  EHR?: Maybe<EhrQueries>;
  /** PgX module's mutation */
  PgX?: Maybe<PgXQueries>;
  /** Healt Score module's mutation */
  HealthScore?: Maybe<HealthScoreQueries>;
};

export enum Relationship {
  Parent = 'PARENT',
  Guardian = 'GUARDIAN'
}

export enum Report_Type {
  DnaPremium = 'DNA_PREMIUM',
  Dna_360 = 'DNA_360',
  Ancestry = 'ANCESTRY',
  Mcu = 'MCU',
  Prodigy = 'PRODIGY',
  Nutrigenetic = 'NUTRIGENETIC'
}

export type ReportAncestryAncient = {
  __typename?: 'ReportAncestryAncient';
  id?: Maybe<Scalars['String']>;
  charts?: Maybe<Array<Maybe<ReportAncestryAncientChart>>>;
  maps?: Maybe<Array<Maybe<ReportAncestryAncientMap>>>;
};

export type ReportAncestryAncientChartsArgs = {
  options?: Maybe<ReportAncestryAncientChartQueryOptions>;
};

export type ReportAncestryAncientChart = {
  __typename?: 'ReportAncestryAncientChart';
  id?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  estimates?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  region_pop?: Maybe<Scalars['String']>;
};

export type ReportAncestryAncientChartFindOneQueryFilter = {
  id?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
};

export type ReportAncestryAncientChartQueries = {
  __typename?: 'ReportAncestryAncientChartQueries';
  findOne?: Maybe<ReportAncestryAncientChart>;
  find?: Maybe<ReportAncestryAncientChart>;
};

export type ReportAncestryAncientChartQueriesFindOneArgs = {
  id: Scalars['String'];
  options?: Maybe<ReportAncestryAncientChartQueryOptions>;
};

export type ReportAncestryAncientChartQueriesFindArgs = {
  where?: Maybe<ReportAncestryAncientChartFindOneQueryFilter>;
  options?: Maybe<ReportAncestryAncientChartQueryOptions>;
};

export type ReportAncestryAncientChartQueryOptions = {
  showAverage?: Maybe<Scalars['Boolean']>;
};

export type ReportAncestryAncientMap = {
  __typename?: 'ReportAncestryAncientMap';
  id?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  estimates?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  ancestry_ancient_id?: Maybe<Scalars['String']>;
};

export type ReportAncestryAncientQueries = {
  __typename?: 'ReportAncestryAncientQueries';
  findOne?: Maybe<ReportAncestryAncient>;
  Chart?: Maybe<ReportAncestryAncientChartQueries>;
};

export type ReportAncestryCheckStatusQueryFilter = {
  /** only for counselor that need report for client */
  clientProfileId?: Maybe<Scalars['String']>;
};

export type ReportAncestryLineage = {
  __typename?: 'ReportAncestryLineage';
  id?: Maybe<Scalars['String']>;
  mtdna?: Maybe<Array<Maybe<ReportAncestryLineageDna>>>;
  ydna?: Maybe<Array<Maybe<ReportAncestryLineageDna>>>;
  ydna_belong_to?: Maybe<ReportAncestryLineageDnaBelongTo>;
  mtdna_belong_to?: Maybe<ReportAncestryLineageDnaBelongTo>;
  user_id?: Maybe<Scalars['String']>;
};

export type ReportAncestryLineageDna = {
  __typename?: 'ReportAncestryLineageDNA';
  from_lat?: Maybe<Scalars['String']>;
  from_long?: Maybe<Scalars['String']>;
  to_lat?: Maybe<Scalars['String']>;
  to_long?: Maybe<Scalars['String']>;
  haplogroup?: Maybe<Scalars['String']>;
};

export type ReportAncestryLineageDnaBelongTo = {
  __typename?: 'ReportAncestryLineageDNABelongTo';
  haplogroup?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ReportAncestryLineageQueries = {
  __typename?: 'ReportAncestryLineageQueries';
  findOne?: Maybe<ReportAncestryLineage>;
};

export type ReportAncestryModern = {
  __typename?: 'ReportAncestryModern';
  id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  continents?: Maybe<Array<Maybe<ReportAncestryModernContinents>>>;
};

export type ReportAncestryModernContinentsArgs = {
  options?: Maybe<ReportAncestryModernContinentsQueryOptions>;
};

export type ReportAncestryModernContinents = {
  __typename?: 'ReportAncestryModernContinents';
  id?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
  continents?: Maybe<Scalars['String']>;
  continents_code?: Maybe<Scalars['String']>;
  continents_prop?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  is_delete?: Maybe<Scalars['String']>;
  ancestry_modern_id?: Maybe<Scalars['String']>;
  subcontinents?: Maybe<Array<Maybe<ReportAncestryModernSubContinents>>>;
};

export type ReportAncestryModernContinentsQueries = {
  __typename?: 'ReportAncestryModernContinentsQueries';
  findOne?: Maybe<ReportAncestryModernContinents>;
};

export type ReportAncestryModernContinentsQueriesFindOneArgs = {
  id: Scalars['String'];
  options?: Maybe<ReportAncestryModernContinentsQueryOptions>;
};

export type ReportAncestryModernContinentsQueryOptions = {
  showAverage?: Maybe<Scalars['Boolean']>;
};

export type ReportAncestryModernCountries = {
  __typename?: 'ReportAncestryModernCountries';
  id?: Maybe<Scalars['String']>;
  countries?: Maybe<Scalars['String']>;
  countries_code?: Maybe<Scalars['String']>;
  countries_prop?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  is_delete?: Maybe<Scalars['String']>;
};

export type ReportAncestryModernQueries = {
  __typename?: 'ReportAncestryModernQueries';
  findOne?: Maybe<ReportAncestryModern>;
  Continents?: Maybe<ReportAncestryModernContinentsQueries>;
};

export type ReportAncestryModernSubContinents = {
  __typename?: 'ReportAncestryModernSubContinents';
  id?: Maybe<Scalars['String']>;
  sub_continents?: Maybe<Scalars['String']>;
  sub_continents_code?: Maybe<Scalars['String']>;
  sub_continents_prop?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
  is_delete?: Maybe<Scalars['String']>;
  countries?: Maybe<Array<Maybe<ReportAncestryModernCountries>>>;
};

export type ReportAncestryQueries = {
  __typename?: 'ReportAncestryQueries';
  checkStatus?: Maybe<Scalars['Boolean']>;
  Ancient?: Maybe<ReportAncestryAncientQueries>;
  Lineage?: Maybe<ReportAncestryLineageQueries>;
  Modern?: Maybe<ReportAncestryModernQueries>;
};

export type ReportAncestryQueriesCheckStatusArgs = {
  where?: Maybe<ReportAncestryCheckStatusQueryFilter>;
};

export enum ReportMapperSex {
  Male = 'MALE',
  Female = 'FEMALE',
  Both = 'BOTH'
}

export type ReportMutations = {
  __typename?: 'ReportMutations';
  /**
   * processes reports for kitcodes that specified on excel file, the process will
   * executed based on process report type (enum ReportProcessType)
   */
  processReports?: Maybe<Scalars['Boolean']>;
  Prodigy?: Maybe<ReportProdigyMutations>;
  ReportThreeSixty?: Maybe<ReportThreeSixtyMutations>;
};

export type ReportMutationsProcessReportsArgs = {
  form: ReportProcessForm;
};

export type ReportProcessForm = {
  file: Scalars['Upload'];
  type: ReportProcessType;
};

export enum ReportProcessType {
  Map = 'MAP',
  Fetch = 'FETCH',
  Release = 'RELEASE'
}

export type ReportProdigyMutations = {
  __typename?: 'ReportProdigyMutations';
  sendSampleReport?: Maybe<Scalars['Boolean']>;
};

export type ReportProdigyMutationsSendSampleReportArgs = {
  form?: Maybe<ReportProdigySendSampleReportForm>;
};

export type ReportProdigySendSampleReportForm = {
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type ReportQueries = {
  __typename?: 'ReportQueries';
  Ancestry?: Maybe<ReportAncestryQueries>;
  reportStatus?: Maybe<ReportStatus>;
  findAllReportStatus?: Maybe<Array<Maybe<ReportStatusV2>>>;
  findOneLatestChipByReportStatus?: Maybe<ReportStatusV2>;
  ReportThreeSixty?: Maybe<ReportThreeSixtyQueries>;
};

export type ReportQueriesReportStatusArgs = {
  where?: Maybe<ReportStatusQueryFilter>;
};

export type ReportQueriesFindAllReportStatusArgs = {
  where?: Maybe<ReportStatusQueryFilter>;
};

export type ReportStatus = {
  __typename?: 'ReportStatus';
  ANCESTRY?: Maybe<Scalars['Boolean']>;
  DNA_360?: Maybe<Scalars['Boolean']>;
  DNA_PREMIUM?: Maybe<Scalars['Boolean']>;
  PRODIGY?: Maybe<Scalars['Boolean']>;
  NUTRIGENETIC?: Maybe<Scalars['Boolean']>;
};

export type ReportStatusQueryFilter = {
  /** only for counselor that need report for client */
  clientProfileId?: Maybe<Scalars['String']>;
  reportType?: Maybe<Array<Maybe<ReportType>>>;
};

export enum ReportStatusType {
  /** Level status 0 */
  ReadyToUnlockKit = 'READY_TO_UNLOCK_KIT',
  /** Level status 0 */
  ReadyToUnlockReport = 'READY_TO_UNLOCK_REPORT',
  /** Level status 0 */
  Unlocked = 'UNLOCKED',
  /** Level status 1 */
  Activated = 'ACTIVATED',
  /** Level status 2 */
  ReportReleased = 'REPORT_RELEASED'
}

export type ReportStatusV2 = {
  __typename?: 'ReportStatusV2';
  type?: Maybe<ReportType>;
  status?: Maybe<ReportStatusType>;
  chipType?: Maybe<Scalars['String']>;
};

export type ReportThreeSixtyMutations = {
  __typename?: 'ReportThreeSixtyMutations';
  create?: Maybe<Scalars['Boolean']>;
};

export type ReportThreeSixtyQueries = {
  __typename?: 'ReportThreeSixtyQueries';
  findAll?: Maybe<BioinformaticReportQueryResult>;
  findOne?: Maybe<BioinformaticReport>;
  findAllReport?: Maybe<BioinformaticReportQueryResult>;
  findAllHighlightPanel?: Maybe<HighlightQueryResult>;
  findAllInteractome?: Maybe<DnaInteractomeQueryResult>;
  findAllCategory?: Maybe<DnaCategoryWithReportQueryResult>;
  findAllPanel?: Maybe<DnaPanelWithReportQueryResult>;
};

export type ReportThreeSixtyQueriesFindAllArgs = {
  where?: Maybe<BioinformaticReportFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<BioinformaticReportQuerySortBy>;
};

export type ReportThreeSixtyQueriesFindOneArgs = {
  id: Scalars['String'];
  reportType?: Maybe<PurchaseProductType>;
  profile?: Maybe<Scalars['String']>;
};

export type ReportThreeSixtyQueriesFindAllReportArgs = {
  where?: Maybe<BioinformaticReportFilter>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<BioinformaticReportQuerySortBy>;
};

export type ReportThreeSixtyQueriesFindAllHighlightPanelArgs = {
  where?: Maybe<DnaPanelQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportThreeSixtyQueriesFindAllInteractomeArgs = {
  where?: Maybe<DnaInteractomeQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ReportThreeSixtyQueriesFindAllCategoryArgs = {
  where?: Maybe<DnaCategoryWithReportQueryFilter>;
};

export type ReportThreeSixtyQueriesFindAllPanelArgs = {
  where?: Maybe<DnaPanelWithReportQueryFilter>;
};

export enum ReportType {
  DnaPremium = 'DNA_PREMIUM',
  Dna_360 = 'DNA_360',
  Ancestry = 'ANCESTRY',
  Prodigy = 'PRODIGY',
  Pgx = 'PGX',
  Nutrigenetic = 'NUTRIGENETIC'
}

export type ReswabShipmentResult = {
  __typename?: 'ReswabShipmentResult';
  id?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  shipmentCode?: Maybe<Scalars['String']>;
  status?: Maybe<ProductKitPickupStatus>;
  waybill?: Maybe<Scalars['String']>;
};

export type RiskTrait = {
  __typename?: 'RiskTrait';
  id?: Maybe<Scalars['String']>;
  dnaInteractomeId?: Maybe<Scalars['String']>;
  dnaTraitId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  dInteractome?: Maybe<DnaInteractome>;
  dTrait?: Maybe<DnaTrait>;
  /** 360 */
  bioinformaticReportId?: Maybe<Scalars['String']>;
  bReport?: Maybe<BioinformaticReport>;
};

export type Sales = {
  __typename?: 'Sales';
  id?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['Int']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  itemCode?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  priceBox?: Maybe<Scalars['Float']>;
  priceActivation?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  dpp?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  transactionIn?: Maybe<Scalars['Float']>;
  transactionDateIn?: Maybe<Scalars['Date']>;
  transactionType?: Maybe<SalesTransactionType>;
  vendorName?: Maybe<Scalars['String']>;
  paymentChannel?: Maybe<SalesPaymentChannel>;
  ar?: Maybe<Scalars['Float']>;
};

export type SalesForm = {
  no?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  itemCode?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  priceBox?: Maybe<Scalars['Float']>;
  priceActivation?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  dpp?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
  transactionIn?: Maybe<Scalars['Float']>;
  transactionDateIn?: Maybe<Scalars['Date']>;
  transactionType?: Maybe<SalesTransactionType>;
  vendorName?: Maybe<Scalars['String']>;
  paymentChannel?: Maybe<SalesPaymentChannel>;
  ar?: Maybe<Scalars['Float']>;
};

export type SalesMutations = {
  __typename?: 'SalesMutations';
  edit?: Maybe<Sales>;
  exportExcel?: Maybe<Scalars['String']>;
  importExcel?: Maybe<Scalars['Boolean']>;
};

export type SalesMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<SalesForm>;
};

export type SalesMutationsExportExcelArgs = {
  where?: Maybe<SalesQueryFilter>;
  sort?: Maybe<SalesQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type SalesMutationsImportExcelArgs = {
  file: Scalars['Upload'];
};

export enum SalesPaymentChannel {
  Apps = 'APPS',
  Manual = 'MANUAL'
}

export type SalesQueries = {
  __typename?: 'SalesQueries';
  id?: Maybe<Scalars['ID']>;
  findAll?: Maybe<SalesQueryResult>;
  findAllProduct?: Maybe<Array<Maybe<Scalars['String']>>>;
  findAllVendor?: Maybe<Array<Maybe<Scalars['String']>>>;
  findOne?: Maybe<Sales>;
  totalBox?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
};

export type SalesQueriesFindAllArgs = {
  where?: Maybe<SalesQueryFilter>;
  sort?: Maybe<SalesQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type SalesQueriesFindAllProductArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type SalesQueriesFindAllVendorArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type SalesQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type SalesQueriesTotalBoxArgs = {
  where?: Maybe<SalesQueryFilter>;
};

export type SalesQueriesTotalRevenueArgs = {
  where?: Maybe<SalesQueryFilter>;
};

export type SalesQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  transactionDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  quantity?: Maybe<Scalars['Int']>;
  minTotal?: Maybe<Scalars['Float']>;
  maxTotal?: Maybe<Scalars['Float']>;
  transactionTypes?: Maybe<Array<Maybe<SalesTransactionType>>>;
  vendorNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentChannels?: Maybe<Array<Maybe<SalesPaymentChannel>>>;
};

export type SalesQueryResult = {
  __typename?: 'SalesQueryResult';
  results?: Maybe<Array<Maybe<Sales>>>;
  count?: Maybe<Scalars['Int']>;
};

export type SalesQuerySortBy = {
  id?: Maybe<SortDirection>;
  no?: Maybe<SortDirection>;
  transactionDate?: Maybe<SortDirection>;
  quantity?: Maybe<SortDirection>;
  total?: Maybe<SortDirection>;
  ar?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum SalesTransactionType {
  B2B = 'B2B',
  D2C = 'D2C'
}

export type SendOtpToChangeEmailResult = {
  __typename?: 'SendOTPToChangeEmailResult';
  success?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<SendOtpToChangeEmailResultError>>>;
};

export type SendOtpToChangeEmailResultError = {
  __typename?: 'SendOTPToChangeEmailResultError';
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ServiceRateResult = {
  __typename?: 'ServiceRateResult';
  results?: Maybe<Array<Maybe<AnterAjaServicesRate>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Setting = {
  __typename?: 'Setting';
  id?: Maybe<Scalars['Int']>;
  payload?: Maybe<SettingPayload>;
};

export type SettingMutations = {
  __typename?: 'SettingMutations';
  edit?: Maybe<Setting>;
};

export type SettingMutationsEditArgs = {
  id: Scalars['Int'];
  payload?: Maybe<SettingPayloadForm>;
};

export type SettingPayload = {
  __typename?: 'SettingPayload';
  appointment?: Maybe<SettingPayloadAppointment>;
  hotline?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  support?: Maybe<SettingPayloadSupport>;
  app?: Maybe<SettingPayloadApp>;
  invoice?: Maybe<SettingPayloadInvoice>;
  consultationIncome?: Maybe<ConsultationIncome>;
};

export type SettingPayloadApp = {
  __typename?: 'SettingPayloadApp';
  latestVersion?: Maybe<Scalars['String']>;
};

export type SettingPayloadAppointment = {
  __typename?: 'SettingPayloadAppointment';
  phone?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  notificationEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SettingPayloadAppointmentForm = {
  phone?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  notificationEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SettingPayloadForm = {
  appointment?: Maybe<SettingPayloadAppointmentForm>;
  hotline?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  support?: Maybe<SettingPayloadSupportForm>;
  invoice?: Maybe<SettingPayloadInvoiceForm>;
  consultationIncome?: Maybe<ConsultationIncomeForm>;
};

export type SettingPayloadInvoice = {
  __typename?: 'SettingPayloadInvoice';
  format?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type SettingPayloadInvoiceForm = {
  format?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type SettingPayloadSupport = {
  __typename?: 'SettingPayloadSupport';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SettingPayloadSupportForm = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SettingQueries = {
  __typename?: 'SettingQueries';
  findOne?: Maybe<Setting>;
};

/** sort direction */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortKit = {
  code?: Maybe<SortDirection>;
  activatedAt?: Maybe<SortDirection>;
  activatedUser?: Maybe<SortDirection>;
};

export type Splash = {
  __typename?: 'Splash';
  id?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type SplashQueries = {
  __typename?: 'SplashQueries';
  findAll?: Maybe<SplashQueryResult>;
  findOne?: Maybe<Splash>;
};

export type SplashQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type SplashQueryResult = {
  __typename?: 'SplashQueryResult';
  results?: Maybe<Array<Maybe<Splash>>>;
  count?: Maybe<Scalars['Int']>;
};

/** statistics module's queries */
export type StatisticsQueries = {
  __typename?: 'StatisticsQueries';
  /** total order */
  totalOrder?: Maybe<Scalars['Int']>;
  totalKitActivation?: Maybe<Scalars['Int']>;
  totalOngoingReports?: Maybe<Scalars['Int']>;
  totalUsers?: Maybe<Scalars['Int']>;
  totalReportsReleased?: Maybe<Scalars['Int']>;
};

/** statistics module's queries */
export type StatisticsQueriesTotalOrderArgs = {
  where?: Maybe<TotalOrderFilter>;
};

/** statistics module's queries */
export type StatisticsQueriesTotalKitActivationArgs = {
  where?: Maybe<TotalKitActivation>;
};

/** statistics module's queries */
export type StatisticsQueriesTotalOngoingReportsArgs = {
  where?: Maybe<TotalOngoingReportsFilter>;
};

/** statistics module's queries */
export type StatisticsQueriesTotalUsersArgs = {
  where?: Maybe<TotalUsersFilter>;
};

/** statistics module's queries */
export type StatisticsQueriesTotalReportsReleasedArgs = {
  where?: Maybe<TotalReportsReleasedFilter>;
};

export type StatusCategoryAndTrait = {
  category?: Maybe<ProdigyCategoryStatus>;
  trait?: Maybe<ProdigyTraitStatus>;
};

export type SubmitCareerForm = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  reCaptchaToken?: Maybe<Scalars['String']>;
  job?: Maybe<CareerJobForm>;
};

export type Subscription = {
  __typename?: 'Subscription';
  PurchaseCartUpdated?: Maybe<PurchaseCart>;
  UserCounselorBookCreated?: Maybe<UserCounselorBook>;
  UserCounselorBookUpdated?: Maybe<UserCounselorBook>;
  UserCounselorBookDeleted?: Maybe<UserCounselorBook>;
  UserCounselorChatCreated?: Maybe<UserCounselorChat>;
  UserCounselorChatUpdated?: Maybe<UserCounselorChat>;
  UserCounselorQueueCreated?: Maybe<UserCounselorQueue>;
  UserCounselorQueueUpdatedAll?: Maybe<UserCounselorQueue>;
  UserCounselorQueueUpdated?: Maybe<UserCounselorQueue>;
  UserCounselorStatusCreated?: Maybe<UserCounselorStatus>;
  UserCounselorStatusUpdated?: Maybe<UserCounselorStatus>;
  UserNotificationCreated?: Maybe<UserNotification>;
  UserNotificationUpdated?: Maybe<UserNotification>;
  DNAReportCreated?: Maybe<UserNotification>;
  PurchaseProductPickUpRequested?: Maybe<PurchaseProductKit>;
  PurchaseProductPickupHistoryUpdated?: Maybe<ProductKitPickupHistory>;
  DNAReportImportTranslationSucceed?: Maybe<Scalars['Boolean']>;
  DNAReportImportTranslationUploaded?: Maybe<Scalars['Boolean']>;
  DNAGeneImportTranslationSucceed?: Maybe<Scalars['Boolean']>;
  DNAGeneImportTranslationUploaded?: Maybe<Scalars['Boolean']>;
  PurchaseProductKitExportFile?: Maybe<UserNotification>;
  CounselorNotificationCreated?: Maybe<CounselorNotification>;
  PgXGlossaryExportFile?: Maybe<UserNotification>;
  PgXDrugExportFile?: Maybe<UserNotification>;
  PgXDrugClassExportFile?: Maybe<UserNotification>;
};

export type SubscriptionUserCounselorChatCreatedArgs = {
  queueId: Scalars['Int'];
};

export type SubscriptionUserCounselorChatUpdatedArgs = {
  queueId?: Maybe<Scalars['Int']>;
};

export type SubscriptionUserCounselorQueueUpdatedArgs = {
  id: Scalars['Int'];
};

export type SubscriptionUserCounselorStatusUpdatedArgs = {
  id: Scalars['Int'];
};

export type SubscriptionUserNotificationUpdatedArgs = {
  id: Scalars['Int'];
};

export type SwitchUserProfileForm = {
  profileId: Scalars['String'];
};

export type TotalKitActivation = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type TotalOngoingReportsFilter = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type TotalOrderFilter = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type TotalReportsReleasedFilter = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type TotalUsersFilter = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type Tracker = {
  __typename?: 'Tracker';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerBloodPressure = {
  __typename?: 'TrackerBloodPressure';
  id?: Maybe<Scalars['ID']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<TrackerBloodPressureValue>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerBloodPressureForm = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerBloodPressureMutations = {
  __typename?: 'TrackerBloodPressureMutations';
  create?: Maybe<TrackerBloodPressure>;
};

export type TrackerBloodPressureMutationsCreateArgs = {
  form?: Maybe<TrackerBloodPressureForm>;
};

export type TrackerBloodPressureQueries = {
  __typename?: 'TrackerBloodPressureQueries';
  findAll?: Maybe<TrackerBloodPressureQueryResult>;
};

export type TrackerBloodPressureQueriesFindAllArgs = {
  sort?: Maybe<TrackerBloodPressureQuerySorter>;
  where?: Maybe<TrackerBloodPressureQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TrackerBloodPressureQueryFilter = {
  sources?: Maybe<Array<Maybe<TrackerSource>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerBloodPressureQueryResult = {
  __typename?: 'TrackerBloodPressureQueryResult';
  results?: Maybe<Array<Maybe<TrackerBloodPressure>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TrackerBloodPressureQuerySorter = {
  /** value: SortDirection */
  source?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  startDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TrackerBloodPressureValue = {
  __typename?: 'TrackerBloodPressureValue';
  systolic?: Maybe<Scalars['Float']>;
  diastolic?: Maybe<Scalars['Float']>;
};

export type TrackerCalories = {
  __typename?: 'TrackerCalories';
  id?: Maybe<Scalars['ID']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerCaloriesForm = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerCaloriesMutations = {
  __typename?: 'TrackerCaloriesMutations';
  Setting?: Maybe<TrackerCaloriesSettingMutations>;
  create?: Maybe<TrackerCalories>;
};

export type TrackerCaloriesMutationsCreateArgs = {
  form?: Maybe<TrackerCaloriesForm>;
};

export type TrackerCaloriesQueries = {
  __typename?: 'TrackerCaloriesQueries';
  Setting?: Maybe<TrackerCaloriesSettingQueries>;
  findAll?: Maybe<TrackerCaloriesQueryResult>;
};

export type TrackerCaloriesQueriesFindAllArgs = {
  sort?: Maybe<TrackerCaloriesQuerySorter>;
  where?: Maybe<TrackerCaloriesQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TrackerCaloriesQueryFilter = {
  sources?: Maybe<Array<Maybe<TrackerSource>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerCaloriesQueryResult = {
  __typename?: 'TrackerCaloriesQueryResult';
  results?: Maybe<Array<Maybe<TrackerCalories>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TrackerCaloriesQuerySorter = {
  value?: Maybe<SortDirection>;
  source?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  startDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TrackerCaloriesSetting = {
  __typename?: 'TrackerCaloriesSetting';
  id?: Maybe<Scalars['ID']>;
  goal?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerCaloriesSettingForm = {
  goal?: Maybe<Scalars['Float']>;
};

export type TrackerCaloriesSettingMutations = {
  __typename?: 'TrackerCaloriesSettingMutations';
  create?: Maybe<TrackerCaloriesSetting>;
};

export type TrackerCaloriesSettingMutationsCreateArgs = {
  form?: Maybe<TrackerCaloriesSettingForm>;
};

export type TrackerCaloriesSettingQueries = {
  __typename?: 'TrackerCaloriesSettingQueries';
  find?: Maybe<TrackerCaloriesSetting>;
};

export type TrackerDistance = {
  __typename?: 'TrackerDistance';
  id?: Maybe<Scalars['ID']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerDistanceForm = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerDistanceMutations = {
  __typename?: 'TrackerDistanceMutations';
  Setting?: Maybe<TrackerDistanceSettingMutations>;
  create?: Maybe<TrackerDistance>;
};

export type TrackerDistanceMutationsCreateArgs = {
  form?: Maybe<TrackerDistanceForm>;
};

export type TrackerDistanceQueries = {
  __typename?: 'TrackerDistanceQueries';
  Setting?: Maybe<TrackerDistanceSettingQueries>;
  findAll?: Maybe<TrackerDistanceQueryResult>;
};

export type TrackerDistanceQueriesFindAllArgs = {
  sort?: Maybe<TrackerDistanceQuerySorter>;
  where?: Maybe<TrackerDistanceQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TrackerDistanceQueryFilter = {
  sources?: Maybe<Array<Maybe<TrackerSource>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerDistanceQueryResult = {
  __typename?: 'TrackerDistanceQueryResult';
  results?: Maybe<Array<Maybe<TrackerDistance>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TrackerDistanceQuerySorter = {
  value?: Maybe<SortDirection>;
  source?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  startDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TrackerDistanceSetting = {
  __typename?: 'TrackerDistanceSetting';
  id?: Maybe<Scalars['ID']>;
  goal?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerDistanceSettingForm = {
  goal?: Maybe<Scalars['Float']>;
};

export type TrackerDistanceSettingMutations = {
  __typename?: 'TrackerDistanceSettingMutations';
  create?: Maybe<TrackerDistanceSetting>;
};

export type TrackerDistanceSettingMutationsCreateArgs = {
  form?: Maybe<TrackerDistanceSettingForm>;
};

export type TrackerDistanceSettingQueries = {
  __typename?: 'TrackerDistanceSettingQueries';
  find?: Maybe<TrackerDistanceSetting>;
};

export type TrackerForm = {
  types?: Maybe<Array<Maybe<TrackerType>>>;
};

export type TrackerHeartRate = {
  __typename?: 'TrackerHeartRate';
  id?: Maybe<Scalars['ID']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerHeartRateForm = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerHeartRateMutations = {
  __typename?: 'TrackerHeartRateMutations';
  create?: Maybe<TrackerHeartRate>;
};

export type TrackerHeartRateMutationsCreateArgs = {
  form?: Maybe<TrackerHeartRateForm>;
};

export type TrackerHeartRateQueries = {
  __typename?: 'TrackerHeartRateQueries';
  findAll?: Maybe<TrackerHeartRateQueryResult>;
};

export type TrackerHeartRateQueriesFindAllArgs = {
  sort?: Maybe<TrackerHeartRateQuerySorter>;
  where?: Maybe<TrackerHeartRateQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TrackerHeartRateQueryFilter = {
  sources?: Maybe<Array<Maybe<TrackerSource>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerHeartRateQueryResult = {
  __typename?: 'TrackerHeartRateQueryResult';
  results?: Maybe<Array<Maybe<TrackerHeartRate>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TrackerHeartRateQuerySorter = {
  value?: Maybe<SortDirection>;
  source?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  startDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TrackerMutations = {
  __typename?: 'TrackerMutations';
  BloodPressure?: Maybe<TrackerBloodPressureMutations>;
  Calories?: Maybe<TrackerCaloriesMutations>;
  Distance?: Maybe<TrackerDistanceMutations>;
  HeartRate?: Maybe<TrackerHeartRateMutations>;
  OxygenSaturation?: Maybe<TrackerOxygenSaturationMutations>;
  Setting?: Maybe<TrackerSettingMutations>;
  Sleep?: Maybe<TrackerSleepMutations>;
  Steps?: Maybe<TrackerStepsMutations>;
  create?: Maybe<Tracker>;
  sync?: Maybe<Scalars['Boolean']>;
};

export type TrackerMutationsCreateArgs = {
  form?: Maybe<TrackerForm>;
};

export type TrackerMutationsSyncArgs = {
  form?: Maybe<Array<Maybe<TrackerSyncForm>>>;
};

export type TrackerOxygenSaturation = {
  __typename?: 'TrackerOxygenSaturation';
  id?: Maybe<Scalars['ID']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerOxygenSaturationForm = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerOxygenSaturationMutations = {
  __typename?: 'TrackerOxygenSaturationMutations';
  create?: Maybe<TrackerOxygenSaturation>;
};

export type TrackerOxygenSaturationMutationsCreateArgs = {
  form?: Maybe<TrackerOxygenSaturationForm>;
};

export type TrackerOxygenSaturationQueries = {
  __typename?: 'TrackerOxygenSaturationQueries';
  findAll?: Maybe<TrackerOxygenSaturationQueryResult>;
};

export type TrackerOxygenSaturationQueriesFindAllArgs = {
  sort?: Maybe<TrackerOxygenSaturationQuerySorter>;
  where?: Maybe<TrackerOxygenSaturationQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TrackerOxygenSaturationQueryFilter = {
  sources?: Maybe<Array<Maybe<TrackerSource>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerOxygenSaturationQueryResult = {
  __typename?: 'TrackerOxygenSaturationQueryResult';
  results?: Maybe<Array<Maybe<TrackerOxygenSaturation>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TrackerOxygenSaturationQuerySorter = {
  value?: Maybe<SortDirection>;
  source?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  startDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TrackerQueries = {
  __typename?: 'TrackerQueries';
  BloodPressure?: Maybe<TrackerBloodPressureQueries>;
  Calories?: Maybe<TrackerCaloriesQueries>;
  Distance?: Maybe<TrackerDistanceQueries>;
  HeartRate?: Maybe<TrackerHeartRateQueries>;
  OxygenSaturation?: Maybe<TrackerOxygenSaturationQueries>;
  Setting?: Maybe<TrackerSettingQueries>;
  Sleep?: Maybe<TrackerSleepQueries>;
  Steps?: Maybe<TrackerStepsQueries>;
  find?: Maybe<Tracker>;
};

export type TrackerSetting = {
  __typename?: 'TrackerSetting';
  id?: Maybe<Scalars['ID']>;
  types?: Maybe<Array<Maybe<TrackerType>>>;
  source?: Maybe<TrackerSource>;
  enable?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  connectedAt?: Maybe<Scalars['Date']>;
  disconnectedAt?: Maybe<Scalars['Date']>;
};

export type TrackerSettingForm = {
  types?: Maybe<Array<Maybe<TrackerType>>>;
  source?: Maybe<TrackerSource>;
  enable?: Maybe<Scalars['Boolean']>;
};

export type TrackerSettingMutations = {
  __typename?: 'TrackerSettingMutations';
  create?: Maybe<TrackerSetting>;
};

export type TrackerSettingMutationsCreateArgs = {
  form?: Maybe<TrackerSettingForm>;
};

export type TrackerSettingQueries = {
  __typename?: 'TrackerSettingQueries';
  find?: Maybe<TrackerSetting>;
  findAll?: Maybe<Array<Maybe<TrackerSetting>>>;
};

export type TrackerSettingQueriesFindArgs = {
  source?: Maybe<TrackerSource>;
};

export type TrackerSleep = {
  __typename?: 'TrackerSleep';
  id?: Maybe<Scalars['ID']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerSleepForm = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerSleepMutations = {
  __typename?: 'TrackerSleepMutations';
  Setting?: Maybe<TrackerSleepSettingMutations>;
  create?: Maybe<TrackerSleep>;
};

export type TrackerSleepMutationsCreateArgs = {
  form?: Maybe<TrackerSleepForm>;
};

export type TrackerSleepQueries = {
  __typename?: 'TrackerSleepQueries';
  Setting?: Maybe<TrackerSleepSettingQueries>;
  findAll?: Maybe<TrackerSleepQueryResult>;
};

export type TrackerSleepQueriesFindAllArgs = {
  sort?: Maybe<TrackerSleepQuerySorter>;
  where?: Maybe<TrackerSleepQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TrackerSleepQueryFilter = {
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  sources?: Maybe<Array<Maybe<TrackerSource>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerSleepQueryResult = {
  __typename?: 'TrackerSleepQueryResult';
  results?: Maybe<Array<Maybe<TrackerSleep>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TrackerSleepQuerySorter = {
  value?: Maybe<SortDirection>;
  source?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  startDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TrackerSleepSetting = {
  __typename?: 'TrackerSleepSetting';
  id?: Maybe<Scalars['ID']>;
  goal?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerSleepSettingForm = {
  goal?: Maybe<Scalars['Float']>;
};

export type TrackerSleepSettingMutations = {
  __typename?: 'TrackerSleepSettingMutations';
  create?: Maybe<TrackerSleepSetting>;
};

export type TrackerSleepSettingMutationsCreateArgs = {
  form?: Maybe<TrackerSleepSettingForm>;
};

export type TrackerSleepSettingQueries = {
  __typename?: 'TrackerSleepSettingQueries';
  find?: Maybe<TrackerSleepSetting>;
};

export enum TrackerSource {
  Manual = 'MANUAL',
  GoogleFit = 'GOOGLE_FIT',
  HealthKit = 'HEALTH_KIT'
}

export type TrackerSteps = {
  __typename?: 'TrackerSteps';
  id?: Maybe<Scalars['ID']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerStepsForm = {
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  source?: Maybe<TrackerSource>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerStepsMutations = {
  __typename?: 'TrackerStepsMutations';
  Setting?: Maybe<TrackerStepsSettingMutations>;
  create?: Maybe<TrackerSteps>;
};

export type TrackerStepsMutationsCreateArgs = {
  form?: Maybe<TrackerStepsForm>;
};

export type TrackerStepsQueries = {
  __typename?: 'TrackerStepsQueries';
  Setting?: Maybe<TrackerStepsSettingQueries>;
  findAll?: Maybe<TrackerStepsQueryResult>;
};

export type TrackerStepsQueriesFindAllArgs = {
  sort?: Maybe<TrackerStepsQuerySorter>;
  where?: Maybe<TrackerStepsQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type TrackerStepsQueryFilter = {
  sources?: Maybe<Array<Maybe<TrackerSource>>>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type TrackerStepsQueryResult = {
  __typename?: 'TrackerStepsQueryResult';
  results?: Maybe<Array<Maybe<TrackerSteps>>>;
  count?: Maybe<Scalars['Int']>;
};

export type TrackerStepsQuerySorter = {
  value?: Maybe<SortDirection>;
  source?: Maybe<SortDirection>;
  endDate?: Maybe<SortDirection>;
  startDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type TrackerStepsSetting = {
  __typename?: 'TrackerStepsSetting';
  id?: Maybe<Scalars['ID']>;
  goal?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type TrackerStepsSettingForm = {
  goal?: Maybe<Scalars['Float']>;
};

export type TrackerStepsSettingMutations = {
  __typename?: 'TrackerStepsSettingMutations';
  create?: Maybe<TrackerStepsSetting>;
};

export type TrackerStepsSettingMutationsCreateArgs = {
  form?: Maybe<TrackerStepsSettingForm>;
};

export type TrackerStepsSettingQueries = {
  __typename?: 'TrackerStepsSettingQueries';
  find?: Maybe<TrackerStepsSetting>;
};

export type TrackerSyncForm = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<TrackerType>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  sourceName?: Maybe<Scalars['String']>;
  sourceBundleId?: Maybe<Scalars['String']>;
};

export enum TrackerType {
  Sleep = 'SLEEP',
  Steps = 'STEPS',
  Calories = 'CALORIES',
  Distance = 'DISTANCE',
  HeartRate = 'HEART_RATE',
  BloodPressure = 'BLOOD_PRESSURE',
  OxygenSaturation = 'OXYGEN_SATURATION'
}

export type TrackHistoryQueryFilter = {
  /** union DNAReportHistoryStatus | LabSampleStatus */
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TrackHistoryQueryResult = {
  __typename?: 'TrackHistoryQueryResult';
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  releasedAt?: Maybe<Scalars['Date']>;
};

export type TrackHistoryQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type TrackingResult = {
  __typename?: 'TrackingResult';
  results?: Maybe<Array<Maybe<AnterAjaTracking>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Trait = {
  __typename?: 'Trait';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  scoreType?: Maybe<DnaScoreType>;
  status?: Maybe<Scalars['String']>;
  translation?: Maybe<Array<Maybe<DnaTraitTranslation>>>;
  traitScore?: Maybe<Array<Maybe<DnaTraitScore>>>;
  dataStatus?: Maybe<Scalars['String']>;
};

export type TraitForm = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  translation?: Maybe<DnaTraitTranslationForm>;
  traitScore?: Maybe<Array<Maybe<DnaTraitScoreForm>>>;
};

export type TraitQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  keyword?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dnaScoreTypeId?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  translationLang?: Maybe<Array<Maybe<Scalars['String']>>>;
  scoreTranslationLang?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TraitQueryResult = {
  __typename?: 'TraitQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<Trait>>>;
};

export type TraitSortBy = {
  name?: Maybe<SortDirection>;
  scoreType?: Maybe<SortDirection>;
};

export enum User_Counselor_Book_Status {
  Pending = 'PENDING',
  Paid = 'PAID'
}

export enum UserAge {
  Adult = 'ADULT',
  Children = 'CHILDREN',
  Preschool = 'PRESCHOOL',
  Toddler = 'TODDLER',
  Infant = 'INFANT',
  Infant_0_3Months = 'INFANT_0_3_MONTHS',
  Infant_4_6Months = 'INFANT_4_6_MONTHS',
  Infant_7_9Months = 'INFANT_7_9_MONTHS',
  Infant_10_12Months = 'INFANT_10_12_MONTHS'
}

export type UserCounselor = {
  __typename?: 'UserCounselor';
  id?: Maybe<Scalars['Int']>;
  times?: Maybe<UserCounselorTimes>;
  setting?: Maybe<UserCounselorSetting>;
  status?: Maybe<UserCounselorStatus>;
  queues?: Maybe<UserCounselorQueueQueryResult>;
  address?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  schedules?: Maybe<Array<Maybe<UserCounselorSchedule>>>;
  educations?: Maybe<UserCounselorEducationQueryResult>;
  expertises?: Maybe<UserCounselorExpertiseQueryResult>;
  profession?: Maybe<UserCounselorProfession>;
  performance?: Maybe<UserCounselorPerformance>;
  rating?: Maybe<Scalars['Float']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseIssuanceDate?: Maybe<Scalars['String']>;
  startedWorkingDate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  reseller?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorQueuesArgs = {
  where?: Maybe<UserCounselorQueueQueryFilter>;
  sort?: Maybe<UserCounselorQueueQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorEducationsArgs = {
  where?: Maybe<UserCounselorEducationQueryFilter>;
  sort?: Maybe<UserCounselorEducationQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorExpertisesArgs = {
  where?: Maybe<UserCounselorExpertiseQueryFilter>;
  sort?: Maybe<UserCounselorExpertiseQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorRatingArgs = {
  customRating?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorBook = {
  __typename?: 'UserCounselorBook';
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<UserCounselorSettingConsultation>;
  price?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  queue?: Maybe<UserCounselorQueue>;
  status?: Maybe<UserCounselorBookStatus>;
  summary?: Maybe<Scalars['String']>;
  payment?: Maybe<UserCounselorBookPayment>;
  duration?: Maybe<Scalars['Int']>;
  profile?: Maybe<UserProfile>;
  counselor?: Maybe<UserCounselor>;
  startedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  income?: Maybe<UserCounselorIncome>;
  categories?: Maybe<Array<Maybe<UserCounselorBookCategory>>>;
  histories?: Maybe<Array<Maybe<UserCounselorBookHistory>>>;
  invoice?: Maybe<UserCounselorBookInvoice>;
};

export type UserCounselorBookCategory = {
  __typename?: 'UserCounselorBookCategory';
  id?: Maybe<Scalars['String']>;
  book?: Maybe<UserCounselorBook>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  dnaCategory?: Maybe<DnaCategory>;
};

export type UserCounselorBookForm = {
  link?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<UserCounselorSettingConsultation>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<UserCounselorBookStatus>;
  summary?: Maybe<Scalars['String']>;
  payment?: Maybe<UserCounselorBookPaymentForm>;
  duration?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['Date']>;
  categories?: Maybe<Array<Maybe<Scalars['Int']>>>;
  counselorId?: Maybe<Scalars['Int']>;
  queueId?: Maybe<Scalars['Int']>;
};

export type UserCounselorBookHistory = {
  __typename?: 'UserCounselorBookHistory';
  id?: Maybe<Scalars['String']>;
  counselorBookId?: Maybe<Scalars['Int']>;
  status?: Maybe<UserCounselorBookStatus>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorBookHistoryQueries = {
  __typename?: 'UserCounselorBookHistoryQueries';
  findAll?: Maybe<UserCounselorBookHistoryQueryResult>;
};

export type UserCounselorBookHistoryQueriesFindAllArgs = {
  where?: Maybe<UserCounselorBookHistoryQueryFilter>;
  sort?: Maybe<UserCounselorBookHistoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorBookHistoryQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  counselorBookId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  status?: Maybe<Array<Maybe<UserCounselorBookStatus>>>;
};

export type UserCounselorBookHistoryQueryResult = {
  __typename?: 'UserCounselorBookHistoryQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorBookHistory>>>;
};

export type UserCounselorBookHistoryQuerySortBy = {
  id?: Maybe<SortDirection>;
  counselorBookId?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type UserCounselorBookInvoice = {
  __typename?: 'UserCounselorBookInvoice';
  id?: Maybe<Scalars['String']>;
  bookId?: Maybe<Scalars['Int']>;
  pointer?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorBookInvoiceForm = {
  bookId?: Maybe<Scalars['Int']>;
  pointer?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorBookInvoiceQueryResult = {
  __typename?: 'UserCounselorBookInvoiceQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorBookInvoice>>>;
};

export type UserCounselorBookMutations = {
  __typename?: 'UserCounselorBookMutations';
  Shadow?: Maybe<UserCounselorBookShadowMutations>;
  add?: Maybe<UserCounselorBook>;
  edit?: Maybe<UserCounselorBook>;
  delete?: Maybe<Scalars['Boolean']>;
  exportExcel?: Maybe<Scalars['String']>;
};

export type UserCounselorBookMutationsAddArgs = {
  form?: Maybe<UserCounselorBookForm>;
};

export type UserCounselorBookMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorBookForm>;
};

export type UserCounselorBookMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorBookPayment = {
  __typename?: 'UserCounselorBookPayment';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentType>;
  book?: Maybe<UserCounselorBook>;
  status?: Maybe<PaymentStatus>;
  gateway?: Maybe<PaymentGateway>;
  payload?: Maybe<PaymentPayload>;
  voucher?: Maybe<Voucher>;
  /** payment referenceid that we sent to payment-gateway platform. */
  referenceId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorBookPaymentForm = {
  type?: Maybe<PaymentType>;
  phone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  voucherId?: Maybe<Scalars['String']>;
};

export type UserCounselorBookPaymentQueryResult = {
  __typename?: 'UserCounselorBookPaymentQueryResult';
  results?: Maybe<Array<Maybe<UserCounselorBookPayment>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserCounselorBookQueries = {
  __typename?: 'UserCounselorBookQueries';
  Shadow?: Maybe<UserCounselorBookShadowQueries>;
  History?: Maybe<UserCounselorBookHistoryQueries>;
  findAll?: Maybe<UserCounselorBookQueryResult>;
  findOne?: Maybe<UserCounselorBook>;
  filterPrice?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UserCounselorBookQueriesFindAllArgs = {
  where?: Maybe<UserCounselorBookQueryFilter>;
  sort?: Maybe<UserCounselorBookQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorBookQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorBookQueryFilter = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<UserCounselorBookStatus>>>;
  type?: Maybe<Array<Maybe<UserCounselorSettingConsultation>>>;
  duration?: Maybe<Array<Maybe<Scalars['Int']>>>;
  counselor?: Maybe<Array<Maybe<Scalars['Int']>>>;
  total?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  queueStatus?: Maybe<Array<Maybe<UserCounselorQueueStatus>>>;
};

export type UserCounselorBookQueryResult = {
  __typename?: 'UserCounselorBookQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorBook>>>;
};

export type UserCounselorBookQuerySortBy = {
  duration?: Maybe<SortDirection>;
  code?: Maybe<SortDirection>;
  note?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  profile?: Maybe<UserQuerySortBy>;
  counselor?: Maybe<UserCounselorQuerySortBy>;
  startedAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type UserCounselorBookShadow = {
  __typename?: 'UserCounselorBookShadow';
  id?: Maybe<Scalars['String']>;
  batchId?: Maybe<Scalars['String']>;
  kitId?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  counselorId?: Maybe<Scalars['Date']>;
  batchNumber?: Maybe<Scalars['String']>;
  kitCode?: Maybe<Scalars['String']>;
  resellerName?: Maybe<Scalars['String']>;
  activatedAt?: Maybe<Scalars['Date']>;
  kitActivation?: Maybe<KitActivation>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorBookShadowForm = {
  batchOrKitcode: Scalars['String'];
  counselorId: Scalars['String'];
};

export type UserCounselorBookShadowMutations = {
  __typename?: 'UserCounselorBookShadowMutations';
  add?: Maybe<Array<Maybe<UserCounselorBookShadow>>>;
};

export type UserCounselorBookShadowMutationsAddArgs = {
  forms?: Maybe<Array<Maybe<UserCounselorBookShadowForm>>>;
};

export type UserCounselorBookShadowQueries = {
  __typename?: 'UserCounselorBookShadowQueries';
  findAll?: Maybe<UserCounselorBookShadowQueryResult>;
};

export type UserCounselorBookShadowQueriesFindAllArgs = {
  where?: Maybe<UserCounselorBookShadowQueryFilter>;
  sort?: Maybe<UserCounselorBookShadowQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  include?: Maybe<Array<Maybe<UserCounselorBookShadowQueryInclude>>>;
};

export type UserCounselorBookShadowQueryFilter = {
  resellerName?: Maybe<Scalars['String']>;
  kitCode?: Maybe<Array<Maybe<Scalars['String']>>>;
  counselorId?: Maybe<Array<Maybe<Scalars['String']>>>;
  batchCode?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum UserCounselorBookShadowQueryInclude {
  KitActivation = 'KIT_ACTIVATION'
}

export type UserCounselorBookShadowQueryResult = {
  __typename?: 'UserCounselorBookShadowQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorBookShadow>>>;
};

export type UserCounselorBookShadowQuerySortBy = {
  batchId?: Maybe<SortDirection>;
  kitId?: Maybe<SortDirection>;
  profileId?: Maybe<SortDirection>;
  counselorId?: Maybe<SortDirection>;
  batchNumber?: Maybe<SortDirection>;
  kitCode?: Maybe<SortDirection>;
  resellerName?: Maybe<SortDirection>;
  activatedAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum UserCounselorBookStatus {
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  PaymentReceived = 'PAYMENT_RECEIVED',
  PaymentExpired = 'PAYMENT_EXPIRED',
  Pending = 'PENDING',
  Waiting = 'WAITING',
  Rescheduled = 'RESCHEDULED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED'
}

export type UserCounselorChat = {
  __typename?: 'UserCounselorChat';
  id?: Maybe<Scalars['Int']>;
  to?: Maybe<UserProfile>;
  from?: Maybe<UserProfile>;
  queue?: Maybe<UserCounselorQueue>;
  unread?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserCounselorChatForm = {
  message?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['Int']>;
};

export type UserCounselorChatMutations = {
  __typename?: 'UserCounselorChatMutations';
  add?: Maybe<UserCounselorChat>;
  edit?: Maybe<UserCounselorChat>;
  read?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  deletes?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorChatMutationsAddArgs = {
  form?: Maybe<UserCounselorChatForm>;
};

export type UserCounselorChatMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorChatForm>;
};

export type UserCounselorChatMutationsReadArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  queueId?: Maybe<Scalars['Int']>;
};

export type UserCounselorChatMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorChatMutationsDeletesArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  paranoid?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorChatQueries = {
  __typename?: 'UserCounselorChatQueries';
  findAll?: Maybe<UserCounselorChatQueryResult>;
  findOne?: Maybe<UserCounselorChat>;
};

export type UserCounselorChatQueriesFindAllArgs = {
  where?: Maybe<UserCounselorChatQueryFilter>;
  sort?: Maybe<UserCounselorChatQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorChatQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorChatQueryFilter = {
  queueId?: Maybe<Scalars['Int']>;
};

export type UserCounselorChatQueryResult = {
  __typename?: 'UserCounselorChatQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorChat>>>;
};

export type UserCounselorChatQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type UserCounselorCheckEmailAvailabilityQueryFilter = {
  /** email that want to check. */
  email: Scalars['String'];
  excludedEmail?: Maybe<Scalars['String']>;
};

export type UserCounselorEducation = {
  __typename?: 'UserCounselorEducation';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};

export type UserCounselorEducationForm = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type UserCounselorEducationMutations = {
  __typename?: 'UserCounselorEducationMutations';
  add?: Maybe<UserCounselorEducation>;
  edit?: Maybe<UserCounselorEducation>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorEducationMutationsAddArgs = {
  form?: Maybe<UserCounselorEducationForm>;
};

export type UserCounselorEducationMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorEducationForm>;
};

export type UserCounselorEducationMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorEducationQueries = {
  __typename?: 'UserCounselorEducationQueries';
  findAll?: Maybe<UserCounselorEducationQueryResult>;
  findOne?: Maybe<UserCounselorEducation>;
};

export type UserCounselorEducationQueriesFindAllArgs = {
  where?: Maybe<UserCounselorEducationQueryFilter>;
  sort?: Maybe<UserCounselorEducationQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorEducationQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorEducationQueryFilter = {
  name?: Maybe<Scalars['String']>;
  counselor?: Maybe<Scalars['Int']>;
};

export type UserCounselorEducationQueryResult = {
  __typename?: 'UserCounselorEducationQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorEducation>>>;
};

export type UserCounselorEducationQuerySortBy = {
  name?: Maybe<SortDirection>;
  year?: Maybe<SortDirection>;
};

export type UserCounselorExpertise = {
  __typename?: 'UserCounselorExpertise';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type UserCounselorExpertiseForm = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type UserCounselorExpertiseMutations = {
  __typename?: 'UserCounselorExpertiseMutations';
  add?: Maybe<UserCounselorExpertise>;
  edit?: Maybe<UserCounselorExpertise>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorExpertiseMutationsAddArgs = {
  form?: Maybe<UserCounselorExpertiseForm>;
};

export type UserCounselorExpertiseMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorExpertiseForm>;
};

export type UserCounselorExpertiseMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorExpertiseQueries = {
  __typename?: 'UserCounselorExpertiseQueries';
  findAll?: Maybe<UserCounselorExpertiseQueryResult>;
  findOne?: Maybe<UserCounselorExpertise>;
};

export type UserCounselorExpertiseQueriesFindAllArgs = {
  where?: Maybe<UserCounselorExpertiseQueryFilter>;
  sort?: Maybe<UserCounselorExpertiseQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorExpertiseQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorExpertiseQueryFilter = {
  name?: Maybe<Scalars['String']>;
  counselor?: Maybe<Scalars['Int']>;
};

export type UserCounselorExpertiseQueryResult = {
  __typename?: 'UserCounselorExpertiseQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorExpertise>>>;
};

export type UserCounselorExpertiseQuerySortBy = {
  name?: Maybe<SortDirection>;
};

export type UserCounselorForm = {
  times?: Maybe<UserCounselorTimesForm>;
  price?: Maybe<Scalars['Int']>;
  status?: Maybe<UserCounselorStatusForm>;
  address?: Maybe<Scalars['String']>;
  setting?: Maybe<UserCounselorSettingForm>;
  profile?: Maybe<UserForm>;
  password?: Maybe<Scalars['String']>;
  educations?: Maybe<Array<Maybe<UserCounselorEducationForm>>>;
  expertises?: Maybe<Array<Maybe<UserCounselorExpertiseForm>>>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseIssuanceDate?: Maybe<Scalars['String']>;
  startedWorkingDate?: Maybe<Scalars['String']>;
  professionId?: Maybe<Scalars['Int']>;
  reseller?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorIncome = {
  __typename?: 'UserCounselorIncome';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  status?: Maybe<User_Counselor_Book_Status>;
  queue?: Maybe<UserCounselorQueue>;
  counselor?: Maybe<UserCounselor>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  attachment?: Maybe<Scalars['String']>;
};

export type UserCounselorIncomeMutationEdit = {
  status?: Maybe<User_Counselor_Book_Status>;
  attachment?: Maybe<Scalars['String']>;
};

export type UserCounselorIncomeMutations = {
  __typename?: 'UserCounselorIncomeMutations';
  edit?: Maybe<UserCounselorIncome>;
};

export type UserCounselorIncomeMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<UserCounselorIncomeMutationEdit>;
};

export type UserCounselorIncomeQuery = {
  __typename?: 'UserCounselorIncomeQuery';
  findOne?: Maybe<UserCounselorIncome>;
  findAll?: Maybe<UserCounselorIncomeQueryResults>;
};

export type UserCounselorIncomeQueryFindOneArgs = {
  id?: Maybe<Scalars['String']>;
};

export type UserCounselorIncomeQueryFindAllArgs = {
  where?: Maybe<UserCounselorIncomeQueryFilter>;
  sort?: Maybe<UserCounselorIncomeQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UserCounselorIncomeQueryFilter = {
  status?: Maybe<Array<Maybe<User_Counselor_Book_Status>>>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type UserCounselorIncomeQueryResults = {
  __typename?: 'UserCounselorIncomeQueryResults';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorIncome>>>;
};

export type UserCounselorIncomeQuerySortBy = {
  status?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type UserCounselorMutations = {
  __typename?: 'UserCounselorMutations';
  Book?: Maybe<UserCounselorBookMutations>;
  Chat?: Maybe<UserCounselorChatMutations>;
  Education?: Maybe<UserCounselorEducationMutations>;
  Expertise?: Maybe<UserCounselorExpertiseMutations>;
  Notification?: Maybe<CounselorNotificationMutations>;
  Profession?: Maybe<UserCounselorProfessionMutations>;
  Queue?: Maybe<UserCounselorQueueMutations>;
  Schedule?: Maybe<UserCounselorScheduleMutations>;
  Setting?: Maybe<UserCounselorSettingMutations>;
  Status?: Maybe<UserCounselorStatusMutations>;
  Times?: Maybe<UserCounselorTimesMutations>;
  Income?: Maybe<UserCounselorIncomeMutations>;
  add?: Maybe<UserCounselor>;
  edit?: Maybe<UserCounselor>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorMutationsAddArgs = {
  form?: Maybe<UserCounselorForm>;
};

export type UserCounselorMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorForm>;
};

export type UserCounselorMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorPerformance = {
  __typename?: 'UserCounselorPerformance';
  chat?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  voice_call?: Maybe<Scalars['Float']>;
  video_call?: Maybe<Scalars['Float']>;
  face_to_face?: Maybe<Scalars['Float']>;
};

export type UserCounselorPerformanceQueryFilter = {
  duration?: Maybe<Array<Maybe<Scalars['Date']>>>;
  name?: Maybe<Scalars['String']>;
};

export type UserCounselorPerformanceQuerySortBy = {
  chat?: Maybe<SortDirection>;
  total?: Maybe<SortDirection>;
  profile?: Maybe<UserQuerySortBy>;
  voice_call?: Maybe<SortDirection>;
  video_call?: Maybe<SortDirection>;
  face_to_face?: Maybe<SortDirection>;
};

export type UserCounselorProfession = {
  __typename?: 'UserCounselorProfession';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_id?: Maybe<Scalars['String']>;
  setting?: Maybe<UserCounselorProfessionSetting>;
};

export type UserCounselorProfessionForm = {
  name_en?: Maybe<Scalars['String']>;
  name_id?: Maybe<Scalars['String']>;
};

export type UserCounselorProfessionMutations = {
  __typename?: 'UserCounselorProfessionMutations';
  Setting?: Maybe<UserCounselorProfessionSettingMutations>;
  add?: Maybe<UserCounselorProfession>;
  edit?: Maybe<UserCounselorProfession>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorProfessionMutationsAddArgs = {
  form?: Maybe<UserCounselorProfessionForm>;
};

export type UserCounselorProfessionMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorProfessionForm>;
};

export type UserCounselorProfessionMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorProfessionQueries = {
  __typename?: 'UserCounselorProfessionQueries';
  Setting?: Maybe<UserCounselorProfessionSettingQueries>;
  findAll?: Maybe<UserCounselorProfessionQueryResult>;
  findOne?: Maybe<UserCounselorProfession>;
};

export type UserCounselorProfessionQueriesFindAllArgs = {
  where?: Maybe<UserCounselorProfessionQueryFilter>;
  sort?: Maybe<UserCounselorProfessionQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorProfessionQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorProfessionQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type UserCounselorProfessionQueryResult = {
  __typename?: 'UserCounselorProfessionQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorProfession>>>;
};

export type UserCounselorProfessionQuerySortBy = {
  name?: Maybe<SortDirection>;
  name_en?: Maybe<SortDirection>;
  name_id?: Maybe<SortDirection>;
};

export type UserCounselorProfessionSetting = {
  __typename?: 'UserCounselorProfessionSetting';
  id?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Float']>;
  minUnit?: Maybe<UserCounselorProfessionSettingUnit>;
  max?: Maybe<Scalars['Float']>;
  maxUnit?: Maybe<UserCounselorProfessionSettingUnit>;
  profession?: Maybe<UserCounselorProfession>;
};

export type UserCounselorProfessionSettingForm = {
  min?: Maybe<Scalars['Float']>;
  minUnit?: Maybe<UserCounselorProfessionSettingUnit>;
  max?: Maybe<Scalars['Float']>;
  maxUnit?: Maybe<UserCounselorProfessionSettingUnit>;
  professionId?: Maybe<Scalars['Int']>;
};

export type UserCounselorProfessionSettingMutations = {
  __typename?: 'UserCounselorProfessionSettingMutations';
  add?: Maybe<UserCounselorProfessionSetting>;
  edit?: Maybe<UserCounselorProfessionSetting>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorProfessionSettingMutationsAddArgs = {
  form?: Maybe<UserCounselorProfessionSettingForm>;
};

export type UserCounselorProfessionSettingMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<UserCounselorProfessionSettingForm>;
};

export type UserCounselorProfessionSettingMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type UserCounselorProfessionSettingQueries = {
  __typename?: 'UserCounselorProfessionSettingQueries';
  findAll?: Maybe<UserCounselorProfessionSettingQueryResult>;
  findOne?: Maybe<UserCounselorProfessionSetting>;
};

export type UserCounselorProfessionSettingQueriesFindAllArgs = {
  where?: Maybe<UserCounselorProfessionSettingQueryFilter>;
  sort?: Maybe<UserCounselorProfessionSettingQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorProfessionSettingQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type UserCounselorProfessionSettingQueryFilter = {
  professions?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UserCounselorProfessionSettingQueryResult = {
  __typename?: 'UserCounselorProfessionSettingQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorProfessionSetting>>>;
};

export type UserCounselorProfessionSettingQuerySortBy = {
  name?: Maybe<SortDirection>;
  min?: Maybe<SortDirection>;
  minUnit?: Maybe<SortDirection>;
  max?: Maybe<SortDirection>;
  maxUnit?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum UserCounselorProfessionSettingUnit {
  Days = 'DAYS',
  Hours = 'HOURS',
  Weeks = 'WEEKS'
}

export type UserCounselorQueries = {
  __typename?: 'UserCounselorQueries';
  Book?: Maybe<UserCounselorBookQueries>;
  Chat?: Maybe<UserCounselorChatQueries>;
  Education?: Maybe<UserCounselorEducationQueries>;
  Expertise?: Maybe<UserCounselorExpertiseQueries>;
  Notification?: Maybe<CounselorNotificationQueries>;
  Profession?: Maybe<UserCounselorProfessionQueries>;
  Rating?: Maybe<UserCounselorRatingQueries>;
  Queue?: Maybe<UserCounselorQueueQueries>;
  Setting?: Maybe<UserCounselorSettingQueries>;
  Status?: Maybe<UserCounselorStatusQueries>;
  Times?: Maybe<UserCounselorTimesQueries>;
  Income?: Maybe<UserCounselorIncomeQuery>;
  findAll?: Maybe<UserCounselorQueryResult>;
  findOne?: Maybe<UserCounselor>;
  performance?: Maybe<UserCounselorQueryResult>;
  checkEmailAvailability?: Maybe<CheckEmailAvailabilityResult>;
};

export type UserCounselorQueriesFindAllArgs = {
  where?: Maybe<UserCounselorQueryFilter>;
  sort?: Maybe<UserCounselorQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorQueriesPerformanceArgs = {
  where?: Maybe<UserCounselorPerformanceQueryFilter>;
  sort?: Maybe<UserCounselorPerformanceQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorQueriesCheckEmailAvailabilityArgs = {
  where?: Maybe<UserCounselorCheckEmailAvailabilityQueryFilter>;
};

export type UserCounselorQueryFilter = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<UserStatus>>>;
  setting?: Maybe<UserCounselorSettingQueryFilter>;
  professions?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rating?: Maybe<Scalars['Int']>;
  reseller?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorQueryResult = {
  __typename?: 'UserCounselorQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselor>>>;
};

export type UserCounselorQuerySortBy = {
  profile?: Maybe<UserQuerySortBy>;
  profession?: Maybe<UserCounselorProfessionQuerySortBy>;
  licenseNumber?: Maybe<SortDirection>;
  licenseIssuanceDate?: Maybe<SortDirection>;
  startedWorkingDate?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type UserCounselorQueue = {
  __typename?: 'UserCounselorQueue';
  id?: Maybe<Scalars['Int']>;
  book?: Maybe<UserCounselorBook>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<UserCounselorSettingConsultation>;
  status?: Maybe<UserCounselorQueueStatus>;
  summary?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  counselor?: Maybe<UserCounselor>;
  feedback?: Maybe<UserCounselorQueueFeedback>;
  createdAt?: Maybe<Scalars['Date']>;
  startedAt?: Maybe<Scalars['Date']>;
  finishedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorQueueFeedback = {
  __typename?: 'UserCounselorQueueFeedback';
  id?: Maybe<Scalars['Int']>;
  queueId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorQueueFeedbackForm = {
  rating: Scalars['Int'];
  comments?: Maybe<Scalars['String']>;
  queueId: Scalars['Int'];
};

export type UserCounselorQueueFeedbackMutations = {
  __typename?: 'UserCounselorQueueFeedbackMutations';
  add?: Maybe<UserCounselorQueueFeedback>;
};

export type UserCounselorQueueFeedbackMutationsAddArgs = {
  form?: Maybe<UserCounselorQueueFeedbackForm>;
};

export type UserCounselorQueueFeedbackQueries = {
  __typename?: 'UserCounselorQueueFeedbackQueries';
  findOne?: Maybe<UserCounselorQueueFeedback>;
};

export type UserCounselorQueueFeedbackQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorQueueForm = {
  note?: Maybe<Scalars['String']>;
  type?: Maybe<UserCounselorSettingConsultation>;
  status?: Maybe<UserCounselorQueueStatus>;
  summary?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['Date']>;
  finishedAt?: Maybe<Scalars['Date']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type UserCounselorQueueMutations = {
  __typename?: 'UserCounselorQueueMutations';
  add?: Maybe<UserCounselorQueue>;
  edit?: Maybe<UserCounselorQueue>;
  delete?: Maybe<Scalars['Boolean']>;
  Feedback?: Maybe<UserCounselorQueueFeedbackMutations>;
};

export type UserCounselorQueueMutationsAddArgs = {
  form?: Maybe<UserCounselorQueueForm>;
};

export type UserCounselorQueueMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorQueueForm>;
};

export type UserCounselorQueueMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorQueueQueries = {
  __typename?: 'UserCounselorQueueQueries';
  findAll?: Maybe<UserCounselorQueueQueryResult>;
  findOne?: Maybe<UserCounselorQueue>;
  Feedback?: Maybe<UserCounselorQueueFeedbackQueries>;
};

export type UserCounselorQueueQueriesFindAllArgs = {
  where?: Maybe<UserCounselorQueueQueryFilter>;
  sort?: Maybe<UserCounselorQueueQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorQueueQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorQueueQueryFilter = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<UserCounselorQueueStatus>>>;
  profile?: Maybe<Scalars['String']>;
  counselor?: Maybe<Scalars['Int']>;
  counselors?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UserCounselorQueueQueryResult = {
  __typename?: 'UserCounselorQueueQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorQueue>>>;
};

export type UserCounselorQueueQuerySortBy = {
  type?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  profile?: Maybe<UserQuerySortBy>;
  counselor?: Maybe<UserCounselorQuerySortBy>;
  createdAt?: Maybe<SortDirection>;
  startedAt?: Maybe<SortDirection>;
  finishedAt?: Maybe<SortDirection>;
};

export enum UserCounselorQueueStatus {
  Pending = 'PENDING',
  Waiting = 'WAITING',
  Started = 'STARTED',
  Timeout = 'TIMEOUT',
  Invalid = 'INVALID',
  Finished = 'FINISHED',
  Declined = 'DECLINED',
  Cancelled = 'CANCELLED'
}

export type UserCounselorRating = {
  __typename?: 'UserCounselorRating';
  id?: Maybe<Scalars['String']>;
  bookId?: Maybe<Scalars['Int']>;
  book?: Maybe<UserCounselorBook>;
  counselorId?: Maybe<Scalars['Int']>;
  counselor?: Maybe<UserCounselor>;
  profileId?: Maybe<Scalars['String']>;
  customer?: Maybe<UserProfile>;
  rating?: Maybe<Scalars['Int']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedback?: Maybe<Scalars['String']>;
  consultationBookType?: Maybe<UserCounselorSettingConsultation>;
  consultationStartedAt?: Maybe<Scalars['Date']>;
  consultationFinishedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorRatingQueries = {
  __typename?: 'UserCounselorRatingQueries';
  findAll?: Maybe<UserCounselorRatingQueryResult>;
};

export type UserCounselorRatingQueriesFindAllArgs = {
  where?: Maybe<UserCounselorRatingQueryFilter>;
  sort?: Maybe<UserCounselorRatingQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorRatingQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  rating?: Maybe<Array<Maybe<Scalars['Int']>>>;
  consultationDate?: Maybe<Array<Maybe<Scalars['Date']>>>;
  counselorId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  professionId?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UserCounselorRatingQueryResult = {
  __typename?: 'UserCounselorRatingQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorRating>>>;
};

export type UserCounselorRatingQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
  consultationStartedAt?: Maybe<SortDirection>;
};

export type UserCounselorSchedule = {
  __typename?: 'UserCounselorSchedule';
  id?: Maybe<Scalars['String']>;
  day?: Maybe<Day>;
  times?: Maybe<Array<Maybe<UserCounselorScheduleTime>>>;
  available?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorScheduleForm = {
  day?: Maybe<Day>;
  times?: Maybe<Array<Maybe<UserCounselorScheduleTimeForm>>>;
  available?: Maybe<Scalars['Boolean']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type UserCounselorScheduleMutations = {
  __typename?: 'UserCounselorScheduleMutations';
  Time?: Maybe<UserCounselorScheduleTimeMutations>;
  add?: Maybe<UserCounselorSchedule>;
  edit?: Maybe<UserCounselorSchedule>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorScheduleMutationsAddArgs = {
  form?: Maybe<UserCounselorScheduleForm>;
};

export type UserCounselorScheduleMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<UserCounselorScheduleForm>;
};

export type UserCounselorScheduleMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type UserCounselorScheduleTime = {
  __typename?: 'UserCounselorScheduleTime';
  id?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserCounselorScheduleTimeForm = {
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  scheduleId?: Maybe<Scalars['String']>;
};

export type UserCounselorScheduleTimeMutations = {
  __typename?: 'UserCounselorScheduleTimeMutations';
  add?: Maybe<UserCounselorScheduleTime>;
  edit?: Maybe<UserCounselorScheduleTime>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorScheduleTimeMutationsAddArgs = {
  form?: Maybe<UserCounselorScheduleTimeForm>;
};

export type UserCounselorScheduleTimeMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<UserCounselorScheduleTimeForm>;
};

export type UserCounselorScheduleTimeMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type UserCounselorSetting = {
  __typename?: 'UserCounselorSetting';
  id?: Maybe<Scalars['Int']>;
  membership?: Maybe<Array<Maybe<UserMembership>>>;
  consultation?: Maybe<Array<Maybe<UserCounselorSettingConsultation>>>;
  timePerSession?: Maybe<Scalars['Int']>;
  pricePerSession?: Maybe<Scalars['Int']>;
  counselorPercentage?: Maybe<Scalars['Int']>;
  companyPercentage?: Maybe<Scalars['Int']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export enum UserCounselorSettingConsultation {
  Chat = 'CHAT',
  VoiceCall = 'VOICE_CALL',
  VideoCall = 'VIDEO_CALL',
  FaceToFace = 'FACE_TO_FACE'
}

export type UserCounselorSettingForm = {
  id?: Maybe<Scalars['Int']>;
  membership?: Maybe<Array<Maybe<UserMembership>>>;
  consultation?: Maybe<Array<Maybe<UserCounselorSettingConsultation>>>;
  counselorId?: Maybe<Scalars['Int']>;
  timePerSession?: Maybe<Scalars['Int']>;
  pricePerSession?: Maybe<Scalars['Int']>;
  counselorPercentage?: Maybe<Scalars['Int']>;
  companyPercentage?: Maybe<Scalars['Int']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorSettingMutations = {
  __typename?: 'UserCounselorSettingMutations';
  add?: Maybe<UserCounselorSetting>;
  edit?: Maybe<UserCounselorSetting>;
  delete?: Maybe<Scalars['Boolean']>;
  toggleVisibility?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorSettingMutationsAddArgs = {
  form?: Maybe<UserCounselorSettingForm>;
};

export type UserCounselorSettingMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorSettingForm>;
};

export type UserCounselorSettingMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorSettingMutationsToggleVisibilityArgs = {
  id: Scalars['Int'];
  counselorId: Scalars['Int'];
};

export type UserCounselorSettingQueries = {
  __typename?: 'UserCounselorSettingQueries';
  findAll?: Maybe<UserCounselorSettingQueryResult>;
  findOne?: Maybe<UserCounselorSetting>;
};

export type UserCounselorSettingQueriesFindAllArgs = {
  where?: Maybe<UserCounselorSettingQueryFilter>;
  sort?: Maybe<UserCounselorSettingQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorSettingQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorSettingQueryFilter = {
  membership?: Maybe<Array<Maybe<UserMembership>>>;
  consultation?: Maybe<Array<Maybe<UserCounselorSettingConsultation>>>;
  counselor?: Maybe<Scalars['Int']>;
  isVisible?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorSettingQueryResult = {
  __typename?: 'UserCounselorSettingQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorSetting>>>;
};

export type UserCounselorSettingQuerySortBy = {
  membership?: Maybe<SortDirection>;
  consultation?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type UserCounselorStatus = {
  __typename?: 'UserCounselorStatus';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<UserCounselorStatusType>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  autoUpdate?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorStatusForm = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<UserCounselorStatusType>;
  message?: Maybe<Scalars['String']>;
  autoUpdate?: Maybe<Scalars['Boolean']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type UserCounselorStatusMutations = {
  __typename?: 'UserCounselorStatusMutations';
  add?: Maybe<UserCounselorStatus>;
  edit?: Maybe<UserCounselorStatus>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorStatusMutationsAddArgs = {
  form?: Maybe<UserCounselorStatusForm>;
};

export type UserCounselorStatusMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorStatusForm>;
};

export type UserCounselorStatusMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorStatusQueries = {
  __typename?: 'UserCounselorStatusQueries';
  findAll?: Maybe<UserCounselorStatusQueryResult>;
  findOne?: Maybe<UserCounselorStatus>;
};

export type UserCounselorStatusQueriesFindAllArgs = {
  where?: Maybe<UserCounselorStatusQueryFilter>;
  sort?: Maybe<UserCounselorStatusQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorStatusQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorStatusQueryFilter = {
  type?: Maybe<UserCounselorStatusType>;
  counselor?: Maybe<Scalars['Int']>;
};

export type UserCounselorStatusQueryResult = {
  __typename?: 'UserCounselorStatusQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorStatus>>>;
};

export type UserCounselorStatusQuerySortBy = {
  type?: Maybe<SortDirection>;
  message?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export enum UserCounselorStatusType {
  Break = 'BREAK',
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  InConsultation = 'IN_CONSULTATION'
}

export type UserCounselorTimes = {
  __typename?: 'UserCounselorTimes';
  id?: Maybe<Scalars['Int']>;
  days?: Maybe<Array<Maybe<UserCounselorTimesDay>>>;
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
};

export enum UserCounselorTimesDay {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type UserCounselorTimesForm = {
  id?: Maybe<Scalars['Int']>;
  days?: Maybe<Array<Maybe<UserCounselorTimesDay>>>;
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  counselorId?: Maybe<Scalars['Int']>;
};

export type UserCounselorTimesMutations = {
  __typename?: 'UserCounselorTimesMutations';
  add?: Maybe<UserCounselorTimes>;
  edit?: Maybe<UserCounselorTimes>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserCounselorTimesMutationsAddArgs = {
  form?: Maybe<UserCounselorTimesForm>;
};

export type UserCounselorTimesMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserCounselorTimesForm>;
};

export type UserCounselorTimesMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserCounselorTimesQueries = {
  __typename?: 'UserCounselorTimesQueries';
  findAll?: Maybe<UserCounselorTimesQueryResult>;
  findOne?: Maybe<UserCounselorTimes>;
};

export type UserCounselorTimesQueriesFindAllArgs = {
  where?: Maybe<UserCounselorTimesQueryFilter>;
  sort?: Maybe<UserCounselorTimesQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserCounselorTimesQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserCounselorTimesQueryFilter = {
  counselor?: Maybe<Scalars['Int']>;
};

export type UserCounselorTimesQueryResult = {
  __typename?: 'UserCounselorTimesQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserCounselorTimes>>>;
};

export type UserCounselorTimesQuerySortBy = {
  createdAt?: Maybe<SortDirection>;
};

export type UserCustomerMutations = {
  __typename?: 'UserCustomerMutations';
  exportExcel?: Maybe<Scalars['String']>;
};

export type UserCustomerMutationsExportExcelArgs = {
  sort?: Maybe<UserCustomerQuerySortBy>;
  where?: Maybe<UserCustomerQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UserCustomerQueries = {
  __typename?: 'UserCustomerQueries';
  findAll?: Maybe<UserCustomerQueryResult>;
};

export type UserCustomerQueriesFindAllArgs = {
  sort?: Maybe<UserCustomerQuerySortBy>;
  where?: Maybe<UserCustomerQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UserCustomerQueryFilter = {
  gender?: Maybe<Array<Maybe<UserGender>>>;
  status?: Maybe<Array<Maybe<UserCustomerStatus>>>;
  keyword?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserCustomerQueryResult = {
  __typename?: 'UserCustomerQueryResult';
  results?: Maybe<Array<Maybe<UserProfile>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserCustomerQuerySortBy = {
  name?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  phone?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  kit?: Maybe<PurchaseProductKitQuerySortBy>;
};

export enum UserCustomerStatus {
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

export type UserFamily = {
  __typename?: 'UserFamily';
  id?: Maybe<Scalars['Int']>;
  age?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  profile?: Maybe<UserProfile>;
  birthday?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserFamilyForm = {
  name?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  birthday?: Maybe<Scalars['String']>;
};

export type UserFamilyMutations = {
  __typename?: 'UserFamilyMutations';
  add?: Maybe<UserFamily>;
  edit?: Maybe<UserFamily>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserFamilyMutationsAddArgs = {
  form?: Maybe<UserFamilyForm>;
};

export type UserFamilyMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserFamilyForm>;
};

export type UserFamilyMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserFamilyQueries = {
  __typename?: 'UserFamilyQueries';
  findAll?: Maybe<UserFamilyQueryResult>;
  findOne?: Maybe<UserFamily>;
};

export type UserFamilyQueriesFindAllArgs = {
  where?: Maybe<UserFamilyQueryFilter>;
  sort?: Maybe<UserFamilyQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserFamilyQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserFamilyQueryFilter = {
  name?: Maybe<Scalars['String']>;
};

export type UserFamilyQueryResult = {
  __typename?: 'UserFamilyQueryResult';
  results?: Maybe<Array<Maybe<UserFamily>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserFamilyQuerySortBy = {
  name?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type UserForm = {
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['Upload']>;
  photos?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  birthday?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  membership?: Maybe<UserMembership>;
};

export enum UserGender {
  Male = 'MALE',
  Female = 'FEMALE'
}

export enum UserMembership {
  Personal = 'PERSONAL',
  Business = 'BUSINESS'
}

/** user module's mutations */
export type UserMutations = {
  __typename?: 'UserMutations';
  Counselor?: Maybe<UserCounselorMutations>;
  Customer?: Maybe<UserCustomerMutations>;
  Family?: Maybe<UserFamilyMutations>;
  Quota?: Maybe<UserQuotaMutations>;
  Setting?: Maybe<UserSettingMutations>;
  Notification?: Maybe<UserNotificationMutations>;
  SubProfile?: Maybe<UserSubProfileMutations>;
  /** delete user account by it's profile id */
  delete?: Maybe<Scalars['Boolean']>;
  /** softdelete user account by it's profile id */
  softDelete?: Maybe<Scalars['Boolean']>;
  /** softdelete validation */
  validateSoftDelete?: Maybe<Scalars['Boolean']>;
  /** delete multiple user accounts by it's profile id */
  deleteMultiple?: Maybe<Scalars['Boolean']>;
  /** edit user profile of current context */
  edit?: Maybe<UserProfile>;
  /** edit/add user shipment */
  shipment?: Maybe<Scalars['Boolean']>;
  /** edit user password of current context */
  editPassword?: Maybe<Scalars['Boolean']>;
  editPhoto?: Maybe<Scalars['Boolean']>;
  /** resend activation token via user's business email or personal email */
  resendActivationToken?: Maybe<Scalars['Boolean']>;
  /** reset user password */
  resetPassword?: Maybe<Scalars['Boolean']>;
  resendPassword?: Maybe<Scalars['Boolean']>;
  /** send onboarding email */
  sendOnboardingEmail?: Maybe<Scalars['Boolean']>;
  sendOTPToChangeEmail?: Maybe<SendOtpToChangeEmailResult>;
  changeEmail?: Maybe<SendOtpToChangeEmailResult>;
};

/** user module's mutations */
export type UserMutationsDeleteArgs = {
  id: Scalars['String'];
};

/** user module's mutations */
export type UserMutationsDeleteMultipleArgs = {
  ids: Array<Maybe<Scalars['String']>>;
};

/** user module's mutations */
export type UserMutationsEditArgs = {
  form?: Maybe<UserForm>;
};

/** user module's mutations */
export type UserMutationsShipmentArgs = {
  form?: Maybe<UserShipmentForm>;
};

/** user module's mutations */
export type UserMutationsEditPasswordArgs = {
  form?: Maybe<UserPasswordForm>;
};

/** user module's mutations */
export type UserMutationsEditPhotoArgs = {
  url?: Maybe<Scalars['String']>;
};

/** user module's mutations */
export type UserMutationsResendActivationTokenArgs = {
  id: Scalars['String'];
};

/** user module's mutations */
export type UserMutationsResetPasswordArgs = {
  username: Scalars['String'];
};

/** user module's mutations */
export type UserMutationsResendPasswordArgs = {
  username: Scalars['String'];
};

/** user module's mutations */
export type UserMutationsSendOtpToChangeEmailArgs = {
  newEmail?: Maybe<Scalars['String']>;
};

/** user module's mutations */
export type UserMutationsChangeEmailArgs = {
  otp?: Maybe<Scalars['String']>;
  newEmail?: Maybe<Scalars['String']>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  id?: Maybe<Scalars['Int']>;
  to?: Maybe<UserProfile>;
  from?: Maybe<UserProfile>;
  href?: Maybe<Scalars['String']>;
  type?: Maybe<UserNotificationType>;
  title?: Maybe<Scalars['String']>;
  unread?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UserNotificationForm = {
  unread?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationMutations = {
  __typename?: 'UserNotificationMutations';
  Setting?: Maybe<UserNotificationSettingMutations>;
  edit?: Maybe<UserNotification>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserNotificationForm>;
};

export type UserNotificationMutationsDeleteArgs = {
  id: Scalars['Int'];
};

export type UserNotificationQueries = {
  __typename?: 'UserNotificationQueries';
  Setting?: Maybe<UserNotificationSettingQueries>;
  findAll?: Maybe<UserNotificationQueryResult>;
  findOne?: Maybe<UserNotification>;
};

export type UserNotificationQueriesFindAllArgs = {
  where?: Maybe<UserNotificationQueryFilter>;
  sort?: Maybe<UserNotificationQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserNotificationQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserNotificationQueryFilter = {
  type?: Maybe<Array<Maybe<UserNotificationType>>>;
  unread?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationQueryResult = {
  __typename?: 'UserNotificationQueryResult';
  results?: Maybe<Array<Maybe<UserNotification>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserNotificationQuerySortBy = {
  type?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  id?: Maybe<Scalars['String']>;
  kydz?: Maybe<Scalars['Boolean']>;
  ancestry?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingForm = {
  kydz?: Maybe<Scalars['Boolean']>;
  ancestry?: Maybe<Scalars['Boolean']>;
};

export type UserNotificationSettingMutations = {
  __typename?: 'UserNotificationSettingMutations';
  edit?: Maybe<UserNotificationSetting>;
};

export type UserNotificationSettingMutationsEditArgs = {
  form?: Maybe<UserNotificationSettingForm>;
};

export type UserNotificationSettingQueries = {
  __typename?: 'UserNotificationSettingQueries';
  findOne?: Maybe<UserNotificationSetting>;
};

export enum UserNotificationType {
  AppointmentReminder = 'APPOINTMENT_REMINDER',
  AppointmentBookUpdated = 'APPOINTMENT_BOOK_UPDATED',
  ArticleCreated = 'ARTICLE_CREATED',
  CartReminderDaily = 'CART_REMINDER_DAILY',
  CartReminderWeekly = 'CART_REMINDER_WEEKLY',
  OrderReminderDaily = 'ORDER_REMINDER_DAILY',
  OrderReminderWeekly = 'ORDER_REMINDER_WEEKLY',
  OrderPaymentReceived = 'ORDER_PAYMENT_RECEIVED',
  OrderCreated = 'ORDER_CREATED',
  PickupKitRequest = 'PICKUP_KIT_REQUEST',
  DnaReportMapperSucceed = 'DNA_REPORT_MAPPER_SUCCEED',
  DnaReportMapperProcessed = 'DNA_REPORT_MAPPER_PROCESSED',
  DnaReportMapperSkipped = 'DNA_REPORT_MAPPER_SKIPPED',
  DnaReportMapperFailed = 'DNA_REPORT_MAPPER_FAILED',
  DnaReportPdfSucceed = 'DNA_REPORT_PDF_SUCCEED',
  DnaReportPdfFailed = 'DNA_REPORT_PDF_FAILED',
  DnaReportResultNotification = 'DNA_REPORT_RESULT_NOTIFICATION',
  DnaReportImportTranslationSucceed = 'DNA_REPORT_IMPORT_TRANSLATION_SUCCEED',
  DnaReportImportTranslationUploadSucceed = 'DNA_REPORT_IMPORT_TRANSLATION_UPLOAD_SUCCEED',
  DnaReportImportTranslationUploadFailed = 'DNA_REPORT_IMPORT_TRANSLATION_UPLOAD_FAILED',
  DnaGeneImportTranslationSucceed = 'DNA_GENE_IMPORT_TRANSLATION_SUCCEED',
  DnaGeneImportTranslationUploadSucceed = 'DNA_GENE_IMPORT_TRANSLATION_UPLOAD_SUCCEED',
  DnaGeneImportTranslationUploadFailed = 'DNA_GENE_IMPORT_TRANSLATION_UPLOAD_FAILED',
  KitExportFileSucceed = 'KIT_EXPORT_FILE_SUCCEED',
  KitExportFileFailed = 'KIT_EXPORT_FILE_FAILED',
  ThreesixtyPanelExportExcelSucceed = 'THREESIXTY_PANEL_EXPORT_EXCEL_SUCCEED',
  ThreesixtyPanelExportExcelFailed = 'THREESIXTY_PANEL_EXPORT_EXCEL_FAILED',
  ThreesixtyCategoryExportExcelFailed = 'THREESIXTY_CATEGORY_EXPORT_EXCEL_FAILED',
  ThreesixtyCategoryExportExcelSucceed = 'THREESIXTY_CATEGORY_EXPORT_EXCEL_SUCCEED',
  ThreesixtySubcategoryExportExcelFailed = 'THREESIXTY_SUBCATEGORY_EXPORT_EXCEL_FAILED',
  ThreesixtySubcategoryExportExcelSucceed = 'THREESIXTY_SUBCATEGORY_EXPORT_EXCEL_SUCCEED',
  ThreesixtyTraitExportExcelFailed = 'THREESIXTY_TRAIT_EXPORT_EXCEL_FAILED',
  ThreesixtyTraitExportExcelSucceed = 'THREESIXTY_TRAIT_EXPORT_EXCEL_SUCCEED',
  ThreesixtyMapperExportExcelFailed = 'THREESIXTY_MAPPER_EXPORT_EXCEL_FAILED',
  ThreesixtyMapperExportExcelSucceed = 'THREESIXTY_MAPPER_EXPORT_EXCEL_SUCCEED',
  PgxGlossaryExcelFailed = 'PGX_GLOSSARY_EXCEL_FAILED',
  PgxGlossaryExcelSucceed = 'PGX_GLOSSARY_EXCEL_SUCCEED',
  PgxDrugClassExcelSucceed = 'PGX_DRUG_CLASS_EXCEL_SUCCEED',
  PgxDrugClassExcelFailed = 'PGX_DRUG_CLASS_EXCEL_FAILED',
  PgxDrugExcelFailed = 'PGX_DRUG_EXCEL_FAILED',
  PgxDrugExcelSucceed = 'PGX_DRUG_EXCEL_SUCCEED',
  ProdigyImportUploadSucceed = 'PRODIGY_IMPORT_UPLOAD_SUCCEED',
  ProdigyImportUploadFailed = 'PRODIGY_IMPORT_UPLOAD_FAILED',
  BulkManualActivation = 'BULK_MANUAL_ACTIVATION',
  BulkManualActivationSucceed = 'BULK_MANUAL_ACTIVATION_SUCCEED',
  BulkManualActivationFailed = 'BULK_MANUAL_ACTIVATION_FAILED',
  NutrigeneticMapperExportExcelSucceed = 'NUTRIGENETIC_MAPPER_EXPORT_EXCEL_SUCCEED',
  NutrigeneticMapperExportExcelFailed = 'NUTRIGENETIC_MAPPER_EXPORT_EXCEL_FAILED',
  ContentManagementImportTrait = 'CONTENT_MANAGEMENT_IMPORT_TRAIT',
  ContentManagementImportTraitSucceed = 'CONTENT_MANAGEMENT_IMPORT_TRAIT_SUCCEED',
  ContentManagementImportTraitFailed = 'CONTENT_MANAGEMENT_IMPORT_TRAIT_FAILED',
  ContentMapperExportExcelSucceed = 'CONTENT_MAPPER_EXPORT_EXCEL_SUCCEED',
  ContentMapperExportExcelFailed = 'CONTENT_MAPPER_EXPORT_EXCEL_FAILED'
}

export type UserPasswordForm = {
  password?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  newPasswordConfirmation?: Maybe<Scalars['String']>;
};

/** user profile data structure */
export type UserProfile = {
  __typename?: 'UserProfile';
  id?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Float']>;
  kit?: Maybe<PurchaseProductKit>;
  kits?: Maybe<Array<Maybe<PurchaseProductKit>>>;
  role?: Maybe<UserRole>;
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  quota?: Maybe<UserQuota>;
  gender?: Maybe<UserGender>;
  status?: Maybe<UserStatus>;
  setting?: Maybe<UserSetting>;
  birthday?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['Boolean']>;
  counselor?: Maybe<UserCounselor>;
  membership?: Maybe<UserMembership>;
  businessEmail?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  activatedAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  master?: Maybe<UserProfile>;
  allergies?: Maybe<HealthAllergyQueryResult>;
  background?: Maybe<HealthBackground>;
  information?: Maybe<HealthInformation>;
  medications?: Maybe<HealthMedicationQueryResult>;
  reportStatus?: Maybe<UserCustomerStatus>;
  report?: Maybe<UserProfileReport>;
};

export enum UserProfileCheckEmailAvailabilityQueryInclude {
  SubProfiles = 'SUB_PROFILES'
}

export type UserProfileCheckEmailAvailabilityQueryResult = {
  __typename?: 'UserProfileCheckEmailAvailabilityQueryResult';
  isProfileCreated?: Maybe<Scalars['Boolean']>;
  masterProfile?: Maybe<UserProfile>;
  subProfileIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  subProfileCount?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  subProfileCreationQuota?: Maybe<Scalars['Int']>;
};

export type UserProfileQueries = {
  __typename?: 'UserProfileQueries';
  findAll?: Maybe<UserProfileQueryResult>;
  switchProfile?: Maybe<Scalars['String']>;
  checkEmailAvailability?: Maybe<UserProfileCheckEmailAvailabilityQueryResult>;
  isPersonalInfoComplete?: Maybe<Scalars['Boolean']>;
};

export type UserProfileQueriesFindAllArgs = {
  where?: Maybe<UserProfileQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UserProfileQueriesSwitchProfileArgs = {
  form?: Maybe<SwitchUserProfileForm>;
};

export type UserProfileQueriesCheckEmailAvailabilityArgs = {
  email: Scalars['String'];
};

export type UserProfileQueryFilter = {
  email?: Maybe<Scalars['String']>;
  isMasterProfile?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  masterProfileId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserProfileQueryResult = {
  __typename?: 'UserProfileQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserProfile>>>;
};

export type UserProfileQuerySortBy = {
  name?: Maybe<SortDirection>;
};

export type UserProfileReport = {
  __typename?: 'UserProfileReport';
  id?: Maybe<Scalars['ID']>;
  dna?: Maybe<DnaReport>;
  isAncestryReady?: Maybe<Scalars['Boolean']>;
};

export type UserProfileReportReady = {
  __typename?: 'UserProfileReportReady';
  ANCESTRY?: Maybe<Scalars['Boolean']>;
  DNA_360?: Maybe<Scalars['Boolean']>;
  DNA_PREMIUM?: Maybe<Scalars['Boolean']>;
  NUTRIGENETIC?: Maybe<Scalars['Boolean']>;
};

/** user module's queries */
export type UserQueries = {
  __typename?: 'UserQueries';
  id?: Maybe<Scalars['ID']>;
  Counselor?: Maybe<UserCounselorQueries>;
  Customer?: Maybe<UserCustomerQueries>;
  Family?: Maybe<UserFamilyQueries>;
  Quota?: Maybe<UserQuotaQueries>;
  Report?: Maybe<UserReportQueries>;
  Notification?: Maybe<UserNotificationQueries>;
  Profile?: Maybe<UserProfileQueries>;
  SubProfile?: Maybe<UserSubProfileQueries>;
  findAll?: Maybe<UserQueryResult>;
  /** get user profile data of current session */
  profile?: Maybe<UserProfile>;
  shipment?: Maybe<UserShipment>;
  status?: Maybe<UserStatus>;
  sentPassword?: Maybe<Scalars['Date']>;
  latestSentTokenDate?: Maybe<Scalars['Date']>;
};

/** user module's queries */
export type UserQueriesFindAllArgs = {
  where?: Maybe<UserQueryFilter>;
  sort?: Maybe<UserQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

/** user module's queries */
export type UserQueriesStatusArgs = {
  username: Scalars['String'];
};

/** user module's queries */
export type UserQueriesSentPasswordArgs = {
  username: Scalars['String'];
};

/** user module's queries */
export type UserQueriesLatestSentTokenDateArgs = {
  username: Scalars['String'];
};

export type UserQueryFilter = {
  id?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  status?: Maybe<Array<Maybe<UserStatus>>>;
};

export type UserQueryResult = {
  __typename?: 'UserQueryResult';
  results?: Maybe<Array<Maybe<UserProfile>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserQuerySortBy = {
  name?: Maybe<SortDirection>;
  gender?: Maybe<SortDirection>;
  email?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  businessEmail?: Maybe<SortDirection>;
};

export type UserQuota = {
  __typename?: 'UserQuota';
  id?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  usage?: Maybe<Scalars['Int']>;
  payment?: Maybe<Payment>;
  profile?: Maybe<UserProfile>;
  expiredAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
};

export type UserQuotaForm = {
  usage?: Maybe<Scalars['Int']>;
};

export type UserQuotaMutations = {
  __typename?: 'UserQuotaMutations';
  edit?: Maybe<UserQuota>;
};

export type UserQuotaMutationsEditArgs = {
  id: Scalars['Int'];
  form?: Maybe<UserQuotaForm>;
};

export type UserQuotaQueries = {
  __typename?: 'UserQuotaQueries';
  findAll?: Maybe<UserQuotaQueryResult>;
  findOne?: Maybe<UserQuota>;
};

export type UserQuotaQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserQuotaQueryResult = {
  __typename?: 'UserQuotaQueryResult';
  results?: Maybe<Array<Maybe<UserQuota>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserReport = {
  __typename?: 'UserReport';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
  kit?: Maybe<PurchaseProductKit>;
  score?: Maybe<DnaScore>;
  trait?: Maybe<DnaTrait>;
  profile?: Maybe<UserProfile>;
  category?: Maybe<DnaCategory>;
};

export type UserReportByCategory = {
  __typename?: 'UserReportByCategory';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['Float']>;
  reports?: Maybe<Array<Maybe<UserReport>>>;
  response?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type UserReportByCategoryFilter = {
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
};

export type UserReportByCategoryQueryResult = {
  __typename?: 'UserReportByCategoryQueryResult';
  results?: Maybe<Array<Maybe<UserReportByCategory>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserReportByCategorySortBy = {
  name?: Maybe<SortDirection>;
  average?: Maybe<SortDirection>;
};

export type UserReportFilter = {
  traits?: Maybe<Array<Maybe<Scalars['Int']>>>;
  categories?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type UserReportQueries = {
  __typename?: 'UserReportQueries';
  findAll?: Maybe<UserReportQueryResult>;
  findByCategory?: Maybe<UserReportByCategoryQueryResult>;
  findOne?: Maybe<UserReport>;
};

export type UserReportQueriesFindAllArgs = {
  where?: Maybe<UserReportFilter>;
  sort?: Maybe<UserReportSortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserReportQueriesFindByCategoryArgs = {
  where?: Maybe<UserReportByCategoryFilter>;
  sort?: Maybe<UserReportByCategorySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type UserReportQueriesFindOneArgs = {
  id: Scalars['Int'];
};

export type UserReportQueryResult = {
  __typename?: 'UserReportQueryResult';
  results?: Maybe<Array<Maybe<UserReport>>>;
  count?: Maybe<Scalars['Int']>;
};

export type UserReportSortBy = {
  score?: Maybe<SortDirection>;
  trait?: Maybe<DnaTraitSortBy>;
  category?: Maybe<DnaCategorySortBy>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Patient = 'PATIENT',
  Counselor = 'COUNSELOR',
  Lab = 'LAB'
}

export type UserSetting = {
  __typename?: 'UserSetting';
  id?: Maybe<Scalars['String']>;
  playOpeningSound?: Maybe<Scalars['Boolean']>;
  playOpeningSoundAt?: Maybe<Scalars['Date']>;
};

export type UserSettingForm = {
  playOpeningSound?: Maybe<Scalars['Boolean']>;
  playOpeningSoundAt?: Maybe<Scalars['Date']>;
};

export type UserSettingMutations = {
  __typename?: 'UserSettingMutations';
  edit?: Maybe<UserSetting>;
};

export type UserSettingMutationsEditArgs = {
  form?: Maybe<UserSettingForm>;
};

export type UserShipment = {
  __typename?: 'UserShipment';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type UserShipmentForm = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  NotActive = 'NOT_ACTIVE',
  Deleted = 'DELETED'
}

export type UserSubProfileAddForm = {
  name: Scalars['String'];
  birthday: Scalars['String'];
  gender: UserGender;
  masterProfileId: Scalars['String'];
};

export type UserSubProfileMutations = {
  __typename?: 'UserSubProfileMutations';
  add?: Maybe<UserProfile>;
  delete?: Maybe<Scalars['Boolean']>;
  recover?: Maybe<Scalars['Boolean']>;
};

export type UserSubProfileMutationsAddArgs = {
  form?: Maybe<UserSubProfileAddForm>;
};

export type UserSubProfileMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type UserSubProfileMutationsRecoverArgs = {
  id: Scalars['String'];
};

export type UserSubProfileQueries = {
  __typename?: 'UserSubProfileQueries';
  findAll?: Maybe<UserSubProfileQueryResult>;
  findOne?: Maybe<UserProfile>;
};

export type UserSubProfileQueriesFindAllArgs = {
  where?: Maybe<UserSubProfileQueryFilter>;
  masterProfileId: Scalars['String'];
  sort?: Maybe<UserProfileQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type UserSubProfileQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type UserSubProfileQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
};

export type UserSubProfileQueryResult = {
  __typename?: 'UserSubProfileQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<UserProfile>>>;
};

export type Voucher = {
  __typename?: 'Voucher';
  id?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<VoucherStatus>;
  type?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<VoucherAmountType>;
  onetimeUse?: Maybe<Scalars['Boolean']>;
  linkToKitcode?: Maybe<Scalars['Boolean']>;
  kitcode?: Maybe<Scalars['String']>;
  category?: Maybe<VoucherCategory>;
  expiredAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  condition?: Maybe<VoucherCondition>;
  link?: Maybe<Scalars['String']>;
  bookPayments?: Maybe<UserCounselorBookPaymentQueryResult>;
  orderPayments?: Maybe<PurchasePaymentQueryResult>;
  lockedBy?: Maybe<UserProfile>;
  lockedAt?: Maybe<Scalars['Date']>;
  partnership?: Maybe<Partnership>;
};

export enum VoucherAmountType {
  Price = 'PRICE',
  Percentage = 'PERCENTAGE'
}

export enum VoucherCategory {
  Shop = 'SHOP',
  ShopUnlock = 'SHOP_UNLOCK',
  Consultation = 'CONSULTATION',
  WalkIn = 'WALK_IN',
  HomeService = 'HOME_SERVICE'
}

export type VoucherCondition = {
  __typename?: 'VoucherCondition';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<VoucherConditionType>;
  description?: Maybe<Scalars['String']>;
  payload?: Maybe<VoucherConditionPayload>;
};

export type VoucherConditionCounselorPayload = {
  __typename?: 'VoucherConditionCounselorPayload';
  id?: Maybe<Scalars['String']>;
  counselor?: Maybe<UserCounselor>;
};

export type VoucherConditionCounselorPayloadForm = {
  id?: Maybe<Scalars['Int']>;
};

export type VoucherConditionForm = {
  type?: Maybe<VoucherConditionType>;
  description?: Maybe<Scalars['String']>;
  payload?: Maybe<VoucherConditionPayloadForm>;
};

export type VoucherConditionMutations = {
  __typename?: 'VoucherConditionMutations';
  edit?: Maybe<VoucherCondition>;
};

export type VoucherConditionMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<VoucherConditionForm>;
};

export type VoucherConditionPayload = {
  __typename?: 'VoucherConditionPayload';
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<VoucherConditionProductPayload>>>;
  counselors?: Maybe<Array<Maybe<VoucherConditionCounselorPayload>>>;
};

export type VoucherConditionPayloadForm = {
  startedAt?: Maybe<Scalars['String']>;
  finishedAt?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<VoucherConditionProductPayloadForm>>>;
  counselors?: Maybe<Array<Maybe<VoucherConditionCounselorPayloadForm>>>;
};

export type VoucherConditionProductPayload = {
  __typename?: 'VoucherConditionProductPayload';
  id?: Maybe<Scalars['String']>;
  isUpsell?: Maybe<Scalars['Boolean']>;
  product?: Maybe<PurchaseProduct>;
};

export type VoucherConditionProductPayloadForm = {
  id?: Maybe<Scalars['String']>;
  isUpsell?: Maybe<Scalars['Boolean']>;
};

export enum VoucherConditionType {
  Users = 'USERS',
  ProductBuyer = 'PRODUCT_BUYER',
  Shop = 'SHOP',
  ShopUnlock = 'SHOP_UNLOCK',
  WalkIn = 'WALK_IN',
  HomeService = 'HOME_SERVICE',
  Consultation = 'CONSULTATION'
}

export type VoucherForm = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<VoucherType>;
  amount?: Maybe<Scalars['Float']>;
  amountType?: Maybe<VoucherAmountType>;
  onetimeUse?: Maybe<Scalars['Boolean']>;
  linkToKitcode?: Maybe<Scalars['Boolean']>;
  kitcode?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  category?: Maybe<VoucherCategory>;
  expiredAt?: Maybe<Scalars['Date']>;
  condition?: Maybe<VoucherConditionForm>;
  partnershipId?: Maybe<Scalars['String']>;
};

export type VoucherMutations = {
  __typename?: 'VoucherMutations';
  Condition?: Maybe<VoucherConditionMutations>;
  add?: Maybe<Voucher>;
  edit?: Maybe<Voucher>;
  delete?: Maybe<Scalars['Boolean']>;
  export?: Maybe<Scalars['String']>;
};

export type VoucherMutationsAddArgs = {
  form?: Maybe<VoucherForm>;
};

export type VoucherMutationsEditArgs = {
  id: Scalars['String'];
  form?: Maybe<VoucherForm>;
};

export type VoucherMutationsDeleteArgs = {
  id: Scalars['String'];
};

export type VoucherMutationsExportArgs = {
  where?: Maybe<VoucherQueryFilter>;
  sort?: Maybe<VoucherQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type VoucherQueries = {
  __typename?: 'VoucherQueries';
  id?: Maybe<Scalars['String']>;
  findAll?: Maybe<VoucherQueryResult>;
  findAllTypes?: Maybe<VoucherTypeQueryResult>;
  findOne?: Maybe<Voucher>;
  findByCode?: Maybe<Voucher>;
};

export type VoucherQueriesFindAllArgs = {
  where?: Maybe<VoucherQueryFilter>;
  sort?: Maybe<VoucherQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type VoucherQueriesFindAllTypesArgs = {
  where?: Maybe<VoucherTypeQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type VoucherQueriesFindOneArgs = {
  id: Scalars['String'];
};

export type VoucherQueriesFindByCodeArgs = {
  code: Scalars['String'];
  category?: Maybe<VoucherCategory>;
  productId?: Maybe<Array<Maybe<Scalars['String']>>>;
  counselorId?: Maybe<Scalars['Int']>;
  isUpsell?: Maybe<Scalars['Boolean']>;
};

export type VoucherQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<VoucherCategory>>>;
  status?: Maybe<Array<Maybe<VoucherStatus>>>;
  expiredAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnershipId?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VoucherQueryResult = {
  __typename?: 'VoucherQueryResult';
  results?: Maybe<Array<Maybe<Voucher>>>;
  count?: Maybe<Scalars['Int']>;
};

export type VoucherQuerySortBy = {
  code?: Maybe<SortDirection>;
  name?: Maybe<SortDirection>;
  type?: Maybe<SortDirection>;
  status?: Maybe<SortDirection>;
  amount?: Maybe<SortDirection>;
  category?: Maybe<SortDirection>;
  expiredAt?: Maybe<SortDirection>;
  createdAt?: Maybe<SortDirection>;
  updatedAt?: Maybe<SortDirection>;
};

export enum VoucherStatus {
  Expired = 'EXPIRED',
  Active = 'ACTIVE'
}

export enum VoucherType {
  Free = 'FREE',
  Discount = 'DISCOUNT',
  Partnership = 'PARTNERSHIP'
}

export type VoucherTypeQueryFilter = {
  keyword?: Maybe<Scalars['String']>;
};

export type VoucherTypeQueryResult = {
  __typename?: 'VoucherTypeQueryResult';
  count?: Maybe<Scalars['Int']>;
  results?: Maybe<Array<Maybe<VoucherTypeResult>>>;
};

export type VoucherTypeResult = {
  __typename?: 'VoucherTypeResult';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AddHealthArticleCategoryMutationVariables = Exact<{
  form?: Maybe<HealthArticleCategoryForm>;
}>;

export type AddHealthArticleCategoryMutation = { __typename?: 'Mutation' } & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryMutations' } & {
              add?: Maybe<
                { __typename?: 'HealthArticleCategory' } & Pick<
                  HealthArticleCategory,
                  'id'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type AddHealthArticleSubCategoryMutationVariables = Exact<{
  form?: Maybe<HealthArticleSubCategoryForm>;
}>;

export type AddHealthArticleSubCategoryMutation = {
  __typename?: 'Mutation';
} & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          SubCategory?: Maybe<
            { __typename?: 'HealthArticleSubCategoryMutations' } & {
              add?: Maybe<
                { __typename?: 'HealthArticleSubCategory' } & Pick<
                  HealthArticleSubCategory,
                  'id'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type GetHealthArticleCategoriesQueryVariables = Exact<{
  where?: Maybe<HealthArticleCategoryQueryFilter>;
  sort?: Maybe<HealthArticleCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetHealthArticleCategoriesQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'HealthArticleCategoryQueryResult' } & Pick<
                  HealthArticleCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'HealthArticleCategory' } & Pick<
                            HealthArticleCategory,
                            'id' | 'name'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type GetHealthArticleCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetHealthArticleCategoryQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryQueries' } & {
              findOne?: Maybe<
                { __typename?: 'HealthArticleCategory' } & Pick<
                  HealthArticleCategory,
                  'id' | 'name'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type EditHealthArticleCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  form?: Maybe<HealthArticleCategoryForm>;
}>;

export type EditHealthArticleCategoryMutation = { __typename?: 'Mutation' } & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryMutations' } & {
              edit?: Maybe<
                { __typename?: 'HealthArticleCategory' } & Pick<
                  HealthArticleCategory,
                  'id'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type EditHealthArticleSubCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  form?: Maybe<HealthArticleSubCategoryForm>;
}>;

export type EditHealthArticleSubCategoryMutation = {
  __typename?: 'Mutation';
} & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          SubCategory?: Maybe<
            { __typename?: 'HealthArticleSubCategoryMutations' } & {
              edit?: Maybe<
                { __typename?: 'HealthArticleSubCategory' } & Pick<
                  HealthArticleSubCategory,
                  'id'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type GetHealthArticleSubCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetHealthArticleSubCategoryQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          SubCategory?: Maybe<
            { __typename?: 'HealthArticleSubCategoryQueries' } & {
              findOne?: Maybe<
                { __typename?: 'HealthArticleSubCategory' } & Pick<
                  HealthArticleSubCategory,
                  'id' | 'name'
                > & {
                    category?: Maybe<
                      { __typename?: 'HealthArticleCategory' } & Pick<
                        HealthArticleCategory,
                        'id' | 'name'
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type GetHealthArticleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetHealthArticleQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          findOne?: Maybe<
            { __typename?: 'HealthArticle' } & Pick<
              HealthArticle,
              'id' | 'title' | 'content' | 'thumbnail' | 'createdAt'
            > & {
                category?: Maybe<
                  { __typename?: 'HealthArticleCategory' } & Pick<
                    HealthArticleCategory,
                    'id' | 'name'
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type FindAllNotificationQueryVariables = Exact<{
  where?: Maybe<UserNotificationQueryFilter>;
  sort?: Maybe<UserNotificationQuerySortBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;

export type FindAllNotificationQuery = { __typename?: 'Query' } & {
  User?: Maybe<
    { __typename?: 'UserQueries' } & Pick<UserQueries, 'id'> & {
        Notification?: Maybe<
          { __typename?: 'UserNotificationQueries' } & {
            findAll?: Maybe<
              { __typename?: 'UserNotificationQueryResult' } & Pick<
                UserNotificationQueryResult,
                'count'
              > & {
                  results?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'UserNotification' } & Pick<
                          UserNotification,
                          | 'id'
                          | 'href'
                          | 'type'
                          | 'title'
                          | 'unread'
                          | 'message'
                          | 'createdAt'
                        > & {
                            to?: Maybe<
                              { __typename?: 'UserProfile' } & Pick<
                                UserProfile,
                                'id' | 'name'
                              >
                            >;
                            from?: Maybe<
                              { __typename?: 'UserProfile' } & Pick<
                                UserProfile,
                                'id' | 'name'
                              >
                            >;
                          }
                      >
                    >
                  >;
                }
            >;
          }
        >;
      }
  >;
};

export type CountUnreadNotificationQueryVariables = Exact<{
  where?: Maybe<UserNotificationQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type CountUnreadNotificationQuery = { __typename?: 'Query' } & {
  User?: Maybe<
    { __typename?: 'UserQueries' } & Pick<UserQueries, 'id'> & {
        Notification?: Maybe<
          { __typename?: 'UserNotificationQueries' } & {
            findAll?: Maybe<
              { __typename?: 'UserNotificationQueryResult' } & Pick<
                UserNotificationQueryResult,
                'count'
              >
            >;
          }
        >;
      }
  >;
};

export type CountWaitingPickupConfirmationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CountWaitingPickupConfirmationQuery = { __typename?: 'Query' } & {
  Purchase?: Maybe<
    { __typename?: 'PurchaseQueries' } & {
      Product?: Maybe<
        { __typename?: 'PurchaseProductQueries' } & {
          Pickup?: Maybe<
            { __typename?: 'PurchaseProductPickupQueries' } & {
              findAll?: Maybe<
                { __typename?: 'PurchaseProductPickupQueryResult' } & Pick<
                  PurchaseProductPickupQueryResult,
                  'count'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type NewPickupRequestedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NewPickupRequestedSubscription = { __typename?: 'Subscription' } & {
  PurchaseProductPickUpRequested?: Maybe<
    { __typename?: 'PurchaseProductKit' } & Pick<PurchaseProductKit, 'id'>
  >;
};

export type PickupStatusUpdatedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type PickupStatusUpdatedSubscription = {
  __typename?: 'Subscription';
} & {
  PurchaseProductPickupHistoryUpdated?: Maybe<
    { __typename?: 'ProductKitPickupHistory' } & Pick<
      ProductKitPickupHistory,
      'id'
    >
  >;
};

export type KitsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type KitsQuery = { __typename?: 'Query' } & {
  Country?: Maybe<
    { __typename?: 'CountryQueries' } & Pick<CountryQueries, 'provinces'>
  >;
  Purchase?: Maybe<
    { __typename?: 'PurchaseQueries' } & Pick<PurchaseQueries, 'id'> & {
        Product?: Maybe<
          { __typename?: 'PurchaseProductQueries' } & {
            Kit?: Maybe<
              { __typename?: 'PurchaseProductKitQueries' } & {
                findOne?: Maybe<
                  { __typename?: 'PurchaseProductKit' } & Pick<
                    PurchaseProductKit,
                    | 'id'
                    | 'code'
                    | 'createdAt'
                    | 'updatedAt'
                    | 'printedAt'
                    | 'activatedAt'
                    | 'requestPickupDate'
                    | 'attachment'
                  > & {
                      pickupShipment?: Maybe<
                        { __typename?: 'PickupKitShipment' } & Pick<
                          PickupKitShipment,
                          | 'id'
                          | 'firstName'
                          | 'lastName'
                          | 'email'
                          | 'phone'
                          | 'address'
                          | 'district'
                          | 'city'
                          | 'province'
                          | 'postcode'
                          | 'shipmentCode'
                          | 'status'
                          | 'waybill'
                        >
                      >;
                      activation?: Maybe<
                        { __typename?: 'PurchaseProductActivation' } & Pick<
                          PurchaseProductActivation,
                          'id' | 'code'
                        >
                      >;
                      activatedUser?: Maybe<
                        { __typename?: 'UserProfile' } & Pick<
                          UserProfile,
                          'id' | 'name' | 'email'
                        >
                      >;
                    }
                >;
              }
            >;
          }
        >;
      }
  >;
};

export type GetNewsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetNewsQuery = { __typename?: 'Query' } & {
  News?: Maybe<
    { __typename?: 'NewsQueries' } & Pick<NewsQueries, 'id'> & {
        findOne?: Maybe<
          { __typename?: 'News' } & Pick<
            News,
            | 'id'
            | 'title'
            | 'public'
            | 'content'
            | 'thumbnail'
            | 'link'
            | 'createdAt'
          >
        >;
      }
  >;
};

export type OrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OrderQuery = { __typename?: 'Query' } & {
  Country?: Maybe<
    { __typename?: 'CountryQueries' } & Pick<CountryQueries, 'provinces'>
  >;
  Purchase?: Maybe<
    { __typename?: 'PurchaseQueries' } & Pick<PurchaseQueries, 'id'> & {
        Order?: Maybe<
          { __typename?: 'PurchaseOrderQueries' } & {
            findOne?: Maybe<
              { __typename?: 'PurchaseOrder' } & Pick<
                PurchaseOrder,
                | 'id'
                | 'code'
                | 'total'
                | 'type'
                | 'status'
                | 'discount'
                | 'buyOneGetOneDiscount'
                | 'promoDiscount'
                | 'subTotal'
                | 'createdAt'
                | 'updatedAt'
                | 'attachment'
                | 'purchaseType'
                | 'preOrderFullfillmentDate'
                | 'isUpsell'
                | 'source'
                | 'receivedAt'
                | 'deliveredAt'
              > & {
                  shipment?: Maybe<
                    { __typename?: 'PurchaseOrderShipment' } & Pick<
                      PurchaseOrderShipment,
                      | 'id'
                      | 'type'
                      | 'firstName'
                      | 'lastName'
                      | 'email'
                      | 'phone'
                      | 'address'
                      | 'city'
                      | 'district'
                      | 'province'
                      | 'postcode'
                      | 'waybill'
                      | 'shipmentCode'
                    > & {
                        courier?: Maybe<
                          {
                            __typename?: 'PurchaseOrderShipmentCourier';
                          } & Pick<
                            PurchaseOrderShipmentCourier,
                            'id' | 'name' | 'thumbnail'
                          >
                        >;
                      }
                  >;
                  payment?: Maybe<
                    { __typename?: 'PurchaseOrderPayment' } & Pick<
                      PurchaseOrderPayment,
                      'id' | 'referenceId'
                    > & {
                        voucher?: Maybe<
                          { __typename?: 'Voucher' } & Pick<
                            Voucher,
                            'id' | 'code' | 'amount' | 'amountType'
                          >
                        >;
                      }
                  >;
                  invoice?: Maybe<
                    { __typename?: 'PurchaseOrderInvoice' } & Pick<
                      PurchaseOrderInvoice,
                      'invoiceNumber' | 'attachment'
                    >
                  >;
                  items?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'PurchaseOrderItem' } & Pick<
                          PurchaseOrderItem,
                          'id' | 'quantity'
                        > & {
                            product?: Maybe<
                              { __typename?: 'PurchaseProduct' } & Pick<
                                PurchaseProduct,
                                'id' | 'title' | 'price'
                              > & {
                                  discount?: Maybe<
                                    {
                                      __typename?: 'PurchaseProductDiscount';
                                    } & Pick<
                                      PurchaseProductDiscount,
                                      'id' | 'amount' | 'percentage'
                                    >
                                  >;
                                  upsell?: Maybe<
                                    {
                                      __typename?: 'PurchaseProductUpsell';
                                    } & Pick<
                                      PurchaseProductUpsell,
                                      | 'id'
                                      | 'totalPrice'
                                      | 'promoPrice'
                                      | 'totalDiscount'
                                    >
                                  >;
                                }
                            >;
                          }
                      >
                    >
                  >;
                  profile?: Maybe<
                    { __typename?: 'UserProfile' } & Pick<
                      UserProfile,
                      'firstName' | 'lastName' | 'email' | 'phone'
                    >
                  >;
                }
            >;
          }
        >;
      }
  >;
};

export type AddDnaPersonalizedReportMutationVariables = Exact<{
  forms?: Maybe<Array<Maybe<DnaPersonalizedReportForm>>>;
}>;

export type AddDnaPersonalizedReportMutation = { __typename?: 'Mutation' } & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      PersonalizedReport?: Maybe<
        { __typename?: 'DnaPersonalizedReportMutations' } & {
          createOrUpdate?: Maybe<
            Array<
              Maybe<
                { __typename?: 'DnaPersonalizedReport' } & Pick<
                  DnaPersonalizedReport,
                  'id'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type AddDnaPersonalizedReportCategoryMutationVariables = Exact<{
  forms?: Maybe<Array<Maybe<DnaPersonalizedReportCategoryForm>>>;
}>;

export type AddDnaPersonalizedReportCategoryMutation = {
  __typename?: 'Mutation';
} & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      PersonalizedReport?: Maybe<
        { __typename?: 'DnaPersonalizedReportMutations' } & {
          Category?: Maybe<
            { __typename?: 'DnaPersonalizedReportCategoryMutations' } & {
              createOrUpdate?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'DnaPersonalizedReportCategory' } & Pick<
                      DnaPersonalizedReportCategory,
                      'id'
                    >
                  >
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type AddDnaPersonalizedReportPageMutationVariables = Exact<{
  forms?: Maybe<Array<Maybe<DnaPersonalizedReportPageForm>>>;
}>;

export type AddDnaPersonalizedReportPageMutation = {
  __typename?: 'Mutation';
} & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      PersonalizedReport?: Maybe<
        { __typename?: 'DnaPersonalizedReportMutations' } & {
          Page?: Maybe<
            { __typename?: 'DnaPersonalizedReportPageMutations' } & {
              createOrUpdate?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'DnaPersonalizedReportPage' } & Pick<
                      DnaPersonalizedReportPage,
                      'id'
                    >
                  >
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type DeleteDnaPersonalizedReportCategoryMutationVariables = Exact<{
  forms: Array<Maybe<DnaPersonalizedReportCategoryDelete>>;
}>;

export type DeleteDnaPersonalizedReportCategoryMutation = {
  __typename?: 'Mutation';
} & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      PersonalizedReport?: Maybe<
        { __typename?: 'DnaPersonalizedReportMutations' } & {
          Category?: Maybe<
            { __typename?: 'DnaPersonalizedReportCategoryMutations' } & Pick<
              DnaPersonalizedReportCategoryMutations,
              'delete'
            >
          >;
        }
      >;
    }
  >;
};

export type DeleteDnaPersonalizedReportPageMutationVariables = Exact<{
  forms: Array<Maybe<DnaPersonalizedReportPageDelete>>;
}>;

export type DeleteDnaPersonalizedReportPageMutation = {
  __typename?: 'Mutation';
} & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      PersonalizedReport?: Maybe<
        { __typename?: 'DnaPersonalizedReportMutations' } & {
          Page?: Maybe<
            { __typename?: 'DnaPersonalizedReportPageMutations' } & Pick<
              DnaPersonalizedReportPageMutations,
              'delete'
            >
          >;
        }
      >;
    }
  >;
};

export type FindInteractomeQueryVariables = Exact<{
  where?: Maybe<DnaInteractomeQueryFilter>;
  sort?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type FindInteractomeQuery = { __typename?: 'Query' } & {
  DNA?: Maybe<
    { __typename?: 'DNAQueries' } & {
      Interactome?: Maybe<
        { __typename?: 'DnaInteractomeQueries' } & {
          findAll?: Maybe<
            { __typename?: 'DnaInteractomeQueryResult' } & Pick<
              DnaInteractomeQueryResult,
              'count'
            > & {
                results?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'DnaInteractome' } & Pick<
                        DnaInteractome,
                        'id' | 'isActive' | 'position' | 'dnaTraitId'
                      > & {
                          dTrait?: Maybe<
                            { __typename?: 'DnaTrait' } & Pick<
                              DnaTrait,
                              'id' | 'name'
                            >
                          >;
                          dInteractomeCard?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'Card' } & Pick<
                                  Card,
                                  | 'id'
                                  | 'dnaInteractomeId'
                                  | 'title'
                                  | 'imageUrl'
                                  | 'language'
                                  | 'position'
                                >
                              >
                            >
                          >;
                          dInteractomeRiskTrait?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'RiskTrait' } & Pick<
                                  RiskTrait,
                                  | 'id'
                                  | 'dnaInteractomeId'
                                  | 'position'
                                  | 'dnaTraitId'
                                > & {
                                    dTrait?: Maybe<
                                      { __typename?: 'DnaTrait' } & Pick<
                                        DnaTrait,
                                        'id' | 'name'
                                      > & {
                                          translation?: Maybe<
                                            {
                                              __typename?: 'DnaTraitTranslation';
                                            } & Pick<
                                              DnaTraitTranslation,
                                              | 'id'
                                              | 'name'
                                              | 'description'
                                              | 'langCode'
                                            >
                                          >;
                                        }
                                    >;
                                  }
                              >
                            >
                          >;
                          dInteractomeComplicationTrait?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'ComplicationTrait' } & Pick<
                                  ComplicationTrait,
                                  | 'id'
                                  | 'dnaInteractomeId'
                                  | 'position'
                                  | 'dnaTraitId'
                                > & {
                                    dTrait?: Maybe<
                                      { __typename?: 'DnaTrait' } & Pick<
                                        DnaTrait,
                                        'id' | 'name'
                                      > & {
                                          translation?: Maybe<
                                            {
                                              __typename?: 'DnaTraitTranslation';
                                            } & Pick<
                                              DnaTraitTranslation,
                                              | 'id'
                                              | 'name'
                                              | 'description'
                                              | 'langCode'
                                            >
                                          >;
                                        }
                                    >;
                                  }
                              >
                            >
                          >;
                          dInteractomeCounselor?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'InteractomeCounselor' } & Pick<
                                  InteractomeCounselor,
                                  'id' | 'dnaInteractomeId'
                                > & {
                                    counselor?: Maybe<
                                      { __typename?: 'UserCounselor' } & Pick<
                                        UserCounselor,
                                        'id' | 'licenseNumber'
                                      > & {
                                          profile?: Maybe<
                                            {
                                              __typename?: 'UserProfile';
                                            } & Pick<
                                              UserProfile,
                                              | 'id'
                                              | 'name'
                                              | 'photo'
                                              | 'email'
                                              | 'phone'
                                            >
                                          >;
                                          profession?: Maybe<
                                            {
                                              __typename?: 'UserCounselorProfession';
                                            } & Pick<
                                              UserCounselorProfession,
                                              'id' | 'name'
                                            >
                                          >;
                                          educations?: Maybe<
                                            {
                                              __typename?: 'UserCounselorEducationQueryResult';
                                            } & {
                                              results?: Maybe<
                                                Array<
                                                  Maybe<
                                                    {
                                                      __typename?: 'UserCounselorEducation';
                                                    } & Pick<
                                                      UserCounselorEducation,
                                                      'id' | 'name'
                                                    >
                                                  >
                                                >
                                              >;
                                            }
                                          >;
                                          expertises?: Maybe<
                                            {
                                              __typename?: 'UserCounselorExpertiseQueryResult';
                                            } & {
                                              results?: Maybe<
                                                Array<
                                                  Maybe<
                                                    {
                                                      __typename?: 'UserCounselorExpertise';
                                                    } & Pick<
                                                      UserCounselorExpertise,
                                                      'id' | 'name'
                                                    >
                                                  >
                                                >
                                              >;
                                            }
                                          >;
                                        }
                                    >;
                                  }
                              >
                            >
                          >;
                          dInteractomeArticle?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'InteractomeArticle' } & Pick<
                                  InteractomeArticle,
                                  'id' | 'articleId' | 'dnaInteractomeId'
                                > & {
                                    article?: Maybe<
                                      { __typename?: 'HealthArticle' } & Pick<
                                        HealthArticle,
                                        | 'id'
                                        | 'title'
                                        | 'thumbnail'
                                        | 'content'
                                        | 'createdAt'
                                      > & {
                                          category?: Maybe<
                                            {
                                              __typename?: 'HealthArticleCategory';
                                            } & Pick<
                                              HealthArticleCategory,
                                              'id' | 'name'
                                            >
                                          >;
                                          subCategories?: Maybe<
                                            Array<
                                              Maybe<
                                                {
                                                  __typename?: 'HealthArticleSubCategory';
                                                } & Pick<
                                                  HealthArticleSubCategory,
                                                  'id' | 'name'
                                                >
                                              >
                                            >
                                          >;
                                        }
                                    >;
                                  }
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type AddDnaPanelFigurePinMutationVariables = Exact<{
  forms: Array<Maybe<DnaPanelFigurePinForm>>;
}>;

export type AddDnaPanelFigurePinMutation = { __typename?: 'Mutation' } & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      Panel?: Maybe<
        { __typename?: 'DnaPanelMutations' } & {
          DnaPanelFigure?: Maybe<
            { __typename?: 'DnaPanelFigureMutations' } & {
              DnaPanelFigurePin?: Maybe<
                { __typename?: 'DnaPanelFigurePinMutations' } & {
                  createOrUpdate?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'DnaPanelFigurePin' } & Pick<
                          DnaPanelFigurePin,
                          'id'
                        >
                      >
                    >
                  >;
                }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type PanelFigureFindAllDnaCategoryQueryVariables = Exact<{
  where?: Maybe<DnaCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaCategorySortBy>;
}>;

export type PanelFigureFindAllDnaCategoryQuery = { __typename?: 'Query' } & {
  DNA?: Maybe<
    { __typename?: 'DNAQueries' } & {
      Category?: Maybe<
        { __typename?: 'DnaCategoryQueries' } & {
          findAll?: Maybe<
            { __typename?: 'DnaCategoryQueryResult' } & Pick<
              DnaCategoryQueryResult,
              'count'
            > & {
                results?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'DnaCategory' } & Pick<
                        DnaCategory,
                        'id' | 'name' | 'reportType'
                      > & {
                          translation?: Maybe<
                            { __typename?: 'DnaCategoryTranslation' } & Pick<
                              DnaCategoryTranslation,
                              'id' | 'name'
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type FindAllDnaPanelQueryVariables = Exact<{
  where?: Maybe<DnaPanelQueryFilter>;
  sort?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type FindAllDnaPanelQuery = { __typename?: 'Query' } & {
  DNA?: Maybe<
    { __typename?: 'DNAQueries' } & {
      Panel?: Maybe<
        { __typename?: 'DnaPanelQueries' } & {
          findAll?: Maybe<
            { __typename?: 'DnaPanelQueryResult' } & Pick<
              DnaPanelQueryResult,
              'count'
            > & {
                results?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'DnaPanel' } & Pick<
                        DnaPanel,
                        | 'id'
                        | 'name'
                        | 'favourableDescription'
                        | 'unfavourableDescription'
                        | 'description'
                      > & {
                          dPanelTranslation?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'DnaPanelTranslation' } & Pick<
                                  DnaPanelTranslation,
                                  | 'id'
                                  | 'language'
                                  | 'name'
                                  | 'description'
                                  | 'favourableDescription'
                                  | 'unfavourableDescription'
                                >
                              >
                            >
                          >;
                          dPanelFigure?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'DnaPanelFigure' } & Pick<
                                  DnaPanelFigure,
                                  'id' | 'dnaPanelId' | 'gender' | 'imageUrl'
                                > & {
                                    dPanelFigurePin?: Maybe<
                                      Array<
                                        Maybe<
                                          {
                                            __typename?: 'DnaPanelFigurePin';
                                          } & Pick<
                                            DnaPanelFigurePin,
                                            'id' | 'lat' | 'long' | 'position'
                                          > & {
                                              dPanelPinCategory?: Maybe<
                                                Array<
                                                  Maybe<
                                                    {
                                                      __typename?: 'DnaPanelPinFigureCategory';
                                                    } & Pick<
                                                      DnaPanelPinFigureCategory,
                                                      'id' | 'position'
                                                    > & {
                                                        dCategory?: Maybe<
                                                          {
                                                            __typename?: 'DnaCategory';
                                                          } & Pick<
                                                            DnaCategory,
                                                            'id' | 'name'
                                                          >
                                                        >;
                                                      }
                                                  >
                                                >
                                              >;
                                            }
                                        >
                                      >
                                    >;
                                  }
                              >
                            >
                          >;
                          dCategory?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'DnaCategory' } & Pick<
                                  DnaCategory,
                                  'id' | 'name'
                                >
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type UpdateDnaPanelFigureMutationVariables = Exact<{
  forms: Array<Maybe<DnaPanelFigureForm>>;
}>;

export type UpdateDnaPanelFigureMutation = { __typename?: 'Mutation' } & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      Panel?: Maybe<
        { __typename?: 'DnaPanelMutations' } & {
          DnaPanelFigure?: Maybe<
            { __typename?: 'DnaPanelFigureMutations' } & {
              createOrUpdate?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'DnaPanelFigure' } & Pick<
                      DnaPanelFigure,
                      'id'
                    >
                  >
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type AddDnaScoreTypeGuidelineResponseMutationVariables = Exact<{
  forms: Array<Maybe<DnaScoreTypeGuidelineResponseForm>>;
}>;

export type AddDnaScoreTypeGuidelineResponseMutation = {
  __typename?: 'Mutation';
} & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      ScoreType?: Maybe<
        { __typename?: 'DnaScoreTypeMutations' } & {
          DnaScoreTypeGuideline?: Maybe<
            { __typename?: 'DnaScoreTypeGuidelineMutations' } & {
              DnaScoreTypeGuidelineResponse?: Maybe<
                { __typename?: 'DnaScoreTypeGuidelineResponseMutations' } & {
                  createOrUpdate?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'DnaScoreTypeGuidelineResponse' } & Pick<
                          DnaScoreTypeGuidelineResponse,
                          'id'
                        >
                      >
                    >
                  >;
                }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type AddDnaScoreTypeGuidelineMutationVariables = Exact<{
  forms: Array<Maybe<DnaScoreTypeGuidelineForm>>;
}>;

export type AddDnaScoreTypeGuidelineMutation = { __typename?: 'Mutation' } & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      ScoreType?: Maybe<
        { __typename?: 'DnaScoreTypeMutations' } & {
          DnaScoreTypeGuideline?: Maybe<
            { __typename?: 'DnaScoreTypeGuidelineMutations' } & {
              createOrUpdate?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'DnaScoreTypeGuideline' } & Pick<
                      DnaScoreTypeGuideline,
                      | 'id'
                      | 'dnaScoreTypeId'
                      | 'from'
                      | 'to'
                      | 'response'
                      | 'mappedScore'
                      | 'riskLevel'
                    >
                  >
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type AddDnaScoreTypeMutationVariables = Exact<{
  forms: Array<Maybe<DnaScoreTypeForm>>;
}>;

export type AddDnaScoreTypeMutation = { __typename?: 'Mutation' } & {
  DNA?: Maybe<
    { __typename?: 'DNAMutations' } & {
      ScoreType?: Maybe<
        { __typename?: 'DnaScoreTypeMutations' } & {
          createOrUpdate?: Maybe<
            Array<
              Maybe<{ __typename?: 'DnaScoreType' } & Pick<DnaScoreType, 'id'>>
            >
          >;
        }
      >;
    }
  >;
};

export type ThreesixtyDnaCategoryFindAllDnaCategoryQueryVariables = Exact<{
  where?: Maybe<DnaCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaCategorySortBy>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type ThreesixtyDnaCategoryFindAllDnaCategoryQuery = {
  __typename?: 'Query';
} & {
  DNA?: Maybe<
    { __typename?: 'DNAQueries' } & {
      Category?: Maybe<
        { __typename?: 'DnaCategoryQueries' } & {
          findAll?: Maybe<
            { __typename?: 'DnaCategoryQueryResult' } & Pick<
              DnaCategoryQueryResult,
              'count'
            > & {
                results?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'DnaCategory' } & Pick<
                        DnaCategory,
                        'id' | 'name' | 'reportType'
                      > & {
                          translation?: Maybe<
                            { __typename?: 'DnaCategoryTranslation' } & Pick<
                              DnaCategoryTranslation,
                              'id' | 'name'
                            >
                          >;
                          dnaSubCategory?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: 'DnaSubCategory' } & Pick<
                                  DnaSubCategory,
                                  'id' | 'name'
                                >
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type ThreesixtySubCategoryFindAllDnaCategoryQueryVariables = Exact<{
  where?: Maybe<DnaSubCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  sortObject?: Maybe<DnaSubCategoryQuerySortBy>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type ThreesixtySubCategoryFindAllDnaCategoryQuery = {
  __typename?: 'Query';
} & {
  DNA?: Maybe<
    { __typename?: 'DNAQueries' } & {
      Category?: Maybe<
        { __typename?: 'DnaCategoryQueries' } & {
          DnaSubCategory?: Maybe<
            { __typename?: 'DnaSubCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'DnaSubCategoryQueryResult' } & Pick<
                  DnaSubCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'DnaSubCategory' } & Pick<
                            DnaSubCategory,
                            'id' | 'name' | 'dnaCategoryId'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type FindAllDnaCategoryQueryVariables = Exact<{
  where?: Maybe<DnaCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<DnaCategorySortBy>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type FindAllDnaCategoryQuery = { __typename?: 'Query' } & {
  DNA?: Maybe<
    { __typename?: 'DNAQueries' } & {
      Category?: Maybe<
        { __typename?: 'DnaCategoryQueries' } & {
          findAll?: Maybe<
            { __typename?: 'DnaCategoryQueryResult' } & Pick<
              DnaCategoryQueryResult,
              'count'
            > & {
                results?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'DnaCategory' } & Pick<
                        DnaCategory,
                        'id' | 'name' | 'reportType'
                      > & {
                          translation?: Maybe<
                            { __typename?: 'DnaCategoryTranslation' } & Pick<
                              DnaCategoryTranslation,
                              'id' | 'name'
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type FindAllDnaSubCategoryQueryVariables = Exact<{
  where?: Maybe<DnaSubCategoryQueryFilter>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  include?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;

export type FindAllDnaSubCategoryQuery = { __typename?: 'Query' } & {
  DNA?: Maybe<
    { __typename?: 'DNAQueries' } & {
      Category?: Maybe<
        { __typename?: 'DnaCategoryQueries' } & {
          DnaSubCategory?: Maybe<
            { __typename?: 'DnaSubCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'DnaSubCategoryQueryResult' } & Pick<
                  DnaSubCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'DnaSubCategory' } & Pick<
                            DnaSubCategory,
                            'id' | 'name' | 'dnaCategoryId'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type AddHealthArticleMutationVariables = Exact<{
  form?: Maybe<HealthArticleForm>;
}>;

export type AddHealthArticleMutation = { __typename?: 'Mutation' } & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          add?: Maybe<
            { __typename?: 'HealthArticle' } & Pick<HealthArticle, 'id'>
          >;
        }
      >;
    }
  >;
};

export type ArticleAddGetHealthArticleCategoriesQueryVariables = Exact<{
  where?: Maybe<HealthArticleCategoryQueryFilter>;
  sort?: Maybe<HealthArticleCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type ArticleAddGetHealthArticleCategoriesQuery = {
  __typename?: 'Query';
} & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'HealthArticleCategoryQueryResult' } & Pick<
                  HealthArticleCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'HealthArticleCategory' } & Pick<
                            HealthArticleCategory,
                            'id' | 'name'
                          > & {
                              subcategories?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'HealthArticleSubCategory';
                                    } & Pick<
                                      HealthArticleSubCategory,
                                      'id' | 'name' | 'categoryId'
                                    >
                                  >
                                >
                              >;
                            }
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type GetHealthArticleSubCategoriesQueryVariables = Exact<{
  where?: Maybe<HealthArticleSubCategoryQueryFilter>;
  sort?: Maybe<HealthArticleSubCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetHealthArticleSubCategoriesQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          SubCategory?: Maybe<
            { __typename?: 'HealthArticleSubCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'HealthArticleSubCategoryQueryResult' } & Pick<
                  HealthArticleSubCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'HealthArticleSubCategory' } & Pick<
                            HealthArticleSubCategory,
                            'id' | 'name'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type ArchiveHealthArticleMutationVariables = Exact<{
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;

export type ArchiveHealthArticleMutation = { __typename?: 'Mutation' } & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          archive?: Maybe<
            { __typename?: 'HealthArticle' } & Pick<HealthArticle, 'id'>
          >;
        }
      >;
    }
  >;
};

export type GetHealthArticlesQueryVariables = Exact<{
  where?: Maybe<HealthArticleQueryFilter>;
  sort?: Maybe<HealthArticleQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetHealthArticlesQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          findAll?: Maybe<
            { __typename?: 'HealthArticleQueryResult' } & Pick<
              HealthArticleQueryResult,
              'count'
            > & {
                results?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'HealthArticle' } & Pick<
                        HealthArticle,
                        | 'id'
                        | 'title'
                        | 'content'
                        | 'thumbnail'
                        | 'createdAt'
                        | 'isArchived'
                        | 'nViewers'
                      > & {
                          category?: Maybe<
                            { __typename?: 'HealthArticleCategory' } & Pick<
                              HealthArticleCategory,
                              'id' | 'name'
                            >
                          >;
                          subCategories?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'HealthArticleSubCategory';
                                } & Pick<
                                  HealthArticleSubCategory,
                                  'id' | 'name'
                                >
                              >
                            >
                          >;
                          relatedSubCategories?: Maybe<
                            Array<
                              Maybe<
                                {
                                  __typename?: 'HealthArticleSubCategory';
                                } & Pick<
                                  HealthArticleSubCategory,
                                  'id' | 'name'
                                >
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type ArticleGetHealthArticleCategoriesQueryVariables = Exact<{
  where?: Maybe<HealthArticleCategoryQueryFilter>;
  sort?: Maybe<HealthArticleCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type ArticleGetHealthArticleCategoriesQuery = {
  __typename?: 'Query';
} & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'HealthArticleCategoryQueryResult' } & Pick<
                  HealthArticleCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'HealthArticleCategory' } & Pick<
                            HealthArticleCategory,
                            'id' | 'name'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type ArticleCategoryGetHealthArticleCategoriesQueryVariables = Exact<{
  where?: Maybe<HealthArticleCategoryQueryFilter>;
  sort?: Maybe<HealthArticleCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type ArticleCategoryGetHealthArticleCategoriesQuery = {
  __typename?: 'Query';
} & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'HealthArticleCategoryQueryResult' } & Pick<
                  HealthArticleCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'HealthArticleCategory' } & Pick<
                            HealthArticleCategory,
                            'id' | 'name' | 'isAssociated'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type DeleteHealthArticleCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteHealthArticleCategoryMutation = {
  __typename?: 'Mutation';
} & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryMutations' } & Pick<
              HealthArticleCategoryMutations,
              'delete'
            >
          >;
        }
      >;
    }
  >;
};

export type DeleteHealthArticleMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteHealthArticleMutation = { __typename?: 'Mutation' } & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & Pick<
          HealthArticleMutations,
          'delete'
        >
      >;
    }
  >;
};

export type GetHealthArticleForEditQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetHealthArticleForEditQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          findOne?: Maybe<
            { __typename?: 'HealthArticle' } & Pick<
              HealthArticle,
              | 'id'
              | 'title'
              | 'content'
              | 'thumbnail'
              | 'createdAt'
              | 'slug'
              | 'metaKeyphrase'
              | 'metaTitle'
              | 'metaDescription'
            > & {
                category?: Maybe<
                  { __typename?: 'HealthArticleCategory' } & Pick<
                    HealthArticleCategory,
                    'id' | 'name'
                  > & {
                      subcategories?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'HealthArticleSubCategory' } & Pick<
                              HealthArticleSubCategory,
                              'id' | 'name' | 'categoryId'
                            >
                          >
                        >
                      >;
                    }
                >;
                subCategories?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'HealthArticleSubCategory' } & Pick<
                        HealthArticleSubCategory,
                        'id' | 'name'
                      >
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type HealthArticleEditGetHealthArticleCategoriesQueryVariables = Exact<{
  where?: Maybe<HealthArticleCategoryQueryFilter>;
  sort?: Maybe<HealthArticleCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type HealthArticleEditGetHealthArticleCategoriesQuery = {
  __typename?: 'Query';
} & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          Category?: Maybe<
            { __typename?: 'HealthArticleCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'HealthArticleCategoryQueryResult' } & Pick<
                  HealthArticleCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'HealthArticleCategory' } & Pick<
                            HealthArticleCategory,
                            'id' | 'name'
                          > & {
                              subcategories?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'HealthArticleSubCategory';
                                    } & Pick<
                                      HealthArticleSubCategory,
                                      'id' | 'name' | 'categoryId'
                                    >
                                  >
                                >
                              >;
                            }
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type EditHealthArticleMutationVariables = Exact<{
  id: Scalars['Int'];
  form?: Maybe<HealthArticleForm>;
}>;

export type EditHealthArticleMutation = { __typename?: 'Mutation' } & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          edit?: Maybe<
            { __typename?: 'HealthArticle' } & Pick<HealthArticle, 'id'>
          >;
        }
      >;
    }
  >;
};

export type DeleteHealthArticleSubCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteHealthArticleSubCategoryMutation = {
  __typename?: 'Mutation';
} & {
  Health?: Maybe<
    { __typename?: 'HealthMutations' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleMutations' } & {
          SubCategory?: Maybe<
            { __typename?: 'HealthArticleSubCategoryMutations' } & Pick<
              HealthArticleSubCategoryMutations,
              'delete'
            >
          >;
        }
      >;
    }
  >;
};

export type GetHealthSubArticleCategoriesQueryVariables = Exact<{
  where?: Maybe<HealthArticleSubCategoryQueryFilter>;
  sort?: Maybe<HealthArticleSubCategoryQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetHealthSubArticleCategoriesQuery = { __typename?: 'Query' } & {
  Health?: Maybe<
    { __typename?: 'HealthQueries' } & {
      Article?: Maybe<
        { __typename?: 'HealthArticleQueries' } & {
          SubCategory?: Maybe<
            { __typename?: 'HealthArticleSubCategoryQueries' } & {
              findAll?: Maybe<
                { __typename?: 'HealthArticleSubCategoryQueryResult' } & Pick<
                  HealthArticleSubCategoryQueryResult,
                  'count'
                > & {
                    results?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'HealthArticleSubCategory' } & Pick<
                            HealthArticleSubCategory,
                            'id' | 'name' | 'isAssociated'
                          > & {
                              category?: Maybe<
                                { __typename?: 'HealthArticleCategory' } & Pick<
                                  HealthArticleCategory,
                                  'id' | 'name'
                                >
                              >;
                            }
                        >
                      >
                    >;
                  }
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type CheckEmailAvailabilityQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type CheckEmailAvailabilityQuery = { __typename?: 'Query' } & {
  User?: Maybe<
    { __typename?: 'UserQueries' } & {
      Profile?: Maybe<
        { __typename?: 'UserProfileQueries' } & {
          checkEmailAvailability?: Maybe<
            {
              __typename?: 'UserProfileCheckEmailAvailabilityQueryResult';
            } & Pick<
              UserProfileCheckEmailAvailabilityQueryResult,
              | 'isProfileCreated'
              | 'subProfileCreationQuota'
              | 'subProfileIds'
              | 'message'
            > & {
                masterProfile?: Maybe<
                  { __typename?: 'UserProfile' } & Pick<UserProfile, 'id'>
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type CheckKitActivationAvailabilityQueryVariables = Exact<{
  where?: Maybe<KitCheckActivationAvailabilityQuery>;
}>;

export type CheckKitActivationAvailabilityQuery = { __typename?: 'Query' } & {
  Purchase?: Maybe<
    { __typename?: 'PurchaseQueries' } & {
      Product?: Maybe<
        { __typename?: 'PurchaseProductQueries' } & {
          Activation?: Maybe<
            { __typename?: 'PurchaseProductActivationQueries' } & {
              checkKitActivationAvailability?: Maybe<
                { __typename?: 'KitCheckActivationAvailabilityResult' } & Pick<
                  KitCheckActivationAvailabilityResult,
                  'ableToActivate' | 'message' | 'errorCode'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type FindAllProfileQueryVariables = Exact<{
  where?: Maybe<UserProfileQueryFilter>;
}>;

export type FindAllProfileQuery = { __typename?: 'Query' } & {
  User?: Maybe<
    { __typename?: 'UserQueries' } & {
      Profile?: Maybe<
        { __typename?: 'UserProfileQueries' } & {
          findAll?: Maybe<
            { __typename?: 'UserProfileQueryResult' } & Pick<
              UserProfileQueryResult,
              'count'
            > & {
                results?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'UserProfile' } & Pick<
                        UserProfile,
                        | 'id'
                        | 'name'
                        | 'firstName'
                        | 'lastName'
                        | 'email'
                        | 'birthday'
                        | 'gender'
                      > & {
                          master?: Maybe<
                            { __typename?: 'UserProfile' } & Pick<
                              UserProfile,
                              | 'id'
                              | 'name'
                              | 'email'
                              | 'firstName'
                              | 'lastName'
                              | 'birthday'
                              | 'gender'
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        }
      >;
    }
  >;
};

export type ActivateKitManuallyMutationVariables = Exact<{
  form?: Maybe<KitActivateKitManuallyForm>;
}>;

export type ActivateKitManuallyMutation = { __typename?: 'Mutation' } & {
  Purchase?: Maybe<
    { __typename?: 'PurchaseMutations' } & {
      Product?: Maybe<
        { __typename?: 'PurchaseProductMutations' } & {
          Activation?: Maybe<
            { __typename?: 'PurchaseProductActivationMutations' } & {
              activateKitManually?: Maybe<
                { __typename?: 'ProcessResult' } & Pick<
                  ProcessResult,
                  'success'
                >
              >;
            }
          >;
        }
      >;
    }
  >;
};

export type AddNewsMutationVariables = Exact<{
  form?: Maybe<NewsForm>;
}>;

export type AddNewsMutation = { __typename?: 'Mutation' } & {
  News?: Maybe<
    { __typename?: 'NewsMutations' } & {
      add?: Maybe<{ __typename?: 'News' } & Pick<News, 'id'>>;
    }
  >;
};

export type DeleteNewsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteNewsMutation = { __typename?: 'Mutation' } & {
  News?: Maybe<
    { __typename?: 'NewsMutations' } & Pick<NewsMutations, 'delete'>
  >;
};

export type EditNewsMutationVariables = Exact<{
  id: Scalars['Int'];
  form?: Maybe<NewsForm>;
}>;

export type EditNewsMutation = { __typename?: 'Mutation' } & {
  News?: Maybe<
    { __typename?: 'NewsMutations' } & {
      edit?: Maybe<{ __typename?: 'News' } & Pick<News, 'id'>>;
    }
  >;
};

export type GetNewsForEditQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type GetNewsForEditQuery = { __typename?: 'Query' } & {
  News?: Maybe<
    { __typename?: 'NewsQueries' } & {
      findOne?: Maybe<
        { __typename?: 'News' } & Pick<
          News,
          | 'id'
          | 'title'
          | 'public'
          | 'content'
          | 'thumbnail'
          | 'link'
          | 'createdAt'
        >
      >;
    }
  >;
};

export type GetAllNewsQueryVariables = Exact<{
  where?: Maybe<NewsQueryFilter>;
  sort?: Maybe<NewsQuerySortBy>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type GetAllNewsQuery = { __typename?: 'Query' } & {
  News?: Maybe<
    { __typename?: 'NewsQueries' } & Pick<NewsQueries, 'id'> & {
        findAll?: Maybe<
          { __typename?: 'NewsQueryResult' } & Pick<
            NewsQueryResult,
            'count'
          > & {
              results?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'News' } & Pick<
                      News,
                      | 'id'
                      | 'title'
                      | 'public'
                      | 'content'
                      | 'thumbnail'
                      | 'link'
                      | 'createdAt'
                    >
                  >
                >
              >;
            }
        >;
      }
  >;
};
