import Vue from 'vue';
import { isObject, isNil } from 'lodash';

/**
 *  Pipe for transforming currency value.
 */
Vue.filter('decimal', function (
  value: any,
  config?: {
    locale?: string;
  }
) {
  if (isNaN(Number(value))) return '';

  if (isObject(config) === false) {
    config = {};
  }
  if (isNil(config.locale)) {
    config.locale = 'id-ID';
  }

  return new Intl.NumberFormat(config.locale, {
    style: 'decimal',
    minimumFractionDigits: 0
  }).format(value);
});
