import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/voucher/index.ts'), 'voucher/index.ts')
  resolveStoreModules(require('../store/user/index.ts'), 'user/index.ts')
  resolveStoreModules(require('../store/sku/index.ts'), 'sku/index.ts')
  resolveStoreModules(require('../store/sales/index.ts'), 'sales/index.ts')
  resolveStoreModules(require('../store/reswab/index.ts'), 'reswab/index.ts')
  resolveStoreModules(require('../store/promotion/index.ts'), 'promotion/index.ts')
  resolveStoreModules(require('../store/product/index.ts'), 'product/index.ts')
  resolveStoreModules(require('../store/partnership/index.ts'), 'partnership/index.ts')
  resolveStoreModules(require('../store/partner/index.ts'), 'partner/index.ts')
  resolveStoreModules(require('../store/order/index.ts'), 'order/index.ts')
  resolveStoreModules(require('../store/news/index.ts'), 'news/index.ts')
  resolveStoreModules(require('../store/mcu/index.ts'), 'mcu/index.ts')
  resolveStoreModules(require('../store/kit/index.ts'), 'kit/index.ts')
  resolveStoreModules(require('../store/homeservice/index.ts'), 'homeservice/index.ts')
  resolveStoreModules(require('../store/glossary/index.ts'), 'glossary/index.ts')
  resolveStoreModules(require('../store/dashboard/index.ts'), 'dashboard/index.ts')
  resolveStoreModules(require('../store/customer/index.ts'), 'customer/index.ts')
  resolveStoreModules(require('../store/counselor/index.ts'), 'counselor/index.ts')
  resolveStoreModules(require('../store/article/index.ts'), 'article/index.ts')
  resolveStoreModules(require('../store/appointment/index.ts'), 'appointment/index.ts')
  resolveStoreModules(require('../store/voucher/tracking/index.ts'), 'voucher/tracking/index.ts')
  resolveStoreModules(require('../store/sportgenomic/report-mapper/index.ts'), 'sportgenomic/report-mapper/index.ts')
  resolveStoreModules(require('../store/sku/traits/index.ts'), 'sku/traits/index.ts')
  resolveStoreModules(require('../store/sku/subcategory/index.ts'), 'sku/subcategory/index.ts')
  resolveStoreModules(require('../store/sku/score/index.ts'), 'sku/score/index.ts')
  resolveStoreModules(require('../store/sku/panel/index.ts'), 'sku/panel/index.ts')
  resolveStoreModules(require('../store/sku/mapper/index.ts'), 'sku/mapper/index.ts')
  resolveStoreModules(require('../store/sku/interactome/index.ts'), 'sku/interactome/index.ts')
  resolveStoreModules(require('../store/sku/category/index.ts'), 'sku/category/index.ts')
  resolveStoreModules(require('../store/promotion/referral/index.ts'), 'promotion/referral/index.ts')
  resolveStoreModules(require('../store/promotion/bundling/index.ts'), 'promotion/bundling/index.ts')
  resolveStoreModules(require('../store/promotion/banner/index.ts'), 'promotion/banner/index.ts')
  resolveStoreModules(require('../store/product/type/index.ts'), 'product/type/index.ts')
  resolveStoreModules(require('../store/product/report/index.ts'), 'product/report/index.ts')
  resolveStoreModules(require('../store/product/category/index.ts'), 'product/category/index.ts')
  resolveStoreModules(require('../store/prodigy/traits/index.ts'), 'prodigy/traits/index.ts')
  resolveStoreModules(require('../store/prodigy/score/index.ts'), 'prodigy/score/index.ts')
  resolveStoreModules(require('../store/prodigy/panel/index.ts'), 'prodigy/panel/index.ts')
  resolveStoreModules(require('../store/prodigy/mapper/index.ts'), 'prodigy/mapper/index.ts')
  resolveStoreModules(require('../store/prodigy/localization/index.ts'), 'prodigy/localization/index.ts')
  resolveStoreModules(require('../store/prodigy/category/index.ts'), 'prodigy/category/index.ts')
  resolveStoreModules(require('../store/partnership/detail/index.ts'), 'partnership/detail/index.ts')
  resolveStoreModules(require('../store/partner/role/index.ts'), 'partner/role/index.ts')
  resolveStoreModules(require('../store/partner/order/index.ts'), 'partner/order/index.ts')
  resolveStoreModules(require('../store/partner/bank/index.ts'), 'partner/bank/index.ts')
  resolveStoreModules(require('../store/nutrigenetic/report-mapper/index.ts'), 'nutrigenetic/report-mapper/index.ts')
  resolveStoreModules(require('../store/media/podcast/index.ts'), 'media/podcast/index.ts')
  resolveStoreModules(require('../store/media/music/index.ts'), 'media/music/index.ts')
  resolveStoreModules(require('../store/mcu/patient/index.ts'), 'mcu/patient/index.ts')
  resolveStoreModules(require('../store/kit/batch/index.ts'), 'kit/batch/index.ts')
  resolveStoreModules(require('../store/dna360/traits/index.ts'), 'dna360/traits/index.ts')
  resolveStoreModules(require('../store/dna360/subcategory/index.ts'), 'dna360/subcategory/index.ts')
  resolveStoreModules(require('../store/dna360/score/index.ts'), 'dna360/score/index.ts')
  resolveStoreModules(require('../store/dna360/panel/index.ts'), 'dna360/panel/index.ts')
  resolveStoreModules(require('../store/dna360/mapper/index.ts'), 'dna360/mapper/index.ts')
  resolveStoreModules(require('../store/dna360/interactome/index.ts'), 'dna360/interactome/index.ts')
  resolveStoreModules(require('../store/dna360/category/index.ts'), 'dna360/category/index.ts')
  resolveStoreModules(require('../store/dna/pdf-report/index.ts'), 'dna/pdf-report/index.ts')
  resolveStoreModules(require('../store/counselor/profession/index.ts'), 'counselor/profession/index.ts')
  resolveStoreModules(require('../store/counselor/performance/index.ts'), 'counselor/performance/index.ts')
  resolveStoreModules(require('../store/asaxperience/merchant/index.ts'), 'asaxperience/merchant/index.ts')
  resolveStoreModules(require('../store/article/subcategory/index.ts'), 'article/subcategory/index.ts')
  resolveStoreModules(require('../store/article/category/index.ts'), 'article/category/index.ts')
  resolveStoreModules(require('../store/appointment/upcoming/index.ts'), 'appointment/upcoming/index.ts')
  resolveStoreModules(require('../store/appointment/rating/index.ts'), 'appointment/rating/index.ts')
  resolveStoreModules(require('../store/appointment/feedback/index.ts'), 'appointment/feedback/index.ts')
  resolveStoreModules(require('../store/appointment/consultation/index.ts'), 'appointment/consultation/index.ts')
  resolveStoreModules(require('../store/analytic/tracking/index.ts'), 'analytic/tracking/index.ts')
  resolveStoreModules(require('../store/sku/interactome/traits/index.ts'), 'sku/interactome/traits/index.ts')
  resolveStoreModules(require('../store/product/report/purchase/index.ts'), 'product/report/purchase/index.ts')
  resolveStoreModules(require('../store/partnership/report/category/index.ts'), 'partnership/report/category/index.ts')
  resolveStoreModules(require('../store/master/dictionary/content/index.ts'), 'master/dictionary/content/index.ts')
  resolveStoreModules(require('../store/master/data/product-type/index.ts'), 'master/data/product-type/index.ts')
  resolveStoreModules(require('../store/ehr/lab/result-type/index.ts'), 'ehr/lab/result-type/index.ts')
  resolveStoreModules(require('../store/dna360/interactome/traits/index.ts'), 'dna360/interactome/traits/index.ts')
  resolveStoreModules(require('../store/asaxperience/merchant/voucher/index.ts'), 'asaxperience/merchant/voucher/index.ts')
  resolveStoreModules(require('../store/asaxperience/merchant/category/index.ts'), 'asaxperience/merchant/category/index.ts')
  resolveStoreModules(require('../store/appointment/consultation/add/index.ts'), 'appointment/consultation/add/index.ts')
  resolveStoreModules(require('../store/partnership/report/category/view/index.ts'), 'partnership/report/category/view/index.ts')
  resolveStoreModules(require('../store/master/data/report/traits/index.ts'), 'master/data/report/traits/index.ts')
  resolveStoreModules(require('../store/master/data/report/mapper/index.ts'), 'master/data/report/mapper/index.ts')
  resolveStoreModules(require('../store/master/data/report/category/index.ts'), 'master/data/report/category/index.ts')
  resolveStoreModules(require('../store/asaxperience/merchant/voucher/tracking/index.ts'), 'asaxperience/merchant/voucher/tracking/index.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
