import { get, replace, some, trim, upperCase } from 'lodash';
import envmodules, { MODULE } from '../../config';

/**
 * @description Guardian a̶n̶g̶e̶l̶ of modules.
 * @export
 * @param {string} mod Desired module.
 * @param {*} usemodules Registered modules.
 * @param {*} components Key of components.
 * @returns {*}
 */
export default function (
  mod: string | MODULE,
  usemodules?: Array<string>,
  components?: string
): boolean {
  let enable = true;

  if (mod) {
    if (usemodules) {
      enable = some(
        usemodules || [],
        (v) => upperCase(trim(v)) === upperCase(trim(mod))
      );
    }

    if (envmodules) {
      const key = replace(mod, /\.|(~>)|(->)|(=>)/g, '.modules.').concat(
        components
          ? '.components.'.concat(
              replace(components, /\.|(~>)|(->)|(=>)/g, '.')
            )
          : ''
      );
      const module = get(envmodules, key);
      if (typeof module === 'boolean') {
        enable = enable && module === true;
      } else if (typeof module?.show === 'boolean') {
        enable = enable && module.show === true;
      } else {
        enable = false;
      }
    }
  }

  return enable;
}
