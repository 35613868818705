import Vue from 'vue';

Vue.filter('shipmentType', function (value: string) {
  switch (value) {
    case 'REGULAR':
      return 'Regular Shipping';
    case 'HOME_SERVICE':
      return 'Home Service';
    case 'WALK_IN':
      return 'Walk-in';
    default:
      return value;
  }
});
