import Vue from 'vue';

Vue.filter('replace', function (
  value: string,
  searchValue: string,
  replacer: string
) {
  if (typeof value !== 'string') value = '';
  return value.replace(new RegExp(searchValue, 'i'), replacer);
});
