import { MutationTree } from 'vuex';

export const state = () => ({
  id: null
});

export type PartnerOrderState = ReturnType<typeof state>;

export const mutations: MutationTree<PartnerOrderState> = {
  changeId: (state, id) => (state.id = id)
};
