import { Context } from '@nuxt/types';
import { some } from 'lodash';
import modules from '../config';

export default function (context: Context) {
  let access = context.$access('');

  if (process.server) return;

  const path = context.route.path;
  const whitelist = ['/', '/login', '/logout'];

  if (whitelist.some((v) => v === path)) access = true;
  else {
    for (const key in modules) {
      const value = modules[key];
      if (value.href === path) {
        access = context.$access(key);
        break;
      }

      for (const page of value.pages || []) {
        if (page.href === path) {
          if (some(page.roles || [], (o) => o === 'EDITOR')) {
            access = context.$access(key) && context.$editor();
          } else {
            access = context.$access(key);
          }
          break;
        }
      }

      for (const subkey in value.modules || {}) {
        const subvalue = value.modules[subkey];
        if (subvalue.href === path) {
          access = context.$access([key, subkey].join('->'));
          break;
        }

        for (const page of subvalue.pages || []) {
          if (page.href === path) {
            if (some(page.roles || [], (o) => o === 'EDITOR')) {
              access =
                context.$access([key, subkey].join('->')) && context.$editor();
            } else {
              access = context.$access([key, subkey].join('->'));
            }
            break;
          }
        }

        for (const subsubkey in subvalue.modules || {}) {
          const subsubvalue = subvalue.modules[subsubkey];
          if (subsubvalue.href === path) {
            access = context.$access([key, subkey, subsubkey].join('->'));
            break;
          }

          for (const page of subsubvalue.pages || []) {
            if (page.href === path) {
              if (some(page.roles || [], (o) => o === 'EDITOR')) {
                access =
                  context.$access([key, subkey, subsubkey].join('->')) &&
                  context.$editor();
              } else {
                access = context.$access([key, subkey, subsubkey].join('->'));
              }
              break;
            }
          }
        }
      }
    }
  }

  if (access !== true) {
    context.error({
      statusCode: 401,
      message: 'You are not allowed to access this page'
    });
  }
}
